import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import CustomInput from "../../../Components/CustomInput/index.web";

const Password = ({user, setUser}) => {
    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:10}}>
            If this is a new user, you can leave the password field blank and a password will be generated for this user, they will also receive an email with their login credentials. If this is an existing user, you can reset their password here, they will not receive an email.
        </Text>
        <CustomInput secure title={'Password'} value={user.password} onChangeText={e => setUser({...user, password: e})} containerStyle={{marginBottom:10}} />
        </>
    )
}

export default Password;