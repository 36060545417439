import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import CustomInput from "../../../Components/CustomInput/index.web";

const NameAndEmail = ({user, setUser}) => {
    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:10}} >
            Set up the users name and email address. The email address is used for logging in and must be unique.
        </Text>

        <CustomInput onChangeText={e => setUser({...user, name: e})} value={user.name} title={'Name'} containerStyle={{marginBottom:10}} />
        <CustomInput onChangeText={e => setUser({...user, email: e})} value={user.email} title={'Email Address'} />

        </>
    )
}

export default NameAndEmail;