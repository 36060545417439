/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import { StyleSheet, View, ActivityIndicator, Dimensions } from 'react-native';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';

// import { Bubbles, } from 'react-native-loader';
import AppColor from '../../Constants/Color';
const Loader = (props) => {
  return (
    <>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          backgroundColor: props.backgroundColor,
          zIndex: 999,
          position: 'absolute',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
        {/* <Bubbles size={10} color={AppColor.PrimaryColor}  /> */}
        <ActivityIndicator
          size={Dimensions.get('window').width * 0.05}
          color={AppColor.PrimaryColor}
        />
      </View>
    </>
  );
};
Loader.defaultProps = {
  backgroundColor: 'rgba(0,0,0,0.5)',
};

export default Loader;
