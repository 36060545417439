import React from 'react';
import {View, Text, TouchableOpacity, FlatList} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import AntDesign from 'react-native-vector-icons/AntDesign';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useToast } from 'native-base';
import { deleteDistribution } from '../../Redux/distributions';
import Loader from '../../Components/Loader';
import { Icon } from 'react-native-elements';


const DistributionListView = ({navigation, search}) => {
    const contactData = useSelector((state) => state.distributions).data;
    const [loading, setLoading] = React.useState(false);
    const Toast = useToast();
    const dispatch = useDispatch();

    const handleDelete = (id) => {
        setLoading(true);
        dispatch(deleteDistribution(id)).then((x) => {
          setLoading(false);
          if (!x.error) {
            Toast.show({
              duration: 2000,
              title: 'Successfully deleted',
              status: 'success',
            });
          }
        });
      };

      
    const renderListView = ({ item }) => {
        return (
            <TouchableOpacity
            accessibilityRole='button'
              onPress={() => {
                navigation.navigate('DistributionDetail', { dist: item })
            }}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:'space-between',
                width:'100%',
              }}>
              <View style={{flexDirection:'row',alignItems:'center'}}>
                <AntDesign name="tags" size={40} color={AppColor.PrimaryColor} />
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 17,
                    marginHorizontal: 10,
                    color: AppColor.Dark,
                    lineHeight: 60,
                  }}>
                  {item.text}
                </Text>
              </View>
              <View style={{flexDirection:'row',alignItems:'center',gap:10,justifyContent:'flex-end'}}>
                
                <Icon onPress={() => {
                   navigation.navigate('Messages', { dl: item });
                }} name='message1' type='antdesign' size={25} color={'black'} />

                <AntDesign
              accessible={true}
              accessibilityRole='button'
              accessibilityLabel='Trash'
              accessibilityHint='Delete distribution list.'
              onPress={() => handleDelete(item.id)}
              name="delete"
              size={25}
              color={AppColor.danger}
              />
            </View>
            </TouchableOpacity>

        );
      };
      const filteredData = contactData.filter(e => e?.text?.toLowerCase().includes(search?.toLowerCase()));

    return(
        <View style={{flex:1}}>
            <FlatList
            data={filteredData }
            keyExtractor={(item, index) => item.id + '' + index}
            renderItem={renderListView}
            />
            {loading && <Loader/>}

        </View>
    )
}

export default DistributionListView;