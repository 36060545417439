import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contact: '',
  email: '',
  password: '',
  password2: '',
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: {
    phone: '',
    countryCode: '+1',
  },
  groupCode: null,
};
const signUpDataFormSlice = createSlice({
  name: 'signupdata',
  initialState: initialState,
  reducers: {
    setSignUpData: (state, action) => {
		console.log("data:",action.payload.data);
      return { ...state, ...action.payload.data };
    },
  },
});

export const { setSignUpData } = signUpDataFormSlice.actions;

export default signUpDataFormSlice.reducer;
