import React from "react"
import { Text, View } from "react-native"
import AppFonts from "../../Constants/Fonts";

const DrawerLabel = props => {
    return (
      <View style={{
        ...props.style
      }
      }>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 16,
              color: props.focused ? 'white' : 'black',
              marginLeft: -20,
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            {props.label}
          </Text>
          
        </View>
      </View>
    )
}

export default DrawerLabel;