import React from 'react';
import { Text, View } from 'react-native';
import CustomButton from '../../../../Components/Button';
import AppFonts from '../../../../Constants/Fonts';
import AppColors from '../../../../Constants/Color';
import IntegrationComponent from './IntegrationComponent';

export default function NoopComponent({
  provider,
  integration,
  allowContactEdits,
}) {
  let label =
    'Login to the web application to setup the initial configuration for this ARCHIVE.';
  let text = 'Login to ' + provider?.label;
  return (
    <View style={{ marginHorizontal: 15 }}>
      <Text
        style={{
          color: AppColors.Dark,
          fontSize: 18,
          marginBottom: 10,
          fontFamily: AppFonts.Roboto_Regular,
          letterSpacing: 1,
        }}>
        {label}
      </Text>
      <IntegrationComponent provider={provider} integration={integration} />
    </View>
  );
}
