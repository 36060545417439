import { Box, useToast } from 'native-base';
import React from 'react';
import { useState } from 'react';
import {
  Text,
  View,
  Platform,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from 'react-native';
import Alert from 'Alert';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'rc-time-picker';
import { Icon } from 'react-native-elements';

import CustomModal from '../../Components/DropDown';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import 'rc-time-picker/assets/index.css';
import useLogEvent from '../../Middleware/useLogEvent';

const moment = require('moment');

const ScheduleContactMessage = ({ navigation, route }) => {
  const [message, setMessage] = React.useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [frequency, setFrequency] = React.useState(0);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(moment());
  const [mode, setMode] = useState('date');
  const [contact, setContact] = React.useState(null);
  const [dl, setdl] = React.useState(null);
  const [show, setShow] = useState(false);
  const [time_zone, setTimeZone] = useState(null);
  const [messages, setMessages] = useState([]);
  const Toast = useToast();
  const logEvent = useLogEvent();
  
  const frequencylist = [
    {
      label: 'Just once',
      value: 0,
    },
    {
      label: 'Daily',
      value: 1,
    },
    {
      label: 'Weekly',
      value: 2,
    },
    {
      label: 'Monthly',
      value: 3,
    },
    {
      label: 'Annually',
      value: 4,
    },
    {
      label: 'Quarterly',
      value: 5,
    },
    {
      label: 'Semi-Annually',
      value: 6,
    },
  ];
  React.useEffect(() => {
    const subscribe = navigation.addListener('focus', () => {
      setMessage('')
      setFrequency(0);
      setTime(moment());
      setDate(new Date());
      if (route.params?.member) {
        setContact(route.params.member);
        loadScheduleMessages(route.params.member.id);
      } else if(route.params?.memberData){
        setContact(route.params?.memberData)
        loadScheduleMessages(route.params?.memberData.id)
      }
      if (route.params?.dl) {
        setdl(route.params?.dl);
      }
      if (route.params?.template_message) {
        setMessage(route.params.template_message);
        if(route.params.deliveryDate) setDate(new Date(route.params.deliveryDate));
        if(route.params.time) setTime(moment(route.params.time));
        if(route.params.frequency) setFrequency(route.params.frequency);
        }
    })

    return subscribe;
 
  }, [route]);

  const loadScheduleMessages = async (id) => {
    try {
      let body = {
        action: 'get_scheduled_messages',
      };
      if (dl == null) {
        body['contact'] = id;
      } else {
        body['distributionList'] = dl.id;
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('ScheduleContactMessage', result);
      if (result.data.success) {
        setMessages(result.data.messages);
      } else {
        // Alert.alert("Error", "Server error")
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleSave = async () => {
    const mergedDate = moment(
      moment(date).format('MM/DD/YYYY') + ' ' + moment(time).format('hh:mm a')
    ).format()

    if (message.trim() != '') {
      setLoading(true);
      try {
        let body = {
          deliveryDate: new Date(mergedDate).getTime(),
          message: message,
          frequency: frequency,
          timezone: time_zone,
        };
        if (dl == null) {
          body['contact'] = contact.id;
        } else {
          body['distributionList'] = dl.id;
        }
        body['action'] = 'create_scheduled_message';
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.data.success) {
          logEvent('message_scheduled');
          Toast.show({
            duration: 2000,
            title: 'New Schedule message has been saved.',
            status: 'success',
          });
          // navigation.goBack();
          navigation.navigate('Messages', {
            message: 'clear',
            template_message: '',
            memberData: route.params.memberData
          });
        } else {
          Alert.alert('Error', 'Server error');
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      Toast.show({
        duration: 2000,
        title: 'Message must not be empty.',
        status: 'warning',
      });
    }
  };
  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === 'ios');
    setDate(currentDate);
  };
  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };
  const showDatepicker = () => {
    showMode('date');
  };

  const showTimepicker = () => {
    showMode('time');
  };
  const getTimeZone = async () => {
    // const timeZone = await TimeZone.getTimeZone().then(zone => zone);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  };
  React.useEffect(() => {
    getTimeZone();
  }, []);

  return (
    <ScrollView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Schedule Message'}
        back={true}
        onPress={() => {
          // navigation.goBack()
          navigation.navigate('Messages', {
            message: '',
            template_message: '',
            memberData: null
          });
        }}
      />
      <Box flex={1}>
        <View style={{ height: 20 }}></View>
        <Text
          style={{
            width: '90%',
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 16,
            color: AppColor.Dark,
          }}>
          Schedule this message for delivery by choosing the date,time and
          frequency you would like it delivered.
        </Text>
        <View>
          <Text
            style={{
              width: '90%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              color: AppColor.PrimaryColor,
              padding: 10,
            }}>
            Date*
          </Text>
        </View>
        <View style={{ alignItems: 'center', zIndex: 9 }}>
          <View
            style={{
              width: '90%',
              zIndex: 9,
            }}>
            <DateTimePicker
              calendarType="US"
              onChange={setDate}
              value={date}
              calendarIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
        </View>
        <View>
          <Text
            style={{
              width: '90%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Bold,
              color: AppColor.PrimaryColor,
              padding: 10,
            }}>
            Time*
          </Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View
            style={{
              width: '90%',
            }}>
            <TimePicker
              value={time}
              onChange={(val) => setTime(val)}
              showSecond={false}
              minuteStep={15}
              use12Hours={true}
              inputIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'back-in-time'}
                  type={'entypo'}
                />
              }
              clearIcon={null}
            />
          </View>
        </View>
        <Text
          style={{
            width: '90%',
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Bold,
            color: AppColor.PrimaryColor,
            padding: 10,
          }}>
          Frequency*
        </Text>
        <View style={{ width: '90%', alignSelf: 'center' }}>
          <CustomModal
            place={'Select Here'}
            value={frequency}
            fun={(v) => setFrequency(v)}
            data={frequencylist}
          />
        </View>
        <View style={{ height: 20 }}></View>
        <View
          style={{
            width: '90%',
            alignSelf: 'center',
            marginHorizontal: 35,
            borderBottomWidth: 1,
            borderBottomColor: AppColor.PrimaryColor,
          }}>
          <Text
            style={{
              marginHorizontal: 15,
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 15,
              color: AppColor.PrimaryColor,
              marginBottom: 10,
            }}>
            Message*
          </Text>
          <TextInput
            style={{ paddingVertical: 10 }}
            multiline={true}
            value={message}
            onChangeText={(t) => setMessage(t)}
            title={'Message*'}
            placeholder={'Type Something'}
          />
        </View>
        {/* <CustomInput textInputStyle={{maxHeight:500,minHeight:50}} containerStyle={{maxHeight:500,minHeight:50}} multiline={true} value={message} onChangeText={t => setMessage(t)} title={'Message*'} placeholder={'Type Something'} /> */}
        <View style={{ height: 10 }}></View>
        <View style={{ width: '90%', alignSelf: 'center' }}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('SelectTemplates', {
                screen: 'ScheduleContactMessage',
                deliveryDate: date,
                time: time,
                frequency: frequency
              });
            }}
            style={{
              borderBottomColor: AppColor.PrimaryColor,
              borderBottomWidth: 1,
              width: 120,
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                color: AppColor.PrimaryColor,
              }}>
              Select Template
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ height: 20 }}></View>
        <CustomButton
          onPress={() => {
            handleSave();
          }}
          title={'Schedule'}
        />
        <View style={{ height: 20 }}></View>
        <View style={{ marginHorizontal: 20 }}>
          <Text
            style={{
              width: '90%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 16,
              color: AppColor.PrimaryColor,
            }}>
            Scheduled Messages:
          </Text>
          {messages.map((item) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  if (dl != null) {
                  } else {
                    navigation.navigate('AddScheduledMessage', {
                      item: {
                        ...item,
                        fname: contact.fname,
                        lname: contact.lname,
                      },
                      loadMonthData: () => loadScheduleMessages(contact.id),
                    });
                  }
                }}
                style={{
                  width: '90%',
                  alignSelf: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  height: 65,
                  marginTop: 10,
                }}
                key={item.id}>
                <View style={{ justifyContent: 'center' }}>
                  <Icon
                    type={'MaterialIcons'}
                    name={'timer'}
                    style={{ color: AppColor.PrimaryColor, fontSize: 35 }}
                  />
                </View>
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Bold,
                      fontSize: 17,
                      marginHorizontal: 10,
                      color: 'black',
                    }}
                    numberOfLines={1}>
                    {item.fname} {item.lname}
                  </Text>
                  <Text
                    style={{
                      fontFamily: AppFonts.Roboto_Regular,
                      fontSize: 14,
                      lineHeight: 20,
                      marginHorizontal: 10,
                      color: AppColor.Dark,
                    }}
                    numberOfLines={1}>
                    {item.message}
                  </Text>
                </View>
                <View style={{ justifyContent: 'center' }}>
                  <Text style={{ color: AppColor.Dark, fontSize: 12 }}>
                    {moment(item.deliveryDate).format('DD/MM/YYYY')}
                  </Text>
                  <Text style={{ color: AppColor.Dark, fontSize: 12 }}>
                    {moment(item.deliveryDate).format('hh:mm a')}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })}
        </View>
      </Box>
      {loading && <Loader />}
    </ScrollView>
  );
};
export default ScheduleContactMessage;
