import { Checkbox } from "native-base";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { CheckBox } from "react-native-elements";
import AppFonts from "../Constants/Fonts";


const SimpleCheckbox = ({value,onChange,label,style, disabled}) => {
    const checked = value ? value : false;
    const handleChange = () => onChange(!checked);

    return(
        <TouchableOpacity onPress={!disabled ? handleChange : null} style={{flexDirection:'row',alignItems:'center',marginLeft:0,paddingLeft:0, ...style}}>
        <CheckBox onPress={!disabled ? handleChange : null} wrapperStyle={{marginLeft:0,paddingLeft:0}} textStyle={{marginLeft:0,paddingLeft:0}} style={{marginLeft:0,paddingLeft:0}} containerStyle={{marginLeft:0,paddingLeft:0}} checked={checked} />
        <Text style={{marginLeft:0, fontFamily:AppFonts.Roboto_Regular}}>{label}</Text>
        </TouchableOpacity>
    )
}

export default SimpleCheckbox;