import React from "react";
import { Text, View } from "react-native";
import AccountsDropDownMenu from "./AccountsDropDownMenu";
import useResponsiveLayout from "../Middleware/useResponsiveLayout";

const DrawerHeader = ({onLogout, onFeedback}) => {
    const {isWebCompact} = useResponsiveLayout();
    return(
        <View style={{flexDirection:'row',alignItems:'center',gap:10}}>
{!isWebCompact &&        <Text onPress={onFeedback} style={{cursor:'pointer',color:'white',marginRight:10,padding:10,borderWidth:1,borderColor:'white',borderRadius:5}}>
          Feedback
        </Text>
}        <AccountsDropDownMenu handleLogout={onLogout} />
        </View>

    )
}

export default DrawerHeader;