import React from 'react';
import { View, ScrollView, Image, Pressable, } from 'react-native';
import Modal from 'modal-react-native-web';
import Search from '../Search';
import { Icon } from 'react-native-elements';
import CustomButton from '../Button/index.web';
import { giphySearch, giphyTrending } from '../../Api/ten4';

const GifModal = React.memo(props => {
    const [search, setSearch] = React.useState('');
    const [gifs, setGifs] = React.useState([]);

    React.useEffect(()=>{
        getTrendingGifs()
    },[])

    const getTrendingGifs = () => {
        giphyTrending({limit:20,offset:0})
        .then(result => {
            if(result.data.success){
                const data = [];
                Object.keys(result.data.data).forEach(e => {
                    data.push(result.data.data[e].images.fixed_width.url)
                })
                setGifs(data);
            }
        })
    }

    const searchGifs = () => {
        giphySearch({limit:20,offset:0,query:search})
        .then(result => {
            if(result.data.success){
                const data = [];
                Object.keys(result.data.data).forEach(e => {
                    data.push(result.data.data[e].images.fixed_width.url)
                })
                setGifs(data);
            }

        })
    }

    return(
        <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        appElement={document.getElementById('app')} 
        transparent={true}
        visible={props.visible}
        onDismiss={() => {
          props.setVisible(false);
        }}>
        <View
        style={{position:'absolute',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center'}}>
            <View style={{padding: 30,
            backgroundColor:'white',
            position:'relative',
            width: '50%',
            height:'70%',
            justifyContent:'center',
            alignItems:'center',
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            pointerEvents:'box',
            elevation: 5,
        
            }}>
                <View style={{flexDirection:'column',width:'100%',alignItems:'flex-end'}}>
                <Icon 
                type='Ionicons'
                name="close"
                size={30}
                onPress={()=>{props.setVisible(false)}}
                style={{alignSelf:'flex-end'}}
                />
                </View>

                <View style={{flexDirection:'row',alignItems:'center',width:'75%',justifyContent:'center',alignSelf:'center'}}>
                <Search onChangeText={e => setSearch(e)} width='50%'/>
                <CustomButton onPress={searchGifs} title="Search" style={{marginLeft:10,width:'25%'}}/>
                </View>
            <ScrollView style={{flex:1}}>
            <View style={{flexDirection:'row',maxWidth:'100%',flexWrap:'wrap',alignSelf:'center'}}>
                {
                    gifs.map(e => {
                        return(
                            <img
                            key={e.split('/')[4]}
                            onClick={()=>{props.onPressGif(e)}}
                            src={e}
                            style={{width:200,height:200,margin:10}}
                            />
                        )
                    })
                }
                </View>
                </ScrollView>
            </View>
            </View>
        </Modal>
    )
})

export default GifModal;
