import React from "react";
import { ScrollView, Switch, Text, View } from "react-native";
import SimpleBulletList from "../../Components/SimpleBulletList";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import { useSelector } from "react-redux";
import useUpdateAffiliate from "../useUpdateAffiliate";

const WelcomeEmail = () => {

    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const [data, setData] = React.useState({
        welcomeEmailSubject: affiliate?.welcomeEmailSubject || '',
        welcomeEmailBody: affiliate?.welcomeEmailBody || '',
    })


    const bulletPoints = [
        "${account.name} - the customer/business name of the account",
        "${account.contact} - the account contact name",
        "${account.email} - the email address of the account",
        "${account.address} - the address line of the account",
        "${account.city} - the city of the account",
        "${account.state} - the state of the account (two letter)",
        "${account.zip} - the zip code of the account",
        "${account.phone} - the MyRepChat number of the account",
        "${username} - the username for the account login",
        "${server} - the link to the server to login to"
    ];

    return(
        <ScrollView contentContainerStyle={{flex:1,paddingTop:15}}>
            <Text>You can customize the email that is sent to users when they sign up. The login details will appear after the body of the email.
                {'\n'}{'\n'}
                You can use the following place holders in your message
            </Text>
            <SimpleBulletList data={bulletPoints} style={{marginVertical:20}}/>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>
            {"When the message is sent, these place holders will be replaced with the values from the account record. Note that ${username} and ${server} will automatically be added after your welcome body if you do not specify them in the body text."}
            </Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Subject</Text>
            <CustomInput
            onChange={e => setData({ ...data, welcomeEmailSubject: e.target.value })}
            value={data.welcomeEmailSubject}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold,marginTop:20 }}>Body</Text>
            <CustomInput
             onChange={e => setData({ ...data, welcomeEmailBody: e.target.value })}
             textInputStyle={{minHeight:150,maxHeight:300}}
             multiline
            value={data.welcomeEmailBody}
            />


            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end',marginTop:20,marginRight:10 }}
            />

         </ScrollView>
    )
}

export default WelcomeEmail;