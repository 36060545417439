import React from "react";
import Modal from 'modal-react-native-web';
import { TouchableOpacity, View , Text} from "react-native";
import AppColor from "../../Constants/Color";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header";
import CustomButton from "../Button";
import { saveFile, updateAvatar } from "../../Api/ten4";
import { setProfile } from "../../Redux";
import Loader from "../Loader";

const UserAvatarPickerModal = props => {
    const fileInput = React.useRef();
    const [previewURL, setPreviewURL] = React.useState('');
    const tenantId = useSelector((state) => state.User?.profile)?.tenant?.id;
    const [loading, setLoading] = React.useState(false);
    const user = useSelector((state) => state.User.profile)?.user;
    const dispatch = useDispatch();

    let initials = '';

    const getInitials = name => {
        let result = ''
        const split = name.split(' ');
        split.forEach(letter => {
            result += letter.charAt(0).toUpperCase()
        })
        return result;
    }

    if(!user.avatar){
        initials = getInitials(user.name);
    }

    const element = 
    previewURL ?
    <img
        alt="User Avatar."
        src={previewURL}
        style={{borderRadius:100,height:60,width:60,cursor:'pointer', objectFit: 'cover'}}
        />
    :
    user.avatar ? 
        <img
        alt="User Avatar."
        src={user.avatar}
        style={{borderRadius:100,height:60,width:60,cursor:'pointer', objectFit: 'cover'}}
        />
    :
    <TouchableOpacity style={{cursor:'pointer',backgroundColor:AppColor.PrimaryColor,borderRadius:100,height:60,width:60,justifyContent:'center',alignItems:'center'}}>
        <Text style={{color:'white',letterSpacing:-1}}>{initials}</Text>
    </TouchableOpacity>

    const handleImageUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const uploadFile = () => {
    if(previewURL){
    const data = previewURL;
    setLoading(true);
    saveFile({base64Data: data, tenant: tenantId})
    .then(result => {
        if(result?.data?.success){
        const avatarUrl = result.data.fileUrl
        
            const params = {
                id: user.user_id,
                avatar: avatarUrl
            }
            updateAvatar({params: params})
            .then(result => {
                console.log("update_avatar",result);
                if(result.data.success){
                    console.log(avatarUrl);
                    dispatch(setProfile({
                        user: {
                        ...user,
                        avatar: avatarUrl
                        }
                    }))
                    props.setVisible(false);                       
                }
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            })
        }
    })
    .catch(err => {
        console.log(err);
    })
    }
}


 
    return(
        <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        appElement={document.getElementById('app')} 
        transparent={true}
        visible={props.visible}
        onDismiss={() => {
          props.setVisible(false);
        }}>
        <View
        
        style={{width:'100vw',height:'100vh',backgroundColor:'rgba(0,0,0,.65)',justifyContent:'center',alignItems:'center'}}>
            <View style={{minWidth:300,backgroundColor:'white',alignItems:'center',padding:10}}>
                <Header title="User Avatar"/>
                {element}
                <CustomButton title="Select Image" onPress={handleClick} style={{marginVertical:10}}/>
                <CustomButton onPress={uploadFile} title="Save" style={{marginBottom:10}}/>
                <CustomButton onPress={() => props.setVisible(false)} title="Cancel" />
                <input 
                    type='file' 
                    accept='image/*' 
                    style={{ display: 'none' }} 
                    ref={fileInput} 
                    onChange={handleImageUpload} 
                />
            </View>
        </View>
        {loading && <Loader/>}
        </Modal>

    )
}

export default UserAvatarPickerModal;