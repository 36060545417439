import React from "react";
import { ScrollView, Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import ComponentAccordion from "../../Components/ComponentAccordion";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";
import moment from "moment";
import { View, useToast } from "native-base";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";

const ArchiveSchedule = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const { refetch, data, isLoading, isError } = getQuery({
        affiliateId: affiliate.id,
        action: "get_archive_schedule"
    })

    const { data: Layouts, isLoading: isLoadingLayouts, isError: isLayoutError }  = getQuery({
        affiliateId: affiliate.id,
        action: "get_archive_layout"
    })

    const Toast = useToast();

    if (isLoading || isLoadingLayouts) return <Loader backgroundColor={'transparent'} />

    if (!data || !Layouts) return null;
    const layoutOptions = Layouts.layouts.map(e => { return { label: e.name, value: String(e.id) } });


    const generateTimeOptions = () => {
        const baseTimestamp = 1719460800000; // This represents 0:00:00
        const millisecondsInHour = 3600000;
        const timeArray = [];
    
        for (let i = 0; i < 24; i++) {
            const timestamp = baseTimestamp + (i * millisecondsInHour);
            const hoursString = String(i).padStart(2, '0') + ":00:00";
            
            timeArray.push({
                value: timestamp,
                label: hoursString
            });
        }
    
        return timeArray;
    }
      
    const hourOptions = generateTimeOptions();

    const stateOptions = [
        {
            label: 'Active',
            value: "active"
        },
        {
            label: 'Disabled',
            value: "disabled"
        },
        {
            label: 'Deleted',
            value: "deleted"
        }
    ]

    const providerOptions = [];

    const providerSet = new Set();

    data.schedules.forEach(item => {
    const provider = item.provider;
    if (!providerSet.has(provider)) {
        providerSet.add(provider);
        const formattedProvider = {
        value: provider.toLowerCase(),
        label: provider.charAt(0).toUpperCase() + provider.slice(1)
        };
        providerOptions.push(formattedProvider);
    }
    });

    const RenderComponent = ({ item }) => {
        const [obj, setObj] = React.useState({
            layoutId: String(item?.layoutId) || "1",
            frequencyHours: item?.frequencyHours || 24,
            startTimestamp: item?.startTimestamp ? moment(item.startTimestamp).valueOf() : moment({ hour: 0 }).valueOf(),
            state: item?.state || "active",
            auditEmail: item?.auditEmail || "",
            provider: item?.provider || "",
        });

        const handleTest = async () => {
            const body = {
                action: 'test_archive_schedule',
                affiliateId: affiliate.id,
                id: item.id,
            }

            const result = await RequestMaker(body);
            if(result.data.success){
                Toast.show({
                    title: "Test email sent successfully",
                    status: "success",
                })
            }
        }

        const handleSave = async () => {
            if(obj?.frequencyHours > 24){
                Toast.show({
                    title: "Frequency hours should be less than 24",
                    status: "error",
                })
                return;
            }

            try{
            const body = {
                action: 'create_archive_schedule',
                affiliateId: affiliate.id,
                locked: false,
                ...obj
            }

            if(item?.id){
                body.id = item.id;
                body.action = 'update_archive_schedule'
            }

            const result = await RequestMaker(body);
            if(result.data.success){
                Toast.show({
                    title: "Schedule saved successfully",
                    status: "success",
                })
                refetch();
            } else if(result.data.errorMessage){
                Toast.show({
                    title: result.data.errorMessage,
                    status: "error",
                })
            }
            } catch(err){
                Toast.show({
                    title: "An error occurred",
                    status: "error",
                })
            }

        }

        return (
            <>
            
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 20 }}>
                    Frequency (hours)
                </Text>
                <CustomInput
                    type='number'
                    value={obj.frequencyHours}
                    onChangeText={(text) => {
                        if(!item?.id){
                            setObj({ ...obj, frequencyHours: text })
                        }
                    }}
                />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 20 }}>
                    Start Hour
                </Text>

                <SimpleDropdown
                    data={hourOptions}
                    value={obj.startTimestamp}
                    onChange={val => {
                        if(!item?.id){
                            setObj({ ...obj, startTimestamp: val })}
                        }
                    }
                />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 20 }}>
                    Layout
                </Text>

                <SimpleDropdown
                    data={layoutOptions}
                    value={obj.layoutId}
                    onChange={val => setObj({ ...obj, layoutId: val })}
                />

                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 20 }}>
                    State
                </Text>

                <SimpleDropdown
                    data={stateOptions}
                    value={obj.state}
                    onChange={val => setObj({ ...obj, state: val })}
                />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 20 }}>
                    Audit Email
                </Text>
                <CustomInput
                    type='number'
                    value={obj.auditEmail}
                    onChangeText={(text) => setObj({ ...obj, auditEmail: text })}
                    placeholder="Optional"
                />
                {
                    !item?.id && 
                    <>

                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 20 }}> Provider </Text>
                        <SimpleDropdown 
                            value={obj.provider}
                            onChange={val => setObj({...obj, provider: val})}
                            data={providerOptions}
                        />
                    </>
                }
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, gap: 20 }}>
                    { item?.id && <CustomButton
                        title="Test"
                        outline
                        onPress={handleTest}
                        width={'20%'}
                    />}
                    <CustomButton
                        title="Save"
                        onPress={handleSave}
                        width={'20%'}
                    />

                </View>


            </>
        )
    }

    return (
        <ScrollView style={{flex:1}}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 20 }}>
                Define schedules that set the frequency and layout for your archives, and provide an optional audit email address to receive a separate email with an audit of your archive.
            </Text>
            <ComponentAccordion
                allowAdd={true}
                data={data.schedules}
                renderComponent={item => <RenderComponent item={item} />}
                renderListItem={item => [item.provider, item.state]}
                columns={['Schedule', 'State']}
            />
        </ScrollView>
    )
}

export default ArchiveSchedule;