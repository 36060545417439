import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import CustomButton from "../../Components/Button/index.web";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const ResellerInformation = ({reseller}) => {
    const { data, isLoading, refetch } = getQuery({
        resellerId: reseller.id,
        action: "get_billing_rebate"
    });

    const [resellerData, setResellerData] = React.useState({
        name: reseller.name,
        handlesBilling: reseller.handlesBilling,
        billingEmail: reseller.billingEmail,
        addonNotifications: reseller.addonNotifications,
    });


    const [rebateData, setRebateData] = React.useState({
        type: data?.rebate?.type ?? "account",
        email: data?.rebate?.email ?? "",
        value: data?.rebate?.value ?? 0,
    })
    
    const Toast = useToast();

    React.useEffect(() => {
        if(data?.rebate && data.rebate !== null){
            setRebateData({
                type: data.rebate.type,
                email: data.rebate.email,
                value: data.rebate.value
            })
        }
    },[data])

    


    if(isLoading) return <Loader backgroundColor={'transparent'}/>;
    if(!data) return null;


    const rebateTypes = [
        {
            label: 'Percentage of Total',
            value: 'percentage'
        },
        {
            label: '# of Accounts',
            value: 'account'
        }
    ]

    const handleUpdateReseller = async () => {
        try{
         const body = {
            action: 'update_reseller',
            id: reseller.id,
            name: resellerData.name,
            billingEmail: resellerData.billingEmail,
            handlesBilling: resellerData.handlesBilling,
            addonNotifications: resellerData.addonNotifications
         }

         const result = await RequestMaker(body);

         if(result.data.success){
                Toast.show({title: 'Reseller updated successfully', status: 'success'});
         } else {
            throw new Error(result.data?.errorMessage ?? 'An error occurred');
         }

        } catch(err){
            Toast.show({title: new Error(err).message, status: 'error'});
        }
    }

    const handleSaveRebate = async () => {
        try{
        const body = {
            action: "save_billing_rebate",
            resellerId: reseller.id,
            type: rebateData.type,
            email: rebateData.email,
            value: rebateData.value,
        }
        const result = await RequestMaker(body);

        if(result.data.success){
            Toast.show({title: 'Rebate saved successfully', status: 'success'});

        } else {
            throw new Error(result.data?.errorMessage ?? 'An error occurred');
        }

        } catch(err){
            Toast.show({title: new Error(err).message, status: 'error'});
        }

    }

    const handleDeleteRebate = async () => {

        try{

            const body = {
                resellerId: reseller.id,
                action: "delete_billing_rebate"
            }

            const result = await RequestMaker(body);
            
            if(result.data.success){
                Toast.show({title: 'Rebate deleted successfully', status: 'success'});
                
                setRebateData({
                    type: 'account',
                    email: '',
                    value: 0
                })
    
            } else {
                throw new Error(result.data?.errorMessage ?? 'An error occurred');
            }

        } catch(err){
            Toast.show({title: new Error(err).message, status: 'error'});
        }

    }


    return(
        <ScrollView style={{flex:1,padding:10}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>Please provide the basic account information.</Text>
            <CustomInput title={'Reseller/Organization Name'} onChangeText={e => setResellerData({...resellerData, name: e })} value={resellerData.name} />
            <CustomInput onChangeText={e => setResellerData({...resellerData, billingEmail: e})} titleStyle={{marginTop:10}} title={'Email Address'} value={resellerData.billingEmail} />
            <SimpleCheckbox onChange={e => setResellerData({...resellerData, addonNotifications: e})} style={{marginTop:10}} label={'Add-On Notifications'} value={resellerData.addonNotifications} />
            <SimpleCheckbox onChange={e => setResellerData({...resellerData, handlesBilling: e})} style={{marginTop:10}} label={'Handles their own billing'} value={resellerData.handlesBilling} />
            <CustomButton onPress={handleUpdateReseller} title={'Save'} width={'20%'} style={{alignSelf:'flex-end'}} />

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginBottom:10}}>Billing Rebates</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
            Manage any rebate for this reseller. Rebates are not sent automatically - Email is for reference only. Use the report on the Billing Dashboard to get amounts.
            </Text>

            <CustomInput value={rebateData.email} onChangeText={e => setRebateData({...rebateData, email: e})} titleStyle={{marginTop:10}} title={'Rebate Payor Email'} />
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10,marginBottom:5}}>
                Rebate Type
            </Text>

            <SimpleDropdown
            data={rebateTypes}
            onChange={e => setRebateData({...rebateData, type: e})}
            value={rebateData.type} 
            />
            <CustomInput value={rebateData.value} onChangeText={e => setRebateData({...rebateData, value: e})} containerStyle={{marginBottom:10}} titleStyle={{marginTop:10}} title={'Rebate Value/Amount'} />
            <View style={{justifyContent:'flex-end',gap:20,flexDirection:'row'}}>
            <CustomButton onPress={handleDeleteRebate} outline title={'Delete'} width={'20%'} />
            <CustomButton onPress={handleSaveRebate} title={'Save'} width={'20%'}  />
            </View>

        </ScrollView>
    )
}

export default ResellerInformation;