import React from "react";
import { Switch, Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";
import Loader from "../../Components/Loader/index.web";

const ContentIntegrations = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const Toast = useToast();

    const { refetch, data, isLoading, isError } = getQuery({
        action: 'get_integrations',
        group: affiliate.id,
        type: "data"
    })

    if(isLoading) return <Loader backgroundColor={'transparent'}/>

    if(!data) return null;

    const riskalyze = data.integrations.find(e => e.provider === "riskalyze");
    const isRiskalyzeActive = riskalyze?.enabled;

    const handleChange = async e => {
        try{
        const body = {
            group: affiliate.id,
            type: "data",
            provider: "riskalyze",
            enabled: e,
            id: riskalyze.id,
            action: "update_integration"
        }

        const result = await RequestMaker(body);
        if(result.data.success){
            refetch();
            Toast.show({
                status: 'success',
                title: 'Updated succesfully'
            })
        } else {
            Toast.show({
                status: 'error',
                title: result.data.errorMessage
            })
        }
    } catch(e){
        Toast.show({
            status: 'error',
            title: e.message
        })
    }

    }

    return(
        <>
           <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:10 }}>Enable and disable content integrations. When a content integration is disabled the account user will see the content integration in their settings, but they will not be able to use it.</Text>
           <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Enable Riskalyze</Text>
           <Switch
                value={isRiskalyzeActive}
                onValueChange={handleChange}
           />

        </>
    )
}

export default ContentIntegrations;