import { Icon } from 'react-native-elements';
import React from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Modal,
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadScheduledMessageThunk,
  deleteScheduledMessageThunk,
} from '../../Redux/scheduled_messages';
import { useFocusEffect } from '@react-navigation/core';

import { Calendar } from 'react-native-calendars';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import Search from '../../Components/Search';
import { Bubbles } from '../Authentication/Login/Bubbles';
import AddAutoResponse from './AddScheduledMessage';
import getData from '../../AsyncUtils/GetData';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import CalendarEvent from './CalendarEvent';
import ViewCalendarEvent from './ViewCalendarEvent';
import Header from '../../Components/Header';
import { RemovePhoneModal } from '../Contacts/modals';
import useUndo from '../../Middleware/useUndo';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

var months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const moment = require('moment');

const ScheduledMessage = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const current_date = moment().format('YYYY-MM-DD');
  const [selected_date, setSelectedDate] = React.useState(null);
  const scheduled_messages = useSelector((state) => state.scheduled_messages);
  const [messages, setMessages] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const group = useSelector((state) => state.User)?.profile?.group;
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;
  const [removeModal, setRemoveModal] = React.useState(false);
  const [toDelete, setToDelete] = React.useState(null);

  const [currentMonth, setCurrentMonth] = React.useState(
    Number(moment().format('MM'))
  );
  const [currentYear, setCurrentYear] = React.useState(
    Number(moment().format('YYYY'))
  );
  const [markedDates, setMarkDates] = React.useState({
    [`${selected_date}`]: {
      selected: true,
      selectedColor: 'AppColor.PrimaryColor',
    },
  });

  const [loading, setLoading] = React.useState(false);

  const [messageConfig, setMessageConfig] = React.useState(null);

  const [crmCategories,setCRMCategories] = React.useState([]);
  const [calendarEvents, setCalendarEvents] = React.useState([]);
  
  const undo = useUndo();
  const {isWebCompact} = useResponsiveLayout();

  React.useEffect(() => {
    let array = [];

    let selectedDate = moment(selected_date);
    
    [...calendarEvents,...scheduled_messages.data].forEach(e => {

      let deliveryDate = moment(e?.deliveryDate || e?.startTimestamp);
      const index = messages.findIndex(x => x.id === e.id);

      if(deliveryDate.isSame(selectedDate, 'day')){
        array.push(e);
      }

    })
    
    setMessages(array)
  }, [selected_date, scheduled_messages, calendarEvents]);
  
  React.useEffect(() => {
    let mdates = {};
    const data = [...scheduled_messages.data, ...calendarEvents];
    if (data.length > 0) {
      for (let x of data) {
        let deliveryDate = moment(x?.deliveryDate || x?.startTimestamp);
        
        if(deliveryDate.isAfter(moment())){
          deliveryDate = deliveryDate.format('YYYY-MM-DD');
          mdates[`${deliveryDate}`] = { marked: true, dotColor:'orange' };
        }else{
          deliveryDate = deliveryDate.format('YYYY-MM-DD');
          mdates[`${deliveryDate}`] = { marked: true, dotColor:'blue' };
        }

        if(x?.unread === true){
          mdates[`${deliveryDate}`] = { marked: true, dotColor:'red' };
        }
        if(x?.consented === false){
          mdates[`${deliveryDate}`] = { marked: true, dotColor:'red' };
        }

        let frequency = x.frequency;

        switch(frequency){
          case 1:
            let startDate = moment(x.deliveryDate);
            for (let i = 0; i < 31; i++){
              startDate.add(1, 'd');
              let formattedDate = startDate.format('YYYY-MM-DD')
              mdates[`${formattedDate}`] = { marked: true, dotColor:'orange' };
            }
            break;
          case 2:
            startDate = moment(x.deliveryDate);
            for (let i = 0; i < 3; i++){
              startDate.add(1, 'w');
              let formattedDate = startDate.format('YYYY-MM-DD')
              mdates[`${formattedDate}`] = { marked: true, dotColor:'orange' };
            }
            break;
        }


      }
    }

    mdates[`${selected_date}`] = {
      selected: true,
      selectedColor: AppColor.PrimaryColor,
    };

    setMarkDates(mdates);
  }, [scheduled_messages, selected_date, calendarEvents]);

  const loadSMessage = () => {
    var date = new Date();
    var firstDay = new Date(currentYear, currentMonth - 1, 1);
    var lastDay = new Date(currentYear, currentMonth, 1);
    dispatch(loadScheduledMessageThunk(firstDay.getTime(), lastDay.getTime()));
  };

  useFocusEffect(
    React.useCallback(() => {
      loadSMessage();
      if(affiliate?.allowSyncCalendarEvents === true){
        if(crmCategories.length === 0){
          getCalendarCategories();
        } else {
          getCalendarEvents();
        }
      }
    }, [])
  );

  const getCalendarCategories = async () => {
    const body = {
      action:'get_calendarcategories',
      toast: false
    }
    try {
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        if(result.data.categories.length){
          setCRMCategories(result.data.categories);
          var firstDay = new Date(currentYear, currentMonth - 1, 1);
          var lastDay = new Date(currentYear, currentMonth, 1);      
          getCalendarEvents(firstDay.getTime(), lastDay.getTime() - 1);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  const getCalendarEvents = async (from = new Date(currentYear, currentMonth - 1, 1).getTime(), to = new Date(currentYear, currentMonth, 1).getTime() - 1) => {
    const body = {
      action:'get_calendarevents',
      unread: true,
      lastUpdated:true,
    }

    try {
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
          setCalendarEvents(result.data.calendarevents);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const recreateScheduledMessage = async () => {
    const body = {
      action: 'create_scheduled_message',
      contact: toDelete.contact,
      deliveryDate: toDelete.deliveryDate,
      frequency: toDelete.frequency,
      message: toDelete.message,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    setLoading(true);
    const result = await RequestMaker(body);
    if(result.data.success){
      var firstDay = new Date(currentYear, currentMonth - 1, 1);
      var lastDay = new Date(currentYear, currentMonth, 1);  
      dispatch(loadScheduledMessageThunk(firstDay.getTime(), lastDay.getTime()))    
    }
    setLoading(false)
  }

  const handleMonthChange = (month) => {
    var firstDay = new Date(currentYear, month - 1, 1);
    var lastDay = new Date(currentYear, month, 1);
    dispatch(loadScheduledMessageThunk(firstDay.getTime(), lastDay.getTime()));
  };

  React.useEffect(() => {
    handleMonthChange(currentMonth);
  }, [currentMonth, currentYear]);


  const renderFullListView = ({ item }) => {
    const focused = item?.id === messageConfig?.id;

    const handlePress = () => {
      navigation.setParams({template_message: null});
      setMessageConfig(item)
    }

    if(item?.categoryId) return <CalendarEvent focused={focused} onPress={handlePress} item={item}/>

    return (
      <TouchableOpacity
        onPress={handlePress}
        style={{
          width: '100%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          paddingHorizontal:10,
          marginTop: 10,
          borderTopColor: '#116AB4',
          borderTopWidth: focused ? 1 : 0,
          borderBottomColor: '#116AB4',
          borderBottomWidth: focused ? 1 : 0,
          backgroundColor: focused ? '#ECF6FF' : 'transparent'

        }}>
        <View style={{ justifyContent: 'center' }}>
        {
        (item?.consented === false && group?.consentEnabled === true) ? 
        <Icon
        type={'material'}
        name={'warning'}
        color={AppColor.danger}
      />
        :
        item.delivered ?
          <Icon name="check" type="antdesign" color={AppColor.PrimaryColor} />
          :
          <Icon
            type={'material'}
            name={ item.frequency > 0 ? 'repeat' : 'timer'}
            color={AppColor.PrimaryColor}
          />
        }
        </View>
        <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
          
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 17,
                marginHorizontal: 10,
                color: 'black',
              }}
              numberOfLines={1}>
              {item.fname} {item.lname}
            </Text>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 14,
                lineHeight: 20,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}
              numberOfLines={1}>
              {item.message}
            </Text>
          </View>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <Text>{moment(item.deliveryDate).format('MMM D YYYY')}</Text>
          <Text>{moment(item.deliveryDate).format('hh:mm a')}</Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => {
              setToDelete(item)
              setRemoveModal(true);
            }}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  };

  const searchFilter = e => {
    const fullName = `${e?.fname} ${e?.lname}`
    return String(fullName).toLowerCase()?.includes(search.toLowerCase()) || e?.message?.toLowerCase()?.includes(search) || e?.body?.toLowerCase()?.includes(search) || e?.title?.toLowerCase()?.includes(search) || e?.invitees?.findIndex(e => e?.name?.toLowerCase()?.includes(search.toLowerCase()) ) > -1;
  }

  const dateFilter = e => {
    console.log(currentMonth);
    return moment(e?.startTimestamp)?.month() + 1 === currentMonth;
  }


    const uniqueArray = [];
    const uniqueIds = {};

    scheduled_messages.data.forEach(obj => {
      if (!uniqueIds[obj.id]) {
        uniqueIds[obj.id] = true;
        uniqueArray.push(obj);
      }
    });

  const inputRef = React.useRef();

  const handleClick = () => {
    inputRef.current.showPicker();
  };

  const handleChangeMonthViaInput = e => {
    setCurrentYear(Number(e.currentTarget.value.split('-')[0]) );
    setCurrentMonth(Number(e.currentTarget.value.split('-')[1]) );
    handleMonthChange(Number(e.currentTarget.value.split('-')[1]))
  }


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Wrapper>
      { (!isWebCompact || messageConfig === null) &&
      <SideBar>

          <Search 
          actionButton 
          actionButtonTitle='Message'
          onPressActionButton={() => {
            navigation.setParams({
              date:selected_date !== null ? moment(selected_date) : null,
              template_message: null,
              deliveryDate: null,
              time: null,
              frequency: null
            });

            setMessageConfig('create')}
          } 
            onChangeText={e => {setSearch(e)}} 
            
            />
          <Calendar
        style={{borderColor:'rgb(241, 241, 241)',borderWidth:10,minHeight:380,borderRadius:20}}
          current={current_date}
          markedDates={markedDates}
          onDayPress={(day) => {
            // alert("changes")
            if (day.month < 10) {
              day.month = '0' + day.month;
            }
            if (day.day < 10) {
              day.day = '0' + day.day;
            }
            setSelectedDate(`${day.year}-${day.month}-${day.day}`);
          }}
          onDayLongPress={(day) => {
            // alert("changes")
          }}
          monthFormat={'yyyy MM'}
          onMonthChange={(month) => {
            // handleMonthChange(month.month);
            setCurrentMonth(month.month);
            setCurrentYear(month.year);
          }}
          hideExtraDays={true}
          disableMonthChange={true}
          renderArrow={(direction) =>
            direction == 'left' ? (
              <MaterialIcons
                name="chevron-left"
                size={22}
              />
            ) : (
              <MaterialIcons
                name="chevron-right"
                size={22}
              />
            )
          }
          onPressArrowLeft={(subtractMonth) => subtractMonth()}
          onPressArrowRight={(addMonth) => addMonth()}
          disableAllTouchEventsForDisabledDays={true}
          
          renderHeader={(date) => {
            return (
              <>
              <Text onPress={handleClick} style={{cursor:'pointer',fontFamily:AppFonts.Roboto_Regular,textTransform:'uppercase',fontSize: 20}}>
              {months[currentMonth -1]} {currentYear}
              </Text>
              <input defaultValue={`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`} onChange={handleChangeMonthViaInput} ref={inputRef} type='month' style={{position:'absolute',left:'50%',top:10,visibility:'hidden',width:0}} />
              </>
            );
          }}
          enableSwipeMonths={true}
        />
      <TouchableOpacity onPress={() =>{
        if(selected_date !== null);
         setSelectedDate(null)
        }} 
        style={{marginLeft:15, }}>
      <Text style={{color: selected_date === null ? 'rgba(0,0,0,.7)' : AppColor.BLUE100}}>Show entire month</Text>
      </TouchableOpacity>

      <ScrollView 
      style={{flex:1}}
      >
        {
          selected_date === null ? 
          <FlatList
          data={[...calendarEvents.filter(dateFilter), ...uniqueArray].filter(searchFilter)}
          renderItem={renderFullListView}
          ListEmptyComponent={
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.Dark,
                }}>
                No Messages Scheduled
              </Text>
            </View>
          }
        />
        :
        <FlatList
        data={messages.filter(searchFilter)}
        renderItem={renderFullListView}
        ListEmptyComponent={
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
            }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
              }}>
              No Messages Scheduled
            </Text>
          </View>
        }
      />
      }
      </ScrollView>
      </SideBar>}

      { (!isWebCompact || messageConfig !== null) && 
      <Content>
        { messageConfig === null ?
        <Bubbles>
          <Text style={{color:'#116AB4',fontSize:32,width:'70%'}}>
            Select a scheduled message to edit or create a new one.
          </Text>
        </Bubbles>
          :
        messageConfig?.categoryId ? 
        <ViewCalendarEvent
        categories={crmCategories}
        item={messageConfig} 
        onUpdate={()=>{
          setMessageConfig(null);
          getCalendarEvents();
        }}
        onSave={() =>{
          setMessageConfig(null);
          getCalendarEvents();
          loadSMessage()
         }} 
         onCancel={() => setMessageConfig(null)}
        />
        :
        <AddAutoResponse 
        categories={crmCategories}
        onSave={() =>{
           setMessageConfig(null);
           loadSMessage()
          }} onCancel={() => setMessageConfig(null)} 
          navigation={navigation} 
          item={messageConfig} 
          route={route}/>
      }
      </Content>
      }
    </Wrapper>

      {loading && <Loader />}

      {
        removeModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={removeModal} transparent={true}>
          <RemovePhoneModal
          title="Delete Scheduled Message"
          message={toDelete?.frequency > 0 ? "Are you sure you'd like to permanently delete this scheduled message? This is a recurring message and all associated messages will be affected." : "Are you sure you'd like to permanently delete this scheduled message?"}
            handleRemove={() => {
              dispatch(deleteScheduledMessageThunk(toDelete.id)).then((x) => {
                handleMonthChange(currentMonth);
                setRemoveModal(false)
                undo(recreateScheduledMessage);
              });
            }}
            setRemoveModal={setRemoveModal}
          />
        </Modal>
      }
    </SafeAreaView>
  );
};
export default ScheduledMessage;
