import React, { useState } from 'react';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Image,
  FlatList,
  Switch,
  TextInput,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import DateTimePicker from 'react-datetime-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, Content } from 'native-base';
import Alert from 'Alert';
import { Icon } from 'react-native-elements';

import CustomButton from '../../Components/Button';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import RequestMaker from '../../Middleware/ApiCaller';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';
import ContactsModal from './ContactsModal';

const AccountReport = ({ navigation, route }) => {
  const [conversationFrom, setConverationFrom] = useState(new Date());
  const [conversationTo, setConverationTo] = useState(new Date());
  const [billingFrom, setBillingFrom] = useState(new Date());
  const [billingTo, setBillingTo] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const group = useSelector((state) => state.User)?.profile?.group;

  const handleExportConversation = async () => {
    
    const url = `${End_Points.Base_url}${
      End_Points.End
    }?action=generate_report&from=${new Date(
      conversationFrom
    ).getTime()}&to=${new Date(
      conversationTo
    ).getTime()}&format=text&tenantUUID=${tenant.uuid}`;

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        Alert.alert('Error', "Don't know how to open URI");
        console.log("Don't know how to open URI: " + url);
      }
    });
  };

  const handleExportHistory = async () => {
    
    const url = `${End_Points.Base_url}${
      End_Points.End
    }?action=run_billing_audit&from=${new Date(
      billingFrom
    ).getTime()}&to=${new Date(billingTo).getTime()}&format=csv&tenantUUID=${
      tenant.uuid
    }`;

    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        Alert.alert('Error', "Don't know how to open URI");
        console.log("Don't know how to open URI: " + this.props.url);
      }
    });
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        {
         group?.showTenantExport && <>
        <Text style={styles.subTitle}>Account Report</Text>
        <View style={styles.itemContainer}>
          <View style={styles.item}>
            <Text style={styles.label}>From</Text>
            <DateTimePicker
              onChange={setConverationFrom}
              value={conversationFrom}
              calendarIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>To</Text>
            <DateTimePicker
              onChange={setConverationTo}
              value={conversationTo}
              calendarIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
        </View>
        <View style={styles.button}>
          <CustomButton
            onPress={() => {
              handleExportConversation();
            }}
            title="Export Conversations"
          />
        </View>
        </>
        }
        <Text style={styles.subTitle}>Billing Report</Text>
        <View style={[styles.itemContainer, { zIndex: 2 }]}>
          <View style={[styles.item, { zIndex: 2 }]}>
            <Text style={styles.label}>From</Text>
            <DateTimePicker
              onChange={setBillingFrom}
              value={billingFrom}
              calendarIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
          <View style={styles.item}>
            <Text style={styles.label}>To</Text>
            <DateTimePicker
              onChange={setBillingTo}
              value={billingTo}
              calendarIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
        </View>
        <View style={styles.button}>
          <CustomButton
            onPress={() => {
              handleExportHistory();
            }}
            title="Export Billing History"
          />
        </View>

        <View style={styles.bottomContainer}>
          <Text style={styles.subTitle}>Contacts Report</Text>
          <View style={styles.button}>
            <CustomButton
              onPress={() => setVisible(true)}
              title="Export Contacts"
            />
          </View>
        </View>
      </View>
      {loading && <Loader />}
      <ContactsModal visible={isVisible} setVisible={setVisible} />
    </SafeAreaView>
  );
};

export default AccountReport;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    padding: 30,
  },
  itemContainer: {
    width: '100%',
    zIndex: 9,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf:'flex-start',
    gap:20,
    justifyContent: 'space-between',
  },
  item: {
    width: '40%',
    zIndex: 9,
  },
  bottomContainer: {
    width: widthPercentageToDP(90),
    alignItems: 'center',
    zIndex: 1,
    flexDirection: 'column',
    marginTop: 50,
  },
  subTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 20,
    color: AppColor.BlackColor,
    marginVertical: 15,
    alignSelf:'flex-start'
  },
  label: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 18,
    color: AppColor.PrimaryColor,
  },
  button: {
    width: '100%',
    maxWidth: 340,
    marginVertical: 15,
  },
});
