import React from 'react';
import { SafeAreaView,  ScrollView } from 'react-native';
import Header from '../../Components/Header';
import { useSelector } from 'react-redux';
import { deleteChatChannel, getChannelMembers, getChatUsers, updateFullChatChannel } from '../../Api/ten4';
import CustomButton from '../../Components/Button';
import { MembersList } from './MembersList';
import Alert from '../../Components/Alert';
const EditPrivateChannel = ({navigation, route}) => {
    
    const tenant = useSelector((state) => state.User.profile)?.tenant;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [avatarPath, setAvatarPath] = React.useState(route.params.avatar);
    const [users, setUsers] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [channelName, setChannelName] = React.useState('');
    const [isActive, setIsActive] = React.useState(route.params.active);


    React.useEffect(()=>{
        const fetchChatUsers = navigation.addListener('focus', () => {
            handleGetUsers()
        })

        return fetchChatUsers;
    },[navigation])

    const handleGetUsers = async () => {
        const [allUsers, usersInGroup] = await Promise.all([getChatUsers({affiliate: affiliate.id}), getChannelMembers({affiliate: affiliate.id, channel: route.params.id}) ])
        if(allUsers.data.success && usersInGroup.data.success){
            setUsers(allUsers.data.users);
            const membersArray = [];
            usersInGroup.data.members.forEach(e => {
                membersArray.push(e.userId);
            })
            setMembers(membersArray);
        }
    }


    const handleAddMember = id => {
        if(!members.includes(id)){
            setMembers(members => [...members, id])
        } else {
            const copy = [...members];
            const index = copy.indexOf(id);
            if(index > -1){
                copy.splice(index, 1);
                setMembers(copy);
            }
        }
    }
    
    const handleSave = () => {
        const params = {
            affiliate: affiliate.id,
            members: members,
            id: route.params.id
        }
        
        updateFullChatChannel({params:params})
        .then( result => {
            if(result.data.success){
                navigation.goBack();
            }
        })

    }

    const handleDelete = () => {
        const params = {
            affiliate: affiliate.id,
            id: route.params.id
        }

        Alert.alert('Confirm', 'Are you sure you\'d like to permanently delete this channel and its messages?', [{text: 'Cancel', onPress: () => {return}, style:'cancel'}, {text: 'Confirm', onPress: () => {
            deleteChatChannel({...params})
            .then(result => {
                if(result.data.success){
                    navigation.goBack();
                }
            })
        }, style:'default'}])
  
    }

    return(
        <SafeAreaView style={{flex:1}}>
            <Header
            title={`Edit Private Conversation`}
            onPress={() => navigation.goBack()}
            back={true}
            />
            <ScrollView contentContainerStyle={{justifyContent:'space-evenly'}}>


            <MembersList onPress = {id => handleAddMember(id)} allUsers={users} selectedUsers={members} />
            
            <CustomButton title='Save' onPress={handleSave} style={{marginTop:20}} />
            <CustomButton title='Delete Channel' onPress={handleDelete} style={{marginTop:20}} />

            </ScrollView>
        </SafeAreaView>
    )
}


export default EditPrivateChannel;