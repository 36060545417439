import React from 'react';
import { Linking, Text, View } from 'react-native';
import CustomButton from '../../../../Components/Button';
import AppFonts from '../../../../Constants/Fonts';
import IntegrationComponent from './IntegrationComponent';
import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import Loader from '../../../../Components/Loader';
import End_Points from '../../../../Constants/Api';

const JunxureComponent = ({provider, integration,allowContactEdits}) => {
    const [loading, setLoading] = React.useState(false);
    const formRef = React.useRef(null);
    const [SAML, setSAML] = React.useState('');
    const [relay, setRelay] = React.useState('');

    const loginToCapsule = async () => {
        setLoading(true);
        const body = {
            action: 'get_junxure_sso_request',
        };
         
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
            method: 'post',
            url: End_Points.Base_url + End_Points.End,
            data: body,
            headers: headers,
            redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if(result.data.success){
            setSAML(result.data.samlResponse);
            setRelay(result.data.relayState);
            if(formRef.current) formRef.current.submit();
        }

        setLoading(false);
    }

    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',width:'80%',fontSize:18}}>
            MyRepChat can send messages to contacts from your AdvisorEngine CRM and add messages as actions within AdvisorEngine CRM. To get started, click the Connect button below.
        </Text>
        <CustomButton 
        onPress={loginToCapsule}
        title="Login to AdvisorEngine CRM"
        />
        <View style={(!integration || !integration.enabled) && {opacity:.5, pointerEvents:'none'}}>

        <IntegrationComponent
        provider={provider}
        integration={integration}
        allowContactEdits={allowContactEdits}
        />
        </View>
        {loading && <Loader />}
        <div style={{display:'none'}}>
			<form ref={formRef} action="https://www.junxurecloud.com/SSO/SAMLSSORequest?PartnerID=4563078b-04f3-47ba-9bb4-b270dc9dce4c" method="post">
				<input type="text" name="SAMLResponse" value={SAML}/>
				<input type="text" name="RelayState" value={relay}/>
			</form>
		</div>
        </>
    )
}

export default JunxureComponent;