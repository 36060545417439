import React, { useState } from 'react';
import { Text, View, StyleSheet, Alert, Switch } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';

import Header from '../../../Components/Header';
import Loader from '../../../Components/Loader';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import getData from '../../../AsyncUtils/GetData';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';
import { useSelector } from 'react-redux';

const CallerId = ({ navigation }) => {
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(tenant?.hideCallerId);
  const Toast = useToast();

  const handleSave = async () => {
    try {
      setChecked(!checked);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let body = {
        action: 'update_hide_callerid',
        hideCallerId: !checked,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Hide Caller Id updated',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.comment}>
          Choose if you want the incoming caller's number to display on your caller ID
          when you receive a call, or if you want the MyRepChat number to
          display.
        </Text>
        <View style={styles.switchWrapper}>
          <Text style={styles.label}>SHOW CALLER</Text>
          <Switch
            trackColor={{ false: 'red', true: 'green' }}
            thumbColor={AppColor.SecondaryColor}
            ios_backgroundColor="red"
            onValueChange={handleSave}
            value={checked}
            style={{ marginHorizontal: 10 }}
          />
          <Text style={styles.label}>SHOW MYREPCHAT NUMBER</Text>
        </View>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

export default CallerId;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
  },
  content: {
    width: '100%',
    padding: 15,
    marginVertical: 30,
    alignSelf: 'center',
    maxWidth: 767,
  },
  comment: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginVertical: 5,
    lineHeight: 24,
  },
  switchWrapper: {
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
    width: '100%',
  },
  label: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 14,
  },
});
