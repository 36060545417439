import React from 'react';
import {View} from 'react-native';

const Wrapper = props => {

    return(
        <View style={{flexDirection:'row',alignItems:'center',flex:1,}}>
            {props.children}
        </View>
    )
}

export default Wrapper;