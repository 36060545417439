import React from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { Linking } from 'react-native';

import CustomButton from '../../../../Components/Button';
import AppFonts from '../../../../Constants/Fonts';
import AppColors from '../../../../Constants/Color';
import IntegrationComponent from './IntegrationComponent';
import End_Points from '../../../../Constants/Api';
import Loader from '../../../../Components/Loader';
import RequestMaker from '../../../../Middleware/ApiCaller';
import getData from '../../../../AsyncUtils/GetData';
import { useNavigation } from '@react-navigation/native';
import Modal from 'modal-react-native-web';
export default function SamlComponent({
  provider,
  integration,
  allowContactEdits,
}) {
  let label =
    'Please login to ' + provider.label + ' and accept MyRepChat access.';
  let text = 'Login to ' + provider.label;
  const [loading, setLoading] = React.useState(false);
  const navigation = useNavigation();
  const handleLogin = async () => {
    const body = {
      action: 'get_saml_connect',
      provider: provider.name,
    };
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
    	Linking.canOpenURL(result.data.url).then((supported) => {
      		if (supported) {
				console.log("Opening link",result.data.url)
        		Linking.openURL(result.data.url);
      		} else {
        		console.log("Don't know how to open URI: ");
      		}
    	});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <View style={{ marginHorizontal: 15 }}>
        <Text
          style={{
            color: AppColors.Dark,
            fontSize: 18,
            marginBottom: 10,
            fontFamily: AppFonts.Roboto_Regular,
            letterSpacing: 1,
          }}>
          {label}
        </Text>
        <CustomButton
          onPress={() => {
            handleLogin();
          }}
          title={text}
          disabled={integration == null || integration.enabled != true}
        />
        <IntegrationComponent provider={provider} integration={integration} />
      </View>
      <Modal
        visible={loading}
        useNativeDriver={true}
        animationIn="fadeIn"
        onBackdropPress={() => {}}>
        <View
          style={{
            backgroundColor: 'white',
            borderRadius: 10,
            width: 60,
            height: 60,
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ActivityIndicator color={AppColors.PrimaryColor} size={'large'} />
        </View>
      </Modal>
    </>
  );
}
