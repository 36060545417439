import React from "react";
import { Alert, Image, Linking, Text, View } from "react-native";
import AppFonts from "../Constants/Fonts";
import CustomButton from "./Button";

const ErrorBoundary = ({onRefresh}) => {
    const handleMail = () => {
        Linking.canOpenURL('mailto:support@myrepchat.com')
        .then((supported) => {
            if (supported) {
                Linking.openURL('mailto:support@myrepchat.com');
            } else {
                console.log("Unable to open email client.");
            }
        })
        .catch((err) => console.error("An error occurred", err));
    }

        return(
        <View style={{flex:1,padding:15,justifyContent:'center',alignItems:'center'}}>
        <img
          alt='MyRepChat logo'
          src={require('../Assets/Images/splashlogo.png')}
          style={{ width: 100, height: 100, resizeMode: 'contain' }}
        />
            <Text style={{fontFamily: AppFonts.Roboto_Bold,fontWeight:600,fontSize:22,marginTop:20}}>Something Went Wrong</Text>
            <Text style={{fontFamily: AppFonts.Roboto_Regular,textAlign:'center',fontSize:22,marginTop:10}}>Please try again later. If the problem persists, contact support at <Text onPress={handleMail} style={{color:'blue'}}>support@myrepchat.com</Text>.</Text>
            <CustomButton textStyle={{textTransform:'none'}} title="Go Back" onPress={onRefresh} style={{marginTop:20,width:'40%'}} />
        </View>
    )   
}

export default ErrorBoundary;