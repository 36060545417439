import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View, Platform, TouchableOpacity, ScrollView, StyleSheet, Modal } from 'react-native';
import Alert from 'Alert';
import { createElement } from 'react-native-web';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Menu, useToast } from 'native-base';
import { Icon } from 'react-native-elements';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'rc-time-picker';

import CustomModal from '../../Components/DropDown';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { setSelectedContact } from '../../Redux/selected_contact';
import './index.css';
import 'rc-time-picker/assets/index.css';
import { deleteScheduledMessageThunk } from '../../Redux/scheduled_messages';
import { getCalendarEvents } from '../../Api/calendar';
import { RemovePhoneModal } from '../Contacts/modals';
import useLogEvent from '../../Middleware/useLogEvent';

const moment = require('moment');

const AddAutoResponse = ({ navigation, item, route, onSave, onCancel, categories }) => {
  const Toast = useToast();
  const [message, setMessage] = React.useState('');
  const selected_contact = useSelector((state) => state.selected_contact);
  const [id, setId] = React.useState(null);
  const [type, setType] = React.useState('create');
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [frequency, setFrequency] = React.useState(0);
  const [time_zone, setTimeZone] = useState(null);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(moment());
  const [consented, setConsented] = useState(true);
  const [contact, setContact] = useState(null);
  const [calendarEvent, setCalendarEvent] = React.useState(null);
  const [confirmUpdateModal, setConfirmUpdateModal] = React.useState(false);
  const group = useSelector((state) => state.User)?.profile?.group;
  const logEvent = useLogEvent();

  const frequencylist = [
    {
      label: 'Just once',
      value: 0,
    },
    {
      label: 'Daily',
      value: 1,
    },
    {
      label: 'Weekly',
      value: 2,
    },
    {
      label: 'Monthly',
      value: 3,
    },
    {
      label: 'Annually',
      value: 4,
    },
    {
      label: 'Quarterly',
      value: 5,
    },
    {
      label: 'Semi-Annually',
      value: 6,
    },
  ];

  React.useEffect(() => {
    
    
    if (item && typeof item !== 'string') {
  
      setType('update');
      if (item.contact) {
        dispatch(
          setSelectedContact({
            data: { id: item.contact, fname: item.fname, lname: item.lname },
            dl: false,
          })
        );
      } else {
        dispatch(
          setSelectedContact({
            data: { id: item.distributionList, text: item.lname },
            dl: true,
          })
        );
      }

      setDate(new Date(item.deliveryDate));
      setTime(moment(new Date(item.deliveryDate)));
      setFrequency(item.frequency);
      setMessage(item.message);
      setId(item.id);
      setConsented(true);
      if(item?.consented === false && group?.consentEnabled === true) setConsented(false)
      setContact(item.contact);

    } else if (route.params?.template_message && typeof item === 'string') {
      setMessage(route.params.template_message);
      setDate(new Date(route.params.deliveryDate));
      setTime(moment(route.params.time));
      setFrequency(route.params.frequency);

    } else {

      setType('create');
      if(route?.params?.date){
        setDate(new Date(route.params.date));
      } else {
        setDate(new Date());
      }
      setConsented(true);
      setTime(moment());
      setFrequency(0);
      setContact(null);
      setMessage('');
      setId(null);
      dispatch(setSelectedContact({
        data: null,
        dl: false,
      }))

    }

    if(item?.calendarEventId){
      getEventDetails(item.calendarEventId);
    }
  }, [route, item]);

  React.useEffect(()=>{
    if(route?.params?.template_message){
      setMessage(route.params.template_message)
    }
  },[route])


  const getEventDetails = async (eventId) => {
    console.log(eventId);
    const result = await getCalendarEvents({id: eventId});
    const index = result?.data?.calendarevents?.findIndex(e => e.id === eventId);
    if(index > -1){
      setCalendarEvent(result.data.calendarevents[index]);
    } else {
      setCalendarEvent(null);
    }
  }

  const handleConsentSend = async () => {
    setLoading(true)
    try {
      let body = {};

      if (item.dl) {
        body = {
          action: 'send_consent',
          distributionList: contact,
        };
      } else {
        body = {
          action: 'send_consent',
          id: contact,
        };
      }

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      setLoading(false);
      if(result?.data?.success){
        Toast.show({
          duration: 2000,
          title: 'Consent successfully sent.',
          status: 'success',
        });
      } else {
        alert(result?.data?.errorMessage || 'Something went wrong, please try again later')
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    } 
  };



  const handleSave = async () => {
    const mergedDate = moment(
      moment(date).format('MM/DD/YYYY') + ' ' + moment(time).format('hh:mm a')
    ).format();

    if (!selected_contact.data?.id) {
      Toast.show({
        duration: 2000,
        status: 'info',
        title: 'Please select user',
      });
      return;
    }
    const now = new Date().getTime();
    if (now > new Date(mergedDate).getTime()) {
      Toast.show({
        duration: 2000,
        status: 'info',
        title: 'The message delivery date must be in the future.',
      });
      return;
    }

    try {
      setLoading(true);
      let body = {
        deliveryDate: new Date(mergedDate).getTime(),
        message: message,
        frequency: frequency,
        timezone: time_zone,
      };
      if (selected_contact.dl) {
        body['distributionList'] = selected_contact.data?.id;
      } else {
        body['contact'] = selected_contact.data?.id;
      }
      if (type == 'update') {
        body['action'] = 'update_scheduled_message';
        body['id'] = id;
      } else {
        body['action'] = 'create_scheduled_message';
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {

        if(type === 'create'){
          logEvent('message_scheduled');
        }

        Toast.show({
          duration: 2000,
          title: 'Your new message is scheduled.',
          status: 'success',
        });
        onSave();
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTimeZone = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const timeZone= await TimeZone.getTimeZone().then(zone => zone);
    setTimeZone(timeZone);
  };
  React.useEffect(() => {
    getTimeZone();
  }, []);

  // const handleDelete = () => {
  //   if(type === 'create'){
  //     navigation.goBack();
  //   } else {
  //     if(id){
  //     dispatch(deleteScheduledMessageThunk(id)).then((x) => {
  //       navigation.goBack();
  //     });
  //     } else {
  //       alert('Something went wrong. Please try again later.')
  //     }

  //   }
  // }
  const calendarEventCategory = categories?.find(e => e?.sourceId === calendarEvent?.categoryId)?.name;

  const runPreSaveCheck = () => {
      if(type !== 'create' && item.frequency > 0 ){
        setConfirmUpdateModal(true);
      } else {
        handleSave();
      }
  } 

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <ScrollView style={{ flex: 1, padding: 30 }}>
        <View>
          <Text
            style={{
              width: '100%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              marginVertical: 10,
              }}>
            To
          </Text>
          <TouchableOpacity
            accessibilityRole='button'
            onPress={() => navigation.navigate('SelectContactForSchedule')}
            style={{
              width: '100%',
              alignSelf: 'center',
              flexDirection: 'row',
              padding: 10,
              paddingHorizontal: 20,
              fontFamily: AppFonts.Roboto_Bold,
              backgroundColor: '#F6F6F6',
            }}>
            <Icon
              color={AppColor.PrimaryColor}
              name={'user'}
              type={'font-awesome'}
            />
            {!selected_contact.dl && (
              <Text style={{ marginLeft: 10 }}>
                {selected_contact.data?.fname} {selected_contact.data?.lname}
              </Text>
            )}
            {selected_contact.dl && (
              <Text style={{ marginLeft: 10 }}>
                {selected_contact.data.text}
              </Text>
            )}
          </TouchableOpacity>
          { consented === false && <View style={{flexDirection:'row',alignItems:'flex-end'}}>
              <Icon name='warning' type={'material'} color={AppColor.danger}/>
              <Text style={{color:AppColor.danger,marginLeft:5}}>This contact has not consented to receive texts. <Text onPress={handleConsentSend} style={{color:AppColor.BLUE100,textDecorationLine:'underline',cursor:'pointer'}}>Send Consent Now</Text></Text>
          </View>
          }
        </View>
        <View style={{ height: 20 }} />
        <View>
          <Text
            style={{
              width: '100%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              marginVertical: 10,
              }}>
            Date
          </Text>
        </View>
        <View style={{ alignItems: 'center', zIndex: 9 }}>
          <View
            style={{
              width: '100%',
              zIndex: 9,
            }}>
            <DateTimePicker
              format="MM/dd/yyyy"
              calendarType="US"
              onChange={setDate}
              value={date}
              disableClock={true}
            />
          </View>
        </View>
        <View>
          <Text
            style={{
              width: '100%',
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              marginVertical: 10,
              }}>
            Time
          </Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <View
            style={{
              width: '100%',
            }}>
            <TimePicker
              popupClassName='time-picker-popup'
              value={time}
              onChange={(val) => setTime(val)}
              showSecond={false}
              minuteStep={15}
              use12Hours={true}
              inputIcon={
                <Icon
                  accessible={true}
                  accessibilityRole='button'
                  color={AppColor.PrimaryColor}
                  name={'back-in-time'}
                  type={'entypo'}
                />
              }
              clearIcon={null}
            />
          </View>
        </View>
        <Text
          style={{
            width: '100%',
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Regular,
            marginVertical: 10,
          }}>
          Frequency
        </Text>


      <Menu w={500} placement='bottom left'  trigger={triggerProps => {
      return( 
        <TouchableOpacity
        style={{backgroundColor:'#F6F6F6',alignItems:'center',justifyContent:'space-between',padding:10,paddingLeft:20,flexDirection:'row'}}
        onPress={e =>  triggerProps.onPress() }
        {...triggerProps}
        > 
         <Text>{
          frequencylist.find(e => e.value === frequency).label
          }</Text> 
          <Icon color={AppColor.PrimaryColor} name='chevron-down' type='entypo' size={24}/>
        </TouchableOpacity>
      )
      }}>
        {
          frequencylist.map(e => {
            return(
              <Menu.Item onPress={() => setFrequency(e.value)}>
              <Text>{e.label}</Text>
            </Menu.Item>
            )
          })
        }
        </Menu>


{/* 
          <CustomModal
            place={'Select Here'}
            value={frequency}
            fun={(v) => setFrequency(v)}
            data={frequencylist}
          /> */}


        <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='Select Template.'
            onPress={() => {
              navigation.navigate('SelectTemplates', {
                screen: 'ScheduledMessage',
                deliveryDate: date,
                time: time,
                frequency: frequency          
              });
            }}
            style={{
              borderBottomColor: AppColor.PrimaryColor,
              borderBottomWidth: 1,
              width: 120,
              marginVertical: 20,
              alignItems: 'center',
            }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                color: AppColor.PrimaryColor,
              }}>
              Select Template
            </Text>
          </TouchableOpacity>

        <View style={{ width: '100%', alignSelf: 'center' }}>
          <CustomInput
            multiline={true}
            value={message}
            textInputStyle={{minHeight:200}}
            onChangeText={(t) => setMessage(t)}
            title={'Message'}
            placeholder={'Type Something'}
          />
        </View>
        {
          calendarEvent &&
          <View>
          <Text style={{marginVertical:10,fontFamily: AppFonts.Roboto_Regular,}}>Calendar Event Title</Text>
          <Text style={styles.cellText}>{calendarEvent?.title}</Text>
          <Text style={{marginVertical:10,fontFamily: AppFonts.Roboto_Regular,}}>Calendar Event Category</Text>
          <Text style={styles.cellText}>{calendarEventCategory}</Text>
          <Text style={{marginVertical:10,fontFamily: AppFonts.Roboto_Regular,}}>Calendar Event Source</Text>
          <Text style={styles.cellText}>{calendarEvent?.source}</Text>

          </View>
        }
        <View
          style={{
            marginVertical: 10,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap:20,
            width: '100%',
            alignSelf: 'center',
          }}>
          <CustomButton
            onPress={() => onCancel()}
            style={{ width: '40%' }}
            title={'Cancel'}
            outline
          />
          <CustomButton
            onPress={() => {
              runPreSaveCheck();
            }}
            style={{ width: '40%' }}
            title={type == 'create' ? 'Save' : 'Update'}
          />
          
        </View>
      </ScrollView>
      {loading && <Loader />}
      {
        confirmUpdateModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={confirmUpdateModal} transparent={true}>
          <RemovePhoneModal
          title="Edit Scheduled Message"
          message={"Are you sure you'd like to edit this scheduled message? This is a recurring message and all associated messages will be affected." }
            handleRemove={() => {
              handleSave();
            }}
            setRemoveModal={setConfirmUpdateModal}
            continueLabel={'Save'}
          />
        </Modal>
      }
    </SafeAreaView>
  );
};

// function DateTimePicker({ value, onChange }) {
//   return createElement('input', {
//     type: 'datetime-local',
//     value: value,
//     onInput: onChange,
//   });
// }


const styles = StyleSheet.create({
  cellTitle: {
      marginVertical:10
  },
  cellText:{
      marginBottom:10,
      fontSize:16,
      backgroundColor: '#F6F6F6',
      paddingVertical:15,
      paddingLeft:10
  }
})

export default AddAutoResponse;
