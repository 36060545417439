import { createSlice } from '@reduxjs/toolkit';
import { EnumFrequency, EnumLocation } from '../Screens/IntegrationProvider';

// realtimeNotes:NoteFrequency.REALTIME.equals(frequency)
// active:!NoteFrequency.NEVER.equals(frequency)

const intitialData = {
  enabled: true,
  frequency: EnumFrequency.DAILY.key,
  location: EnumLocation.NOTE.key,
  createcontact: false,
  updatecontact: false,
  calendarsync: false,
  attributes: null,
  locked: false,
};

const integrationDataSlice = createSlice({
  name: 'integrationData',
  initialState: intitialData,
  reducers: {
    setEnabled: (state, action) => {
      return { ...state, enabled: action.payload };
    },
    setFrequency: (state, action) => {
      return { ...state, frequency: action.payload };
    },
    setLocation: (state, action) => {
      return { ...state, location: action.payload };
    },
    setCreateContact: (state, action) => {
      return { ...state, createcontact: action.payload };
    },
    setUpdateContact: (state, action) => {
      return { ...state, updatecontact: action.payload };
    },
    setCalendarSync: (state, action) => {
      return {...state, calendarsync: action.payload}
    },
    setAttributes: (state, action) => {
      return { ...state, attributes: action.payload };
    },
  },
});

export const {
  setEnabled,
  setFrequency,
  setLocation,
  setUpdateContact,
  setCreateContact,
  setAttributes,
  setCalendarSync
} = integrationDataSlice.actions;

export default integrationDataSlice.reducer;
