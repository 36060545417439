import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const savedMessagesSlice = createSlice({
  name: 'saved_messages',
  initialState: {
    data: [],
  },
  reducers: {
    setSaveMessages: (state, action) => {
      return {
        data: action.payload.data,
      };
    },
  },
});

export const { setSaveMessages } = savedMessagesSlice.actions;

export const loadSavedMessagesThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_saved_messages',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setSaveMessages({ data: result.data.messages }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteSavedMessageThunk = (id) => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'delete_saved_message',
        id: id,
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(loadSavedMessagesThunk());
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export default savedMessagesSlice.reducer;
