import React, { useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'modal-react-native-web';
import { useToast } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import Alert from 'Alert';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Loader from '../../../Components/Loader';
import AppFonts from '../../../Constants/Fonts';
import AppColor from '../../../Constants/Color';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import { loadAccountHealthThunk } from '../../../Redux/account_health';

const AddPhoneModal = ({ visible, setVisible, handleGetPhone }) => {
  const [verficationSent, setVerficationSent] = useState(false);
  const [type, setType] = useState('sms');
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [loading, setLoading] = useState(false);
  const Toast = useToast();

  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.User.profile)?.tenant;

  const sendPhoneCode = async (sms) => {
    let body = {
      action: 'verify_phone',
      phone: phoneNum,
      sms: sms,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    if (!phoneNum || phoneNum.length !== 10) {
      Alert.alert(
        'Error',
        'Your phone number must be 10 digits in order to be valid. Please correct and try again.'
      );
      return;
    }
    try {
      setLoading(true);
      let { data } = await RequestMaker(Config);
      if (data.success) {
        if (!data.verified) {
          setVerficationSent(true);
          if (sms) {
            setType('sms');
            Alert.alert(
              'Heads up!',
              ' We just sent a text message with a verification code to ' +
                phoneNum +
                '. Please get the code from the text and enter it into the verification code field to continue.'
            );
          } else {
            setType('call');
            Alert.alert(
              'Heads up!',
              'You should receive a call in the next few seconds, get your code from the message and enter it into the verification code field to continue.'
            );
          }
        } else {
          setVerficationSent(true);
          setIsVerified(true);
          Alert.alert(
            'Great News!',
            ' This phone number has already been verified. Click continue to proceed to the next step.'
          );
        }
      } else {
        Alert.alert('Error', data.errorMessage);
      }
      setLoading(false);
    } catch (err) {
      Alert.alert('Error', err);
      setLoading(false);
    }
  };

  const handleContinue = async () => {
    if (!isVerified) {
      let body = {
        action: 'verify_phone',
        phone: phoneNum,
        code: otp,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
      };
      setLoading(true);
      try {
        let { data } = await RequestMaker(Config);
        setLoading(false);
        if (data.success) {
          if (data.verified) {
            setIsVerified(true);
            setVisible(false);
            handleSave(phoneNum);
          }
        } else {
          Alert.alert('Error', data.errorMessage);
        }
      } catch (err) {
        setLoading(false);
        Alert.alert('Error', err.message);
      }
    } else {
      setVisible(false);
      handleSave(phoneNum);
    }
  };

  const handleSave = async (phone) => {
    setLoading(true);
    try {
      let body = { action: 'set_forward', countryCode: '1', phone: phone };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Updated Successfully',
          status: 'success',
        });
        handleGetPhone();
        setVerficationSent(false);
        setPhoneNum('');
        setIsVerified(false);
        if (tenant?.id) {
          dispatch(loadAccountHealthThunk(tenant.id));
        }
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={visible}
      transparent={true}
      onDismiss={() => {
        setVerficationSent(false);
        setPhoneNum('');
        setIsVerified(false);
        setVisible(false);
      }}
      ariaHideApp={false}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}
      onBackdropPress={() => {
        setVerficationSent(false);
        setPhoneNum('');
        setIsVerified(false);
        setVisible(false);
      }}>
      {loading && <Loader backgroundColor="transparent" />}
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <TouchableOpacity onPress={() => setVisible(false)}>
              <EvilIcons name="close" size={30} />
            </TouchableOpacity>
          </View>
          <Text style={styles.label}>
            Change and Verify your forwarding number:
          </Text>
          <Text style={styles.description}>
            Step 1: Enter your new forwarding number
          </Text>
          <Text style={styles.description}>
            Step 2: Select the method of verification suitable for the
            forwarding number
          </Text>
          <Text style={styles.description}>
            Step 3: Enter the code received to complete the change
          </Text>
          {verficationSent ? (
            <View>
              {isVerified ? (
                <View>
                  <View style={styles.button}>
                    <CustomButton
                      title="Continue"
                      onPress={() => handleContinue()}
                    />
                  </View>
                  <View style={styles.button}>
                    <CustomButton
                      title="Cancel"
                      onPress={() => {
                        setVerficationSent(false);
                        setPhoneNum('');
                        setIsVerified(false);
                        setVisible(false);
                      }}
                    />
                  </View>
                </View>
              ) : (
                <>
                  <View style={styles.input}>
                    <CustomInput
                      value={otp}
                      onChangeText={(t) => setOtp(t)}
                      title={''}
                      placeholder="Verfication Code"
                      width="100%"
                    />
                  </View>
                  <View
                    style={[
                      styles.button,
                      { flexDirection: 'row', justifyContent: 'space-between' },
                    ]}>
                    <TouchableOpacity
                      onPress={() => {
                        setIsVerified(false);
                        setVerficationSent(false);
                      }}>
                      <Text
                        style={{
                          fontSize: 15,
                          color: AppColor.PrimaryColor,
                          fontFamily: AppFonts.Roboto_Bold,
                          textDecorationLine: 'underline',
                          textDecorationColor: AppColor.PrimaryColor,
                        }}>
                        Change Number
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        if (type === 'sms') {
                          sendPhoneCode(true);
                        } else {
                          sendPhoneCode(false);
                        }
                      }}>
                      <Text
                        style={{
                          fontSize: 15,
                          color: AppColor.PrimaryColor,
                          fontFamily: AppFonts.Roboto_Bold,
                          textDecorationLine: 'underline',
                          textDecorationColor: AppColor.PrimaryColor,
                        }}>
                        Resend Code
                      </Text>
                    </TouchableOpacity>
                  </View>

                  <View>
                    <View style={styles.button}>
                      <CustomButton
                        title="Continue"
                        onPress={() => handleContinue()}
                        disabled={!otp}
                      />
                    </View>
                    <View style={styles.button}>
                      <CustomButton
                        title="Cancel"
                        onPress={() => {
                          setVerficationSent(false);
                          setPhoneNum('');
                          setIsVerified(false);
                          setVisible(false);
                        }}
                      />
                    </View>
                  </View>
                </>
              )}
            </View>
          ) : (
            <>
              <View>
                <CustomInput
                  value={phoneNum}
                  onChangeText={(t) => setPhoneNum(t)}
                  title={''}
                  placeholder="Phone number"
                />
              </View>
              <View style={styles.button}>
                <CustomButton
                  title="Text Me"
                  onPress={() => sendPhoneCode(true)}
                />
              </View>
              <View style={styles.button}>
                <CustomButton
                  title="Call Me"
                  onPress={() => sendPhoneCode(false)}
                />
              </View>
            </>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default AddPhoneModal;

const styles = StyleSheet.create({
  button: {
    marginVertical: 5,
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
  },
  phoneInput: {
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginVertical: 20,
  },
  input: { alignSelf: 'center', width: '100%' },
  label: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 20,
    marginBottom: 10,
  },
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 5,
  },
});
