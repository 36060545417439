import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";
import DateTimePicker from 'react-datetime-picker';
import CustomButton from "../../Components/Button/index.web";
import { useSelector } from "react-redux";
import End_Points from "../../Constants/Api";
import moment from "moment";
import { useToast } from "native-base";

const AccountsReport = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [isOpen, setOpen] = React.useState('');
    const [dates, setDates] = React.useState({
        'all': {
            to: null,
            from: null
        },
        'change': {
            to: null,
            from: null
        },
        'user':{
            to: null,
            from: null
        },
        'billing':{
            to: null,
            from: null
        }
    })

    const Toast = useToast();

    const runReport = type => {
        let url = '';
        const token = localStorage.getItem('token');
        const showToast = () => Toast.show({ title: 'Please select a date range', status: 'error', isClosable: true });

        switch (type) {
            case 'all': {
                if(!dates.all.from || !dates.all.to) return showToast();
                url = `${End_Points.Base_url}/dart/core?action=run_account_report&from=${moment(dates.all.from)}&to=${moment(dates.all.to)}&format=${'csv'}&affiliate=${affiliate.id}&token=${token}`
                
                break;
            }
            case 'change': {
                if(!dates.change.from || !dates.change.to) return showToast();
                url = `${End_Points.Base_url}/dart/core?action=run_account_creation_report&from=${moment(dates.change.from)}&to=${moment(dates.change.to)}&format=${'csv'}&affiliate=${affiliate.id}&token=${token}`
                break;
            }
            case 'user': {
                if(!dates.user.from || !dates.user.to) return showToast();
               url = `${End_Points.Base_url}/dart/core?action=run_user_report&from=${moment(dates.user.from)}&to=${moment(dates.user.to)}&format=${'csv'}&affiliate=${affiliate.id}&token=${token}`
               break;
            }
            case 'billing': {
                if(!dates.billing.from || !dates.billing.to) return showToast();
                url = `${End_Points.Base_url}/dart/core?action=run_billing_audit&from=${moment(dates.user.from)}&to=${moment(dates.user.to)}&format=${'csv'}&affiliate=${affiliate.id}&token=${token}`
                break;
            }

        }

        window.open(url, '_blank');
    }

    return (
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:20}}>All Accounts Activity Report</Text>

            <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginBottom:10 }}>Generates a CSV report of all accounts in the group filtered for account activity in the date range.</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center',gap:20,zIndex:9 }}>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, all: { ...dates.all, from: date } })}
                    value={dates.all.from}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />
                <Text>-</Text>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, all: { ...dates.all, to: date } })}
                    value={dates.all.to}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }
                    on
                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />

            </View>
            <CustomButton onPress={() => runReport('all')} title={'Export All Accounts'} width={'40%'} style={{ alignSelf: 'flex-end',marginTop:20 }} />
            
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:20}}>Account Change Report</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginBottom:10 }}>Generates a CSV report of all accounts that have been created or its state changed in the group during the date range.</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center',gap:20,zIndex:8 }}>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, change: { ...dates.change, from: date } })}
                    value={dates.change.from}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />
                <Text>-</Text>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, change: { ...dates.change, to: date } })}
                    value={dates.change.to}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />

            </View>
            <CustomButton onPress={() => runReport('change')} title={'Export Accounts Created'} width={'40%'} style={{ alignSelf: 'flex-end',marginTop:20 }} />

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:20}}>User Report</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginBottom:10 }}>Generates a CSV report of all users that have been created or whose state has changed in the group during the date range.</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center',gap:20, zIndex: isOpen === 'user' ? 99: 0 }}>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, user: { ...dates.user, from: date } })}
                    value={dates.user.from}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }
                    onCalendarOpen={() => setOpen('user')}

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />
                <Text>-</Text>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, user: { ...dates.user, to: date } })}
                    value={dates.user.to}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }
                    onCalendarOpen={() => setOpen('user')}

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />

            </View>
            <CustomButton onPress={() => runReport('user')} title={'Export Users Created'} width={'40%'} style={{ alignSelf: 'flex-end',marginTop:20 }} />
            


            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:20}}>Billing Report</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginBottom:10 }}>Run a report (generates a CSV) that contains a billing history.</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center',gap:20, zIndex: isOpen === 'billing' ? 99: 0}}>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, billing: { ...dates.billing, from: date } })}
                    value={dates.billing.from}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }
                    onCalendarOpen={() => setOpen('billing')}

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />
                <Text>-</Text>
                <DateTimePicker
                    calendarType="US"
                    onChange={date => setDates({ ...dates, billing: { ...dates.billing, to: date } })}
                    value={dates.billing.to}
                    calendarIcon={
                        <Icon
                            accessibilityRole='button'
                            accessible={true}
                            color={AppColor.PrimaryColor}
                            name={'calendar'}
                            type={'antdesign'}
                        />
                    }
                    onCalendarOpen={() => setOpen('billing')}

                    clearIcon={null}
                    disableClock={true}
                    format="MM/dd/y"
                />

            </View>
            <CustomButton onPress={() => runReport('billing')} title={'Export Billing History'} width={'40%'} style={{ alignSelf: 'flex-end',marginTop:20 }} />
            


        </ScrollView>
    )
}

export default AccountsReport;