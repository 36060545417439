import React from 'react';
import { View, TouchableOpacity, ActivityIndicator } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import SoundPlayer from 'react-native-sound-player';

export default function AudioPlayer(props) {
  const [play, setPlay] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let _onFinishedPlayingSubscription = SoundPlayer.addEventListener(
      'FinishedPlaying',
      ({ success }) => {
        console.log('finished playing', success);
        setPlay(false);
        SoundPlayer.pause();
      }
    );
    let _onFinishedLoadingSubscription = SoundPlayer.addEventListener(
      'FinishedLoading',
      ({ success }) => {
        console.log('finished loading', success);
        setLoading(false);
      }
    );

    return () => {
      _onFinishedPlayingSubscription.remove();
      _onFinishedLoadingSubscription.remove();
    };
  }, []);
  React.useEffect(() => {
    if (play) {
      try {
        SoundPlayer.playUrl(props.url);
        setLoading(true);
      } catch (e) {
        console.log(`cannot play the sound file`, e);
        setLoading(false);
      }
    } else {
      try {
        SoundPlayer.pause();
      } catch (e) {
        console.log(`cannot play the sound file`, e);
      }
    }
  }, [play]);

  return (
    <TouchableOpacity
      onPress={() => {
        setPlay(!play);
      }}>
      {!loading && (
        <AntDesign
          name={!play ? 'play' : 'pause'}
          color={props.color}
          size={80}
        />
      )}
      {loading && <ActivityIndicator color={'white'} size={20} />}
    </TouchableOpacity>
  );
}

AudioPlayer.defaultProps = {
  color: 'white',
  url: '',
};
