import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import CustomInput from "../../../Components/CustomInput/index.web";
import CustomButton from "../../../Components/Button/index.web";
import { useToast } from "native-base";
import RequestMaker from "../../../Middleware/ApiCaller";

const PushKey = ({user,setUser}) => {
    const Toast = useToast();

    const handleSendPushNotification = async () => {
        try{
            const body = {
                action: "send_test_push_message",
                id: user.user_id
            }
            const result = await RequestMaker(body);
            if(result.data.success && !String(result.data?.response).includes('Error')){
                Toast.show({
                    title: 'Push notification sent',
                    status: 'success'
                })
            } else {
                throw new Error(result.data?.response || result.data?.errorMessage || 'An error occurred')
            }
        } catch(err){
            Toast.show({
                title: new Error(err).message,
                status: 'error'
            })
        }
    }

    return(
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>
                This is a unique phone identifier that is used to push messages to a users phone. This is set when a user logs in on their mobile device. This is just here for display, it cannot be set/changed.
            </Text>
            <CustomInput containerStyle={{marginBottom:20}} title={'Push Key'} value={user?.pushKey ?? ''} disabled={true} />
            <CustomButton style={{alignSelf:'flex-end'}} onPress={handleSendPushNotification} width={'40%'} title={'Send Push Notification'}/>
        </>
    )
}

export default PushKey;