import React from 'react';
import { View, ScrollView, Image, Pressable, Text} from 'react-native';
import Modal from 'modal-react-native-web';
import { Icon } from 'react-native-elements';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import CustomButton from '../Button/index.web';

const AttachmentPreviewModal = React.memo(props => {
    console.log(props);
    return(
        <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        appElement={document.getElementById('app')} 
        transparent={true}
        visible={props.visible}
        onDismiss={() => {
          props.setVisible(false);
        }}>
        <View
        style={{position:'absolute',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center'}}>
            <View style={{padding: 30,
            backgroundColor:'white',
            position:'relative',
            justifyContent:'center',
            alignItems:'flex-start',
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            pointerEvents:'box',
            elevation: 5,
        
            }}>
                <View style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',marginBottom:20,width:'100%'}}>
                <Text style={{fontFamily:AppFonts.Roboto_Regular, fontSize:22}}>Upload file</Text>
                <Icon onPress={()=>props.setVisible(false)} name='close' type='font-awesome' style={{lineHeight:22}}/>
                </View>

                {
                props.file?.file?.type?.includes('image') ? 
                <img src={ props.file.uri} style={{maxWidth:500}}/>:
                <View
                style={{flexDirection:'row', alignItems:'center',padding:10,backgroundColor:'rgb(227, 227, 227)',borderRadius:5}}
                >
                    <Icon type='antdesign' name='file1' size={27}  style={{marginRight:10}}/>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular,color:AppColor.PrimaryColor,fontWeight:'200'}}>{props?.file?.name}</Text>
                    <Icon type='antdesign' name='download' style={{marginLeft:10}} />
                </View>
                }
                <CustomButton title="SEND" style={{alignSelf:'flex-end',marginTop:20}} onPress={props.onPress} />


            </View>
            </View>
        </Modal>
    )
})

export default AttachmentPreviewModal;
