import React from "react";
import { Switch, Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import useUpdateAffiliate from "../useUpdateAffiliate";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";

const CustomSupport = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const [data, setData] = React.useState({
        customSupport: affiliate?.customSupport || false,
        supportPhone: affiliate?.supportPhone || '',
        supportEmail: affiliate?.supportEmail || '',
        supportHours: affiliate?.supportHours || ''

    })

    return (
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                If you intend to provide support for MyRepChat, you can enable custom support here.
                When custom support is enabled, the phone, email, and hours that you provide will be displayed to the user in the Contact Us area of Support within the portal and mobile app.
            </Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Use Custom Support</Text>
            <Switch
            style={{marginTop:10}}
            value={data.customSupport}
            onValueChange={e => setData({ ...data, customSupport: e })}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Support Phone Number</Text>
            <CustomInput
            onChangeText={e => setData({ ...data, supportPhone: e })}
            value={data.supportPhone}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Support Email Address</Text>
            <CustomInput
            onChangeText={e => setData({ ...data, supportEmail: e })}
            value={data.supportEmail}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Support Hours</Text>
            <CustomInput
                onChangeText={e => setData({ ...data, supportHours: e })}
                value={data.supportHours}
                placeholder='ex: 8am - 5pm CST'
            />
            
            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end', marginBottom: 10, marginRight: 10 }}
            />

        </>
    )
}

export default CustomSupport;