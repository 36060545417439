import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const initialState = {
  mrcNumber: {
    value: '',
    healthy: true,
  },
  voice: {
    capable: true,
    value: '',
    healthy: true,
  },
  voiceHealth: {
    missingGreetings: [],
    missingAvailability: [],
    usingVoiceAvailability: true,
  },
  contacts: [
    {
      name: '',
      hasMissing: true,
      hasMessaging: true,
      hasDuplicate: true,
    },
  ],
  orphans: [],
  crms: [
    {
      provider: '',
      active: true,
      enabled: true,
      connectionTest: '',
    },
  ],
  content: [
    {
      provider: '',
      active: true,
      enabled: true,
    },
  ],
  archive: [
    {
      provider: '',
      active: true,
      enabled: true,
    },
  ],
  warnings: {
    timezone: true,
  },
  success: true,
  errorMessage: null,
  loading: false,
};

const accountHealthSlice = createSlice({
  name: 'account_health',
  initialState: initialState,
  reducers: {
    setAccountHealthRequest: (state, action) => {
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    },
    setAccountHealth: (state, action) => {
      return {
        ...state,
        ...action.payload.data,
        loading: false,
      };
    },
    setAccountHealthError: (state) => {
      return {
        ...state,
        loading: false,
        errorMessage: 'An error occurred',
      };
    },
  },
});

export const {
  setAccountHealth,
  setAccountHealthRequest,
  setAccountHealthError,
} = accountHealthSlice.actions;

export const loadAccountHealthThunk = (tenant) => {
  return async (dispatch) => {
    try {
      dispatch(setAccountHealthRequest());
      const body = {
        action: 'run_health_check',
        tenant: tenant,
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setAccountHealth({ data: result.data }));
      } else {
        dispatch(setAccountHealthError());
      }
    } catch (err) {
      dispatch(setAccountHealthError());
      console.log(err);
    }
  };
};

export default accountHealthSlice.reducer;
