import React from "react";
import { Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";

const Disclosure = ({tenant}) => {
    const Tenant = tenant.data.tenant;
    const {data, isLoading, isError} = getQuery(
        {
            action: 'get_matching_disclosure',
            tenantId: Tenant.id
        }
    )

    if(isLoading) return <Loader backgroundColor="transparent"/>;
    if(!data) return null;

    let disclosureFrequency = '';
    let disclosureText = '';

    if(data.disclosure){
        if (data.disclosure.frequencyType === "always") {
            disclosureFrequency = "Always";
        } else if (data.disclosure.frequencyType === "once") {
            disclosureFrequency = "Once";
        } else {
            disclosureFrequency = "Every " + data.disclosure.frequencyUnit + " " + data.disclosure.frequencyValue;
        }

        disclosureText = data.disclosure.text;
    }



    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                The disclosure that is used for this account. Disclosures are managed by your group administrator.
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Frequency
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {disclosureFrequency}
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Text
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                {disclosureText}
            </Text>


        </View>
    )

}

export default Disclosure;