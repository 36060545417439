import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step: {
    phone: false,
    card: false,
    crm: false,
    archive: false,
    payment: false,
    finished: false,
  },
};
const signUpWizard = createSlice({
  name: 'signUpWizard',
  initialState: initialState,
  reducers: {
    setCompletedStep: (state, action) => {
      return {
        ...state,
        step: {
          ...state.step,
          ...action.payload,
        },
      };
    },
  },
});

export const { setCompletedStep } = signUpWizard.actions;

export default signUpWizard.reducer;
