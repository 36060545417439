import React from "react";
import { Content, SideBar, Wrapper } from "../../Components/Layout";
import Accordion from "../../Components/Accordion";
import AppFonts from "../../Constants/Fonts";
import ResellerDashboard from "../Reseller/ResellerDashboard";
import { Text, View } from "react-native";
import { Icon } from "react-native-elements";
import ResellerInformation from "./ResellerInformation";
import ResellerLogins from "./ResellerLogins";

const Reseller = ({route, navigation}) => {
    const resellerId = route.params.reseller.id;
    const [screen, setScreen] = React.useState('');

    const resellerSettings = [
        {
            title: 'Reseller Dashboard',
            onPress: () => setScreen('Reseller Dashboard')
        },
        {
            title: 'Reseller Information',
            onPress: () => setScreen('Reseller Information')
        },
        {
            title: 'Logins',
            onPress: () => setScreen('Logins')
        },
    ]

    const KEY_COMPONENT = {
        'Reseller Dashboard': <ResellerDashboard route={{params: {reseller: resellerId}}} />,
        'Reseller Information': <ResellerInformation reseller={route.params.reseller} />,
        'Logins': <ResellerLogins reseller={route.params.reseller} />,
    }

    return(
        <Wrapper>
            <SideBar>
            <View style={{flexDirection:'row',alignItems:'center',borderBottomWidth:1,borderBottomColor:'rgba(0,0,0,.25)'}}>
                <Icon style={{padding:10}} onPress={() => navigation.pop()} name='chevron-left' type={'ionicons'} size={24}/>
                <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{route.params.reseller.name}</Text>
                </View>

                <Accordion 
                data={resellerSettings}
                />

            </SideBar>
            <Content>
            {
                screen &&
                <View style={{flex:1}}>

                    <Text style={{fontFamily:AppFonts.Roboto_Bold,marginBottom:0,borderBottomColor:'rgba(0,0,0,.25)',borderBottomWidth:1,paddingBottom:10,paddingTop:10,paddingLeft:10}}>{screen}</Text>
                    <View style={{flex:1}}>
                    {KEY_COMPONENT[screen]}
                    </View>
                </View>
             }

            </Content>
        </Wrapper>
    )
}

export default Reseller;