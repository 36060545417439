import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  Switch,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'native-base';

import Alert from 'Alert';
import CustomButton from '../../../../Components/Button';
import CustomInput from '../../../../Components/CustomInput';
import Loader from '../../../../Components/Loader';
import Header from '../../../../Components/Header';
import AppColor from '../../../../Constants/Color';
import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api/index';
import { loadRecentMediaThunk } from '../../../../Redux/recent_media';
import AppFonts from '../../../../Constants/Fonts';
import { setAttributes } from '../../../../Redux/integrationData';
import { loadIntegrationsThuunk } from '../../../../Redux/integrations';

const Email = ({ provider, integration, groupId }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);

  const { profile } = useSelector((state) => state.User);
  const { attributes } = useSelector((state) => state.integrationData);

  const Toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    if (integration) {
      console.log(integration);
      setEmail(integration.email);
      setAddress(integration.senderEmail);
      setName(integration.senderName);
      setActive(integration.active);
    }
  }, [integration]);

  const handleSave = async () => {
    dispatch(
      setAttributes({
        ...attributes,
        name,
        address,
        email,
        active,
      })
    );

    let body = {
      action: 'create_integration',
      email: email,
      provider: 'email',
      senderEmail: address,
      senderName: name,
      active: active, 
      tenant: profile.tenant?.id,
    };
    if (integration?.id) {
      body.action = 'update_integration';
      body.id = integration.id;
    }
    if(groupId){
      body.group = groupId;
    }

    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'This email address has been Saved',
          status: 'success',
        });
        if (body.action === 'create_integration') await createSchedule();
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({ duration: 2000, title: err.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const createSchedule = async () => {
    const body = {
      action: 'create_archive_schedule',
      provider: 'email',
      type: 'archive',
      email: email,
      active: 'active',
      frequency: 1,
      tenantId: profile.tenant?.id,
      frequencyHours: 24,
      layoutId: 2,
      locked: false,
      startTimestamp: new Date().setHours(0, 0, 0),
      state: 'active',
    };
    if(groupId){
      body.affiliateId = groupId;
    }
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        dispatch(loadIntegrationsThuunk());
        Alert.alert(
          'A default schedule for your archive has been created using a default layout. To modify your archive configuration, look at Archive Layout and Archive Schedules. More information is available in our support section.'
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSender = async () => {
    const body = {
      action: 'confirm_email',
      email: email,
    };
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        if (result.data.confirmed) {
          Toast.show({
            duration: 2000,
            title: 'This email address has been confirmed',
            status: 'success',
          });
        } else {
          Toast.show({
            duration: 2000,
            title:
              'A confirmation email has been sent to this email address. Please follow the instruction in this email to confirm your email address.',
            status: 'info',
          });
        }
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({ duration: 2000, title: err.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          <View style={styles.topComment}>
            <Text style={styles.text}>
              MyRepChat can send archive reports to an email address. The email
              address below will replace the TO address in the layout. To send
              to multiple addresses, separate the addresses with a semi-colon
              (;).
            </Text>
            <Text style={styles.text}>
              You can also provide a sender email and name which when present
              will be used in place of the default (journal@mail.ionlake.com)
              email address. This is known as spoofing and some email servers
              may detect this and reject the email because of it. Only use a
              different sender if you know it is required for your situation.
            </Text>
          </View>
          <CustomInput
            title="EMAIL ADDRESS*"
            value={email}
            onChangeText={(val) => setEmail(val)}
          />
          <CustomInput
            title="SENDER ADDRESS (EMAIL SPOOFING)"
            value={address}
            onChangeText={(val) => setAddress(val)}
            placeholder="(Optional - leave blank for default)"
          />
          <CustomInput
            title="SENDER NAME (EMAIL SPOOFING)"
            value={name}
            onChangeText={(val) => setName(val)}
            placeholder="(Optional - leave blank for default)"
          />

        <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 10,
        }}>
        <Text
          style={{
            marginRight: 5,
            fontSize: 18,
            color: AppColor.Black200,
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          ACTIVATE ARCHIVING
        </Text>
        <Switch
          value={active}
          trackColor={{ false: 'red', true: 'green' }}
          
          onValueChange={(v) => {
            setActive(v)
          }}
        />
      </View>

          <View style={styles.buttonWrapper}>
            <CustomButton
              title="save"
              onPress={handleSave}
              width={150}
              disabled={!email}
            />
            <CustomButton
              title="Confirm Sender"
              onPress={handleConfirmSender}
              width={250}
              disabled={!email}
            />
          </View>
        </View>
      </SafeAreaView>
      {loading && <Loader backgroundColor="transparent" />}
    </>
  );
};

export default Email;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 30,
  },
  topComment: {},
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
});
