import { useNavigation } from '@react-navigation/native';
import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import { Icon } from 'react-native-elements';
import AppColor from '../Constants/Color';
import AppFonts from '../Constants/Fonts';
import useResponsiveLayout from '../Middleware/useResponsiveLayout';

const DrawerMenuItem = props => {
    const {isWebCompact} = useResponsiveLayout();


    return(
        <TouchableOpacity
        accessible={true}
        accessibilityRole='button'
          onPress={props.onPress}
          style={{
            minHeight: 35,
            flexDirection: 'row',
            marginHorizontal:20,
          }}>
          <View style={{ justifyContent: 'center' }}>
            <Icon
              color={'white'}
              name={props.iconName}
              type={props.iconType}
            />
          </View>
          <View
            style={{
              justifyContent: 'center',
              flex: 1,
              alignItems: 'flex-start',
              paddingVertical: !isWebCompact ? 0 : 10,

            }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 16,
                color: 'white',
                marginLeft: 15,
              }}>

              {!isWebCompact && props.label}
            </Text>
          </View>
        </TouchableOpacity>

    )
}

export default DrawerMenuItem;