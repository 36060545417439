import { Icon } from 'react-native-elements';
import React from 'react';
import { Text, TouchableOpacity, View, Image, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedContact } from '../../Redux/selected_contact';
import AlphabetList from 'react-native-flatlist-alphabet';
import { CheckBox } from 'react-native-elements';
import End_Points from '../../Constants/Api';
import { providers } from '../IntegrationProvider';
import LogoContactImage from '../../Assets/Images/splashlogo.png';

const StartConversation = ({ navigation }) => {
  const contactData = useSelector((state) => state.contacts).data;
  const [search, setSearch] = React.useState('');
  const dls = useSelector((state) => state.distributions).data;
  const [allContactData, setAllContactData] = React.useState([]);
  const [dlSelected, setdlSelected] = React.useState(true);
  const [contactSelected, setContactSelected] = React.useState(true);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter(
        (x) =>
          x.fname.toLowerCase().includes(search.trim().toLowerCase()) ||
          x.lname.toLowerCase().includes(search.trim().toLowerCase())
      );
      const searchDls = dls.filter((x) =>
        x.text.toLowerCase().includes(search.trim().toLowerCase())
      );
      let arr = [];
      for (let x of searchDls) {
        arr.push({ id: x.id, name: x.text, dl: true, item: x });
      }
      setAllContactData(searchedData.concat(arr));
    } else {
      setAllContactData(contactData);
    }
  }, [search]);

  React.useEffect(() => {
    if (contactData.length > 0) {
      let arr = [];
      for (let x of contactData) {
        if (x.phoneNumbers.length > 0) {
          for (let phone of x.phoneNumbers) {
            if (phone.messaging) {
              let data = { ...x };
              data['mobile'] = phone.longCode;
              arr.push(data);
            }
          }
        }
      }
      for (let x of dls) {
        arr.push({ id: x.id, name: x.text, dl: true, item: x });
      }
      setAllContactData(arr);
    }
  }, [contactData, dls]);

  React.useEffect(() => {
    if (dlSelected & contactSelected) {
      let arr = [];
      for (let x of contactData) {
        if (x.phoneNumbers.length > 0) {
          for (let phone of x.phoneNumbers) {
            if (phone.messaging) {
              let data = { ...x };
              data['mobile'] = phone.longCode;
              arr.push(data);
            }
          }
        }
      }
      for (let x of dls) {
        arr.push({ id: x.id, name: x.text, dl: true, item: x });
      }
      setAllContactData(arr);
    } else {
      if (dlSelected) {
        let arr = [];
        for (let x of dls) {
          arr.push({ id: x.id, name: x.text, dl: true, item: x });
        }
        setAllContactData(arr);
      } else if (contactSelected) {
        let arr = [];
        for (let x of contactData) {
          if (x.phoneNumbers.length > 0) {
            for (let phone of x.phoneNumbers) {
              if (phone.messaging) {
                let data = { ...x };
                data['mobile'] = phone.longCode;
                arr.push(data);
              }
            }
          }
        }
        setAllContactData(arr);
      } else {
        setAllContactData([]);
      }
    }
  }, [contactSelected, dlSelected]);
  const renderListView = ({ item }) => {
    let image = LogoContactImage;
    let provider = Object.values(providers).find((i) => i.name == item.source);
    if (provider) {
        image = provider.smallImageUrlPath;
    }

    if (item.dl) {
      return <RenderListView1 item={item.item} />;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          dispatch(setSelectedContact({ data: item, dl: false }));
          navigation.goBack();
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Image
            source={image}
            style={{ height: 40, width: 40 }}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 12,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.mobile}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const RenderListView1 = ({ item }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          dispatch(setSelectedContact({ data: item, dl: true }));
          navigation.goBack();
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon name="tags" type="antdesign" color={AppColor.PrimaryColor} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          {item.text && (
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}>
              {item.text}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Search
        onChangeText={(t) => setSearch(t)}
        placeholder={'Filter by name'}
      />
      <View style={{ flexDirection: 'row', paddingHorizontal: 30 }}>
        <CheckBox
          containerStyle={{ flex: 1 }}
          title="DLs"
          checked={dlSelected}
          onPress={() => setdlSelected((state) => !state)}
        />
        <CheckBox
          containerStyle={{ flex: 1 }}
          title="Contacts"
          checked={contactSelected}
          onPress={() => setContactSelected((state) => !state)}
        />
      </View>
      <ScrollView style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 30 }}>
        <AlphabetList
          data={allContactData.map((x, i) => ({
            value: x.name ?? '',
            item: x,
            key: x.id + '' + i,
          }))}
          renderItem={renderListView}
          renderSectionHeader={(d) => {
            return (
              <View style={{ marginHorizontal: 10, marginVertical: 5 }}>
                <Text
                  style={{
                    backgroundColor: AppColor.PrimaryColor,
                    width: 20,
                    textAlign: 'center',
                    color: 'white',
                  }}>
                  {d.title}
                </Text>
              </View>
            );
          }}
          sectionHeaderHeight={40}
          indexLetterColor={AppColor.PrimaryColor}
          letterItemStyle={{ marginTop: 8 }}
        />
      </ScrollView>
    </SafeAreaView>
  );
};
export default StartConversation;
