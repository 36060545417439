import React, { useEffect } from 'react';
import { Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import Loader from '../../../Components/Loader';

const SSOScreen = ({ navigation, route }) => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (!route?.params.url) return;
    Linking.canOpenURL(route?.params.url).then((supported) => {
      if (supported) {
        Linking.openURL(route?.params.url);
      } else {
        console.log("Don't know how to open URI: ");
      }
    });
  }, [route]);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Single Sign On'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default SSOScreen;
