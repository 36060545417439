import React from "react";
import { Text, View } from "react-native";
import AppFonts from "../Constants/Fonts";

const SimpleBulletList = ({data, style}) => {
    return(
        <View style={{gap:5,...style}}>
        {
            data.map(e => {
                return (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ backgroundColor: 'black', width: 4, height: 4, borderRadius: 100, marginRight: 5 }} />
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{e}</Text>
                    </View>
                )
            })
        }
        </View>

    )
}

export default SimpleBulletList;