import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';

const DateBtn = ({ item, onPress, selectedDate }) => {
  return (
    <TouchableOpacity onPress={() => onPress(item)}>
      <View
        style={[
          styles.container,
          selectedDate === item && styles.selectedDate,
        ]}>
        <Text
          style={[styles.text, selectedDate === item && styles.selectedText]}>
          {item}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default DateBtn;

const styles = StyleSheet.create({
  container: {
    margin: 5,
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    borderColor: '#f4f4f4',
  },
  text: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 16,
    color: AppColor.PrimaryColor,
  },
  selectedText: {
    color: AppColor.SecondaryColor,
  },
  selectedDate: {
    backgroundColor: AppColor.PrimaryColor,
    borderColor: AppColor.PrimaryColor,
  },
  selectedAvailability: {
    backgroundColor: AppColor.SuccessColor,
    borderColor: AppColor.SuccessColor,
  },
});
