/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';

const CustomButton = (props) => {

  const [isHovered, setIsHovered] = React.useState(false)

  const outlineStyle = {
    backgroundColor: isHovered ? props.backgroundHoverColor ? props.backgroundHoverColor : '#E1F1FF' : 'transparent',
    borderColor: AppColor.PrimaryColor,
    borderWidth: 2,
  }

  const solidStyle = {
    backgroundColor: isHovered ? '#20508A' : AppColor.PrimaryColor,
    borderColor: 'transparent',
    borderWidth: 0,
  }

  const commonStyle = {
    height: 40,
    width: props.width ? props.width : '80%',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  }

  const style = {
    ...commonStyle,
    ...(props.outline ? outlineStyle : solidStyle),
    ...props.style,
    borderRadius:50,
  }

  let textColor = 'white';

  if(props.outline){
    textColor = AppColor.PrimaryColor;
  }

  if(props.textColor){
    textColor = props.textColor;
  }
  
  if(props.textHoverColor && isHovered){
    textColor = props.textHoverColor;
  }

  return (
    <>
      <TouchableOpacity
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        disabled={props.disabled}
        onPress={props.onPress}
        accessible={true}
        accessibilityRole='button'
        style={style}>
        {props.icon}
        <Text
          numberOfLines={1}
          style={{
            fontWeight: props.outline ? 500 : 200,
            fontSize: 18,
            fontFamily: AppFonts.Roboto_Regular,
            textTransform: 'uppercase',
            color: textColor,
            ...props.textStyle,
          }}>
          {props.title}
        </Text>
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  conatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CustomButton;
