import React from 'react';
import { Text, View } from 'react-native';
import Channels from './Channels';
import Ten4Chat from './Ten4Chat';
import Header from '../../Components/Header';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import { Bubbles } from '../Authentication/Login/Bubbles';
import { useSelector } from 'react-redux';
import { getChatUsers } from '../../Api/ten4';

const TenFour = ({route, navigation}) => {
    const [channel, setChannel] = React.useState({});
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [chatUsers, setChatUsers] = React.useState([]);

    React.useEffect(()=> {
        const unsubscribe = navigation.addListener('focus', () => {
            getTen4Users()
        })
        
        return unsubscribe;

    },[navigation])
    
    React.useEffect(()=> {
        const unsubscribe = navigation.addListener('blur', () => {
            setChannel({});
        })
        
        return unsubscribe;

    },[navigation])
    
    const getTen4Users = async () => {
        getChatUsers({affiliate: affiliate.id})
        .then(result => {
            if(result.data.success){
                setChatUsers(result.data.users);
            }
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    const type = route.params?.type === 'private' ? 'private' : 'public'
    return(
        <>
        <Header title="Ten4"/>
        <Wrapper>
            <SideBar>
                <Channels 
                type={type}
                navigation = {navigation}
                setChannel = {setChannel}
                channel={channel}
                />
            </SideBar>
            <Content>
                { channel?.id ?
                <Ten4Chat
                    users={chatUsers}
                    navigation={navigation}
                    channel={channel}
                />
                :
                <Bubbles/>
                }
            </Content>

        </Wrapper>
        </>
    )

}

export default TenFour;