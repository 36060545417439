import React from "react";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import CustomButton from "../../Components/Button/index.web";
import { useSelector } from "react-redux";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const AddPattern = ({ pattern, onSave }) => {

    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const Toast = useToast();

    const [item, setItem] = React.useState({
        name: pattern.name,
        pattern: pattern.pattern,
        holdMessage: pattern.holdMessage,
        rejectMessage: pattern.rejectMessage,
    })

    React.useEffect(() => {
        setItem(pattern);
    }, [pattern])

    const handleSave = async () => {
        try {
            const body = {
                groupid: affiliate.id,
                ...pattern,
                ...item,
            }
            if (pattern.id) {
                body.action = 'update_monitor_pattern';
            } else {
                body.action = 'create_monitor_pattern';
            }

            const result = await RequestMaker(body);
            if (result.data.success) {
                onSave()
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <>
            <CustomInput value={item.name} onChangeText={val => setItem({ ...item, name: val })} title={'Name of Pattern'} />
            <CustomInput value={item.pattern} onChangeText={val => setItem({ ...item, pattern: val })} containerStyle={{ marginBottom: 10 }} titleStyle={{ marginTop: 10 }} title={'Pattern Regex'} />
            <SimpleCheckbox value={item.holdMessage} onChange={val => setItem({ ...item, holdMessage: val })} label={'Hold Message'} />
            <SimpleCheckbox value={item.rejectMessage} onChange={val => setItem({ ...item, rejectMessage: val })} label={'Reject Message'} />
            <CustomButton onPress={handleSave} title={'Save'} width={'30%'} style={{ alignSelf: 'flex-end', marginTop: 10 }} />
        </>
    )
}

export default AddPattern;