import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import SimpleDropdown from "../../../Components/SimpleDropdown";

const Status = ({user, setUser}) => {

    const statusSettings = [
        {
            value: "1",
            label: 'Active'
        },
        {
            value: "0",
            label: 'Inactive'
        },
        {
            value: "3",
            label: 'Review'
        },
    ]

    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:10}}>
            Use this to enable/disable this user.
        </Text>
        <SimpleDropdown 
            data={statusSettings}
            value={String(user.status)}
            onChange={e => setUser({...user, status: e})}
        />
        </>
    )
}

export default Status;