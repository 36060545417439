import React from "react"
import { useFocusEffect } from "@react-navigation/native";
import { Text, View } from "react-native";
import RequestMaker from "../Middleware/ApiCaller";
import { useSelector } from "react-redux";
import { Content, SideBar, Wrapper } from "../Components/Layout";
import { TitleCard } from "../Screens/Dashboard/TitleCard";
import AppFonts from "../Constants/Fonts";
import moment from "moment";
import getQuery from "./getQuery";
import Loader from "../Components/Loader/index.web";
import BoxButton from "../Components/BoxButton";
import { Icon } from "react-native-elements";


const Dashboard = ({ route, navigation }) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const {data, isLoading} = getQuery(
        {
            action: "get_affiliate_stats",
            affiliateId: affiliate?.id
        }
    )

    if (isLoading) return <Loader backgroundColor={'transparent'}/>
    if (!data) return null;

    const sumSMSValues = (data) => {
        let inboundValues = 0;
        let outboundValues = 0;
        
        data.forEach(item => {
            if (item.type === "comm.sms.inbound") {
                inboundValues += item.value;
            } else if (item.type === "comm.sms.outbound") {
                outboundValues += item.value;
            }
        });
        return {
            inboundValues,
            outboundValues
        };
    }
    const todayDate = Date.now();
    const sevenDayBefore = todayDate - 7 * 24 * 60 * 60 * 1000;
  

    const {inboundValues, outboundValues} = sumSMSValues(data.messageStats); 

    return (
        <Wrapper>
            <SideBar>
            {route.params?.reseller &&
                    <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,.25)' }}>
                        <Icon style={{ padding: 10 }} onPress={() => navigation.pop()} name='chevron-left' type={'ionicons'} size={24} />
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{affiliate.name}</Text>
                    </View>
                }


                <View style={{ flex: 1, paddingVertical: 25, paddingHorizontal: 15 }}>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontWeight: 500, fontSize: 18, marginBottom: 20 }}>Account Data</Text>

                    <TitleCard type='row'
                        title='Accounts'
                        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                        subtitle={data.tenants}
                    />
                    <TitleCard type='row'
                        title='Logins'
                        style={{ borderRadius: 0, borderTop: 'none' }}
                        subtitle={data.loginCount}
                    />
                    <TitleCard type='row'
                        title='Additional Logins'
                        style={{ borderRadius: 0, borderTop: 'none' }}
                        subtitle={data.addnlLogins}
                    />
                    <TitleCard type='row'
                        title='Voice Add-Ons'
                        style={{ borderRadius: 0, borderTop: 'none' }}
                        subtitle={data.voiceCount}
                    />
                    <TitleCard type='row'
                        title='Archive Add-Ons'
                        style={{ borderRadius: 0, borderTop: 'none' }}
                        subtitle={data.archiveCount}
                    />
                    <TitleCard type='row'
                        title='Recording Add-Ons'
                        style={{ borderRadius: 0, borderTop: 'none' }}
                        subtitle={data.recordCount}
                    />
                    <TitleCard type='row'
                        title='Transcribe Add-Ons'
                        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTop: 'none' }}
                        subtitle={data.transcribeCount} />

                    <Text style={{ marginTop: 50,fontFamily: AppFonts.Roboto_Regular, fontWeight: 500, fontSize: 18 }}>Message Activity</Text>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular,fontWeight:100,marginTop:10,marginBottom:20}}>({moment(sevenDayBefore).format('MMM D YYYY')} - {moment(todayDate).format('MMM D YYYY')})</Text>

                    <View style={{ flexDirection: 'row', gap: 20 }}>
                        <TitleCard type='block' subtitle={`${inboundValues}`} title='Received' />
                        <TitleCard type='block' subtitle={`${outboundValues}`} title='Sent' />

                    </View>



                </View>
            </SideBar>
            <Content>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 15,padding:15}}>

                <BoxButton onPress={() => navigation.navigate('Accounts', {screen: 'AccountsList', params:{tenantStatus: 1}})} title={'Active Accounts'} count={data.activeAccounts} iconName={'user'} iconType={'font-awesome'} />
                <BoxButton onPress={() => navigation.navigate('Accounts', {screen: 'AccountsList',params:{tenantStatus: 2}})} title={'Inactive Accounts'} count={data.inactiveAccounts} iconName={'user-slash'} iconType={'font-awesome-5'} />
                <BoxButton onPress={() => navigation.navigate('Accounts', {screen: 'AccountsList',params:{tenantStatus: 4}})} title={'Trial Accounts'} count={data.trialAccounts} iconName={'user-clock'} iconType={'font-awesome-5'} />
                <BoxButton onPress={() => navigation.navigate('Accounts', {screen: 'AccountsList',params:{tenantStatus: 3}})} title={'Review Accounts'} count={data.reviewAccounts} iconName={'user-check'} iconType={'font-awesome-5'} />
                <BoxButton onPress={() => navigation.navigate('Accounts', {screen: 'AccountsList',params:{tenantStatus: 90}})} title={'Comp Accounts'} count={data.compAccounts} iconName={'gift'} iconType={'feather'} />
                <BoxButton onPress={() => navigation.navigate('Accounts', {screen: 'AccountsList',params:{tenantStatus: 5}})} title={'Default Accounts'} count={data.defaultAccounts} iconName={'payment'} iconType={'material'} />
            </View>
            </Content>
        </Wrapper>
    )
}

export default Dashboard;