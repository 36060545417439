import React from 'react';
import {
  StyleSheet,
  Image,
  Text,
  SafeAreaView,
  KeyboardAvoidingView,
  TouchableOpacity,
  Platform,
  View,
  Keyboard,
  TextInput,
  ActivityIndicator,
  Pressable,
} from 'react-native';
// document picker
// import DocumentPicker from 'react-native-document-picker';
import * as DocumentPicker from 'expo-document-picker';
// image picker
import Alert from 'Alert';
// import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { useRoute } from '@react-navigation/native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import getData from '../../AsyncUtils/GetData';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api';
import { useToast } from 'native-base';
import { useSelector } from 'react-redux';
import Octicons from 'react-native-vector-icons/Octicons';

export default function AllCollections({
  navigation,
  setVisible,
  member,
  loadMessage,
  setLoading,
  dl,
  typedmessage,
  memberData
}) {
  // const [loading, setLoading] = React.useState(false)

  const route = useRoute();




  const group = useSelector((state) => state.User?.profile?.group);

  const tenant = useSelector((state) => state.User?.profile?.tenant);
  const Toast = useToast();
  const integrations = useSelector((state) => state.integrations)?.integrations;
  const [sendContent, setSendContent] = React.useState(false);

  React.useEffect(() => {
    if (integrations?.length > 0) {
      for (let i = 0; i < integrations.length; i++) {
        if (integrations[i]?.type == 'content' && integrations[i]?.enabled) {
          setSendContent(true);
          break;
        }
      }
    }
  }, [integrations]);
  const handleDocumentPicker = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: '*/*',
      });
      setVisible(false);

      if (res.type == 'success') {
        AlertAskPermission(res);
      }
    } catch (err) {
      setVisible(false);
      console.log(err);
      // if (DocumentPicker.isCancel(err)) {
      //     console.log("canecled")
      //     // User cancelled the picker, exit any dialogs or menus and move on
      // } else {
      //     throw err;
      // }
    }
  };

  const handleSendMedia = async (mediaId) => {
    try {
      const data = {
        action: 'send_text_message',
        type: 'mms',
        text: '',
        mediaId: mediaId,
      };
      if (dl == null) {
        data['members'] = [member.id];
      } else {
        data['distributionLists'] = [{ text: dl.text }];
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: data,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.status == 400) {
        if (tenant.status == 3) {
          Alert.alert(
            'Server Error',
            'You are not able to send messages while your account is in review state.',
            'Ok',
            null
          );
        } else {
          Alert.alert(
            'Server Error',
            'You are not able to send messages at this time.'
          );
        }
      }
      if (result.data.success) {
        Alert.alert('Successfully sent.', 'MMS message has been sent.', [
          {
            text: 'OK',
            onPress: () => {
              loadMessage();
            },
          },
        ]);
      } else if (
        result.data.failures != null &&
        result.data.failures.length > 0
      ) {
        Toast.show({
          duration: 2000,
          title: result.data.failures.join(','),
          status: 'error',
        });
      } else if (result.data.errorMessage) {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: ' We were unable to send your message',
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpladMedia = async (data) => { 
    setLoading(true);
    // handleSendMedia(mediaId)
    let b = await fetch(data.uri);
    let file = await b.blob();
    try {
      const body = new FormData();
      body.append('upfile', file, data.file.name);
      
      let device = await getData('@device_id');
      const headers = {
        'x-device': device,
        'content-type': 'multipart/formdata',
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + 'dart/upload',
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        handleSendMedia(result.data.media[0].id);
      } else {
        Alert.alert('Fail to Send Message', result.data.failures.join(','), [
          {
            text: 'OK',
            onPress: () => navigation.goBack(),
          },
        ]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSendVCard = async () => {
    setLoading(true);
    try {
      const body = {
        action: 'send_vcard',
      };
      if (dl == null) {
        body['contact'] = member.id;
      } else {
        body['distributionList'] = dl.id;
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Successfully Sent VCard',
          status: 'success',
        });
        loadMessage();
      } else {
        Toast.show({
          duration: 2000,
          title: 'Fail to Send VCard',
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const AlertAskPermissionForVCard = async () => {
    Alert.alert('Confirm', 'Are you sure want to send this vCard?', [
      {
        text: 'No',
        onPress: () => null,
        style: 'cancel',
      },
      {
        text: 'Yes',
        onPress: () => handleSendVCard(),
      },
    ]);
  };

  const AlertAskPermission = async (data) => {
    Alert.alert('Confirm', 'Are you sure want to send this media?', [
      {
        text: 'Cancel',
        onPress: () => null,
        style: 'cancel',
      },
      {
        text: 'OK',
        onPress: () => handleUpladMedia(data),
      },
    ]);
  };

  const imageGet = async (type) => {
    const res = await DocumentPicker.getDocumentAsync({
      type: type,
    });
    setVisible(false);

    if (res.type == 'success') {
      AlertAskPermission(res);
    }
  };


  return (
    <Pressable
      onPress={() => setVisible(false)}
      style={{
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingHorizontal: 35,
      }}>
      <View
        style={{
          paddingHorizontal: 30,
          justifyContent: 'space-evenly',
          width: 80,
          paddingVertical: 10,
          backgroundColor: 'white',
          marginBottom: 48,
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
        }}>

        <TouchableOpacity
          style={CommonStyle.modalitemsStyle}
          onPress={() => {
            setVisible(false);
            navigation.navigate('SelectBookmark', {
              memberData: memberData,
              screen: route.name,
              message: typedmessage
            });
          }}>
          <Entypo name='bookmark' size={30} color={AppColor.PrimaryColor} />
          <Text style={CommonStyle.modaltextStyle}>Bookmarks</Text>
        </TouchableOpacity>



        {group?.allowMmsOutbound == true ?
          <TouchableOpacity
          onPress={() => {
            setVisible(false);
            navigation.navigate('RecentMedia', { member: member, dl: dl });
          }}
          style={CommonStyle.modalitemsStyle}>
          <MaterialIcons
            name="perm-media"
            size={30}
            color={AppColor.PrimaryColor}
          />
          <Text style={CommonStyle.modaltextStyle}>Recent Media</Text>
          </TouchableOpacity>
        : ''
        }
        
        {group?.allowMmsOutbound == true ?
          <TouchableOpacity
          style={CommonStyle.modalitemsStyle}
          onPress={() => {
            // setVisible(false)
            handleDocumentPicker();
          }}>
          <Entypo name="documents" size={30} color={AppColor.PrimaryColor} />
          <Text style={CommonStyle.modaltextStyle}>File & Docs</Text>
          </TouchableOpacity>
        : ''
        }



        
        <TouchableOpacity
          style={CommonStyle.modalitemsStyle}
          onPress={() => {
            setVisible(false);
            navigation.navigate('SelectTemplates', {
              memberData: memberData,
              screen: route.name,
            });
          }}>
          <Entypo name="message" size={30} color={AppColor.PrimaryColor} />
          <Text style={CommonStyle.modaltextStyle}>Templates</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setVisible(false);
            navigation.navigate('ScheduleContactMessage', {
              member: member,
              dl: dl,
              template_message: typedmessage,
              memberData: memberData
            });
          }}
          style={CommonStyle.modalitemsStyle}>
          <Entypo name="calendar" size={30} color={AppColor.PrimaryColor} />
          <Text style={CommonStyle.modaltextStyle}>Schedule Message</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={CommonStyle.modalitemsStyle}
          onPress={() => {
            setVisible(false);
            AlertAskPermissionForVCard();
          }}>
          <MaterialIcons
            name="contact-page"
            size={30}
            color={AppColor.PrimaryColor}
          />
          <Text style={CommonStyle.modaltextStyle}>VCard</Text>
        </TouchableOpacity>
        {sendContent && (
          <TouchableOpacity
            style={CommonStyle.modalitemsStyle}
            onPress={() => {
              setVisible(false);
              navigation.navigate('ContentScreen', { member: member });
            }}>
            <Octicons name="book" size={30} color={AppColor.PrimaryColor} />
            <Text style={CommonStyle.modaltextStyle}>Content</Text>
          </TouchableOpacity>
        )}
      </View>
    </Pressable>
  );
}

const CommonStyle = StyleSheet.create({
  AuthContainer: {
    flex: 1,
    backgroundColor: 'black',
  },
  headerTextStyle: {
    padding: 10,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'black',
    fontSize: 2,
    fontWeight: '500',
  },
  buttonGroupContainerStyle: {
    backgroundColor: 'black',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    height: 5,
  },
  disableTextStyle: {
    color: 'black',
  },
  selectedTextStyle: {
    color: 'black',
  },
  seperatorStyle: {
    height: 1,
    width: '86%',
    backgroundColor: 'black',
    alignSelf: 'center',
  },

  dayText: {
    color: 'grey',
    fontSize: 13,
  },
  left: {
    backgroundColor: '#F8F8F8',
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
  },
  right: {
    backgroundColor: AppColor.PrimaryColor,
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
  },
  leftImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
  },

  rightImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
    padding: 5,
  },

  bubbleLeftText: {
    color: 'black',
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },
  bubbleRightText: {
    color: AppColor.SecondaryColor,
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },

  inputContainer: {
    minHeight: 6,
    borderTopWidth: 2,
    borderColor: 'black',
    alignItems: 'center',
  },
  inputPrimary: {
    backgroundColor: 'black',
    marginVertical: 4,
    marginHorizontal: 16,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendContainer: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  send: {
    width: 20,
    height: 20,
  },
  composerText: {
    color: AppColor.SecondaryColor,
    fontSize: 14,
  },
  timeTextLeft: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'right',
  },
  timeTextRight: {
    color: AppColor.SecondaryColor,
    fontSize: 12,
  },
  loadEarlierText: {
    color: 'white',
    fontSize: 12,
  },
  modalitemsStyle: { alignItems: 'center', width: '100%', marginTop: 5 },
  modaltextStyle: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
});
