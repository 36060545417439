import React from 'react';

import { providers } from '../../IntegrationProvider';
import OauthComponent from './ui/OauthComponent';
import AttributeComponent from './ui/AttributeComponent';
import TwoStepOauthComponent from './ui/TwoStepOauthComponent';
import NoopComponent from './ui/NoopComponent';
import RedtailComponent from './ui/RedtailComponent';
import SalesforceComponent from './ui/SalesforceComponent';
import SamlComponent from './ui/SamlComponent';
import SmartOfficeComponent from './ui/SmartOfficeComponent';
import MarketingPro from './ui/MarketingPro';
import FMGSuite from './ui/FMGSuite';
import Riskalyze from './ui/Riskalyze';
import JunxureComponent from './ui/JunxureComponent';

class HashMap {
  constructor() {
    this.data = {};
  }
  put(key, value) {
    this.data[key] = value;
  }
  getArray() {
    return Object.entries(this.data);
  }
}

export default function IntegrationComponentFactory({
  provider,
  groupIntegration,
  integration,
  createIntegration,
  updateIntegration,
  loadProviderData,
}) {
  let attrMap = new HashMap();

  switch (provider) {
    case providers.ALLCLIENTSCRM:
      attrMap.put('host', 'Host');
      attrMap.put('accountid', 'Account ID');
      attrMap.put('token', 'Access Key');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
        />
      );
    case providers.BASECRM:
      attrMap.put('token', 'Access Key');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={true}
        />
      );
    case providers.CAPSULE:
      return (
        <OauthComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.CLIENTWORKS:
      return (
        <SamlComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.COPPER:
      attrMap.put('username', 'Email');
      attrMap.put('token', 'Access Key');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
        />
      );
    case providers.DYNAMICS:
      return (
        <TwoStepOauthComponent
          provider={provider}
          defaultDomain={''}
          sampleDomain={'mydomain.crm.dynamics.com'}
          groupIntegration={groupIntegration}
          integration={integration}
          allowContactEdits={true}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.EMAIL:
      break;
    case providers.ERADO:
      break;
    case providers.FMGSUITE:
      attrMap.put('customerId', 'Customer ID');
      return (
        <FMGSuite
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          loadProviderData={loadProviderData}
        />
      );
    case providers.GLOBALRELAY:
      break;
    case providers.HUBSPOT:
      return (
        <OauthComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.INFUSIONSOFT:
      return (
        <OauthComponent
          provider={provider}
          integration={integration}
          allowContactEdits={true}
          allowLocation={true}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.JUNXURE: //FIXME - this has to exist at least in web and probably both.
      return (
        <JunxureComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
        />
        
      );
    case providers.LESSANNOYING:
      attrMap.put('username', 'User Code');
      attrMap.put('token', 'API Token');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          loadProviderData={loadProviderData}
        />
      );
    case providers.MARKETINGPRO:
      attrMap.put('usercode', 'User Code');
      return (
        <MarketingPro
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
          loadProviderData={loadProviderData}
        />
      );
    case providers.MESSAGEWATCHER:
      break;
    case providers.MICROFOCUS:
      break;
    case providers.OFFICE:
      return (
        <OauthComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.OMNI:
      attrMap.put('host', 'Host');
      attrMap.put('username', 'Username');
      attrMap.put('token', 'Access Key');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
        />
      );
    case providers.ONEHQ:
      attrMap.put('username', 'Username');
      attrMap.put('token', 'Access Key');
      attrMap.put('reportId', 'Report ID');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={false}
        />
      );
    case providers.PATRINA:
      break;
    case providers.PRACTIFI:
      return (
        <TwoStepOauthComponent
          provider={provider}
          defaultDomain={'practifi.cloudforce.com'}
          sampleDomain={'practifi.cloudforce.com'}
          groupIntegration={groupIntegration}
          integration={integration}
          allowContactEdits={true}
          allowLocation={true}
          allowAdvancedOptions={true}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.PROOFPOINT:
      break;
    case providers.REDTAIL:
      return (
        <RedtailComponent
          provider={provider}
          integration={integration}
          updateIntegration={updateIntegration}
          createIntegration={createIntegration}
        />
      );
    case providers.RISKALYZE:
      return (
        <Riskalyze
          provider={provider}
          integration={integration}
          allowContactEdits={false}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.SALENTICA:
      return (
        <OauthComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.SALESFORCE:
      return (
        <SalesforceComponent
          provider={provider}
          integration={integration}
          defaultDomain={'login.salesforce.com'}
          sampleDomain={'login.salesforce.com'}
          groupIntegration={groupIntegration}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    case providers.SLACK:
      break;
    case providers.SMARSH:
      break;
    case providers.SMARTOFFICE:
      return (
        <SmartOfficeComponent
          integration={integration}
          provider={provider}
          updateIntegration={updateIntegration}
          createIntegration={createIntegration}
          loadProviderData={loadProviderData}
        />
      );
    case providers.VTIGER:
      attrMap.put('host', 'Host');
      attrMap.put('username', 'Email');
      attrMap.put('token', 'Access Key');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={true}
        />
      );
    case providers.WEALTHBOX:
      attrMap.put('token', 'Token');
      return (
        <AttributeComponent
          provider={provider}
          integration={integration}
          attributes={attrMap}
          allowContactEdits={true}
          allowCalendarSync={true}
        />
      );
    case providers.ZOHO:
      return (
        <OauthComponent
          provider={provider}
          integration={integration}
          allowContactEdits={true}
          allowLocation={true}
          createIntegration={createIntegration}
          updateIntegration={updateIntegration}
        />
      );
    default:
      return (
        <NoopComponent
          provider={provider}
          integration={integration}
          allowContactEdits={false}
        />
      );
  }
  return null;
}
