/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Image,
  Alert,
} from 'react-native';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Box, Text } from 'native-base';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import { CheckBox } from 'react-native-elements';

const VerifyEmail = (props) => {
  const [otp, setOtp] = useState('');
  const [loader, setLoader] = useState(false);
  const email = props?.route?.params?.username;
  const [verificationType, setVerificationType] = React.useState('mobile');

  const handleNext = () => {
    if(verificationType === 'mobile' && otp.length < 5){
      alert('Please enter a valid phone number')
    } else {
      verifyOtp();
    }
  }

  const verifyOtp = async () => {
      let RequestData = {
        action: 'save_mobile',
        userid: props.route.params.userId,
        mobile: otp,
        countryCode: '+1',
      };
      if(verificationType === 'email'){
        RequestData.mobile = '';
        RequestData.mfaType = 'email'
      }
      let x = { 'Content-Type': 'text/plain' };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: RequestData,
        headers: x,
        redirect: 'follow',
      };
      setLoader(true);
      let result = await RequestMaker(Config);
      setLoader(false);
      if (result) {
        if (result.data?.success) {
          props.navigation.navigate('VerifyEmail', {
            userId: props.route.params.userId,
            username: props.route.params.username,
            password: props.route.params.password,
            pushKey: props.route.params.pushKey,
            check: props.route.params.check,
          });
        } else {
          Alert.alert(result.data.errorMessage);
        }
      }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Box style={{ flex: 1 }}>
          {loader ? <Loader /> : null}
          <Box
            maxWidth={{ sm: '90%', md: '60%' }}
            alignSelf="center"
            alignItems="center"
            style={{ flex: 1 }}>
            <View
              style={{ height: heightPercentageToDP(20), marginVertical: 20 }}>
              <img
                style={{
                  maxWidth: 300,
                  alignSelf: 'center',
                  objectFit: 'contain',
                  }}
                alt='MyRepChat logo.'
                src={require('../../../Assets/Images/logo-fullcolor@2x.png')}
              />
            </View>
            <View
              style={{
                width: '80%',
                alignSelf: 'center',
                paddingVertical: 10,
              }}>
              <Text fontSize={{ md: 16, sm: 14 }}>
                For added security, we require a second form of verification. Please enter a phone number or select your email and press 'Next' to continue.
              </Text>
            </View>
            <View style={{flexDirection:'column',alignItems:'center'}}>
            <View style={{flexDirection:'row',alignItems:'center'}}>
            <CheckBox 
            checked={verificationType === 'mobile'}
            onPress={()=>setVerificationType('mobile')}
            />
            <CustomInput
              onChangeText={(s) => {
                if(verificationType !== 'mobile') setVerificationType('mobile')
                setOtp(s)
              }}
              autofocus={true}
              value={otp}
              placeholder={'Enter Phone number'}
            />
            </View>
            <View style={{flexDirection:'row',alignItems:'center'}}>
            <CheckBox 
            checked={verificationType === 'email'}
            onPress={()=>setVerificationType('email')}
            />
            <CustomInput
              disabled={true}
              placeholder={email}
            />
            </View>

            </View>
            
            <View style={{ height: 15 }}></View>
            <CustomButton
              onPress={() => {
                handleNext();
              }}
              title={'Next'}
            />
            <View style={{ height: 15 }}></View>
            <CustomButton
              onPress={() => props.navigation.goBack()}
              title={'Back'}
            />
            <View style={{ marginTop: 10 }}></View>
          </Box>
        </Box>
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
    height: heightPercentageToDP(100),
  },
});

export default VerifyEmail;
