import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, TextInput } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast, Select } from 'native-base';
import { useSelector } from 'react-redux';

import Alert from 'Alert';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import getData from '../../../AsyncUtils/GetData';
import Loader from '../../../Components/Loader';
import RequestMaker from '../../../Middleware/ApiCaller';

const PositiveConsent = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [selectedConsentType, setSelectedConsentType] = useState('');
  const [consentMessage, setConsentMessage] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const Toast = useToast();
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const group = useSelector((state) => state.User)?.profile?.group;

  useEffect(() => {
    handleChangeConsentFormat();
  }, [selectedConsentType]);

  useEffect(() => {
    const consent = navigation.addListener('focus', () => {
      handleGetConsent();

    })
    return consent;
    
  }, [navigation]);


  useEffect(() => {
    setSelectedConsentType(tenant?.consentNameFormat);
    handleGetConsent();

  }, [tenant]);

  useEffect(() => {
  if(group && group.consentMsg){
    try{
      setShowOptions(group?.consentMsg.indexOf('account.consent.name') != -1);
    } catch(err){
      console.log(err);
    }
  }
  }, [group]);

  const handleGetConsent = async () => {
    setLoading(true);
    try {
      const body = {
        member: -1,
        action: 'get_consent',
      };
      
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
      };
      let result = await RequestMaker(Config);
      if (result.status == 200) {
        console.log("shenee",result.data)
        setConsentMessage(result?.data?.consentMsg || '');
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeConsentFormat = async () => {
    setLoading(true);
    try {
      const body = {
        name: selectedConsentType,
        action: 'update_consent_name_format',
      };
      
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
      };
      let result = await RequestMaker(Config);
      if (result.status == 200) {
        Toast.show({
          duration: 2000,
          title: 'Consent Name updated',
          status: 'success',
        });
        handleGetConsent();
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={styles.container}>
        <View
          style={{
            marginBottom: 20,
            marginTop: 20,
          }}>
          <Text style={styles.text}>
            Positive Consent is configured by your group administrator and is
            required to be sent to your contacts. 
            {group?.consentResponseRequired && 
            `The contact must respond with
            the keyword ACCEPT before you can send them subsequent texts.`
            }
          </Text>
          <Text style={styles.text}>
            Below is an example of the message that will be sent.
          </Text>
          <Text style={styles.text}>EXAMPLE MESSAGE</Text>
          <TextInput
            value={consentMessage}
            editable={false}
            disabled={true}
            style={{
              width: '100%',
              padding: 10,
              borderBottomWidth: 1,
              borderBottomColor: AppColor.GREY100,
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              backgroundColor: AppColor.GREY200,
            }}
            multiline={true}
            numberOfLines={10}
          />
		{showOptions &&
          <View style={styles.item}>
            <Text style={[styles.text, styles.title]}>
              USE THE FOLLOWING NAME IN THE MESSAGE
            </Text>
            <Select
              selectedValue={selectedConsentType}
              style={styles.Select}
              onValueChange={(itemValue, itemIndex) =>
                setSelectedConsentType(itemValue)
              }>
              <Select.Item
                value="vcard.contact"
                label="vCard Contact Name Only"
              />
              <Select.Item
                value="vcard.name"
                label="vCard Business Name Only"
              />
              <Select.Item
                value="vcard.both"
                label="vCard Contact with Business Name"
              />
              <Select.Item
                value="account.contact"
                label="Account Contact Name Only"
              />
              <Select.Item
                value="account.name"
                label="Account Business Name Only"
              />
              <Select.Item
                value="account.both"
                label="Account Contact with Business Name"
              />
            </Select>
          </View>
          }
        </View>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default PositiveConsent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '90%',
    alignSelf: 'center',
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    color: '#000000',
    lineHeight: '1.2em',
    fontSize: 16,
    marginVertical: 10,
  },
  Select: {
    width: '100%',
    marginVertical: 10,
  },
  title: {
    textDecorationLine: 'underline',
    textDecorationColor: AppColor.PrimaryColor,
    color: AppColor.PrimaryColor,
    fontSize: 14,
  },
});
