import React, { useState } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';

import CustomButton from '../../../Components/Button';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import SetupIntro from './SetupIntro';
import SetupWizardChooseNum from './ChooseNum';
import SMSEnable from './SMSEnable';
import LOAForm from './LOAForm';

const MyRepChatNum = ({ navigation }) => {
  const [chooseNum, setChooseNum] = useState(false);
  const [textEnabled, setTextEnabled] = useState(false);
  const [LOA, setLOA] = useState(false);
  const [hostedNumber, setHostedNumber] = React.useState();

  const handleTextEnable = () => {
    setChooseNum(false);
    setTextEnabled(true);
  };

  const handleChooseNum = () => {
    setChooseNum(true);
    setTextEnabled(false);
  };

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={'MyRepChat Number Setup'}
        back={chooseNum || textEnabled || LOA}
        onPress={() => {
          setChooseNum(false);
          setTextEnabled(false);
          setLOA(false);
        }}
      />
      {(!chooseNum && !textEnabled && !LOA) && (
        <SetupIntro
          openLink={openLink}
          handleChooseNum={handleChooseNum}
          handleTextEnable={handleTextEnable}
        />
      )}
      {chooseNum && (
        <SetupWizardChooseNum
          goBack={() => setChooseNum(false)}
          navigation={navigation}
        />
      )}
      {textEnabled && (
        <SMSEnable
          goBack={() => setTextEnabled(false)}
          setLOA = {e => {
            setTextEnabled(false);
            setHostedNumber(e)
            setLOA(true);
          }}
          navigation={navigation}
        />
      )}
      {LOA && (
        <LOAForm
        hostedNumber={hostedNumber}
        navigation={navigation}
        />
      )}
    </SafeAreaView>
  );
};

export default MyRepChatNum;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
