import React from 'react'
import {View, Text, ScrollView} from 'react-native';
import { getChatChannels, updateChatStatus } from '../../Api/ten4';
import { useSelector } from 'react-redux';
import { ChannelButton } from '../../Components/Ten4/ChannelButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { TouchableOpacity } from 'react-native-gesture-handler';
import AppFonts from '../../Constants/Fonts';
import { useSocket } from '../../Middleware/useSocket';
import { Content } from '../../Components/Layout';
import Search from '../../Components/Search';
import { Icon } from 'react-native-elements';
import UserAvatarPicker from '../../Components/Ten4/UserAvatarPicker';
import UserAvatarPickerModal from '../../Components/Modals/UserAvatarPickerModal';

const Channels = (props) => {

    const user = useSelector((state) => state.User.profile)?.user;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [publicChannels, setPublicChannels] = React.useState([]);
    const [privateChannels, setPrivateChannels] = React.useState([]);
    const [toggle, setToggle] = React.useState('PUBLIC');
    const socket = useSocket();
    const [isAvatarModalShown,setIsAvatarModalShown] = React.useState(false);

    React.useEffect(()=>{    
        const mount = props.navigation.addListener('focus', () => {
            getAllChatData();
        })
        return mount;
    },[props.navigation])

    const onMessage = React.useCallback(({data}) => {
        const parsed = JSON.parse(data);

        if(parsed.message.subject === "Status Update"){
            const copy = [...privateChannels]
            copy.forEach(e => {
                if(e.members.length === 2){
                    e.members.forEach(e => {
                        if(e.name === socketMessage?.senderName?.replace("#", "")){
                            e.status = socketMessage?.body
                        }
                    })
                }
            })
            setPrivateChannels(copy);
        } else if(parsed.message.subject === 'New channel message'){
            getAllChatData();
        }
    })


    React.useEffect(()=>{
        if(socket){
            socket.addEventListener("message", onMessage);
            return () => {
                socket.removeEventListener("message", onMessage);
            }
        }
    }, [socket, onMessage])



    const getAllChatData = () => {
        getChatChannels({affiliate: affiliate.id, userId: user.user_id })
        .then(result => {
            if(result?.data?.success){
                setPublicChannels(result.data['public']);
                setPrivateChannels(result.data['private']);
                console.log(result.data);
            }
        })
    }

    const clearBadge = (id, type) => {
        if(type==='public'){
            let index = publicChannels.findIndex(e => e.id === id)
            if(index > -1){
                const data = [...publicChannels]
                data[index].unread = 0;
                setPublicChannels(data);
                
            }
        } else if(type==='private'){
            let index = privateChannels.findIndex(e => e.id === id)
            if(index > -1){
                const data = [...privateChannels]
                data[index].unread = 0;
                setPrivateChannels(data);
            }
        }

    }

    const handleSearch = query => {
        if(query === ''){
          setFilteredData(data);
        } else {
          setFilteredData(data.filter(e => e.url.includes(query) || e.label.includes(query)))
        }
      }
    

    return(
        <View style={{flex:1,height:'100%'}}>
        <Content.Row style={{justifyContent:'space-between',paddingVertical:15,paddingHorizontal:5,}}>
            <View style={{flexDirection:'row',alignItems:'center',gap:10}}>
            <UserAvatarPicker showModal={() => setIsAvatarModalShown(true)} />
            <Text style={{fontSize:18}}>{props.type === 'public' ? 'Groups' : 'Direct Messages'}</Text>
            </View>
            <Icon onPress={() => props.navigation.navigate(props.type === 'public' ? 'AddPublicChannel' : 'AddPrivateChannel') } name='add-circle-outline' type='Ionicons' style={{marginLeft:10}}/>
          </Content.Row>
        
        {



        props.type === "public" ? 


        <>
        {/* <View style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',padding:15}}>
                <Text style={{opacity:1, fontWeight:500}}>
                    Public Channels
                </Text>
                <Entypo onPress={() => props.navigation.navigate('AddPublicChannel')} name='plus' size={22} style={{opacity:.8}}/>
        </View> */}
        <ScrollView style={{flex:1}}>
            {publicChannels?.length === 0 && <Text style={{marginTop:10, alignSelf:'center'}}>No groups found.</Text>}
        {
            publicChannels.sort((a,b) => b.unread - a.unread).map(e => {
                return(
                    <ChannelButton name={e.name} 
                    onPress={() => {
                        if(e.active) {
                            props.setChannel(e)
                            clearBadge(e.id, 'public')
                        }else{
                            alert("This channel is inactive.");
                        }
                    }} 
                    avatar={e.avatar}
                    unread={e.unread}
                    id={e.id}
                    channel={props.channel}
                    onEditPress = {() => {

                        props.navigation.navigate('EditPublicChannel',
                        {
                            title: e.name,
                            id: e.id,
                            avatar: e.avatar,
                            active: e.active,
                            webhook: e.webhook,
                        })
                    }}
                    />
                    
                )
            })
        }
                </ScrollView>

        </>


        :


        <>
            {/* <View style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',padding:15}}>
                <Text style={{opacity:1, fontWeight:500}}>
                    Private Channels
                </Text>
                <Entypo onPress={() => props.navigation.navigate('AddPrivateChannel')} name='plus' size={22} style={{opacity:.8}}/>
            </View> */}
            <View style={{flex:1}}>

            <ScrollView style={{flex:1}}>
            {privateChannels?.length === 0 && <Text style={{alignSelf:'center',marginTop:10}}>No Direct Messages found.</Text>}

        {
            privateChannels.sort((a,b) => b.unread - a.unread).map(e => {
                const filteredMembers = e.members.filter(e => e.user_id !== user.user_id);

                let name = '';
                let status;
                if(filteredMembers.length > 0){
                    name = filteredMembers[0].name
                    status = filteredMembers[0].status
                }
                if(filteredMembers.length > 1){
                    name += ` & ${filteredMembers.length - 1} more`
                    status = null;
                }

                return(
                    <>
                    { filteredMembers.length > 0 &&
                    <ChannelButton name={name} 
                    onPress={() => {
                        if(e.active) {
                            props.setChannel({...e, name: name})
                            clearBadge(e.id, 'private')
                        }else{
                            alert("This channel is inactive.");
                        }                    
                    }}
                    memberCount={filteredMembers.length}
                    status={status}
                    unread={e.unread}
                    id={e.id}
                    channel={props.channel}
                    onEditPress = {() => {

                        props.navigation.navigate('EditPrivateChannel',
                        {
                            id: e.id,
                        })
                    }}
                    />
                    }
                    </>
                    
                )
            })
        }
        </ScrollView>
        </View>

        </>}
        {
            isAvatarModalShown && 
            <UserAvatarPickerModal setVisible={setIsAvatarModalShown} visible={isAvatarModalShown}/>
        }
        </View>
    )
}

export default Channels;