import React from "react";
import { Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import { useSelector } from "react-redux";
import SimpleDropdown from "../../Components/SimpleDropdown";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const ChangeProvider = () => {
    const [mrcNumber, setMrcNumber] = React.useState("");
    const [provider, setProvider] = React.useState("twilio");
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const Toast = useToast();

    const handleChangeProvider = async () => {
        try{
        const body = {
            action: "set_provider",
            tw_number: `+1${mrcNumber}`,
            provider: provider,
            affiliate: affiliate.id
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                status:'success',
                title: "Provider changed successfully"
            })
        } else if(result.data.errorMessage){
            Toast.show({
                status:'error',
                title: result.data.errorMessage
            })
        }
        } catch(err){
            Toast.show({
                status:'error',
                title: err.message
            })
        }
    
    }


    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                Change number provider between Twilio and Bandwidth.
            </Text>

            <CustomInput
                title="10-digit MRC number"
                value={mrcNumber}
                onChangeText={(text) => setMrcNumber(text)}
                titleStyle={{marginTop:10}}
                maxLength={10}
            />

            <SimpleDropdown
            style={{marginTop:10}}
            value={provider}
            data={[
                {label:"Twilio",value:"twilio"},
                {label:"Bandwidth",value:"bandwidth"}
            ]}
            onChange={(value) => setProvider(value)}
            />

            <CustomButton onPress={handleChangeProvider} title={'Save'} width={'20%'} style={{alignSelf:'flex-end',marginTop:10}}/>



        </View>
    )
}

export default ChangeProvider;