import React from 'react';
import {
  Text,
  View,
  TouchableOpacity,
  Keyboard,
  Linking,
  ScrollView,
  Switch,
} from 'react-native';
import { createElement } from 'react-native-web';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { CheckBox, Icon } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import { Menu, useToast } from 'native-base';
import Alert from 'Alert';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';

import AppColor from '../../Constants/Color';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import {
  loadAllContactsThunk,
  loadAllBlockedContactsThunk,
} from '../../Redux/contacts';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import AppFonts from '../../Constants/Fonts';
import { phoneNumberTypes } from './AddPhoneNumber';
import { loadReconcileMember, reconcileMember } from './helpers';
import { ReconcileModal, RemovePhoneModal } from './modals';
import CustomInput from '../../Components/CustomInput';
import { formatPhoneNumber } from '../../Middleware/helpers';
import useUndo from '../../Middleware/useUndo';
import useLogEvent from '../../Middleware/useLogEvent';
import './datetimepicker.css';

const EditContact = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const Toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const integrations = useSelector((state) => state.integrations).integrations;
  const [showRisk, setShowRisk] = React.useState(false);
  const [client, setClient] = React.useState(null);
  const [exportVisible, setExportVisible] = React.useState(false);
  const inputFieldDataInitialState = {
    id: null,
    accountId: '',
    fname: '',
    lname: '',
    mname: '',
    email_address: '',
    address: '',
    state: '',
    city: '',
    zip: '',
    phoneNumbers: [
      {
        countryCode: '+1',
        isoCode: 'US',
        longCode: '',
        messaging: true,
        type: 1,
        typeId: 1,
      }

    ],
    distributionLists: [],
    stats: {},
    source: '',
    sourceId: '',
    spam: false,
  }
  const group = useSelector((state) => state.User)?.profile?.group;
  const [inputFieldData, setInputFieldData] = React.useState(inputFieldDataInitialState);
  const [reconcileVisible, setReconcileVisible] = React.useState(false);
  const [reconcileMembers, setReconcileMembers] = React.useState([]);
  const [blockedContact, setBlockedContact] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);
  const [noMessagingModal, setNoMessagingModal] = React.useState(false);
  const undo = useUndo();
  const logEvent = useLogEvent();
  
  React.useEffect(() => {
    // phone number from  add/edit phoneNumber screen

    if (route.params?.item?.id) {
      loadMemberDetails(route.params.item.id);
    } else if(route.params?.dl && route.params?.contact){
      console.log('contact',route.params.contact);
      const contact = {...route.params.contact};

      let DLArr = contact.distributionLists;

      if (DLArr.findIndex((x) => x.id === route.params.dl.id) === -1) {
        DLArr.push(route.params.dl);
        contact.distributionLists =  DLArr
      }

      setInputFieldData(contact);

    }

    if (route.params?.phoneData) {
      let { phoneData } = route.params;
      let arr = { ...inputFieldData }.phoneNumbers;
      if (phoneData.messaging) {
        arr = arr.map((x) => {
          x.messaging = false;
          return x;
        });
      }
      if (phoneData.id) {
        let index = arr.findIndex((x) => x.id === phoneData.id);
        if (index !== -1) {
          arr[index] = phoneData;
        } else {
          arr.push(phoneData);
        }
      }
      if (arr.filter((x) => x.messaging).length === 0) {
        if (arr[0]?.messaging) arr[0].messaging = true;

      }
      let data = { ...inputFieldData, phoneNumbers: arr };
      setInputFieldData(data);
    }


  }, [route.params]);

  React.useEffect(() => {
    let riskalyzeIndex = integrations.findIndex(
      (x) => x.provider === 'riskalyze'
    );
    if (
      riskalyzeIndex != -1 &&
      integrations[riskalyzeIndex].enabled &&
      inputFieldData.id
    ) {
      setShowRisk(true);
      if (
        inputFieldData.email_address != null &&
        inputFieldData.email_address != ''
      ) {
        loadRisk();
      } else {
      }
    } else {
      setShowRisk(false);
    }
  }, [integrations, inputFieldData]);


  const loadRisk = async () => {
    try {
      const body = {
        action: 'get_riskalyze_client',
        email: inputFieldData.email_address,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success === 'false' || result.data.success === null) {
        Alert.alert(
          'Server Error',
          'The server returned an error:' + result.data.errorMessage
        );
        return;
      } else {
        let clientObj = result.data.client;
        if (clientObj != null) {
          setClient(clientObj);
        }
      }
    } catch (err) {
      // Alert.alert("Server Error", "The server returned an error " + err.code + ". Please contact support if this continues.");
    } finally {
    }
  };

  function hasUserInputChanged(initialState, userInputState) {
    const isDifferent = (a, b) => {
      if (Array.isArray(a) && Array.isArray(b)) {
        return a.length !== b.length || a.some((item, index) => isDifferent(item, b[index]));
      } else if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
        return Object.keys(a).length !== Object.keys(b).length || Object.keys(a).some(key => isDifferent(a[key], b[key]));
      } else {
        return a !== b;
      }
    };

    return Object.keys(initialState).some(key => isDifferent(initialState[key], userInputState[key]));
  }


  const SaveContact = async (data) => {
    Keyboard.dismiss();
    try {
      const hasChanged = hasUserInputChanged(inputFieldDataInitialState, inputFieldData);
      if (!hasChanged) {
        Toast.show({
          status:'error',
          title: 'At least one field must be filled out to save a contact.'
        })
        return;
      }

      let result = await apiSaveContact();
      if (result.data.success) {
        logEvent('contact_created')
        dispatch(loadAllContactsThunk());
        dispatch(loadAllBlockedContactsThunk());
        dispatch(loadDuplicateNumbersThunk(tenant.id));
        setInputFieldData(inputFieldDataInitialState)
        Toast.show({
          duration: 3000,
          status: 'success',
          title: result?.data?.message || 'successfully saved contact',
        });
        navigation.navigate('Contacts');
      } else {
        Toast.show({
          duration: 3000,
          title: result?.data?.errorMessage || 'Something went wrong, please try again later.',
          status: 'danger',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const apiSaveContact = async () => {
    setLoading(true);
    const body = {
      fname: inputFieldData.fname,
      mname: inputFieldData.mname,
      lname: inputFieldData.lname,
      address: inputFieldData.address,
      city: inputFieldData.city,
      state: inputFieldData.state,
      zip: inputFieldData.zip,
      email_address: inputFieldData.email_address,
      birth_date: null,
      spam: inputFieldData.spam,
      distributionLists: inputFieldData.distributionLists,
      phoneNumbers: inputFieldData.phoneNumbers,
      source: inputFieldData.source,
      sourceId: inputFieldData.sourceId,
      comments: '',
      email_newsletter: false,
      list_in_directory: false,
      notify: false,
    };
    if (
      !inputFieldData.id ||
      inputFieldData.id === null ||
      inputFieldData.id === 0
    ) {
      body.action = 'create_member';
    } else {
      body.action = 'update_member';
      body.id = inputFieldData.id;
    }

    for (let i = 0; i < body.phoneNumbers.length; i++) {
      if (typeof body.phoneNumbers[i].id === 'string') {
        if (body.phoneNumbers[i].id.includes('E')) {
          delete body.phoneNumbers[i].id;
        }
      }

      if (body.phoneNumbers[i].longCode === '') {
        body.phoneNumbers.splice(i, 1);
      }

    }

    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    console.log(body);
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    return RequestMaker(Config);
  }

  const removeDL = (id) => {
    let arr = { ...inputFieldData }.distributionLists;
    let index = arr.findIndex((x) => x.id === id);
    arr.splice(index, 1);
    let data = { ...inputFieldData, distributionLists: arr };
    setInputFieldData(data);
  };

  const removePhoneNumber = (phone) => {
    let phoneNumbers = [...inputFieldData.phoneNumbers];
    const toDeleteIndex = phoneNumbers.findIndex(e => e.id === phone.id);

    if (toDeleteIndex > -1) {
      const toDeleteObj = phoneNumbers[toDeleteIndex];
      phoneNumbers.splice(toDeleteIndex, 1);
    

      setInputFieldData({ ...inputFieldData, phoneNumbers: phoneNumbers })

      undo(() => {
        setInputFieldData({ ...inputFieldData, phoneNumbers: [...phoneNumbers, toDeleteObj] })
      })
    }
  };

  const loadMemberDetails = async (id) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_member',
        id: id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.status === 200 && result.data.id) {
        setInputFieldData(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };


  const reconcileContact = () => {
    if (
      inputFieldData.id &&
      inputFieldData.id != null &&
      inputFieldData.id != 0
    ) {
      setLoading(true);
      setReconcileVisible(true);
      setReconcileMembers([]);
      loadReconcileMember(inputFieldData.id)
        .then(res => {
          if (res?.data?.success) {
            setReconcileMembers(res.data.contacts);
          }
          setLoading(false);
        })
    }
  };

  const handleReconcile = async (contact) => {
    setReconcileVisible(false);
    setLoading(true);
    reconcileMember(contact)
      .then(res => {
        setLoading(false);
        if (res?.data?.success) {
          loadMemberDetails(route.params.item.id);
          Toast.show({ duration: 2000, title: res?.data?.message, status: 'success' });
        }
        else {
          Toast.show({
            duration: 3000,
            type: 'danger',
            text: res?.data?.errorMessage || 'Something went wrong, please try again later.',
          });
        }
      })
  };

  const deleteContactAsync = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'delete_member',
        id: inputFieldData.id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        undo(async () => {
          const dataCopy = { ...inputFieldData };
          delete dataCopy.id;

          const body = {
            action: 'create_member',
            ...dataCopy
          }
          const result = await RequestMaker(body);
          if(result.data.success){
            dispatch(loadAllContactsThunk());
            dispatch(loadDuplicateNumbersThunk(tenant.id));    
          }
        })
        dispatch(loadAllContactsThunk());
        dispatch(loadDuplicateNumbersThunk(tenant.id));
        setInputFieldData(inputFieldDataInitialState);
        navigation.navigate('Contacts');
      } else {
        Toast.show({
          duration: 2000,
          title: result?.data?.errorMessage || 'Something went wrong. Please try again later.',
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const deleteContact = () => {
    Alert.alert(
      'Delete Contact',
      'Are you sure you want to delete this contact?',
      [
        {
          text: 'Cancel',
        },
        {
          text: 'Ok',
          onPress: () => deleteContactAsync(),
        },
      ]
    );
    if (
      inputFieldData.id &&
      inputFieldData.id != null &&
      inputFieldData.id != 0
    ) {
    }
  };

  const handleChangeNumberEntry = (prevState, newState) => {
    const data = [...inputFieldData.phoneNumbers]
    const index = data.findIndex(e => e.longCode === prevState.longCode);

    if (newState.messaging) {
      data.forEach(e => e.messaging = false);
    }

    if (index > -1) {
      const oldObj = data[index]
      const newObj = mergeObjects(oldObj, newState);
      data[index] = newObj;
    }

    setInputFieldData({ ...inputFieldData, phoneNumbers: data })

  }

  function mergeObjects(obj1, obj2) {
    // Create a new object to hold the merged properties
    const mergedObj = {};

    // Copy all properties from obj1 to the merged object
    for (const prop in obj1) {
      if (obj1.hasOwnProperty(prop)) {
        mergedObj[prop] = obj1[prop];
      }
    }

    // Copy all properties from obj2 to the merged object, overwriting any existing properties
    for (const prop in obj2) {
      if (obj2.hasOwnProperty(prop)) {
        mergedObj[prop] = obj2[prop];
      }
    }

    // Return the merged object
    return mergedObj;
  }

  React.useEffect(() => {
    const blur = navigation.addListener('blur', () => {
      navigation.setParams({ item: null, dl: null, phoneData: null, contact:null });
      setInputFieldData(inputFieldDataInitialState);
    });
    return blur;
  }, [navigation])

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      {/* <Header
        title={'Add/Edit Contact'}
        back={true}
        onPress={() => {
          setInputFieldData(inputFieldDataInitialState);
          navigation.goBack();
          
        }}
        chatIcon={
          <Icon
            color="black"
            name="chatbox"
            type="ionicon"
            onPress={() => {
              if(!inputFieldData.phoneNumbers.length){
                Alert.alert('No messaging number','This contact has no messaging number defined. Go to the contact details and specify a number to use for messaging.')
              } 
              else if (inputFieldData.id && inputFieldData.id != 0) {
                // navigation.navigate('Chats', { memberData: inputFieldData });
                navigation.navigate('Messages', { memberData: inputFieldData });
              }
            }}
          />
        }
      /> */}
      <ScrollView
        contentContainerStyle={{ paddingBottom: 20 }}
        style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 15 }}>
        <Text style={{ fontSize: 16, fontWeight: 500, marginTop: 0, marginBottom: 20 }}>Contact Details</Text>

        <View style={{ flexDirection: 'row', flex: 1, gap: 20 }}>
          <View style={{ flex: 1 }}>
            <CustomInput
              value={inputFieldData.fname}
              onChangeText={(t) => {
                setInputFieldData((state) => ({ ...state, fname: t }));
              }}
              title="First Name"
            />
          </View>
          <View style={{ flex: 1 }}>

            <CustomInput
              value={inputFieldData.mname}

              onChangeText={(t) => {
                setInputFieldData((state) => ({ ...state, mname: t }));
              }}
              title={'Middle Name'}
            />
          </View>
          <View style={{ flex: 1 }}>

            <CustomInput
              value={inputFieldData.lname}

              onChangeText={(t) => {
                setInputFieldData((state) => ({ ...state, lname: t }));
              }}
              title={'Last Name'}
            />
          </View>
        </View>
        {inputFieldData.accountId &&
          <>
            <View style={{ height: 10 }} />
            <CustomInput
              value={inputFieldData.accountId}
              labelStyle={{ color: AppColor.PrimaryColor }}
              title={'Organization'}
            />
          </>
        }
        <CustomInput
          value={inputFieldData.email_address}
          titleStyle={{ marginTop: 10 }}
          labelStyle={{ color: AppColor.PrimaryColor }}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, email_address: t }));
          }}
          title={'Email'}
        />

        <Text style={{ fontSize: 16, fontWeight: 500, marginVertical: 20 }}>Contact Address</Text>

        <CustomInput
          labelStyle={{ color: AppColor.PrimaryColor }}
          value={inputFieldData.address}
          onChangeText={(t) => {
            setInputFieldData((state) => ({ ...state, address: t }));
          }}
          title={'Address'}
        />
        <View style={{ height: 10 }} />
        <View style={{ flexDirection: 'row', justifyContent: 'center', gap: 20 }}>
          <View style={{ flex: 1 }}>

            <CustomInput
              value={inputFieldData.city}
              labelStyle={{ color: AppColor.PrimaryColor }}
              containerStyle={{ flex: 1 }}
              title="City"
              onChangeText={(t) => {
                setInputFieldData((states) => ({ ...states, city: t }));
              }}
            />
          </View>
          <View style={{ flex: 1 }}>

            <CustomInput
              value={inputFieldData.state}
              title="State"
              labelStyle={{ color: AppColor.PrimaryColor }}
              containerStyle={{ flex: 1 }}
              onChangeText={(t) => {
                setInputFieldData((states) => ({ ...states, state: t }));
              }}
            />

          </View>
          <View style={{ flex: 1 }}>

            <CustomInput
              value={inputFieldData.zip}
              labelStyle={{ color: AppColor.PrimaryColor }}
              containerStyle={{ flex: 1 }}
              title="Zip Code"
              onChangeText={(t) => {
                setInputFieldData((states) => ({ ...states, zip: t }));
              }}
              keyboardType={'numeric'}
            />
          </View>
        </View>
        <View style={{ marginVertical: 10 }}>
          <Text style={{ fontSize: 16, fontWeight: 500, marginVertical: 20 }}>Contact Numbers</Text>
          <View style={{ marginVertical: 0 }}>

            {inputFieldData.phoneNumbers.map((x, index) => {

              return (
                <PhoneNumberEntry onChange={handleChangeNumberEntry} index={index} navigation={navigation} x={x} removePhoneNumber={removePhoneNumber} />
              );
            })}

            <TouchableOpacity onPress={() => {
              setInputFieldData(prevState => {
                return (
                  {
                    ...prevState,
                    phoneNumbers: [
                      ...prevState.phoneNumbers,
                      {
                        countryCode: '+1',
                        isoCode: 'US',
                        longCode: '',
                        messaging: false,
                        type: 1,
                        typeId: 1,
                      }
                    ]
                  }
                )
              })
            }} style={{ marginHorizontal: 5, marginVertical: 20, alignSelf: 'flex-start', alignItems: 'center', gap: 10, flexDirection: 'row' }}>
              <Icon name='pluscircleo' type='antdesign' size={18} />
              <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>Add Number</Text>
            </TouchableOpacity>




            <Text style={{ fontSize: 16, fontWeight: 500, marginVertical: 20, }}>Distribution Lists</Text>
            {inputFieldData.distributionLists.length === 0 && (
              <Text style={{ marginBottom: 5, color: AppColor.Dark }}>
                Not a member of any Distribution Lists
              </Text>
            )}
            {inputFieldData.distributionLists.map((x, index) => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    borderRadius: 5,
                    borderColor: AppColor.PrimaryColor,
                    marginBottom: 5,
                  }}
                  key={`distributionLists_${index}`}>
                  <Text style={{ fontSize: 15, color: AppColor.PrimaryColor }}>
                    {x.countryCode}
                    {x.text}
                  </Text>
                  <Icon
                    accessibilityRole='button'
                    accessbilityLabel='Trash can.'
                    accessibilityHint='Remove user from distribution list.'
                    color={AppColor.danger}
                    name="trash-o"
                    type="font-awesome"
                    onPress={() => removeDL(x.id)}
                  />
                  {/* <Text style={{ color: AppColor.danger }}}>Remove</Text> */}
                </View>
              );
            })}
            <TouchableOpacity
              accessibilityRole='button'
              accessibilityLabel='Add to DL.'
              style={{ alignSelf: 'flex-start', marginTop: 10 }}
              onPress={() => {
                navigation.navigate('DLList', { contact: inputFieldData });
              }}>
              <Icon
                color={'black'}
                name="add-circle-outline"
                type="ionicons"
              />

            </TouchableOpacity>

          </View>
          <Text style={{ fontSize: 16, fontWeight: 500, marginVertical: 20 }}>Account Activity</Text>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 20,
              marginTop: 10,
              borderWidth: 1,
              padding: 5,
              borderColor: AppColor.PrimaryColor,
            }}>
            <View style={{ flex: 1.2, alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                Messages Sent
              </Text>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                {inputFieldData?.stats?.outbound ?? 0}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: 'center', }}>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                Responses
              </Text>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                {inputFieldData?.stats?.inbound ?? 0}
              </Text>
            </View>
            <View style={{ flex: 1.2, alignItems: 'center', }}>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                Response Rate
              </Text>
              <Text style={{ fontSize: 18, color: AppColor.PrimaryColor }}>
                {Math.round(
                  (Number(inputFieldData?.stats?.inbound ?? 0) * 100) /
                  Number(inputFieldData?.stats?.outbound ?? 1)
                )}
                %
              </Text>
            </View>
          </View>
          {showRisk && (
            <RiskalyzeComponent client={client} member={inputFieldData} />
          )}
          <Text style={{ fontSize: 16, fontWeight: 500, marginVertical: 20 }}>Account Actions</Text>

          <View style={{ flexDirection: 'row',flexWrap:'wrap', alignItems: 'center', gap: 20 }}>
            <BoxButton>
              <Switch
                accessibilityRole='switch'
                accessibilityLabel='Blocked'
                accessibilityHint='Block Contact'
                style={{ alignSelf: 'center', }}
                value={inputFieldData.spam}
                activeThumbColor={AppColor.PrimaryColor}
                onValueChange={(v) => {
                  if (v) {
                    setBlockedContact(true)
                  } else {
                    setBlockedContact(false);
                  }
                  setInputFieldData((state) => ({ ...state, spam: v }));
                }}
                thumbColor={inputFieldData.spam ? '#990000' : AppColor.PrimaryColor}
                trackColor={{ false: AppColor.GREY100, true: AppColor.danger }}
              />
              <Text
                style={{
                  color: 'block',
                  marginTop: 20,
                }}>
                Block Contact
              </Text>

            </BoxButton>
            <BoxButton accessibilityRole='button' accessibilityLabel='Reconcile Contact.' onPress={reconcileContact}>
              <Icon type='font-awesome' name='expand' />

              <Text
                style={{
                  color: 'block',
                  marginTop: 20,
                }}>
                Reconcile Contact
              </Text>
            </BoxButton>
            {group?.showTenantExport &&
              <BoxButton
                accessibilityRole='button'
                accessibilityLabel='Export Conversation.'
                onPress={() => {
                  setExportVisible(true);
                }}>
                <Icon type='entypo' name='export' />
                <Text
                  style={{
                    marginTop: 10
                  }}>
                  Export Conversation
                </Text>
              </BoxButton>}
            <BoxButton onPress={deleteContact}
              accessibilityRole='button'
              accessibilityLabel='Delete Contact.'
            >
              <Icon type='font-awesome' color={AppColor.danger} name='trash-o' />

              <Text
                style={{
                  color: 'black',
                  marginTop: 10,
                }}>
                Delete Contact
              </Text>
            </BoxButton>
          </View>
        </View>

      </ScrollView>
      <View style={{ width:'100%',flexDirection: 'row', gap: 20, justifyContent: 'flex-end', paddingRight: 5, paddingBottom: 5 }}>
        <CustomButton
          outline
          width={'20%'}
          title={'Cancel'}
          onPress={() => {
            navigation.goBack();
          }}
        />
        <CustomButton
          width={'20%'}
          title={'Save'}
          onPress={() => {
            
            if (blockedContact) {
              setBlockModal(true);
              return;
            }
            if(inputFieldData.phoneNumbers.filter(e => e.messaging).length === 0){
              setNoMessagingModal(true);
              return;
            }
            if(inputFieldData.phoneNumbers.filter(e => e.longCode === '').length > 0){
              Toast.show({
                status: 'error',
                title: 'Each contact number must have a valid phone number.'
              })
              return;
            }

            SaveContact()
          }}
        />

      </View>

      <ExportConversationModal
        navigation={navigation}
        visible={exportVisible}
        setVisible={setExportVisible}
        contact={inputFieldData.id}
      />
      {
        reconcileVisible &&
        <Modal visible={reconcileVisible} transparent={true}>
          <ReconcileModal
            setReconcileVisible={setReconcileVisible}
            reconcileMembers={reconcileMembers}
            handleReconcile={handleReconcile}
            isLoading={loading}
          />
        </Modal>

      }
      {
        blockModal &&
        <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={blockModal} transparent={true}>
          <RemovePhoneModal
            title="Block Contact"
            continueLabel={'Block'}
            message="Are you sure you want to block this contact? You will not receive phone calls or messages from this contact."
            handleRemove={() => {
              setBlockModal(false);
              SaveContact();
            }}
            setRemoveModal={setBlockModal}
          />
        </Modal>
      }
      {
        noMessagingModal &&
        <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={noMessagingModal} transparent={true}>
          <RemovePhoneModal
            title="No Messaging Number"
            hideCancel={true}
            continueLabel={'Okay'}
            message="Please select a default messaging number for this contact."
            handleRemove={() => {
              setNoMessagingModal(false);
            }}
            setRemoveModal={setNoMessagingModal}
          />
        </Modal>
      }

      {loading && <Loader />}
    </SafeAreaView>
  );
};

const BoxButton = props => {
  return (
    <TouchableOpacity
      accessibilityRole={props.accessibilityRole}
      accessibilityLabel={props.accessibilityLabel}
      onPress={props.onPress}
      style={{ width: 150, height: 125, backgroundColor: '#F9F9F9', borderColor: '#DEDCD9', borderWidth: 1, justifyContent: 'center', alignItems: 'center' }}>
      {props.children}
    </TouchableOpacity>
  )
}

export default EditContact;


class Country {
  constructor(label, isoCode, iddPrefix, countryCode, voice, sms, mms) {
    this.label = label;
    this.isoCode = isoCode;
    this.iddPrefix = iddPrefix;
    this.countryCode = countryCode;
    this.voice = voice;
    this.sms = sms;
    this.mms = mms;
  }
  getLabel() {
    return '+' + this.countryCode;
  }
}

const countries = [
  new Country('United States', 'US', '011', 1, true, true, true),
  new Country('Australia', 'AU', '011', 61, true, true, false),
  new Country('Austria', 'AT', '011', 43, false, true, false),
  new Country('Belgium', 'BE', '011', 32, false, true, false),
  new Country('Canada', 'CA', '011', 1, true, true, true),
  new Country('Chile', 'CL', '011', 56, false, true, false),
  new Country('Czech Republic', 'CZ', '011', 420, false, true, false),
  new Country('Estonia', 'EE', '011', 372, false, true, false),
  new Country('France', 'FR', '011', 33, true, true, false),
  new Country('Germany', 'DE', '011', 49, true, true, false),
  new Country('Hong Kong', 'HK', '011', 852, false, true, false),
  new Country('Hungary', 'HU', '011', 36, false, true, false),
  new Country('Ireland', 'IE', '011', 353, false, true, false),
  new Country('Israel', 'IL', '011', 972, true, true, false),
  new Country('Latvia', 'LV', '011', 371, false, true, false),
  new Country('Lithuania', 'LT', '011', 370, false, true, false),
  new Country('Milaysia', 'MY', '011', 60, false, true, false),
  new Country('Mexico', 'MX', '011', 52, false, true, true),
  new Country('Netherlands', 'NL', '011', 31, false, true, false),
  new Country('Norway', 'NO', '011', 47, false, true, false),
  new Country('Poland', 'PL', '011', 48, false, true, false),
  new Country('Portugal', 'PT', '011', 351, false, true, false),
  new Country('Puerto Rico', 'PR', '', 1, true, true, false),
  new Country('Spain', 'ES', '011', 34, false, true, false),
  new Country('Sweden', 'SE', '011', 46, false, true, false),
  new Country('Switzerland', 'CH', '011', 41, false, true, false),
  new Country('United Kingdom', 'UK', '011', 44, true, true, false),
];


const PhoneNumberEntry = ({ index, x, removePhoneNumber, onChange }) => {


  const handleChangeCountry = (v) => {
    let country = countries[v];
    const newData = {
      ...x,
      countryCode: '+' + country.countryCode,
      isoCode: country.isoCode,
    }

    if (x && onChange) onChange(x, newData)
  }

  const handleChangeNumber = e => {
    const newData = { ...x, longCode: e.currentTarget.value }
    if (x && onChange) onChange(x, newData)
  }

  const handleChangeType = (v) => {
    let ph = phoneNumberTypes[v];
    const newData = { ...x, typeId: ph.id, type: ph.id }
    if (x && onChange) onChange(x, newData)
  }

  const handleChangeMessagingNumber = () => {
    const newData = { ...x, messaging: !x.messaging }
    if (x && onChange) onChange(x, newData)
  }

  return <View
    style={{
      flexDirection: 'row',
      borderRadius: 5,
      borderColor: AppColor.PrimaryColor,
      marginBottom: 5,
      alignItems: 'center',
      gap: 15,
      flex: 1,
      flexWrap: 'wrap',
    }}
    key={`phonenumber_${index}`}>
    <Menu w={500} placement='bottom left' trigger={triggerProps => {
      return (
        <TouchableOpacity
          style={{ minWidth: 100, backgroundColor: '#F6F6F6', alignItems: 'center', justifyContent: 'space-between', padding: 10, paddingLeft: 20, flexDirection: 'row' }}
          onPress={e => triggerProps.onPress()}
          {...triggerProps}
        >
          <Text>+{
            countries.find(e => e.getLabel() === x.countryCode)?.countryCode
          } </Text>
          <Icon color={AppColor.PrimaryColor} name='chevron-down' type='entypo' size={24} />
        </TouchableOpacity>
      )
    }}>
      <View style={{ maxHeight: 400, overflowY: 'scroll' }}>
        {
          countries.map((e, i) => {
            return (
              <Menu.Item onPress={() => handleChangeCountry(i)}>
                <Text>+{e.countryCode} {e.label}</Text>
              </Menu.Item>
            )
          })
        }
      </View>
    </Menu>

    <CustomInput placeholder={'Enter Phone Number'} onChange={handleChangeNumber} containerStyle={{ maxWidth: 200, marginTop: 0, height: 40, minHeight: 40 }} value={x.longCode} titleStyle={{ display: 'none' }} />

    <Menu w={500} placement='bottom left' trigger={triggerProps => {
      return (
        <TouchableOpacity
          style={{ minWidth: 150, backgroundColor: '#F6F6F6', alignItems: 'center', justifyContent: 'space-between', padding: 10, paddingLeft: 20, flexDirection: 'row' }}
          onPress={e => triggerProps.onPress()}
          {...triggerProps}
        >
          <Text>{
            phoneNumberTypes.find((z) => z.id === x.typeId)?.label
          } </Text>
          <Icon color={AppColor.PrimaryColor} name='chevron-down' type='entypo' size={24} />
        </TouchableOpacity>
      )
    }}>
      <View style={{ maxHeight: 400, overflowY: 'scroll' }}>
        {
          phoneNumberTypes.map((e, i) => {
            return (
              <Menu.Item onPress={() => handleChangeType(i)}>
                <Text>{e.getLabel()}</Text>
              </Menu.Item>
            )
          })
        }
      </View>
    </Menu>
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <CheckBox onPress={handleChangeMessagingNumber} containerStyle={{ marginRight: 0 }} style={{ marginRight: 0 }} checked={x?.messaging} />
      <Text style={{ marginLeft: 0 }}>Default Messaging Number</Text>
    </View>

    <Icon
      accessibilityRole='button'
      accessibilityLabel='Trash.'
      accessibilityHint='Delete phone number.'
      color={AppColor.danger}
      name="trash-o"
      type="font-awesome"
      onPress={() => {
        removePhoneNumber(x)
      }} />

  </View>;
}

function ExportConversationModal(props) {
  const [show, setShow] = React.useState(false);
  const [show1, setShow1] = React.useState(false);
  const [dateFrom, setDateFrom] = React.useState(new Date());
  const [dateTo, setDateTo] = React.useState(new Date());
  const tenant = useSelector((state) => state.User)?.profile?.tenant;

  const onChange = (event) => {
    // const currentDate = selectedDate ;
    setDateFrom(event.target.value);
  };

  const onChange1 = (event) => {
    // const currentDate = selectedDate;
    setDateTo(event.target.value);
  };

  const onPressSubmit = () => {

    const url = `${End_Points.Base_url}${End_Points.End}?action=generate_report&from=${new Date(dateFrom).getTime()}&to=${new Date(dateTo).getTime()}&contact=${props.contact}&format=text&tenantUUID=${tenant.uuid}`;
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        //Alert.alert('Error', "Don't know how to open URI");
        console.log("Don't know how to open URI: " + url);
      }
    });
    props.setVisible(false);
  };

  return (
    <Modal
      visible={props.visible}
      useNativeDriver={true}
      transparent={true}
    // onBackdropPress={() => props.setVisible(false)}
    >
      <View
        style={{
          padding: 20,
          flex: 1,
          backgroundColor: '#0005',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ backgroundColor: 'white', padding: 20,minWidth:400 }}>
          <Text style={{ fontSize: 18, textAlign: 'center' }}>
            Export Conversation
          </Text>
          <View
            style={{
              marginTop: 30,
              gap:20,
              flex:1,
              zIndex:99,
              width: '100%',
            }}>
            <Text>From*</Text>

            <DateTimePicker
              className='fixedDateForModals'
              calendarType="US"
              onChange={setDateFrom}
              value={dateFrom}
              calendarIcon={
                <Icon
                
                accessibilityRole='button'
                accessible={true}
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
              
              <Text>To*</Text>
              <DateTimePicker
              className='fixedDateForModals'
              calendarType="US"
              onChange={setDateTo}
              value={dateTo}
              calendarIcon={
                <Icon
                
                accessibilityRole='button'
                accessible={true}
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap:20,
              marginTop: 40,
              zIndex:1,
            }}>
            <CustomButton
              outline
              style={{ width: '40%' }}
              title={'Cancel'}
              onPress={() => {
                props.setVisible(false);
              }}
            />
            <CustomButton
              style={{ width: '40%' }}
              title={'OK'}
              onPress={onPressSubmit}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
}

function RiskalyzeComponent({ client, member }) {
  let questionnairesData = {
    SIMPLE: { name: 'simple', label: ' Simple Questionnaire ' },
    STANDARD: { name: 'standard', label: ' Standard Questionnaire ' },
    DETAILED: { name: 'detailed', label: ' Detailed Questionnaire ' },
    // ADVISOR: { name: "advisor", label: " Advisor Questionnaire " },
  };
  const [loading, setLoading] = React.useState(false);
  const [questionnaires, setQuesionaries] = React.useState([]);
  const [type, setType] = React.useState(questionnairesData.SIMPLE);
  const [questionnaireId, setQuestionnaireId] = React.useState(null);

  React.useEffect(() => {
    if (client && client != null) {
      if (client.questionnaires != null) {
        setQuesionaries(client.questionnaires);
      }
    }
  }, [client]);

  const handlePersmissionsforQues = () => {
    Alert.alert('Send', 'Do you really want to send?', [
      {
        text: 'NO',
      },
      {
        text: 'YES',
        onPress: () => {
          handleAskQuestionaire();
        },
      },
    ]);
  };

  const handleAskQuestionaire = async () => {
    if (member.email_address === null || member.email === '') {
      Alert.alert(
        'Riskalyze',
        'The contact must first be saved with an email address.'
      );
      return;
    }

    try {
      setLoading(true);
      const body = {
        action: 'send_riskalyze_questionnaire',
        type: type.name,
        memberId: member.id,
      };

      if (client?.clientId != null) {
        body['clientId'] = client.clientId;
      }
      if (questionnaireId && questionnaireId != null) {
        body['questionnaireId'] = questionnaireId;
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Sent Successfully',
          status: 'success',
        });
      } else {
        // Alert.alert("Server Error",result.data.errorMessage)
        Toast.show({
          duration: 2000,
          title: 'Unable to send. ' + result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <View
      style={{ marginTop: 10, borderTopWidth: 1, borderColor: AppColor.Dark }}>
      <Text style={{ color: AppColor.Dark, marginTop: 10 }}>RISKALYZE</Text>
      <View
        style={{
          borderRadius: 10,
          paddingHorizontal: 2,
          borderWidth: 1,
          alignItems: 'center',
          alignSelf: 'center',
        }}>
        <Text
          style={{
            letterSpacing: 2,
            color: AppColor.Dark,
            fontSize: 18,
            fontFamily: AppFonts.Roboto_Bold,
          }}>
          RISK
        </Text>
        <Text
          style={{
            letterSpacing: 3,
            color: AppColor.PrimaryColor,
            fontSize: 30,
            fontFamily: AppFonts.Roboto_Bold,
          }}>
          {client?.riskNumber ?? '--'}
        </Text>
      </View>
      <View
        style={{
          borderWidth: 1,
          marginHorizontal: 10,
          marginTop: 10,
          borderColor: AppColor.Dark,
        }}>
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: AppColor.PrimaryColor,
            padding: 10,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Text style={{ textTransform: 'uppercase' }}>Score</Text>
          <Text style={{ textTransform: 'uppercase' }}>Goals</Text>
          <Text style={{ textTransform: 'uppercase' }}>Completed</Text>
        </View>
        {questionnaires.slice(0, 3).map((x) => {
          return (
            <TouchableOpacity
              onPress={() => setQuestionnaireId(x.id)}
              style={{
                borderBottomWidth: 1,
                borderColor: AppColor.PrimaryColor,
                padding: 10,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              key={x.id}>
              <Text>{x.riskNumber ?? '--'}</Text>
              <Text style={{ textTransform: 'uppercase' }}>{x.goals}</Text>
              <Text>
                {x.completedTimestamp === null
                  ? '---'
                  : moment(x.completedTimestamp).format('DD/MM/YY')}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <Text
        style={{
          textTransform: 'uppercase',
          color: AppColor.Dark,
          fontSize: 20,
          marginTop: 10,
        }}>
        Question type
      </Text>
      {Object.values(questionnairesData).map((x, index) => {
        return (
          <CheckBox
            title={x.label}
            checked={x.name === type.name}
            onPress={() => setType(x)}
            key={`check_${index}`}
          />
        );
      })}
      <CustomButton
        style={{ marginTop: 10 }}
        title="send selected questionnaire"
        onPress={handlePersmissionsforQues}
      />
    </View>
  );
}
