import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import SimpleCheckbox from "../../../Components/SimpleCheckbox";

const Permissions = ({ user, setUser }) => {
    console.log(user);

    const handleUpdatePermissions = (id) => {
        // Check if the permission with the given id exists in the user's permissions
        const hasPermission = user.permissions.some(e => e?.id === id || e === id);

        if (hasPermission) {
            // Remove the permission with the given id
            setUser({
                ...user,
                permissions: user.permissions.filter(e => e?.id !== id && e !== id)
            });
        } else {
            // Add the new permission
            setUser({
                ...user,
                permissions: [...user.permissions, id]
            });
        }
    }

    return (
        <>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginBottom: 20 }}>
                Permissions allow you to control what users of this account can and cannot do.
            </Text>
            <SimpleCheckbox
                label={'MyRepChat User'}
                onChange={() => handleUpdatePermissions(13)}
                value={user.permissions.some(e => e?.id === 13 || e === 13)}
            />
            <SimpleCheckbox
                label={'Reseller'}
                onChange={() => handleUpdatePermissions(16)}
                value={user.permissions.some(e => e?.id === 16 || e === 16)}
            />
            <SimpleCheckbox
                label={'Group Administrator'}
                onChange={() => handleUpdatePermissions(15)}
                value={user.permissions.some(e => e?.id === 15 || e === 15)}
            />
            <SimpleCheckbox
                label={'System Administrator'}
                onChange={() => handleUpdatePermissions(1)}
                value={user.permissions.some(e => e?.id === 1 || e === 1)}
            />
        </>
    );
}

export default Permissions;
