import { Icon } from 'react-native-elements';
import React from 'react';
import { Text, View, Image, TouchableOpacity, Alert, Linking, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector, useDispatch } from 'react-redux';
import { providers } from '../IntegrationProvider';
import { RenderPhoneModal, RemovePhoneModal,  } from './modals';
import End_Points from '../../Constants/Api';
import Modal from 'modal-react-native-web';
import { useToast, Tooltip } from 'native-base';
import Loader from '../../Components/Loader';
import { loadMemberNumbers, callContact, deleteItem, loadReconcileMember } from './helpers';
import { loadDuplicateNumbersThunk } from '../../Redux/duplicate_members';
import { ReconcileModal } from './modals';

const Contacts = ({ navigation }) => {
  const contactData = useSelector((state) => state.duplicate_members).messagingPhone
  const [search, setSearch] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [allContactData, setAllContactData] = React.useState(contactData);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [hasVoiceAddon, setHasVoiceAddon] = React.useState();
  const [removeModal, setRemoveModal] = React.useState(false);
  const [selectedNumber, setSelectedNumber] = React.useState('');
  const [reconcileVisible, setReconcileVisible] = React.useState(false);
  const [reconcileMembers, setReconcileMembers] = React.useState([]);

  const dispatch = useDispatch();
  const Toast = useToast();

  React.useEffect(() => {
    if (tenant?.voice) {
      setHasVoiceAddon(tenant.voice);
    }
  }, [tenant]);

  React.useEffect(() => {
    setAllContactData(contactData);
  }, [contactData]);

  /*React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter(
        (x) =>
          x.fname.toLowerCase().includes(search.trim().toLowerCase()) ||
          x.lname.toLowerCase().includes(search.trim().toLowerCase())
      );
      setAllContactData(searchedData);
    } else {
      setAllContactData(contactData);
    }
  }, [search]);*/


  const handleCall = async (phoneNumber) => {
    callContact(phoneNumber)
    .then(res => {
      if(res?.data?.success){
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to compelete the call',
          status: 'success',
        });
        setTimeout(() => {
          Linking.openURL(`tel:${phoneNumber}`);
        }, 2000);
      } else {
        Alert.alert('Fail to Send Message', res?.data?.failures?.join(','));
      }
    })    
  };

  const handleLoadMemberNumbers = async (id) => {
    setIsLoading(true);
    loadMemberNumbers(id)
    .then(res => {
      if(res?.data?.success) setPhoneNumbers(res?.data?.phonenumbers)
      setIsLoading(false);
    })    
  };

  const handleRemove = async () => {
    setRemoveModal(false);
    setIsLoading(true);
    deleteItem(selectedNumber)
    .then( res => {
      if (res?.data?.success) {
        Toast.show({ duration: 2000, title: res?.data?.message || 'Successfully Deleted!', status: 'success' });
        dispatch(loadDuplicateNumbersThunk());
      } else {
        Toast.show({
          duration: 2000,
          title: res?.data?.errorMessage || 'Something went wrong',
          status: 'error',
        });
      }
      setIsLoading(false);
    })
  };


  const handleLoadReconcileMember = async (id) => {
    setIsLoading(true);
    loadReconcileMember()
    .then(res => {
      if(res?.data?.success){
        setReconcileMembers(data.contacts);
      }
      setIsLoading(false);
    })     
  };


  const handleReconcile = async (contact) => {
    setReconcileVisible(false);
    setIsLoading(true);
    reconcileMember(contact)
    .then(res => {
      setIsLoading(false);
      if(res?.data?.success){ 
        dispatch(loadDuplicateNumbersThunk());
        Toast.show({ duration: 2000, title: res?.data?.message, status: 'success' });
      }
      else {
        Toast.show({
          duration: 3000,
          type: 'danger',
          text: res?.data?.errorMessage || 'Something went wrong, please try again later.',
        });
      }
    }) 
  };


  const renderListView = (data) => {
    return (
      <>
        <Text
          style={{
            marginHorizontal: 20,
            marginTop: 20,
            color: AppColor.PrimaryColor,
            fontSize: 15,
            fontWeight: '700',
          }}>
          {data[0]}
        </Text>
        {data[1].map((item) => {
          let image = require('../../Assets/Images/splashlogo.png');
          let provider = Object.values(providers).find(
            (x) => x.name == item.source
          );
          if (provider) {
            image = provider.smallImageUrlPath;
          }
          return (
            <TouchableOpacity
            accessibilityRole='button'
              onPress={() => {
                navigation.navigate('ContactEdit', { item: item });
              }}
              style={{
                width: '90%',
                alignSelf: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                height: 60,
                marginBottom: 5,
              }}>
              <View style={{ justifyContent: 'center' }}>
                <img src={image} style={{ height: 40, width: 40 }} />
              </View>
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 17,
                    marginHorizontal: 10,
                    color: AppColor.Dark,
                  }}>
                  {item.name}
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}>
                {hasVoiceAddon &&
                <Tooltip
                  label="Call"
                  openDelay={500}
                  placement="top"
                  hasArrow={true}>
                  <View
                    style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                    <Icon
                      onPress={() => {
                        setPhoneModalVisible(true);
                        handleLoadMemberNumbers(item.id);
                      }}
                      name={'call-outline'}
                      type={'ionicon'}
                      style={{ fontSize: 27, color: AppColor.Dark }}
                    />
                  </View>
                </Tooltip>}

                <Tooltip
                  label="Message"
                  openDelay={500}
                  placement="top"
                  hasArrow={true}>
                  <View
                    style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                    <Icon
                      onPress={() =>
                        navigation.navigate('Chats', { memberData: item, dl: null })
                      }
                      style={{ fontSize: 27, color: AppColor.Dark }}
                      name={'message1'}
                      type={'antdesign'}
                    />
                  </View>
                </Tooltip>

                <Tooltip
                label="Delete"
                openDelay={500}
                placement="top"
                hasArrow={true}>
                  <View
                    style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                    <TouchableOpacity
                      onPress={() => {
                        setRemoveModal(true);
                        setSelectedNumber(item.id);
                      }}>
                      <Icon name="trash-outline" type='ionicon' size={30} />
                    </TouchableOpacity>
                  </View>
              </Tooltip>
              <Tooltip
                  label="Reconnect to CRM"
                  openDelay={500}
                  placement="top"
                  hasArrow={true}>
                  <View
                    style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                    <Icon
                      onPress={() => {
                        setReconcileMembers([]);
                        setReconcileVisible(true);
                        handleLoadReconcileMember(item.id);
                      }}
                      style={{ fontSize: 27, color: AppColor.Dark }}
                      name={'disconnect'}
                      type={'antdesign'}
          />
                  </View>
                </Tooltip>


              </View>
            </TouchableOpacity>
          );
        })}
      </>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <ScrollView style={{flex:1}}>
      {/* <Search onChangeText={(t) => setSearch(t)} /> */}
      {Object.entries(allContactData).length == 0 && (
        <Text style={{ textAlign: 'center', color: AppColor.Dark }}>
          No Duplicate Contacts
        </Text>
      )}
      {Object.entries(allContactData).map((x) => {
        return renderListView(x);
      })}
      </ScrollView>

      <Modal visible={phoneModalVisible} transparent={true}>
          <RenderPhoneModal
            handleCall={handleCall}
            phoneNumbers={phoneNumbers}
            setPhoneModalVisible={setPhoneModalVisible}
          />
      </Modal>      
      <Modal visible={removeModal} transparent={true}>
          <RemovePhoneModal
            handleRemove={handleRemove}
            setRemoveModal={setRemoveModal}
          />
      </Modal>

      <Modal visible={reconcileVisible} transparent={true}>
          <ReconcileModal
            setReconcileVisible={setReconcileVisible}
            reconcileMembers={reconcileMembers}
            handleReconcile={handleReconcile}
            isLoading={isLoading}
          />
      </Modal>


      {isLoading && <Loader />}

    </SafeAreaView>
  );
};
export { Contacts };
export default Contacts;
