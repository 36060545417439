import React from 'react';
import {View, Text, StyleSheet, Image, TouchableOpacity, Linking, Alert} from 'react-native';
import AppColor from '../../Constants/Color';
import Avatar from './Avatar';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Icon } from 'react-native-elements';
import AppFonts from '../../Constants/Fonts';
import { useSelector } from 'react-redux';

const Ten4Message = props => {
    const user = useSelector((state) => state.User.profile)?.user;
    const isSender = user.user_id == props.userId;
    const isHighlighted = props.message.includes(`@${user?.name}`);
    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);
      
        var interval = seconds / 31536000;
      
        if (interval > 1) {
          return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
          return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
          return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
          return Math.floor(interval) + " minutes ago";
        }
        return "Just now";
    }

    const handleAttachmentPress = () => {
        if(props.media){
            Linking.canOpenURL(props.media)
            .then(result => {
                if(result){
                    Linking.openURL(props.media);
                }
            })
        }
    }
    const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;

    const renderText = () =>
      props.message
        .split(" ")
        .map(part =>
          URL_REGEX.test(part) ? <a target={'_blank'} href={part}>{part}</a> : `${part} `
        );
    
    const parsedMessage = renderText()

    return(
        <View style={{flexDirection:isSender? 'row-reverse': 'row', alignItems:'flex-start',alignSelf:isSender ? 'flex-end' : 'flex-start',marginLeft:5,marginBottom:15,}}>
        {!isSender &&
        <Avatar name={props.name} avatar={props.avatar}/>
        }
        <View style={[styles.container, {backgroundColor: isSender ? '#F9F9F9' : isHighlighted ? 'rgba(255,165,90,.2)' : '#ECF6FF',borderLeftWidth: isSender ? 0 : isHighlighted ? 2 : 0,borderLeftColor:'orange',}]}>
            <View style={{flexDirection:'row',alignItems:'flex-end'}}>
            {!isSender && 

                <Text style={{fontSize:16, color:AppColor.BLUE100,marginTop:2 }}>
                    {props.name}
                </Text>
            }
                <Text style={{fontSize:12,marginLeft:isSender ? 0 : 5,opacity:.6}}>
                    {`${timeSince(props.created)}`}
                </Text>
            </View>
            
            {
                !!props.message && (
                <View style={{flex:1}}>
                <Text style={{marginTop:5,flexWrap:'wrap'}}>
                    {parsedMessage}
                </Text>
                </View>
                )
                
            }
            {
                !!props.mediaUrl && 
                (
                props?.contentType?.split('/')[0] === 'image' ?
                <img onClick={props.onImageClick} src={props.mediaUrl} height={'auto'} alt={props?.mediaName}  width={200} style={{marginTop:5,cursor:'pointer'}}/> 
                :
                <a target={'_blank'} href={props?.mediaUrl} style={{textDecoration:'none'}}>
                <View
                style={{flexDirection:'row', alignItems:'center',padding:10,backgroundColor:'rgb(227, 227, 227)',borderRadius:5}}
                >
                    <Icon type='antdesign' name='file1' size={27}  style={{marginRight:10}}/>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular,color:AppColor.PrimaryColor,fontWeight:'200'}}>{props?.mediaName}</Text>
                    <Icon type='antdesign' name='download' style={{marginLeft:10}} />
                </View>
                </a>
                )
                
            }

            

        </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        marginLeft:10,
        flex:1,
        padding:10,
        borderRadius:5
    }
})

export default Ten4Message;