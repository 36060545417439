import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import Modal from 'modal-react-native-web';
import CustomButton from '../Button';
import AppFonts from '../../Constants/Fonts';

export const ShowConfirmModal = ({
  visible, setVisible, isGlobalModalVisible, isMicrofocusVisible, isMsjournalVisible,
}) => {
  let label = 'MicroFocus';
  if (isGlobalModalVisible) {
    label = 'Global Relay.';
  }
  if (isMsjournalVisible) {
    label = 'MSJournal.';
  }
  return (
    <Modal
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      transparent={true}
      visible={visible}
      onDismiss={() => {
        setVisible(false);
      }}>
        <View style={styles.modal}>
          <View style={{minWidth:'45%',maxWidth:'60%', minHeight:'15%',justifyContent:'center',alignItems:'center',backgroundColor:'white',padding:30,borderRadius:5}}>
          <Text style={styles.description}>
            MyRepChat can send your data to {label} for archival. In order to
            set this up you need to contact your group administrator and ask
            them to configure your group for archiving to {label}.
          </Text>
          <CustomButton title="Ok" onPress={() => setVisible(false)} />
          </View>
        </View>
    </Modal>
  );
};


const styles = StyleSheet.create({
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 5,
  },
  modal: {
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
    width: '100%',
    height:'100%',
    justifyContent:'center',
    alignItems:'center'
  },
});
