import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import AppColor from "../../Constants/Color";

const Media = ({tenant}) => {
    const Tenant = tenant.data.tenant;
    const {data, isLoading, isError} = getQuery({
        action: 'get_media',
        account: Tenant.id
    })

    if(isLoading) return <Loader backgroundColor="transparent"/>;
    if(!data) return null;

    const handleOpenLink = url => {
        window.open(url,'_blank');
    }
    const test = [
        {
            "id": 1,
            "name": "97af19ed-dece-4975-bdfc-520887f5ebdequicktime",
            "storageId": 37,
            "contentType": "video/quicktime",
            "url": "https://dart.ionlake.com/dart/download/fdef6421-770f-4b6b-aadc-b74237c84efe.mov",
            "externalurl": "https://dart.ionlake.com/dart/web/storage.html?id=fdef6421-770f-4b6b-aadc-b74237c84efe.mov",
            "height": 0,
            "width": 0,
            "report": false
        }
    ]

    return(
        <ScrollView style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                Here you can view the media files that this account has either sent or received. These are referenced in reports.
            </Text>
            {data.media.map((item,index) => {
                return(
                    <Text onPress={() => handleOpenLink(item.url)} key={index} style={{cursor:'pointer',fontFamily:AppFonts.Roboto_Regular,textDecorationLine:'underline',color:AppColor.BLUE100,marginTop:10}}>
                        {item.name}
                    </Text>
                )
            })
            }
        </ScrollView>
    )
}

export default Media;