import React from "react";
import { Switch, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import ComponentAccordion from "../../Components/ComponentAccordion";
import Loader from "../../Components/Loader";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import moment from "moment";

const TermsOfUseManagement = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const { refetch, data, isLoading, isError } = getQuery({
        action: 'get_tou',
        affiliate: affiliate.id
    })

    if(isLoading) return <Loader backgroundColor={'transparent'}/>

    if(!data) return null;

    const RenderTOU = ({item}) => {
        const [data,setData] = React.useState({
            active: item?.active || false,
            text: item?.text || ''
        })

        const updateTou = async () => {

            const body = {
                action: 'update_tou',
                ...item,
                ...data,
                affiliate: affiliate.id
            }
            if(item?.id){
                body.action = 'update_tou';
            } else {
                body.action= 'create_tou';
            }
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
            }
        }
    

        return(
            <>
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>Active</Text>
            <Switch style={{marginTop:10}} value={data.active} onValueChange={e => setData({...data, active: e})}/>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20}}>Terms of Use Link</Text>
            <CustomInput onChangeText={e => setData({...data, text: e})} value={data.text} />

            <CustomButton title={'Save'} style={{alignSelf:'flex-end',marginTop:20}} width={'20%'} onPress={() => updateTou()}/>
            </>
        )
    }
    
    const listItem = item => {
        const createdAt = moment(item.created).format('MM/DD/YYYY');

        return [createdAt, item.active, item.text]
    }

    return (
        <>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginVertical:20 }}>Here, group admins can create and customize the rules and guidelines that will govern user interactions within their community. By setting these terms, you ensure a safe and enjoyable environment for all members. Please draft your terms carefully to clearly communicate expectations and responsibilities to your users.</Text>
        <ComponentAccordion 
        allowAdd
        data={data.tous} 
        renderListItem={listItem} 
        renderComponent={(item) => <RenderTOU item={item} /> } columns={['Created', 'Active', 'Link']}
        />
        </>
    )
}

export default TermsOfUseManagement;