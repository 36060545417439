import React from 'react';
import {Image, Text, View} from 'react-native';
import AppColor from '../../Constants/Color';

const Avatar = props => {

    const getInitials = () => {
        let result = ''
        const split = props.name.split(' ');
        split.forEach(letter => {
            result += letter.charAt(0).toUpperCase()
        })
        return result;
    }


    return(
        <>
        {
        !!props.avatar ? 
        (
            <img
            alt="User Avatar."
            src={props.avatar}
            style={{borderRadius:100,height:30,width:30, objectFit: 'cover'}}
            />
        )
     : 
        (
            <View style={{backgroundColor:AppColor.PrimaryColor,borderRadius:100,height:30,width:30,justifyContent:'center',alignItems:'center'}}>
                <Text style={{color:'white',letterSpacing:-1}}>{getInitials()}</Text>
            </View>
        )
    
        }
    </>
        
    )
    
}

export default Avatar;