import React, { useState } from 'react';
import { Text, View, StyleSheet, Alert } from 'react-native';
import { useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { useToast } from 'native-base';

import Header from '../../../Components/Header';
import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Loader from '../../../Components/Loader';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import getData from '../../../AsyncUtils/GetData';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';

const CallerTimeout = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const [selectedTimeout, setSelectedTimeout] = useState(tenant?.voiceWaitDelay ? tenant.voiceWaitDelay : 15);

  const Toast = useToast();

  const handleSave = async () => {
    if (!selectedTimeout) {
      Alert.alert('Error', 'Please input timeout');
      return;
    }
    try {
      let body = {
        action: 'update_voice_delays',
        tenantId: tenant.id,
        voiceWaitDelay: selectedTimeout,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Voice delays updated',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <View style={styles.body}>
          <Text style={styles.formLabel}>
            Important: It is very rare that you would need to change this
            setting.
          </Text>
          <Text style={styles.comment}>
            Only change this setting if you are experiencing one of the
            following situations:
          </Text>
          <Text style={styles.comment}>
            -If incoming callers are leaving voice mail messages on your mobile
            phone when you don't answer (Your mobile phone's voicemail is
            answering the call), then change this to a smaller number
          </Text>
          <Text style={styles.comment}>
            -If incoming calls are not ringing long enough for you to answer the
            phone before they are going to MyRepChat voice mail, then change
            this to a larger number
          </Text>
          <Text style={styles.comment}>
            Some testing will be required to assure you get this to an optimal
            setting with your mobile phone.
          </Text>
          <View style={styles.forms}>
            <CustomInput
              value={selectedTimeout}
              onChangeText={(t) => setSelectedTimeout(t)}
              title={'CALL TIMEOUT (DEFAULT=15 SECONDS)'}
              placeholder=""
              width="100%"
              textInputStyle={styles.textInputStyle}
              keyboardType="number-pad"
            />
            <View style={styles.button}>
              <CustomButton title="save" onPress={handleSave} />
            </View>
          </View>
        </View>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

export default CallerTimeout;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
  },
  content: {
    width: '100%',
    maxWidth: 767,
    padding: 15,
    marginVertical: 30,
    alignItems: 'center',
    alignSelf: 'center',
  },
  title: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Regular,
  },
  body: {
    marginTop: 20,
  },
  comment: {
    fontSize: 14,
    fontFamily: AppFonts.Roboto_Regular,
    marginVertical: 5,
  },
  formLabel: {
    color: AppColor.Black,
    fontSize: 14,
    fontFamily: AppFonts.Roboto_Bold,
  },
  forms: {
    marginTop: 30,
    alignItems: 'center',
  },
  button: {
    marginTop: 15,
    width: '100%',
    maxWidth: 400,
  },
  textInputStyle: { width: '100%' },
});
