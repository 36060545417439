import React, { useState } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Alert,
  Pressable,
  TouchableOpacity,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'modal-react-native-web';
import { useToast } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Loader from '../../../Components/Loader';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import { loadAccountHealthThunk } from '../../../Redux/account_health';

const SucessSelectMobile = ({
  visible,
  setVisible,
  selectedNumber,
  handleNextPage,
}) => {
  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={visible}
      transparent={true}
      ariaHideApp={false}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <Text style={styles.description}>
            {`Congratulations! ${selectedNumber} is now YOUR dedicated MyRepChat number. You can write it down if you want to, but it will always be visible in MyRepChat.`}
          </Text>
          <View style={styles.button}>
            <CustomButton title="Ok" onPress={handleNextPage} width={200} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SucessSelectMobile;

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
    maxWidth: 500,
    width: '100%',
  },
  phoneInput: {
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginVertical: 20,
  },
  input: { alignSelf: 'center' },
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 5,
  },
});
