import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, View, Switch } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';

import Alert from 'Alert';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import Loader from '../../../Components/Loader';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import { setProfile } from '../../../Redux';

const RecordingAddOn = ({ navigation, route }) => {
  const [loading, setLoading] = React.useState(false);
  const add_ons = useSelector((state) => state.add_ons);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [value, setValue] = useState(tenant?.record);
  const Toast = useToast();
  const dispatch = useDispatch();

  const budget = useMemo(() => {
    const item = add_ons.biling_details.find((v) => v.addon === 'Record');
    if (item) {
      return Number(item.amount).toFixed(2);
    } else {
      return '5.00';
    }
  }, [add_ons]);

  const handleToggle = async (val) => {
    if(route.params.mutable){
    if (val && !tenant?.voice) {
      Alert.alert('Error', 'Voice must be enabled to enable Recording.');
      setValue(false);
      return;
    }

    if (!add_ons.hasPaymentMethod && route.params?.item === null) {
      Alert.alert(
        'Error',
        'You must set up a payment method in the payment manager to enable add-ons'
      );
      setValue(false);
      return;
    }
    try {
      setLoading(true);
      const body = {
        action: 'update_record',
        record: val,
      };
      setValue(val);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 3000,
          status: 'success',
          title: 'Recording add-on updated.',
        });
        dispatch(
          setProfile({
            tenant: {
              ...tenant,
              record: val,
            },
          })
        );

        // TODO update tenant record
        _getBillingAmount();
      } else {
        Toast.show({
          duration: 3000,
          status: 'error',
          title: result.data.errorMessage,
        });
        setValue(!val);
      }
    } catch (err) {
      console.log(err);
      Alert.alert('Error', err.message);
      setValue(!val);
    } finally {
      setLoading(false);
    }
  } else {
    Alert.alert("Error", 'Your group has disabled your ability to modify this setting.');
  }
  };

  const _getBillingAmount = async () => {
    try {
      setLoading(true);
      const body = {
        action: 'get_billing_amount',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      await RequestMaker(Config);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      
      {loading && <Loader />}
      <View
        style={{
          flex: 1,
          width: '100%',
          padding: 15,
          maxWidth: 767,
          alignSelf: 'center',
        }}>
        <View>
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 16,
            }}>
            Note:Voice must be enabled for this feature to work.
          </Text>
        </View>
        <View style={{ width: '80%', alignSelf: 'center', marginVertical: 10 }}>
          <Text
            style={{
              fontSize: 14,
              lineHeight: 20,
              fontFamily: AppFonts.Roboto_Regular,
              textAlign: 'center',
            }}>
            When this is enabled, all your voice phone calls to/from the
            MyRepChat number will be recorded and available under messages.
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: 10,
            alignSelf: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: AppFonts.Roboto_Regular,
              lineHeight: 20,
              marginRight: 15,
            }}>
            Voice Recording
          </Text>
          <Switch
            value={value}
            onValueChange={() => handleToggle(!value)}
            trackColor={{ false: 'red', true: 'green' }}
          />
          <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginLeft: 5 }}>
            {value ? 'ON' : 'OFF'}
          </Text>
        </View>
        <Text
          style={{
            alignSelf: 'center',
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          {`($${budget}/Month)`}
        </Text>
      </View>
    </SafeAreaView>
  );
};
export default RecordingAddOn;
