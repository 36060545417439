import React from 'react';
import { SafeAreaView, View, TouchableOpacity, Image, ScrollView, Text, Switch } from 'react-native';
import CustomInput from '../../Components/CustomInput';
import Header from '../../Components/Header';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { launchImageLibrary } from 'react-native-image-picker';
import { useSelector } from 'react-redux';
import { createFullChatChannel, getChannelMembers, getChatUsers, saveFile, updateFullChatChannel } from '../../Api/ten4';
import CustomButton from '../../Components/Button';
import AppColor from '../../Constants/Color';
import { MembersList } from './MembersList';
import * as DocumentPicker from 'expo-document-picker';
import Loader from '../../Components/Loader';

const EditPublicChannel = ({navigation, route}) => {
    
    const tenant = useSelector((state) => state.User.profile)?.tenant;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [avatarPath, setAvatarPath] = React.useState(route.params.avatar);
    const [users, setUsers] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [channelName, setChannelName] = React.useState('');
    const [isActive, setIsActive] = React.useState(route.params.active);
    const [loading, setLoading] = React.useState(false);
    const [groupName, setGroupName] = React.useState(route.params.title);

    React.useEffect(()=>{
        const fetchChatUsers = navigation.addListener('focus', () => {
            handleGetUsers()
        })

        return fetchChatUsers;
    },[navigation])

    const handleGetUsers = async () => {
        const [allUsers, usersInGroup] = await Promise.all([getChatUsers({affiliate: affiliate.id}), getChannelMembers({affiliate: affiliate.id, channel: route.params.id}) ])
        if(allUsers.data.success && usersInGroup.data.success){
            setUsers(allUsers.data.users);
            const membersArray = [];
            usersInGroup.data.members.forEach(e => {
                membersArray.push(e.userId);
            })
            setMembers(membersArray);
        }
    }

    const addChannelAvatar = async () => {
        const res = await DocumentPicker.getDocumentAsync({
            type: 'image/*',
        });
        if(res.type === 'success'){
            if (res.uri && res.file.size < 500000) {
                uploadFile(res.uri);
              } else {
                  alert('Please try a smaller image.')
              }
        }

    }

    const uploadFile = (uri) => {
        setLoading(true)
        saveFile({base64Data: uri, tenant: tenant.id})
        .then(result => {
            if(result?.data?.success){
                setAvatarPath(result.data.fileUrl);
            }
            setLoading(false)
        })
    }

    const handleAddMember = id => {
        if(!members.includes(id)){
            setMembers(members => [...members, id])
        } else {
            const copy = [...members];
            const index = copy.indexOf(id);
            if(index > -1){
                copy.splice(index, 1);
                setMembers(copy);
            }
        }
    }
    
    const handleSave = () => {
        try{
        const params = {
            active: isActive,
            affiliate: affiliate.id,
            avatar: avatarPath,
            members: members,
            name: groupName,
            id: route.params.id
        }
        
        updateFullChatChannel({params:params})
        .then( result => {
            if(result.data.success){
                navigation.goBack();
            }
        })
        } catch(err){
            console.log(err);
        }

    }

    const handleDelete = () => {
        try{
        const params = {
            active: false,
            affiliate: affiliate.id,
            avatar: avatarPath,
            members: [],
            name: route.params.title,
            id: route.params.id
        }
        
        updateFullChatChannel({params:params})
        .then( result => {
            if(result.data.success){
                navigation.goBack();
            }
        })
        } catch(err){
            console.log(err);
        }

    }
    

    return(
        <SafeAreaView style={{flex:1}}>
            <Header
            title={`Edit #${route.params.title}`}
            onPress={() => navigation.goBack()}
            back={true}
            />
            <ScrollView contentContainerStyle={{justifyContent:'space-evenly'}}>

            {
            !avatarPath || avatarPath === "null" ? 
            <MaterialIcons onPress={addChannelAvatar} name='add-a-photo' color={'rgba(0,0,0,.5)'} size={42} style={{alignSelf:'center',margin:20}}/>
            :
            <TouchableOpacity onPress={addChannelAvatar}>
                <img alt="Channel Avatar." src={avatarPath} style={{objectFit:'contain', width:75,height:75,borderRadius:100,alignSelf:'center',margin:20}}/>
            </TouchableOpacity>
            }
            <View style={{flexDirection:'row',alignItems:'center',width:'80%',alignSelf:'center'}}>
            <Text style={{marginRight:10,fontSize:16}}>Active</Text>    

                <Switch 
                value={isActive}
                onValueChange={e => setIsActive(e)}
                />
            </View>
            <CustomInput title='Group Name' titleStyle={{marginLeft:'10%',marginTop:20}} containerStyle={{width:'80%'}} value={groupName} onChangeText={e => setGroupName(e)} />

            <MembersList onPress = {id => handleAddMember(id)} allUsers={users} selectedUsers={members} />
            <View style={{width:'80%',alignSelf:'center'}}>
            <Text style={{fontSize:18,marginTop:20,color:AppColor.BLUE100}}>Incoming WebHook</Text>
            <Text style={{marginTop:10}}>Incoming Webhooks are a simple way to post messages from external sources into this channel. They make use of normal HTTP requests with a JSON payloadm which includes the message and a few other optional details described later.</Text>
            <Text selectable={true} style={{marginTop:10}}>{route.params.webhook}</Text>
            </View>
            <CustomButton title='Save' onPress={handleSave} style={{marginTop:20}} />
            <CustomButton title='Delete'  onPress={handleDelete} style={{marginTop:20}} />

            </ScrollView>
            {
                loading && <Loader/>
            }
        </SafeAreaView>
    )
}


export default EditPublicChannel;