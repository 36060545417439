import { useEffect } from 'react';

const useGoogleTagManager = (gtmId) => {
  useEffect(() => {
    if (!gtmId) {
      return;
    }

    // Inject the GTM script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
    });

    // Inject the GTM noscript as the first element in the body.
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);

    document.body.insertBefore(noscript, document.body.firstChild);

    return () => {
      // Clean up script and noscript tags when the component unmounts or the gtmId changes
      document.head.removeChild(script);
      document.body.removeChild(noscript);
    };
  }, [gtmId]);
};

export default useGoogleTagManager;
