import React from "react";
import { useSelector } from "react-redux";
import AppColor from "../../Constants/Color";
import { Text, TouchableOpacity, View } from "react-native";

const UserAvatarPicker = props =>  {
    const user = useSelector((state) => state.User.profile)?.user;

    let initials = '';

    const getInitials = name => {
        let result = ''
        const split = name.split(' ');
        split.forEach(letter => {
            result += letter.charAt(0).toUpperCase()
        })
        return result;
    }

    if(!user.avatar){
        initials = getInitials(user.name);
    }

    const element = user.avatar ? 
        <img
        onClick={props.showModal}
        alt="User Avatar."
        src={user.avatar}
        style={{borderRadius:100,height:30,width:30,cursor:'pointer', objectFit: 'cover'}}
        />
    :
    <TouchableOpacity onPress={props.showModal} style={{cursor:'pointer',backgroundColor:AppColor.PrimaryColor,borderRadius:100,height:30,width:30,justifyContent:'center',alignItems:'center'}}>
        <Text style={{color:'white',letterSpacing:-1}}>{initials}</Text>
    </TouchableOpacity>



    return element;
}

export default UserAvatarPicker;