import React from 'react';
import {
  Day,
  GiftedChat,
  LoadEarlier,
  Composer,
} from 'react-native-gifted-chat';

import {
  StyleSheet,
  Text,
  View,
  Linking,
  Platform,
} from 'react-native';

import { useToast } from 'native-base';
import Alert from 'Alert';
import Modal from 'modal-react-native-web';
import { SafeAreaView } from 'react-native-safe-area-context';
const moment = require('moment');
import { useSelector } from 'react-redux';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';

import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import Loader from '../../Components/Loader';
import AllCollections from './AlltachmentsModalContainer';
import { RenderPhoneModal } from './RenderPhoneModal';
import { RenderBubble } from './RenderBubble';
import { initiateCall } from '../../Api/chat';
import MessageHeader from './MessageHeader';
import MessageInput from './MessageInput';
import { MultiPreviews } from './MultiPreviews';
import getData from '../../AsyncUtils/GetData';
import storeData from '../../AsyncUtils/StoreData';
import EmojisModal from '../../Components/Modals/EmojisModal';
import RequestMaker from '../../Middleware/ApiCaller';
import Entypo from 'react-native-vector-icons/Entypo';
import { RemovePhoneModal } from '../Contacts/modals';
import useLogEvent from '../../Middleware/useLogEvent';
import ConsentIndicator from './ConsentIndicator';

const Chats = ({ navigation, memberData, messages, loadPreviewsAndCurrentChatMessages, loadContactMessages, dl, typedmessage, setTypedmessage,setSelectedChat, handleLoadMoreMessages, isPinned, setIsPinned  }) => {
  const tenant = useSelector((state) => state.User?.profile?.tenant);
  const group = useSelector((state) => state.User)?.profile?.group;
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;
  const Toast = useToast();
  const [isVisibleSubmit, setIsVisibleSubmit] = React.useState(false);
  const [consent, setConsent] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const [multiVisible, setMultiVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [selectedMultiData, setSelectedMultiData] = React.useState({
    type: '',
    url: '',
  });
  const chatRef = React.useRef();
  const textInputRef = React.useRef();
  const [emojiModalVisible, setEmojiModalVisible] = React.useState(false);
  const [accountDefaultedModal, setAccountDefaultedModal] = React.useState(false);
  const logEvent = useLogEvent();
  const listViewRef = React.useRef(null);
  const prevMessageCount = React.useRef(0);
  const isLoadingEarlier = React.useRef(false);

  const [consentDialog, setConsentDialog] = React.useState(null);

  const isGroupConsented = !group?.consentEnabled ? true : memberData?.isGroup ?  memberData?.contacts.every(contact => contact.consented) : null;
  const isGroupStopped = memberData?.contacts?.some(e => e.stopped);

  React.useEffect(() => {
    checkIfEnterToSendEnabled();

    if (memberData) loadChatDetails();

    if(dl) {
      setConsent(true);
      setTypedmessage('');
    }

    if(textInputRef.current){
      textInputRef.current.style.height = 0;
    }

  }, [memberData])

  const loadChatDetails = () => {
    // setTypedmessage('');
    setConsent(true);

    const hasInboundMessage = messages.findIndex(e => e.direction === 'inbound') > -1
    const hasReceievedAcceptMessage = messages.some(communication =>
      communication?.messages?.findIndex(e => e?.sms?.toLowerCase() === "accept") > -1
    );

    if (
      !consent &&
      hasInboundMessage &&
      group?.consentInboundAccepts
    ) {
      setTypedmessage('');
      setConsent(true);
    }

    if (
      !consent &&
      hasReceievedAcceptMessage &&
      group?.consentResponseRequired
    ) {
      setConsent(true);
      setTypedmessage('');
    }
    
    if(memberData?.isGroup && !isGroupConsented && affiliate?.consentEnabled){
      setConsent(false);
    }

    const hasNonConsentedNumber = 
    memberData?.isGroup ? 
    !isGroupConsented
    :
    memberData.phoneNumbers.some(phone => !phone.consented);

    if (hasNonConsentedNumber && group?.consentEnabled) {
      get_consent();
    }
  }

  const processMessages = (communications) => {

    const filteredMessages = communications.filter((msg, i, arr) => {
      const prevMsg = arr[i - 1];
      const isUniqueSmsSid = !(prevMsg?.messages?.some(e => e?.sms_sid === msg?.messages[0]?.sms_sid))
        || !msg?.messages[0]?.sms_sid
        || msg?.messages[0]?.sms_sid === "none";
      const isSmsWithMessages = msg.type === 'sms' && msg.messages.length !== 0;
      const isNotSms = msg.type !== 'sms';

      return isUniqueSmsSid && (isSmsWithMessages || isNotSms);
    });

    const newMessages = filteredMessages.map(x => ({
      _id: x.id,
      createdAt: x.date,
      type: x.type,
      direction: x.direction,
      user: {
        _id: x.direction === 'outbound' ? 1 : 2,
      },
      sms_status: x.sms_status,
      call_status: x.call_status,
      mms: x.mms,
      sms: x.messages,  // This line ensures the sms property is retained
      records: x.recordings,
      transcripts: x.transcripts,
      senderName: x.senders.length === 0 ? '' : x.senders[0].name,
      groupChatIdentifier: x?.groupChatIdentifier ?? null,
    }));

    setTimeout(() => {

      if(isLoadingEarlier.current){
        let index = newMessages.length - prevMessageCount.current - 1
        if(listViewRef.current && newMessages.length && index > - 1) listViewRef.current.scrollToIndex({index, animated: false});
        isLoadingEarlier.current = false
      } else {
        if(listViewRef.current) listViewRef.current.scrollToEnd({ animated: false })
      }
      prevMessageCount.current = newMessages.length;

    }, isLoadingEarlier.current ? 100 : 200);


    return newMessages;

  };

  const processedMessages = (Array.isArray(messages) && messages.length) ? processMessages(messages) : [];

  const handleSendMessageError = (result, typedMessageCopy) => {
    const { failures, errorMessage } = result.data;
    if (failures && failures.length > 0) {
      Toast.show({
        duration: 3000,
        type: 'danger',
        description: failures.join(','),
      });
      if (failures.includes('Message rejected by your home office')) {
        loadContactMessages();
      }
      if (failures.includes("Message exceeds 1600 characters.")) {
        setTypedmessage(typedMessageCopy);
      }
    } else if (errorMessage) {
      Toast.show({
        duration: 3000,
        type: 'danger',
        description: errorMessage,
      });
    } else {
      Toast.show({
        duration: 3000,
        type: 'danger',
        description: 'We were unable to send your message',
      });
    }
  };

  const sendMessage = async (text) => {
    if(tenant.status === 5){
      setAccountDefaultedModal(true);
      return;
    }
    
    setLoading(true);
    const body = {
      action: 'send_text_message',
      type: 'sms',
      text,
    };

    if (memberData) {
      body['members'] = [memberData.id];
    }
    else if (dl) {
      body['distributionLists'] = [{ text: dl.text }]
    }

    try {
      const result = await RequestMaker(body);
      if (result.status === 400) {
        Keyboard.dismiss();
        if(tenant.status === 3){
          Alert.alert('Error', 'You are not able to send messages while your account is in review state.');
        } else if(tenant.status === 5){
          setAccountDefaultedModal(true);
        }
      } else if (result.data.success) {

        if(textInputRef.current){
          textInputRef.current.style.height = 0;
        }
        
        logEvent('message_sent')
        loadPreviewsAndCurrentChatMessages();
        if(isVisibleSubmit) setTypedmessage('');
        
      } else {
        handleSendMessageError(result, text);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onSend =
    (messages = []) => {
      sendMessage(messages.text);
    };

  const renderDay = (props) => {
    return <Day {...props} textStyle={CommonStyle.dayText} />;
  };

  const checkIfEnterToSendEnabled = async () => {
    const result = await getData('@entertosend');
    setIsVisibleSubmit(result);
  }

  const handleCheckEnterToSend = async () => {
    await storeData('@entertosend', !isVisibleSubmit);
    setIsVisibleSubmit(!isVisibleSubmit);
  }

  const handleChangeText = (message) => { 
    if ( dl ||  (consent && !memberData.stopped)) { 
        setTypedmessage(message) 
    }
  }

  const renderInputToolbar = (props) => {
    return (
      <MessageInput
        dl={dl}
        checked={isVisibleSubmit}
        onCheck={handleCheckEnterToSend}
        consent={consent}
        toolBarProps={{ ...props }}
        onPressSend={() => {
          if (consent) {
            const mes = {
              _id: Date.now() + Math.random(0, 2000),
              text: typedmessage,
              sms_status: 'sending',
              createdAt: new Date(),
              user: {
                _id: 1,
              },
              type: '',
              transactionAmount: '402',
              transactionstatus: 'Received',
              records: [],
              transcripts: [],
            };
            typedmessage.trim() !== ''
              ? onSend(mes)
              : setTypedmessage('');
            setTypedmessage('');
          } else {
            handleConsentAction();
          }
        }}
        onPressAttachment={() => setVisible(!visible)}
        onPressEmoji={() => setEmojiModalVisible(!emojiModalVisible)}
      />
    );
  };

  const renderComposer = (props) => {
    return (

      <Composer
        {...props}
        red
        keyboardAppearance='default'
        textInputProps={{
          ref: textInputRef,
          onChange:(e) => {
            e.nativeEvent.target.style.height = 0;
            e.nativeEvent.target.style.height = `${e.nativeEvent.target.scrollHeight}px`;
          },
          placeholder: 'Type here.',
          value: typedmessage,
          onKeyPress: (event) => {
            if (Platform.OS === "web" && event.keyCode === 13 && isVisibleSubmit && !event.shiftKey) {

              if (typedmessage && typedmessage.trim() != '') {
                const mes = {
                  _id: Date.now() + Math.random(0, 2000),
                  text: typedmessage.trim(),
                  sms_status: 'sending',
                  createdAt: new Date(),
                  user: {
                    _id: 1,
                  },
                  type: '',
                  transactionAmount: '402',
                  transactionstatus: 'Recieved',
                  records: [],
                  transcripts: [],
                };
                onSend(mes);
                setTypedmessage('');
              }
            }
          },
          onChangeText: (message) => { if ( dl ||  (consent && !memberData.stopped && !memberData?.isGroup) || (memberData?.isGroup && isGroupConsented && !isGroupStopped)) { setTypedmessage(message) } },
        }}
        textInputStyle={{
          textAlignVertical: 'center',
          marginTop: 0,
          paddingTop: 0,
          marginHorizontal: 0,
          minHeight: '7.5rem',
          height: '7.5rem',
          maxHeight: '25rem',
          lineHeight: '1.5em',
          paddingHorizontal: 10,
          fontSize: 18,
          fontFamily: AppFonts.Roboto_Regular,
          backgroundColor: '#F2F4FB',
        }}
      />
    );
  };

  const renderLoadEarlier = (props) => {
    return <LoadEarlier {...props} textStyle={CommonStyle.loadEarlierText} />;
  };

  const handleCall = async (phoneNumber) => {
    initiateCall({ toNumber: phoneNumber })
      .then(result => {
        if (result.data.success) {
          logEvent('call_placed');
          Toast.show({
            duration: 2000,
            title: 'Call initiated, Use your phone to compelete the call',
            status: 'success',
          });
          setTimeout(() => {
            Linking.openURL(`tel:${phoneNumber}`);
          }, 2000);
        } else if (result.data?.errorMessage) {
          Toast.show({
            duration: 2000,
            title: result.data.errorMessage,
            status: 'error',
          });
        } else {
          Toast.show({
            duration: 2000,
            title: result.data.failures.join(','),
            status: 'error',
          });
        }
        setTimeout(() => {
          loadContactMessages();
        }, 2000);
      })
  };

  const get_consent = async () => {
    if (!memberData?.id) return;

    try {
      const body = {
        action: 'get_consent',
        member: memberData.id,
      };

      const result = await RequestMaker(body);

      // if (!result.data.success) {
      //   Alert.alert('Error', result?.data?.errorMessage);
      //   return;
      // }

      const { state, consent, consentMsg } = result.data;

      switch (state) {
        case 'accepted':
          return true;

        case 'pending':
          if (group?.consentResponseRequired) {
            setConsentDialog(consent)
            setConsent(false);
            if (consentMsg) setTypedmessage(consentMsg);
          }
          break;

        default:
          setTypedmessage(consentMsg);
          setConsentDialog(consent);
          setConsent(false);
          break;
      }

      if(memberData?.isGroup && !isGroupConsented){
        setTypedmessage(result.data.members[0].consentMsg);
        setConsent(false);
      }

      return false;

    } catch (err) {
      console.log(err);
    }
  };

  const handleConsentSend = async (id) => {
    try {
      let body = {
        action: 'send_consent',
        id: id
      };

      if(memberData?.isGroup){
        const ids = memberData.contacts.map(contact => contact.id);
        body['id'] = ids;
      }

      const result = await RequestMaker(body);
      if (result.data.success) {
        if (!group?.consentResponseRequired) {
          setConsent(true);
          setTypedmessage('');
        }
      } else if (result.data.failures) {
        const failures = result.data.failures.join(',');
        Toast.show({ status: 'error', title: failures });
      }
      loadPreviewsAndCurrentChatMessages();
    } catch (err) {
      console.log(err);
    }
  };

  const handleConsentAction = () => {
    if (affiliate?.blockoutboundconsent && affiliate?.blockoutboundconsentmessage) {
      Alert.alert('Consent Blocked', affiliate.blockoutboundconsentmessage);
      return;
    }

    const showConsentAlert = () => {
      if (group?.consentResponseRequired) {
        Alert.alert(
          'Consent Sent',
          'Consent request sent. You will not be able to text this contact until the keyword accept is sent back.',
          [{ text: 'OK' }],
        );
      }
    };

    if (memberData?.id) {
      handleConsentSend(memberData.id, false);
      showConsentAlert();
    } else {
      Alert.alert('Error', 'No contact selected to send consent');
    }

  };

  const addEmoji = e => {
    setTypedmessage(typedmessage + e)
    setEmojiModalVisible(false);
    if (chatRef) {
      chatRef.current.focusTextInput();
    }
  }

  const handleLoadEarlier = () => {
    isLoadingEarlier.current = true;
    handleLoadMoreMessages();
  }

  const pinThread = async () => {
    try{
      const body = {
        action: 'update_pinned_status',
        id: memberData.id,
        pinned: !isPinned
      }

      const result = await RequestMaker(body);
      if(result.data.success){
        await loadPreviewsAndCurrentChatMessages();
        setIsPinned(!isPinned);

      } else if(result.data.errorMessage){
        Toast.show({status: 'error', title: result.data.errorMessage});
      }
    } catch(err){
      Toast.show({status: 'error', title: err.message});
    }
  }

  
  return (
    <>
      <SafeAreaView
        style={{ flex: 1, backgroundColor: 'white' }}
        edges={['bottom', 'top']}>
        <View style={{ flex: 1 }}>

          <MessageHeader
            pinned={isPinned}
            onPin={pinThread}
            setSelectedChat={setSelectedChat}
            dl={dl}
            member={memberData}
            onPress={() => {
              if (dl) {
                navigation.navigate('DistributionDetail', { dist: dl })
              } else {
              navigation.navigate('ContactEdit', { item: memberData });
              }
            }}
            onPhonePress={() => {
              setPhoneModalVisible(true);
            }
            }

          />


          <GiftedChat
          
            maxInputLength={1600}
            ref={chatRef}
            messageContainerRef={listViewRef}
            inverted={false}
            loadEarlier={dl ? false : (messages.length % 25 === 0 && messages.length > 0)}
            renderChatFooter={() => dl ? null : <ConsentIndicator 
              consentSent={messages.some(e => e.direction === 'outbound')} 
              stopped={!memberData?.isGroup ? memberData.stopped : isGroupStopped} 
              consented={!memberData?.isGroup ? memberData.consented : isGroupConsented} 
              consent={!memberData?.isGroup ? consentDialog : isGroupConsented} 
              isGroup={memberData?.isGroup}
              />}
            onLoadEarlier={handleLoadEarlier}
            messages={processedMessages}
            onSend={(messages) => onSend(messages)}
            user={{
              _id: 1,
            }}
            renderDay={renderDay}
            listViewProps={{     
              ref: listViewRef,  
              initialNumToRender: 10,
              onScrollToIndexFailed: info => {
                const wait = new Promise(resolve => setTimeout(resolve, 200));
                wait.then(() => {
                  listViewRef.current?.scrollToIndex({ index: info.index, animated: false });
                });
              }
            }}
            scrollToBottomComponent={() => <Entypo size={32} name='chevron-down' />}
            renderChatEmpty={() => (
              <View
                style={{
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}>
                <Text
                  style={{
                    color: AppColor.Dark,
                    transform: [{ rotateX: '180deg' }],
                  }}>
                  {memberData ? 'Empty messages' : 'Send a message to this Distribution List'}
                </Text>
              </View>
            )}
            
            textInputProps={{
              accessible: true,
              accessibilityRole: 'text',
              autoCapitalize: 'none',
              autoCorrect: true,
              spellCheck: true,
              blurOnSubmit: isVisibleSubmit && Platform.OS === 'web',
              autoFocus: true,
            }}
            
            renderLoading={renderLoadEarlier}
            renderInputToolbar={renderInputToolbar}
            renderComposer={renderComposer}
            renderTime={renderTime}
            renderLoadEarlier={renderLoadEarlier}
            renderBubble={(props) => (
              <RenderBubble
                {...props}
                openDocument={openDocument}
                setMultiVisible={setMultiVisible}
                setSelectedMultiData={setSelectedMultiData}
                setLoading={setLoading}
              />
            )}
            showUserAvatar={false}
            isKeyboardInternallyHandled={true}
            isCustomViewBottom
          />
        </View>
      </SafeAreaView>

      {/* attachment types*/}
      <Modal
        visible={visible}
        // animationIn={"slideInUp"}
        // animationOut={"slideOutUp"}

        transparent={true}
        style={{ justifyContent: 'flex-end' }}>
        <AllCollections
          typedmessage={typedmessage}
          dl={dl}
          navigation={navigation}
          setVisible={setVisible}
          member={memberData}
          loadMessage={loadContactMessages}
          setLoading={setLoading}
          memberData={memberData}
        />
      </Modal>
      {/* multimedia preview */}
      <Modal
        visible={multiVisible}
        onBackdropPress={() => {
          setMultiVisible(false);
          setSelectedMultiData({
            type: '',
            url: '',
          });
        }}
        transparent={true}
        backdropOpacity={0.7}
        style={{ justifyContent: 'center' }}>
        <MultiPreviews data={selectedMultiData} />
      </Modal>
      {memberData && <Modal
        visible={phoneModalVisible}
        onBackdropPress={() => {
          setPhoneModalVisible(false);
        }}
        transparent={true}
        backdropOpacity={0.7}
        useNativeDriver={true}
        style={{ justifyContent: 'center' }}>
        <RenderPhoneModal
          handleCall={handleCall}
          phoneNumbers={memberData.phoneNumbers}
          setPhoneModalVisible={setPhoneModalVisible}
        />
      </Modal>
      }

      {
        emojiModalVisible &&
        <EmojisModal
          visible={emojiModalVisible}
          setVisible={setEmojiModalVisible}
          onPressEmoji={addEmoji}
        />
      }

      {
        accountDefaultedModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={accountDefaultedModal} transparent={true}>
          <RemovePhoneModal
          title="Past Due"
          continueLabel={'Go to Billing'}
          message="You're currently unable to send messages as your account is in default. Please update your payment method to resume messaging."
          handleRemove={() => {
            setAccountDefaultedModal(false);
            navigation.navigate('BillingManagement');
          }}
            setRemoveModal={setAccountDefaultedModal}
          />
        </Modal>
      }


      {loading && <Loader />}
    </>
  );
};

export const openDocument = (url, type, setLoading) => {
  window.open(url, '_blank').focus();
};

const renderTime = (props) => {
  return (
    <View style={{ width: 140, marginHorizontal: 2 }}>
      <Text
        style={{
          textAlign: props.position == 'right' ? 'right' : 'right',
          fontSize: 12,
          fontFamily: AppFonts.Roboto_Regular,
          color: props.position == 'right' ? AppColor.SecondaryColor : 'black',
        }}>
        {props.currentMessage.sms_status}{' '}
        {moment(props.currentMessage.createdAt).format('h:mm a')}
      </Text>
    </View>
  );
};

export const CommonStyle = StyleSheet.create({
  AuthContainer: {
    flex: 1,
    backgroundColor: 'black',
  },
  headerTextStyle: {
    padding: 10,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    color: 'black',
    fontSize: 2,
    fontWeight: '500',
  },
  buttonGroupContainerStyle: {
    backgroundColor: 'black',
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    height: 5,
  },
  disableTextStyle: {
    color: 'black',
  },
  selectedTextStyle: {
    color: 'black',
  },
  seperatorStyle: {
    height: 1,
    width: '86%',
    backgroundColor: 'black',
    alignSelf: 'center',
  },

  dayText: {
    color: 'grey',
    fontSize: 13,
  },
  left: {
    backgroundColor: '#ECF6FF',
    borderRadius: 8,
    paddingVertical: 4,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
    maxWidth: wp(93),
  },
  right: {
    backgroundColor: 'rgb(247, 247, 247)',
    borderRadius: 8,
    padding: 10,
    marginBottom: 2,
    paddingHorizontal: 10,
    minWidth: 70,
    color: "black"
  },
  leftImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
  },

  rightImage: {
    backgroundColor: 'black',
    borderColor: 'black',
    borderWidth: 2.2,
    padding: 5,
  },

  bubbleLeftText: {
    color: 'black',
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },
  bubbleRightText: {
    color: 'black',
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 1,
  },

  inputContainer: {
    minHeight: 6,
    borderTopWidth: 2,
    borderColor: 'black',
    alignItems: 'center',
  },
  inputPrimary: {
    backgroundColor: 'black',
    marginVertical: 4,
    marginHorizontal: 16,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendContainer: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  send: {
    width: 20,
    height: 20,
  },
  composerText: {
    color: AppColor.SecondaryColor,
    fontSize: 14,
  },
  timeTextLeft: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'right',
  },
  timeTextRight: {
    color: AppColor.SecondaryColor,
    fontSize: 12,
  },
  loadEarlierText: {
    color: 'white',
    fontSize: 12,
  },
  modalitemsStyle: { alignItems: 'center', width: '33%' },
  modaltextStyle: {
    color: 'black',
    fontSize: 12,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
});

export default Chats;
