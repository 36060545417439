import React from "react";
import { StyleSheet, Switch, Text, TouchableOpacity, View } from "react-native";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";
import End_Points from "../../Constants/Api";
import { Menu } from "native-base";
import { Icon } from "react-native-elements";
import SimpleDropdown from "../../Components/SimpleDropdown";

const SignupOptions = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const [data, setData] = React.useState({
        newAccountState: affiliate?.newAccountState || 3,
        allowHostedSms: affiliate?.allowHostedSms,
    })

    const accountStates = [
        {
            value: 1,
            label: 'Active - No limitations, everything works.'
        },
        {
            value: 2,
            label: 'Inactive - Not active.'
        },
        {
            value: 3,
            label: 'Review - Limited use until approved by you.'
        },
        {
            value: 4,
            label: 'Trial - 14-day trial period where all features work.'
        }
    ]
    
    const updateAffiliate = useUpdateAffiliate();
    
    return(
        <View style={{gap:10,flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Account Self Signup</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>You can have people in your organization sign up for new accounts on their own. To do this, simply have 
                them use the link provided here or give them the code to use to sign up using the mobile app. 
                The administrator for the group will receive an email for each new account sign up. 
                You can also determine what state you would like new accounts to be created as:</Text>
            {/* <CustomInput value={data.name} onChangeText={e => setData({...data, name: e})} title={'Name'} />  */}
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>Signup Link</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{`${End_Points.Base_url}?a=${affiliate.uuid}`}</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>Signup Code</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{`${affiliate.code}`}</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>New Account State</Text>
            <SimpleDropdown
                value={data.newAccountState} 
                data={accountStates}
                onChange={e => setData({...data, newAccountState: e})}
            />
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>Allow Accounts to use Landline</Text>
            <Switch
            onValueChange={e => {setData({...data, allowHostedSms: e})}}
            value={data.allowHostedSms}
            style={{marginBottom:20}}
            />


            <CustomButton
            onPress={() => updateAffiliate(data)}
            width={'30%'} 
            title={'Save'} 
            style={{marginTop:'auto',alignSelf:'flex-end',marginBottom:10,marginRight:10}}
            />

        </View>
    )
}

export default SignupOptions;