import React from "react";
import { FlatList, Modal, Text, TouchableOpacity, View } from "react-native";
import { Content, SideBar, Wrapper } from "../../Components/Layout";
import InfoBar from "../../Components/Layout/InfoBar";
import AppFonts from "../../Constants/Fonts";
import AppColor from "../../Constants/Color";
import Search from "../../Components/Search";
import getQuery from "../getQuery";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/index.web";
import { Icon } from "react-native-elements";
import RequestMaker from "../../Middleware/ApiCaller";
import { RemovePhoneModal } from "../../Screens/Contacts/modals";
import { Bubbles } from "../../Screens/Authentication/Login/Bubbles";
import AddDisclosure from "./AddDisclosure";

const Disclosures = ({route, navigation}) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [search, setSearch] = React.useState('');
    const [removeModal, setRemoveModal] = React.useState(false);
    const [toDelete, setToDelete] = React.useState(null);
    const [disclosure, setDisclosure] = React.useState(null);

    const {data, isLoading, isError, refetch} = getQuery({
        action: 'get_disclosures',
        affiliateId: affiliate.id
    })

    if(isLoading) return <Loader backgroundColor={'transparent'}/>
    if(!data) return null;


    const renderDisclosures = ({item}) => {

        const CONDITION_COMPONENT = value =>  {
            switch(value){
                case 'none': {
                    return(
                     <View style={{flexDirection:'row',alignItems:'center'}}>
                        <Icon name="close" type='ionicon'/>
                        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>No Condition</Text>

                    </View>
                    )
                    }
                case 'logins': {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                            <Icon name="users" size={22} type='feather'/>
                            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>Login Count</Text>
                        </View>
                    )
                }
                case 'tenant': {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                            <Icon name="user" size={22} type='feather'/>
                            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>Account</Text>
                        </View>
                    )
                }
                case 'state': {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                            <Icon name="map-pin" size={22} type='feather'/>
                            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>Location</Text>
                        </View>
                    )
                }
                case 'consent': {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                            <Icon name="check" size={22} type='feather'/>
                            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>Consent</Text>
                        </View>
                    
                    )
                }
                case 'vcard': {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                            <Icon name="user" size={22} type='feather'/>
                            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>vCard</Text>
                        </View>
                    )
                    
                }
            }
        }

        const capitalizedFrequencyType = item.frequencyType.charAt(0).toUpperCase() + item.frequencyType.slice(1);

        const FREQUENCY_COMPONENT = value => {
            switch(value){
                case 'time': {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                        <Icon name="clock" size={22} type='feather'/>
                        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>Every {item.frequencyValue} {item.frequencyUnit}(s)<Text style={{fontFamily:AppFonts.Roboto_Regular}}> </Text> </Text>
                        </View>
                    )
                }
                default: {
                    return(
                        <View style={{flexDirection:'row',alignItems:'center'}}>
                        <Icon name="refresh" type='ionicon'/>
                        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginLeft:5}}>{capitalizedFrequencyType} <Text style={{fontFamily:AppFonts.Roboto_Regular}}> </Text></Text>
                        </View>
                    )
                }
            }
        }

        const handleReorderUp = async () => {
            const items = [...data.disclosures];

            const index = items.findIndex(disclosure => disclosure.id === item.id);

            if (index > 0) {
                let updatedItem = {...items[index]};
        
                updatedItem.previousId = index - 1 === 0 ? null : items[index - 2].id;
        
                const body =  {
                    ...updatedItem,
                    affiliateId: affiliate.id,
                    action: "update_disclosure"
                };
                const result = await RequestMaker(body);
                if(result.data.success){
                    refetch();
                }
            } 
        }

        const handleReorderDown = async () => {
            const items = [...data.disclosures];
        
            const index = items.findIndex(disclosure => disclosure.id === item.id);
        
            if (index >= 0 && index < items.length - 1) {
                let updatedItem = {...items[index]};
        
                updatedItem.previousId = items[index + 1].id;
        
                const body = {
                    ...updatedItem,
                    affiliateId: affiliate.id,
                    action: "update_disclosure"
                };
        
                const result = await RequestMaker(body);
                if (result.data && result.data.success) {
                    refetch();
                }
            }
        }

        const handleDelete = () => {
            setRemoveModal(true);
            setToDelete(item);
        }

        const selectedStyle = {
            backgroundColor: disclosure?.id === item.id ?  '#ECF6FF' : 'transparent',
            borderColor: disclosure?.id === item.id ? '#116AB4' : 'transparent',
            borderTopWidth: disclosure?.id === item.id ? 1 : 0,
            
            borderBottomWidth: disclosure?.id === item.id ? 1 : 0,
        }

        return(
            <TouchableOpacity onPress={() => setDisclosure(item)} style={[{flexDirection:'row',padding:10}, selectedStyle]}>
                <Icon type="antdesign" size={26} name={'filetext1'} />
                <View style={{marginLeft:5,gap:5}}>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular,marginLeft:5}}>{item.name}</Text>
                    <View style={{flexDirection:'row'}}>
                    {FREQUENCY_COMPONENT(item.frequencyType)}
                    {CONDITION_COMPONENT(item.conditionType)}
                    </View>
                </View>
                <View style={{marginLeft:'auto',gap:5,flexDirection:'row'}}>
                        <Icon onPress={handleReorderUp} name="arrow-up" type='ionicon'/>
                        <Icon onPress={handleReorderDown} name="arrow-down" type='ionicon'/>
                        <Icon onPress={handleDelete} type="feather" size={26} name={'trash'} color={'red'} />
                </View>
            </TouchableOpacity>
        )
    }

    const handleDelete = async () => {
        const body = {
            action: 'delete_disclosure',
            affiliateId: affiliate.id,
            id: toDelete.id
        }

        const result = await RequestMaker(body);
        if(result.data.success) {
            setDisclosure(null);
            refetch()
        }
   }

    /* {
    "id": 2,
    "affiliateId": 9,
    "previousId": null,
    "active": true,
    "name": "mm",
    "text": "test test test test",
    "frequencyType": "always",
    "frequencyStart": "immediately",
    "frequencyValue": 1,
    "frequencyUnit": "day",
    "conditionType": "none",
    "conditionValue": "",
    "append": true
}
*/

    return(
        <Wrapper>
            <SideBar>
            {route.params?.reseller &&
                    <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,.25)' }}>
                        <Icon style={{ padding: 10 }} onPress={() => navigation.pop()} name='chevron-left' type={'ionicons'} size={24} />
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{affiliate.name}</Text>
                    </View>
                }

                <Search 
                onChangeText={ val => setSearch(val)}
                actionButton 
                onPressActionButton={() => {
                    setDisclosure({
                    previousId: null,
                    active: true,
                    name: "",
                    text: "",
                    frequencyType: "always",
                    frequencyStart: "immediately",
                    frequencyValue: 1,
                    frequencyUnit: "day",
                    conditionType: "none",
                    conditionValue: "",
                    append: true
                })}
            
            }
                actionButtonTitle={'Disclosure'} 
                />
                <FlatList
                    data={data.disclosures.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))}
                    renderItem={renderDisclosures}
                />
            </SideBar>
            <Content>
                <Content.Row style={{height:'100%'}}>
                    <View style={{flex:2,height:'100%'}}>
                { disclosure === null ?
                    <Bubbles style={{width:'140%'}}>
                        <Text style={{color:'#116AB4',fontSize:32,width:'70%'}}>
                            Select a disclosure to edit or create a new one.
                        </Text>
                    </Bubbles>
                    :
                    <AddDisclosure 
                    onSave={() => {
                        refetch();
                        setDisclosure(null);
                    }} 
                    item={disclosure}/>
                }                    

                </View>
                            
                    <InfoBar>
                        <Text style={{fontFamily:AppFonts.Roboto_Bold, color: AppColor.PrimaryColor,marginBottom:10}}>Disclosures Guide</Text>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                            A disclosure is a 160 character message that you can have sent automatically to the clients of the accounts within your group.

                            You can specify how often the message should be sent, and define multiple disclosures with rules that define which one to use under certain circumstances.

                            Only the first disclosure that meets the criteria of the rule will be used, make sure to order your disclosure to meet your requirements. Uou need to specify which one should be used first by setting its order higher using the arrow buttons.
                        </Text>

                    </InfoBar>
                </Content.Row>
            </Content>
        {
        removeModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={removeModal} transparent={true}>
          <RemovePhoneModal
          title="Delete Disclosure"
          message="Are you sure you'd like to permanently delete this disclosure?"
            handleRemove={() => {
              handleDelete(toDelete.id);
              setRemoveModal(false);
            }}
            setRemoveModal={setRemoveModal}
          />
        </Modal>
      }

        </Wrapper>
    )
}

export default Disclosures;