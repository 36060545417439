import React from "react";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";
import { Switch, Text } from "react-native";

const GroupEmailSettings = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    return(
    <>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            Enable/Disable various tenant Emails for this group
        </Text>

        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            Enable/Disable Welcome Emails
        </Text>
        <Switch style={{marginTop:10}} value={affiliate.welcomeEmailEnabled} onValueChange={() => updateAffiliate({welcomeEmailEnabled: !affiliate.welcomeEmailEnabled }) } />

        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            Enable/Disable Login Emails     
        </Text>
        <Switch style={{marginTop:10}} value={affiliate.loginEmailDisabled} onValueChange={() => updateAffiliate({loginEmailDisabled: !affiliate.loginEmailDisabled }) } />

        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            Enable/Disable Marketing Emails
        </Text>
        <Switch style={{marginTop:10}} value={affiliate.sysworkEmailDisabled} onValueChange={() => updateAffiliate({sysworkEmailDisabled: !affiliate.sysworkEmailDisabled}) } />
    </>
    )
}

export default GroupEmailSettings;