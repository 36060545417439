import React, { useState } from 'react';
import { View, Text, StyleSheet, Dimensions, Switch, ScrollView, Alert } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../../../../Components/Button';
import CustomInput from '../../../../Components/CustomInput';
import AppColor from '../../../../Constants/Color';
import AppFonts from '../../../../Constants/Fonts';
import { createIntegration, generateSSHKeys } from '../../../../Api/integrations';
import { useToast } from 'native-base';
import { loadIntegrationsThuunk } from '../../../../Redux/integrations';
const SFTP = ({groupId}) => {
  const [hostName, setHostName] = useState('');
  const [portName, setPortName] = useState('')
  const [path, setPath] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [SSHPublicKey, setSSHPublicKey] = useState('');
  const [usingSSH, setUsingSSH] = useState(false);
  const [usingPGP, setUsingPGP] = useState(false);
  const [enable, setEnable] = useState(false);
  const [usingPassword, setUsingPassword] = useState(false);
  const [SSHPrivateKey, setSSHPrivateKey] = useState('')
  const [PGPPublicKey, setPGPPublicKey] = useState('');
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const Toast = useToast();
  const generateKeys = () => {
    generateSSHKeys()
    .then(result => {
      if(result.data.success){
        setSSHPublicKey(result.data.sshPublicKey);
        setSSHPrivateKey(result.data.sshPrivateKey);
      } else {
        Alert.alert(result.data.errorMessage)
      }
    })
  }

  const handleSave = () => {

    const params = {
      active: enable,
      provider: 'filetransfer',
      path: path,
      host: hostName,
      port: portName,
      username: userName,
      password: password,
      pgpPublicKey: PGPPublicKey,
      sshPublicKey:SSHPublicKey,
      sshPrivateKey: SSHPrivateKey,
      type: 'archive',
      tenant: tenant.id,
      authPasswordEnabled: usingPassword,
      authKeyEnabled: usingSSH,
      encryptPgpEnabled: usingPGP
    };
    if(groupId) params.group = groupId;

    createIntegration({params: params})
    .then(result => {
      if(result.data.success){
        dispatch(loadIntegrationsThuunk());
        Toast.show({status:'success',title:'Successfully created integration'});
      } else {
        Toast.show({status:'danger', title:result.data.errorMessage})
      }
    })

  };



  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <View style={styles.topComment}>
          <Text style={styles.text}>
            MyRepChat can send your data to an SFTP site for archival. In order
            to set this up you need to:
          </Text>
          <Text style={styles.text}>
            1. Enter your SFTP server host name and port.
          </Text>
          <Text style={styles.text}>
            2. Enter any path to use as a prefix. This can be blank to write to
            root.
          </Text>
          <Text style={styles.text}>3. Enter the username.</Text>
          <Text style={styles.text}>
            4. Choose authentication method. It can be password-based or ssh key
            based authentication, or both
          </Text>
          <Text style={styles.text}>
            5. Choose if you want to use PGP encryption for files at rest.
          </Text>
          <Text style={styles.text}>6. Activate the integration.</Text>
          <Text style={styles.text}>7. Click the save button.</Text>
        </View>
        <CustomInput
          title="SFTP HOST"
          value={hostName}
          onChangeText={(val) => setHostName(val)}
        />
        <CustomInput
          title="PORT"
          value={portName}
          onChangeText={(val) => setPortName(val)}
        />
        <CustomInput
          title="PATH"
          value={path}
          onChangeText={(val) => setPath(val)}
        />
        <CustomInput
          title="USERNAME"
          value={userName}
          onChangeText={(val) => setUserName(val)}
        />

        <View style={{marginTop:40,flexDirection:'row',alignItems:'center',marginTop:10,marginBottom:10}}>
        <Switch 
        value={usingPassword}
        onValueChange={(e) => {setUsingPassword(e); if(!e) setPassword('')}}
        />
        <Text>  Use Password Authentication</Text>
        </View>

        <CustomInput
          title="PASSWORD"
          value={password}
          onChangeText={(val) => setPassword(val)}
          disabled={!usingPassword}
        />

        <View style={{marginTop:40,flexDirection:'row',alignItems:'center',marginTop:50,marginBottom:10}}>
        <Switch 
        value={usingSSH}
        onValueChange={(e) => {setUsingSSH(e)}}
        />
        <Text>  Use SSH key authentication </Text>
        </View>

        <CustomInput
          title="SSH Public Key"
          disabled={true}
          value={SSHPublicKey}
          multiline
          textInputStyle={{maxHeight:100,minHeight:100}}
        />
        

        <CustomButton
          onPress={()=>generateKeys()}
          title="GENERATE SSH KEY" />

<View style={{marginTop:40,flexDirection:'row',alignItems:'center',marginTop:50,marginBottom:10}}>
        <Switch 
        value={usingPGP}
        onValueChange={(e) => {setUsingPGP(e)}}
        />
        <Text>  Use PGP encryption </Text>
        </View>
        
        <CustomInput
          title="PGP Public Key"
          onChangeText={e => setPGPPublicKey(e)}
          disabled={!usingPGP}
          value={PGPPublicKey}
          multiline
          textInputStyle={{maxHeight:100,minHeight:100}}
        />

<View style={{flexDirection:'row',alignItems:'center',marginTop:50,marginBottom:10}}>
          <Switch
            value={enable}
            onValueChange={() => setEnable(!enable)}
            trackColor={{ false: 'red', true: 'green' }}
          />
        <Text style={{marginLeft:10}}>ACTIVATE ARCHIVING</Text>

        </View>

        <View>
          <CustomButton title="save" style={{marginTop:20,marginBottom:20}} onPress={handleSave} />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default SFTP;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    width:'75%',
    alignSelf:'center'
  },
  topComment: {
    padding: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  setupPaneTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 24,
    marginTop: 50,
  },
  imageWrapper: {
    width: 200,
    height: 150,
    borderRadius: 3,
    borderWidth: 1,
    margin: 5,
    padding: 15,
  },
  image: {
    resizeMode: 'contain',
    width: 160,
    height: 45,
  },
  imageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
