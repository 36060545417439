import { useToast } from 'native-base';
import React from 'react';
import { useState } from 'react';
import { Text, View, Switch, Keyboard, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import Loader from '../../../Components/Loader';

const OutOfOffice = ({ navigation }) => {
  const key = 'sms.response';
  const [value, setValue] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [attributes, setAttributes] = React.useState([]);
  const Toast = useToast();
  const [data, setData] = React.useState({
    id: null,
    val: '',
    name: key,
  });
  const tenant = useSelector((state) => state.User.profile)?.tenant;

  React.useEffect(() => {
    if (attributes?.length > 0) {
      let attribute = attributes.find((z) => z.name == key);
      if (attribute) {
        console.log("attribute", attribute);
        setData(attribute);
        setValue(true);
      } else {
        setData({
          id: null,
          val: '',
          name: key,
        })
      }
    }
  }, [attributes]);

  const loadAttributes = async () => {
    Keyboard.dismiss();
    setLoading(true)
    try {
      let body = { action: 'get_attributes' };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result);
      if (result.data.success) {
        setAttributes(result.data.attributes);
        console.log(result.data.attributes)
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    Keyboard.dismiss();
    setLoading(true);
    try {
      let body = {};
      /*if (value) {
        if (data.id == null) {
          body = { action: 'create_attribute', name: key, val: data.val };
        } else {
          body = {
            action: 'update_attribute',
            name: key,
            val: data.val,
            id: data.id,
          };
        }
      } else if (data.id != null) {
        body = { action: 'delete_attribute', id: data.id };
      }*/

      if(value && !data.id){
        body = { action: 'create_attribute', name: key, val: data.val };
      } else if(!value && data.id){
        body = { action: 'delete_attribute', id: data.id };
      } else if(value && data.id){
        body = {
          action: 'update_attribute',
          name: key,
          val: data.val,
          id: data.id,
        };
      }


      console.log("BODY ODY ODY", body);
      console.log("DATA",data);
      console.log("Value", value);

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Successfully updated Out of Office Message',
          status:'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
      navigation.goBack();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const subscribe = navigation.addListener('focus',() => {
      loadAttributes();
    } );

    return subscribe;
  }, [navigation]);


  React.useEffect(() => {
    const subscribe = navigation.addListener('blur',() => {
      setValue(false);
      setAttributes([])
      setData({
        id: null,
        val: '',
        name: key,
      })
    } );

    return subscribe;
  }, [navigation]);
  React.useEffect(()=>{
    loadAttributes()
  },[tenant])

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 768,
          marginTop: 20,
          padding: 20,
          alignSelf: 'center',
        }}>
        <ScrollView>
          <Text style={{    fontFamily: AppFonts.Roboto_Regular,    fontSize: 16,
            fontFamily: AppFonts.Roboto_Regular,
            textAlign: 'center',
            lineHeight: '1.2em',
          }}>When this setting is turned on, this is the message that all incoming messages will receive as your out-of-office response:
          </Text>
          <CustomInput
            value={data.val}
            placeholder={'Type Message'}
            title={'Message'}
            onChangeText={(t) => setData({ ...data, val: t })}
          />
          <View
            style={{
              width: '100%',
              alignItems: 'center',
              flexDirection: 'row',
              marginHorizontal: 40,
              marginVertical: 40,
            }}>
            <Switch
              value={value}
              onValueChange={() => setValue(!value)}
              trackColor={{ false: 'red', true: 'green' }}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginLeft: 10 }}>
              {value ? 'ON' : 'OFF'}
            </Text>
          </View>

          <View style={{ marginVertical: 30,justifyContent:'center',flexDirection:'row',gap:20 }}>
          <CustomButton
              outline
              width={'20%'}
              onPress={() => navigation.goBack()}
              title={'Cancel'}
            />

            <CustomButton width={'30%'} title={'Save'} onPress={() => handleSave()} />

          </View>
        </ScrollView>
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default OutOfOffice;
