import React from 'react';
import { TouchableOpacity, View, Text, Image, StyleSheet } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import AppColor from '../../Constants/Color';
import End_Points from '../../Constants/Api';


const IntegrationCard = props => {
    return(
    <TouchableOpacity
    onPress={props.onPress}
    style={[styles.container, props.isActive && { borderColor: AppColor.SuccessColor, borderWidth: 3 }]}
    >

    {props.integration.name === 'email' ? (
        <View
        style={{
            flexDirection: 'row',
            alignItems: 'center',
            height: 45,
        }}>
        <MaterialIcons
            name="email"
            size={30}
            color={AppColor.PrimaryColor}
        />
        <Text
            style={{
            color: AppColor.PrimaryColor,
            fontSize: 20,
            marginLeft: 10,
            }}>
            Email
        </Text>
        </View>
    ) : (
        <img
        style={{
            height: 45,
            minWidth:'50%',
            maxWidth: '80%',
            margin: '0px 10px',
            objectFit:'contain'
        }}
        src={props.integration.fullImageUrlPath}
        />
    )}

    {props.isActive && (
        <View
        style={{
            marginTop: 20,
        }}>
        <MaterialIcons
            name="check-circle"
            size={30}
            color={AppColor.SuccessColor}
        />
        </View>
    )}


    </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        borderWidth: 1,
        marginVertical: 5,
        borderColor: AppColor.Dark,
        width: '30%',
        height: 150,
        padding: 15,
        borderRadius: 4,
    }
})

export default IntegrationCard;