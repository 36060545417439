import React from 'react';
import {
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
const moment = require('moment');
import { useSelector } from 'react-redux';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import { CommonStyle } from './chat_side';
import RequestMaker from '../../Middleware/ApiCaller';
import CopyMessageTextModal from '../../Components/Modals/CopyMessageTextModal';

export const RenderBubble = (props) => {
    const tenant = useSelector((state) => state.User?.profile?.tenant);
    const [modal, setModal] = React.useState(false);
    const [text, setText] = React.useState('');
    const {
        user,
        type,
        sms,
        mms,
        direction,
        call_status,
        records,
        transcripts,
        senderName,
        createdAt,
        groupChatIdentifier
    } = props.currentMessage;

    const isUser1 = user._id === 1;
    const isUser2 = user._id === 2;

    let statusCall =
    ((direction === 'inbound' || direction === 'Inbound Call') && call_status === 'ringing')
        ? 'inbound'
        : (call_status === 'unread')
            ? 'Missed'
            : call_status ?? '';

    const handleShowTranscript = async id => {
        const body = {
            action: 'get_transcript',
            communication: id,
        };
        let result = await RequestMaker(body);
        if (result.data.success) {
            setModal(true)
            setText(result?.data?.jobs[0]?.results?.transcripts[0]?.transcript)
        }
    }

    const commonTextStyle = {
        fontSize: 12,
        fontFamily: AppFonts.Roboto_Regular,
        color: isUser1 ? 'rgba(0,0,0,.75)' : AppColor.PrimaryColor,
        textAlign: 'right',
    };

    //links 🤤
    const renderMessageContent = (message) => {
        return message.split("\n").map((line, index) => (
            <React.Fragment key={index}>
                {line.split(" ").map((word, wordIndex) => {
                    if (URL_REGEX.test(word)) {
                        return (
                            <a 
                                key={wordIndex}
                                target="_blank" 
                                rel="noopener noreferrer" // added for security reasons
                                href={word}
                            >
                                {word}
                            </a>
                        );
                    }
                    return `${word} `;
                })}
                {'\n'}
            </React.Fragment>
        ));
    };


    const SMSMessages = () => {
        if (Array.isArray(sms)) return sms.map((x) => (
            x.sms !== '' && (
                <Text
                    activeOpacity={0.5}
                    style={isUser2 ? CommonStyle.left : CommonStyle.right}
                >
                    <Text style={isUser1 ? CommonStyle.bubbleRightText : CommonStyle.bubbleLeftText}>
                        {typeof x.sms === 'string' && renderMessageContent(x.sms)}
                    </Text>
                    <Text style={commonTextStyle}>
                        {senderName !== '' && `(sent by ${senderName}) `}
                        {x.sms_status} {moment(x.time).format('h:mm a')}
                    </Text>
                </Text>
            )
        ))
    };

    const CallMessages = () => {
        if (type !== 'call') return null;

        return (
            <View style={isUser2 ? CommonStyle.left : CommonStyle.right}>
                <MaterialIcons name={'phone'} size={40} color={AppColor.PrimaryColor} />
                <Text style={commonTextStyle}>
                    {statusCall} {moment(createdAt).format('h:mm a')}
                </Text>
            </View>
        );
    };

    const MMSContent = ({ contentType, mms_url }) => {
        const openDocument = (url, type) => {
            props.openDocument(url, type, props.setLoading);
        };

        if (contentType.includes('image')) {
            return <img style={{ height: 100, width: 100, alignSelf: 'center' }} resizeMode='cover' src={mms_url}/>;
        } else if (contentType.includes('audio')) {
            return <Entypo name="controller-play" color={'white'} size={40} />;
        } else if (contentType.includes('video')) {
            return <Entypo name="controller-play" color={AppColor.PrimaryColor} size={40} />;
        } else {
            return (
                <TouchableOpacity onPress={() => openDocument(mms_url, contentType.split('/')[1])}>
                    <AntDesign name="file1" color={AppColor.PrimaryColor } size={30} />
                    <Text style={isUser1 ? CommonStyle.bubbleRightText : CommonStyle.bubbleLeftText}>
                        {contentType.split('/')[1]} file
                    </Text>
                </TouchableOpacity>
            );
        }
    };

    const MMSMessages = () => {
        if (type !== 'mms' && !(tenant?.provider === 'bandwidth' && sms?.findIndex(e => e !== '') > -1)) return null;

        return (
            <>
                {
                    mms.map((x) => (
                         <View style={isUser2 ? CommonStyle.left : CommonStyle.right}
                        key={x.mms_url}>
                                <TouchableOpacity
                                    onPress={() => {
                                        
                                        if (x.contentType.includes('audio') || x.contentType.includes('image') || x.contentType.includes('vcard')) {
                                            props.openDocument(x.mms_url, x.contentType.split('/')[1]);
                                        } else if (x.contentType.includes('video')) {
                                            props.openDocument(x.mms_url, x.mms_url.split('.')[x.mms_url.split('.').length - 1]);
                                        }
                                    }}
                                    activeOpacity={0.6}
                                >
                                    <MMSContent contentType={x.contentType} mms_url={x.mms_url} />
                                    <Text style={commonTextStyle}>
                                        {x.status} {moment(x.time).format('h:mm a')}
                                    </Text>
                                </TouchableOpacity>
                        </View>
                    ))
                }
                <SMSMessages />
            </>
        )
    };

    const RecordMessages = () => {    
        return records.map((x) => (
            <View style={isUser2 ? CommonStyle.left : CommonStyle.right} key={x.recording_url}>
                <View style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Entypo name="mic" color={AppColor.PrimaryColor} size={40} />
                    {x?.recording_url && <audio src={x.recording_url} controls />}
                </View>
                <Text style={commonTextStyle}>
                    {(x.recording_duration / 60).toFixed(2)} min
                </Text>
                <Text style={commonTextStyle}>
                    {x.status} {moment(x.time).format('h:mm a')}
                </Text>
            </View>
        ));
    };


    const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;
    return (
        <>
            <View
                style={{
                    marginBottom: 5,
                    maxWidth: '95%',
                }}>

                { (groupChatIdentifier && !isUser1 ) &&
                
                <Text style={{fontFamily: AppFonts.Roboto_Regular,marginLeft:5}}>
                    {groupChatIdentifier}
                </Text>
                }

                {type == 'sms' &&
                    <SMSMessages />
                }

                {/* type calls */}
                {type == 'call' && 
                    <CallMessages />
                }

                {type === 'mms' && <MMSMessages />}

                
                {!!records.length && <RecordMessages />}

                {(tenant?.transcribe && transcripts.length > 0) && transcripts.map(x => {
                    return (
                        <TouchableOpacity onPress={() => {
                            handleShowTranscript(x.comm);
                        }} activeOpacity={0.5} style={[isUser2 ? CommonStyle.left : CommonStyle.right, { flexDirection: 'row', alignItems: 'center', marginBottom: 20 }]}>
                            <Entypo name='message' size={32} color={AppColor.PrimaryColor} />
                            <Text style={{ marginLeft: 7, fontSize: 16, color: AppColor.PrimaryColor }}>View Transcript</Text>

                        </TouchableOpacity>)
                })
                }

                
            </View>

            <CopyMessageTextModal 
                setModal={setModal}
                modal={modal}
                text={text}
            />
        </>
    );

};
