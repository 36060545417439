/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { StyleSheet, View, Text, Image, Linking, Keyboard, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'Alert';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, Content, useToast } from 'native-base';

import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import storeData from '../../../AsyncUtils/StoreData';
import keyword from '../../../Constants/Keywords';
import { setSignUpData } from '../../../Redux/signupdata';
import { Bubbles } from '../Login/Bubbles';
import { Icon } from 'react-native-elements';
import useResponsiveLayout from '../../../Middleware/useResponsiveLayout';

const GroupSetup = ({ navigation }) => {
  const [groupCode, setGroupCode] = useState('');
  const [loader, setLoading] = useState(false);
  const Toast = useToast();
  const dispatch = useDispatch();
  const {isWebCompact} = useResponsiveLayout();

  const checkGroupCode = async () => {
    let body = {
      action: 'verify_group',
      groupCode: groupCode,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    setLoading(true);
    let result = await RequestMaker(Config);

    if (result.data.success) {
      if(result.data.isValid){
        handleNext();
      } else {
        Alert.alert('Error', 'Invalid Group Code');
      }
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
    setLoading(false);
  };

  const handleNext = () => {
	dispatch(setSignUpData({ data: { groupCode: groupCode}}));
	navigation.navigate('CompanyDetail');
  };

  const handleSkip = () => {
    dispatch(setSignUpData({ data: { groupCode: ''}}));
    navigation.navigate('CompanyDetail');  
  };

  return (
    <View style={{flex:1}}>
      <View style={{flex:1,backgroundColor:'white',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
{  !isWebCompact &&      <Bubbles>
          <View>
            <img
              style={{
                maxWidth: 250,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt={'MyRepChat logo.'}
              src={require('../../../Assets/Images/logo-fullcolor@2x.png')}
            />
            <Text
              style={{
                textAlign: 'right',
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.Dark,
                marginTop:20
              }}>
              Version : 5.5.3
            </Text>
          </View>

        </Bubbles>
}
        <View
        style={{
          flex: 1,
        }}>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View style={{ width: isWebCompact? '80%': '50%' }}>
            <Icon onPress={() => navigation.goBack()} name='arrow-back' style={{alignSelf:'flex-start',marginBottom:10}} type='fontawesome' size={22} />
            <View
              style={{
                alignSelf: 'flex-start',
                paddingVertical: 10,
              }}>
              <Text style={{ fontSize: 20 }}>Create a new account</Text>
            </View>
            <View
              style={{
                alignSelf: 'center',
                paddingVertical: 10,
              }}>
              <Text
                style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 16 }}>
				<Text>
                Are you a part of an Organization that has adopted MyRepChat? 
				If so, please enter the unique CODE associated with that Organization to ensure the proper routing of your account. 
				</Text>
              </Text>
            </View>
            <CustomInput
              onChangeText={(s) => setGroupCode(s)}
              value={groupCode}
              title={'Organization Code'}
              placeholder={'Enter organization code'}
            />
          <View style={{ height: 15 }} />
          <CustomButton
          width={'100%'}
            onPress={() => {
				checkGroupCode();
            }}
            title={'Next'}
          />
                    <TouchableOpacity accessible={true} accessibilityRole='button' onPress={handleSkip} style={{alignSelf:'flex-end',marginTop:20, textDecorationLine: 'underline'}}>
              <Text style={{fontSize:18}}>Skip for now</Text>
          </TouchableOpacity>

          <Text style={{ fontSize: 20,marginTop:40 }}>Don't have a code?</Text>
          <Text style={{marginVertical:10}}>
            Please contact us so that we can assist you with setting up your account.
				  </Text>

          <CustomButton           width={'100%'}title='ASK US' onPress={() => Linking.openURL('mailto:support@ionlake.com?Subject=Organization%20Code')}/>
          </View>

        </View>
      </View>


        </View>
      {loader ? <Loader /> : null}
    </View>
  );
};

export default GroupSetup;
