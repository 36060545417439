import { useToast } from 'native-base';
import React from 'react';
import {
  Text,
  View,
  Keyboard,
  FlatList,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import { Icon } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import { TouchableOpacity } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { CheckBox } from 'react-native-elements';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { providers } from '../IntegrationProvider';
import Alert from 'Alert'
import CustomInput from '../../Components/CustomInput';
import NewAlphabetList from '../Contacts/NewAlphabetList';
import ListHeader from '../../Components/AlphabetList/ListHeader';
import Contact from '../../Components/Contact';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const StartConversation = ({onSelectContact, onSelectDistributionList, setSelectedChat, selectedChat }) => {
  const Toast = useToast();
  const [visible, setVisible] = React.useState(false);
  const [visible1, setVisible1] = React.useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [members, setMembers] = React.useState([]);
  const [dls, setDLs] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [data, setData] = React.useState({
    action: 'search',
    includeDistributionList: true,
    includeContacts: true,
    type: 'crm_and_local',
    import: false,
    filters: {
      scope: 'full',
    },
  });
  const [selectedGroupMembers, setSelectedGroupMembers] = React.useState([]);
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;
  const {isWebCompact} = useResponsiveLayout();
  const contacts = useSelector((state) => state.contacts).data;
  const contactFilter = (x) =>
  (`${x?.fname?.toLowerCase()} ${x?.lname?.toLowerCase()}`)?.includes(search?.trim()?.toLowerCase())


  const handleDone = (d) => {
    setData(d);
  };

  // search begin
  const beginsearch = async () => {
    if (search.trim().length < 2) {
      Toast.show({
        duration: 2000,
        title: 'Search text must be atleast 2 characters',
        status: 'warning',
      });
      return;
    }
    // Keyboard.dismiss()
    try {
      setSearchLoading(true);
      const body = { ...data };
      body.filters['google'] = search;

      if(selectedChat === 'createGroup'){
        body.import = false;
        body.includeContacts = true;
        body.includeDistributionList = false;
        body.type = 'local_only'
        body.filters['scope'] = 'user';
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        //dont allow group contacts to be added to group message
        let filteredData = result.data.contacts;
        if(selectedChat === 'createGroup'){
          filteredData = result.data.contacts.filter((contact) => !contact?.isGroup);
        }
        setMembers(filteredData);
        setDLs(result.data.distributionLists);
      } else {
        Alert.alert('Error', result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSearchLoading(false);
    }
  };

  // create quick contact and goto chat window on create
  const handleQuickContact = async (data) => {
    Keyboard.dismiss();
    try {
      setLoading(true);
      const body = {
        action: 'create_member',
        disallowDuplicate: true, //return an existing contact with same messaging phone instead of creating new
        accountId: data.accountId ?? '',
        fname: data.firstName,
        mname: '',
        lname: data.lastName,
        address: '',
        city: '',
        state: '',
        zip: '',
        email_address: '',
        birth_date: null,
        consented: false,
        spam: false,
        distributionLists: [],
        phoneNumbers: [
          {
            isoCode: 'US',
            countryCode: '+1',
            longCode: data.phoneNo,
            type: 1,
            messaging: true,
          },
        ],
        source: '',
        sourceId: '',
        comments: '',
        email_newsletter: false,
        list_in_directory: false,
        notify: false,
        phone1: data.phoneNo,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        dispatch(loadAllContactsThunk());
        body['id'] = result.data.memberId;
        // navigation.navigate('Chats', { memberData: body });
        //navigation.navigate('Messages', { memberData: body });
        onSelectContact(body);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // after click on searched contact check weather it in already coantact or not else add and goto chat
  const handleCrmSearchClick = async (item) => {
    try {
      setLoading(true);
      if (!item.id || item.id == 0 || item.id == null) {
        const body = {
          action: 'create_member',
          accountId: item.accountId ?? '',
          fname: item.fname ?? '',
          mname: item.mname ?? '',
          lname: item.lname ?? '',
          address: item.address ?? '',
          city: item.city ?? '',
          state: item.state ?? '',
          zip: item.zip ?? '',
          email_address: item.email_address ?? '',
          birth_date: item.birth_date ?? null,
          consented: item.consented ?? false,
          spam: item.spam ?? false,
          distributionLists: [],
          phoneNumbers: item.phoneNumbers,
          source: item.source ?? '',
          sourceId: item.sourceId ?? '',
          comments: item.comments ?? '',
          email_newsletter: false,
          list_in_directory: false,
          notify: false,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('result', result);
        if (result.data.success) {
          dispatch(loadAllContactsThunk());
          body['id'] = result.data.memberId;
          // navigation.navigate('Chats', { memberData: body });
          //navigation.navigate('Messages', { memberData: body });
          onSelectContact(body);
        } else {
          Toast.show({
            duration: 2000,
            title: result.data.errorMessage,
            status: 'error',
          });
        }
      } else {
        // navigation.navigate('Chats', { memberData: item });
        // navigation.navigate('Messages', { memberData: item });
        onSelectContact(item);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };


  const renderContactView = ({ item }) => { 

    const checkForMessagingNumber = () => {
      if(!item.phoneNumbers.length){
        Alert.alert('No messaging number','This contact has no messaging number defined. Go to the contact details and specify a number to use for messaging.')
        return;
      }
      handleCrmSearchClick(item)
    }
    
    const columnsResponsive = isWebCompact ? ['fname', 'phone'] : ['fname', 'lname', 'phone', 'status'];
    
    return (
      <View style={{flexDirection:'row',alignItems:'center'}}>
      {  selectedChat === 'createGroup' &&
      <CheckBox 
      checked={selectedGroupMembers.some(x => x.id === item.id)}
      containerStyle={{right:0,position:'absolute'}}/>
      }
      <Contact
      item={item}
      onPress={() => {
        if(selectedChat === 'createGroup'){
          if(selectedGroupMembers.length === 9){
            Toast.show({
              title:'Maximum 9 contacts can be added to a group message.',
              status:'error'
            })
            return;
          }
          if(selectedGroupMembers.some(x => x.id === item.id)){
            setSelectedGroupMembers(selectedGroupMembers.filter(x => x.id !== item.id));
          } else {
            setSelectedGroupMembers([...selectedGroupMembers, item]);
          }
        } else checkForMessagingNumber()
      }}
      columns={columnsResponsive}
      />
      </View>
    );
  };

  const handleDistributionNavigation = async (item) => {

    if (item.id == 0 || item.id == null) {
      try {
        setLoading(true);
        const body = {
          action: 'import_crm_dls',
          distributionLists: [{provider: item.source, sourceid: item.sourceid, text: item.text}]
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('result', result);
        if (result.data.success) {
          console.log("Data:",result.data);
          dispatch(loadAllDistributionsThunk());
          const data = { id: result.data.id, text: item.text };
          // navigation.navigate('Chats', { dl: data });
          //navigation.navigate('Messages', { dl: data });
          onSelectDistributionList(data);

        } else {
          Toast.show({
            duration: 3000,
            title: result.data.errorMessage,
            status: 'error',
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    } else {
      // navigation.navigate('Chats', { dl: item });
      onSelectDistributionList(item);
      //navigation.navigate('Messages', { dl: data });
    }
  };

  const  getGroupName = (selectedGroupMembers) => {
    if (!selectedGroupMembers || selectedGroupMembers.length === 0) {
        return '';
    }
    
    const membersCount = selectedGroupMembers.length;

    if (membersCount <= 2) {
        return selectedGroupMembers.map(x => `${x.fname} ${x.lname}`).join(', ');
    } else {
        const firstTwoMembers = selectedGroupMembers.slice(0, 2).map(x => `${x.fname} ${x.lname}`);
        const remainingCount = membersCount - 2;
        return `${firstTwoMembers.join(', ')}, & ${remainingCount} more`;
    }
  }

  const startMessagingGroup = async () => {
    try{
    const groupName = getGroupName(selectedGroupMembers);
    const memberIds = selectedGroupMembers.map(x => x.id);
    const body = {
        "fname":groupName,
        "mname":"",
        "lname":"",
        "address":"",
        "city":"",
        "state":"",
        "zip":"",
        "country":"US",
        "email_address":"",
        "birth_date":null,
        "spam":false,
        "comments":"",
        "email_newsletter":false,
        "mail_newsletter":false,
        "list_in_directory":false,
        "distributionLists":[],
        "phoneNumbers":[],
        "members":memberIds,
        "isGroup":true,
        "toast":false,
        "action":"create_member"
      }
      const result = await RequestMaker(body);
      if(result.data.success){
        body['id'] = result.data.memberId;
        onSelectContact(body)
      } else if(result.data.errorMessage){
        throw new Error(result.data.errorMessage)
      }

    } catch(err){
      Toast.show({
        title: err.message,
        status: 'error'
      })
    }
    
        
  }

  const renderDLView = ({ item }) => {

    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == item.source);
    if (provider) {
      image = provider.smallImageUrlPath;
      imageAlt = `${provider.name} logo.`
    }
    
    return (
      <TouchableOpacity
        onPress={() => {
          Alert.alert(
            'Message',
            'Do you want to send message to this distribution?',
            [
              {
                text: 'Cancel',
              },
              {
                text: 'Distribution Message',
                onPress: () => {
                  handleDistributionNavigation(item);
                },
              },
            ]
          );
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
          <img alt={imageAlt} src={image} style={{ height: 40, width: 40 }} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          {item.text && (
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 17,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}>
              {item.text}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };
  const actionButtonResponsiveWidth = isWebCompact ? '35%' : '20%';
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 10,
          marginTop:5,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Icon name='arrow-back' onPress={() => setSelectedChat(null)} />
        
        <Search containerStyle={{flex:1}} onKeyPress={e => {if(e.key === 'Enter') beginsearch()}} value={search} onChangeText={(t) => setSearch(t)} />

        {selectedChat !== 'createGroup' &&
          <TouchableOpacity onPress={() => setVisible(true)}>
          <AntDesign name="filter" size={30} color={AppColor.PrimaryColor} />
        </TouchableOpacity>
        }

      </View>
      <View style={{flexDirection:'row',justifyContent:'flex-end',paddingRight:'7%',gap:20,marginVertical:20,alignItems:'center',alignSelf:'center',width:'100%'}}>
      {
        selectedChat !== 'createGroup' &&
      <CustomButton
      outline
          title="Quick Create"
          style={{ width: actionButtonResponsiveWidth }}
          onPress={() => setVisible1(true)}
        />
      }

      {
        selectedChat !== 'createGroup' &&
        <CustomButton           
      style={{ width:actionButtonResponsiveWidth }}
      title="Search" 
      onPress={beginsearch} />}
      </View>
      {
      !searchLoading && dls.length == 0 && members.length == 0 && selectedChat !== 'createGroup' && (
        <Text style={{ textAlign: 'center' }}>No contact matches</Text>
      )}

      {selectedChat === 'createGroup' &&
        <View style={{flexDirection:'row',flexWrap:'wrap',paddingLeft:15,gap:5}}>
            {
          selectedGroupMembers.map(e => {
            return(
              <TouchableOpacity onPress={() => setSelectedGroupMembers(selectedGroupMembers.filter(x => x.id !== e.id))} style={{backgroundColor:'lightgray',padding:7,borderRadius:50,flexDirection:'row',alignItems:'center'}}>
              <Text>{e.fname} {e.lname}</Text>
              <Icon size={16} name='close' />
              </TouchableOpacity>
            )
          })
        }
        </View>

      }

      {selectedChat === 'createGroup' && 
        <Text style={{fontFamily:AppFonts.Roboto_Regular,paddingLeft:15,marginTop:10}}>
          {selectedGroupMembers.length} / 9 receipients
        </Text>
      }

<View style={{ flex: 1, justifyContent:'center',paddingLeft:25,marginTop:20 }}>
        {(dls.length > 0 && selectedChat !== 'createGroup') && (
          <FlatList
            style={{flex:1}}
            data={dls}
            keyExtractor={(item, index) => item.id + '' + index}
            renderItem={renderDLView}
            ListHeaderComponent={
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 20,
                  fontFamily: AppFonts.Roboto_Bold,
                  marginVertical: 15,
                }}>
                Distribution Lists
              </Text>
            }
          />
        )}

        {(members.length > 0 || selectedChat === 'createGroup') && (

          <NewAlphabetList 
            data={selectedChat !== 'createGroup' ? members : contacts.filter(contactFilter)}
            RenderItem={renderContactView}
            ListHeaderComponent={() => <ListHeader handleSortByProperty={() => {}} columns={ !isWebCompact ? ['fname','lname','phone','status'] : ['fname', 'phone']} />}
          />
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 30,
          width: '100%',
        }}>
      </View>
      <FilterModal
        visible={visible}
        setVisible={setVisible}
        handleDone={handleDone}
      />
      <QuickCreateModal
        visible={visible1}
        setVisible={setVisible1}
        handleQuickContact={handleQuickContact}
      />
      {loading && <Loader />}
      {searchLoading && <Loader />}
      {
        selectedChat === 'createGroup' &&
        <CustomButton onPress={startMessagingGroup} width={'40%'} disabled={selectedGroupMembers.length <= 1 || selectedGroupMembers.length > 9} title={'Start Messaging Group'}/>
      }    
    </SafeAreaView>
  );
};

//filter modal for searching contacts
const FilterModal = ({ visible, setVisible, handleDone }) => {
  const [includeDistributionList, setIncludeDistributionList] =
    React.useState(true);
  const [includeContacts, setIncludeContacts] = React.useState(true);
  const [searchCRM, setSeacrchCRM] = React.useState(true);
  const [searchMRC, setSeacrchMRC] = React.useState(true);
  const [allContacts, setAllContacts] = React.useState(true);
  const [myContacts, setMyContacts] = React.useState(false);

  const handleSave = () => {
    let type = 'crm_and_local';
    let scope = 'full';
    if (searchCRM && searchMRC) {
      type = 'crm_and_local';
    } else {
      if (searchCRM) {
        type = 'crm_only';
      }
      if (searchMRC) {
        type = 'local_only';
      }
    }
    if (myContacts) {
      scope = 'user';
    }
    if (allContacts) {
      scope = 'full';
    }

    const data = {
      action: 'search',
      includeDistributionList: includeDistributionList,
      includeContacts: includeContacts,
      type: type,
      import: false,
      filters: {
        scope: scope,
      },
    };
    handleDone(data);
    setVisible(false);
  };
  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: '90%', backgroundColor: 'white', padding: 20 }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Filter
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>

          <View style={{ marginVertical: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={includeContacts}
                onPress={() => setIncludeContacts(!includeContacts)}
                title="Search Contact"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={includeDistributionList}
                onPress={() =>
                  setIncludeDistributionList(!includeDistributionList)
                }
                title="Search DLs"
                containerStyle={{ flex: 1 }}
              />
            </View>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={searchCRM}
                onPress={() => setSeacrchCRM(!searchCRM)}
                title="Search CRM"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={searchMRC}
                onPress={() => setSeacrchMRC(!searchMRC)}
                title="Search MRC"
                containerStyle={{ flex: 1 }}
              />
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: AppColor.PrimaryColor,
                marginVertical: 10,
              }}></View>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={allContacts}
                onPress={() => setAllContacts(!allContacts)}
                title="All Contacts"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={myContacts}
                onPress={() => setMyContacts(!myContacts)}
                title="My Contacts"
                containerStyle={{ flex: 1 }}
              />
            </View>
          </View>
          <CustomButton title="Done" onPress={() => handleSave()} />
        </View>
      </View>
    </Modal>
  );
};

// quick create  modal
const QuickCreateModal = ({ visible, setVisible, handleQuickContact }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNo, setPhone] = React.useState('');

  function stripNonNumeric(str) {
    return str.replace(/\D/g, '');
  }

  const handleSave = () => {
    let strippedNumber = stripNonNumeric(String(phoneNo));

    if (
      firstName.trim() != '' &&
      (lastName.trim() != '') & (phoneNo.trim() != '')
    ) {
      if(strippedNumber.length !== 10){
        Alert.alert('Error', 'Please enter a valid 10 digit phone number.');
        return;
      }
      setVisible(false);
      handleQuickContact({ firstName, lastName, phoneNo: strippedNumber });
    } else {
      Alert.alert('Message', 'All field with * are required.');
    }
  };
  React.useEffect(() => {
    if (visible) {
      setFirstName('');
      setLastName('');
      setPhone('');
    }
  }, [visible]);

  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '90%',
            maxWidth: 575,
            backgroundColor: 'white',
            padding: 20,
          }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Quick Create
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>
          <View
            style={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
            }}>
            <CustomInput
              value={firstName}
              onChangeText={(t) => setFirstName(t)}
              placeholder="First Name*"
              style={{ fontSize: 15 }}
            />
            <CustomInput
              value={lastName}
              onChangeText={(t) => setLastName(t)}
              placeholder="Last Name*"
              style={{ fontSize: 15 }}
            />
            <CustomInput
              value={phoneNo}
              onChangeText={(t) => setPhone(t)}
              placeholder="Phone Number*"
              style={{ fontSize: 15 }}
              keyboardType={'phone-pad'}
            />
          </View>
          <View style={{ marginVertical: 10 }}></View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            <CustomButton
              outline
              style={{ width: '40%' }}
              title="Cancel"
              onPress={() => setVisible(false)}
            />

            <CustomButton
              style={{ width: '40%' }}
              title="Ok"
              onPress={() => handleSave()}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default StartConversation;
