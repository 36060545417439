import React from 'react';
import { Text, View } from 'react-native';
import { Linking } from 'react-native';


import CustomButton from '../../../../Components/Button';
import Loader from '../../../../Components/Loader';
import AppFonts from '../../../../Constants/Fonts';
import AppFont from '../../../../Constants/Fonts';
import AppColor from '../../../../Constants/Color';
import IntegrationComponent from './IntegrationComponent';
import { Input } from 'react-native-elements';
import { Alert } from 'react-native';
import { setAttributes } from '../../../../Redux/integrationData';

import End_Points from '../../../../Constants/Api';
import RequestMaker from '../../../../Middleware/ApiCaller';
import getData from '../../../../AsyncUtils/GetData';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

export default function TwoStepOauthComponent({
  provider,
  integration,
  defaultDomain,
  sampleDomain,
  groupIntegration,
  allowContactEdits,
  allowLocation,
  allowAdvancedOptions,
  createIntegration,
  updateIntegration,
}) {
  const isLocked = groupIntegration == null ? false : groupIntegration.locked;
  const defaultDomains =
    groupIntegration == null || groupIntegration.host == null
      ? defaultDomain
      : groupIntegration.host;
  const [loading, setLoading] = React.useState(false);
  const [domain, setDomain] = React.useState(defaultDomains);

  const [integ, setIntegration] = React.useState(null);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const handleConnect = async () => {
    if (domain == null || domain.trim() == '') {
      Alert.alert(
        'Error',
        'You must enter your ' + provider.label + ' domain.'
      );
      return;
    }
    if (integ && integ != null) {
      await updateIntegration();
    } else {
      await createIntegration();
    }
    await getOauth();
    console.log('After Calling oauth');
  };

  const getOauth = async () => {
    const body = {
      action: 'get_oauth_connect',
      provider: provider.name,
    };
    try {
      setLoading(true);

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
    	Linking.canOpenURL(result.data.url).then((supported) => {
      		if (supported) {
				console.log("Opening link",result.data.url)
        		Linking.openURL(result.data.url);
      		} else {
        		console.log("Don't know how to open URI: ");
      		}
    	});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
	}
  };
  React.useEffect(() => {
    setIntegration(integration);
    setDomain(integration?.host == null ? defaultDomains : integration?.host);
  }, [integration]);
  React.useEffect(() => {
    dispatch(setAttributes({ host: domain }));
  }, [domain]);

  return (
    <View style={{ marginHorizontal: 15 }}>
      {provider?.name === 'dynamics' && <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',marginBottom:20,width:'95%'}}>MyRepChat can send messages to contacts from Dynamics. To do this you will need to:
      {'\n\n'}
      1. Click the Connect button to go to the Dynamics CRM login page.
      {'\n\n'}
      2. Login to the Dynamics CRM login page.
      </Text>}
      <Input
        label={'Custom Domain'}
        value={domain}
        onChangeText={(t) => setDomain(t)}
        placeholder={sampleDomain}
        labelStyle={{ color: AppColor.PrimaryColor }}
        errorStyle={{ height: 0 }}
        inputStyle={{ fontSize: 15 }}
        containerStyle={{ flex: 1 }}
        inputContainerStyle={{ borderColor: AppColor.PrimaryColor }}
      />
      <CustomButton
        title={`Connect`}
        onPress={handleConnect}
        width={'20%'}
        style={{alignSelf:'flex-end'}}
      />
      <Text style={{fontFamily:AppFonts.Roboto_Regular, fontWeight:'400',marginTop:10,marginBottom:10}}>Return to this screen after connecting to change your integration's settings and test/save the connection.</Text>

      <IntegrationComponent
        provider={provider}
        integration={integration}
        allowContactEdits={allowContactEdits}
        allowLocation={allowLocation}
        allowAdvancedOptions={allowAdvancedOptions}
      />
      {loading && <Loader />}
    </View>
  );
}
