import React from "react";
import { ScrollView, Switch, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import { useSelector } from "react-redux";
import CustomButton from "../../Components/Button/index.web";
import useUpdateAffiliate from "../useUpdateAffiliate";
import SimpleBulletList from "../../Components/SimpleBulletList";


const PositiveConsent = () => {

    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const [data, setData] = React.useState({
        consentEnabled: affiliate?.consentEnabled || false,
        consentResponseRequired: affiliate?.consentResponseRequired || false,
        consentInboundAccepts: affiliate?.consentInboundAccepts || false,
        consentInboundAcceptsAPI: affiliate?.consentInboundAcceptsAPI || false,
        groupLevelConsent: affiliate?.groupLevelConsent || false,
        inboundTextTriggersConsent: affiliate?.inboundTextTriggersConsent || false,
        consentMsg: affiliate?.consentMsg || '',
    })


    const bulletPoints = [
        "${account.name} - the customer/business name of the account",
        "${account.contact} - the account contact name",
        "${account.email} - the email address of the account",
        "${account.address} - the address line of the account",
        "${account.city} - the city of the account",
        "${account.state} - the state of the account (two letter)",
        "${account.zip} - the zip code of the account",
        "${account.phone} - the MyRepChat number of the account",
        "${account.vcard.companyname} - the vCard Company Name. Falls back to ${account.name}",
        "${account.vcard.fullname} - the vCard Name field. Falls back to ${account.contact}",
        "${account.vcard.email} - the vCard Email field. Falls back to ${account.email}",
        "${account.consent.name} - allow the user to choose from name and DBA info from vCard or account."
    ];


    return (
        <ScrollView contentContainerStyle={{ flex: 1 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:10 }}>
                Optional: A positive consent message is a message that an advisor must send to any clients to enable communication.
                If consent response required is enabled, the recipient must reply to the message with the keyword ACCEPT
                in order for the MyRepChat subscriber to send any other messages. {'\n'}{'\n'}
                You can use the following place holders in your message
            </Text>

            <SimpleBulletList data={bulletPoints}  style={{paddingLeft:10, marginTop:20}}/>

            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 20 }}>
                When the message is sent, these place holders will be replaced with the values from the account record.
            </Text>

            <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Positive Consent</Text>
            <Switch
                onValueChange={e => { setData({ ...data, consentEnabled: e }) }}
                value={data.consentEnabled}
                style={{ marginTop: 10 }}
            />


            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Consent Response Required</Text>
            <Switch
                onValueChange={e => { setData({ ...data, consentResponseRequired: e }) }}
                value={data.consentResponseRequired}
                style={{ marginTop: 10 }}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Inbound Text Grants Consent</Text>
            <Switch
                onValueChange={e => { setData({ ...data, consentInboundAccepts: e }) }}
                value={data.consentInboundAccepts}
                style={{ marginTop: 10 }}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Inbound Text Triggers Consent</Text>
            <Switch
                onValueChange={e => { setData({ ...data, inboundTextTriggersConsent: e }) }}
                value={data.inboundTextTriggersConsent}
                style={{ marginTop: 10 }}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Inbound Text Grants Consent for API</Text>
            <Switch
                onValueChange={e => { setData({ ...data, consentInboundAcceptsAPI: e }) }}
                value={data.consentInboundAcceptsAPI}
                style={{ marginTop: 10 }}
            />


            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Group-Level Consent</Text>
            <Switch
                onValueChange={e => { setData({ ...data, groupLevelConsent: e }) }}
                value={data.groupLevelConsent}
                style={{ marginTop: 10 }}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Positive Consent Message</Text>
            <CustomInput
                multiline
                value={data.consentMsg}
                textInputStyle={{minHeight:150,maxHeight:300}}
                onChange={e => setData({ ...data, consentMsg: e.target.value })}
            />

            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end',marginTop:20,marginBottom:10,marginRight:10 }}
            />


        </ScrollView>
    )
}

export default PositiveConsent;