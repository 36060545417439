import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Animated,
  Modal,
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useSelector, useDispatch } from 'react-redux';
import { HStack } from 'native-base';
import { deleteSavedMessageThunk, loadSavedMessagesThunk } from '../../Redux/saved_messages';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import InfoBar from '../../Components/Layout/InfoBar';
import Clipboard from '@react-native-clipboard/clipboard';
import TemplateForm from './TemplateForm';
import { Bubbles } from '../Authentication/Login/Bubbles';
import Search from '../../Components/Search';
import { RemovePhoneModal } from '../Contacts/modals';
import useUndo from '../../Middleware/useUndo';
import RequestMaker from '../../Middleware/ApiCaller';
import Loader from '../../Components/Loader/index.web';
import useLogEvent from '../../Middleware/useLogEvent';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';
const Templates = ({ navigation }) => {
  const saved_messages = useSelector((state) => state.saved_messages).data;
  const [filteredData, setFilteredData] = React.useState(saved_messages);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [toDelete, setToDelete] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {isWebCompact} = useResponsiveLayout();

  const logEvent = useLogEvent();

  const undo = useUndo();
  
  React.useEffect(()=>{setFilteredData(saved_messages)},[saved_messages])

  const createTemplate = async () => {
    //only used for undo.  should consolidate this with the same create method used on TemplateForm here.
   
    const body =  {
      action: "create_saved_message",
      message: toDelete.message,
      name: toDelete.name
    }

    setLoading(true);

    const result = await RequestMaker(body);

    if(result.data.success){
      logEvent('template_created')
      dispatch(loadSavedMessagesThunk());
    }

    setLoading(false);
  }

  const dispatch = useDispatch();
  const handleDelete = (id) => {
    dispatch(deleteSavedMessageThunk(id));
    undo(createTemplate);
  };
  
  const [template, setTemplate] = React.useState();


  const renderListView = (item) => {
    const focused = item.id === template?.id;
    return (
      <TouchableOpacity
        onPress={() => setTemplate(item)}
        style={{
          width: '100%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginTop: 10,
          borderTopColor: '#116AB4',
          borderTopWidth: focused ? 1 : 0,
          borderBottomColor: '#116AB4',
          borderBottomWidth: focused ? 1 : 0,
          paddingHorizontal:7.5,
          backgroundColor: focused ? '#ECF6FF' : 'transparent'
        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon
            type={'antdesign'}
            name={'copy1'}
            color={AppColor.PrimaryColor}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 17,
              marginHorizontal: 10,
              color: 'black',
            }}
            numberOfLines={1}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              lineHeight: 20,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}
            numberOfLines={2}>
            {item.message}
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => {
              setRemoveModal(true)
              setToDelete(item)}
            }
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  };

  const handleSearch = query => {
    if(query === ''){
      setFilteredData(saved_messages);
    } else {
      setFilteredData(saved_messages.filter(e => e.message.includes(query) || e.name.includes(query)))
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Wrapper>
        { (!isWebCompact || !template) && <SideBar>
        <View
          style={{ flex: 1,}}>
          <Search actionButton actionButtonTitle='Template' onPressActionButton={() => setTemplate('create')} onChangeText={handleSearch}/>

            <ScrollView style={{flex:1,marginBottom:20}}>
          <FlatList
            data={filteredData}
            scrollEnabled={false}
            ListEmptyComponent={
              <Text
                style={{
                  textAlign: 'center',
                  marginTop: 20,
                  fontFamily: AppFonts.Roboto_Regular,
                }}>
                No templates found.
              </Text>
            }
            key={(item) => item.name}
            style={{}}
            renderItem={({ item }) => {
              return renderListView(item);
            }}
          />
          </ScrollView>
        </View>
        </SideBar>}
        {  (!isWebCompact || template) &&
          <Content>
          <Content.Row style={{height:'100%'}}>
            <View style={{flex:2,height:'100%'}}>
            {!template ? 
                <Bubbles style={{width:'140%'}}>
                  <Text style={{color:'#116AB4',fontSize:32,width:'70%'}}>
                    Select a template message to edit or create a new one.
                  </Text>
                </Bubbles>

                :
                <TemplateForm onClose={() => setTemplate(null)} item={template}/>
            }
            </View>
            { !isWebCompact &&

            <TemplateInfo/>
            }
          </Content.Row>
        </Content>}
      </Wrapper>
      {
        removeModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={removeModal} transparent={true}>
          <RemovePhoneModal
          title="Delete Template"
          message="Are you sure you'd like to permanently delete this template?"
            handleRemove={() => {
              handleDelete(toDelete.id);
              setRemoveModal(false);
            }}
            setRemoveModal={setRemoveModal}
          />
        </Modal>
      }
      {loading && <Loader/>}
    </SafeAreaView>
  );
};


const TemplateInfo = () => {
  return(
    <InfoBar>
        <View
          style={{
            flex: 1,
          }}>
          <Text style={{fontSize:18,marginVertical:10,textAlign:'center'}}>Template Guide</Text>
          <Text style={styles.notesStyles}>
          Templates allow you to quickly send a preset message to a client. You can create one by typing your message and using the dynamic fields available below. When you send a template message, the dynamic fields you used will get replaced by the actual details of the contact.          </Text>
          <TemplateQuickCopy
          value={'${contact.name}'}
          label='First and last name of contact'
          />
          <TemplateQuickCopy
          value={'${contact.firstname}'}
          label='First name of contact only'
          />
          <TemplateQuickCopy
          value={'${contact.lastname}'}
          label='Last name of contact only'
          />
          <TemplateQuickCopy
          value={'${contact.phone}'}
          label='Messaging number of contact'
          />
          <TemplateQuickCopy
          value={'${contact.address}'}
          label='Address line of contact'
          />
          <TemplateQuickCopy
          value={'${contact.city}'}
          label='City of contact'
          />
          <TemplateQuickCopy
          value={'${contact.state}'}
          label='State of the contact (two letter)'
          />
          <TemplateQuickCopy
          value={'${contact.zip}'}
          label='Zip code of the contact (two letter)'
          />
        </View>
    </InfoBar>
  )
}

const TemplateQuickCopy = props => {
  const [showCopied, setShowCopied] = React.useState(false);

  const handlePress = () => {
    Clipboard.setString(props.value);
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 1500);
  };

  const renderCopiedMessage = () => {
    if (showCopied) {
      return (
        <Animated.View
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 128, 0, 0.6)',
            paddingHorizontal: 5,
            paddingVertical: 2,
            borderRadius: 3,
          }}
        >
          <Text style={{ color: 'white', fontSize: 12 }}>Copied</Text>
        </Animated.View>
      );
    } else {
      return null;
    }
  };

  return (
    <View style={{ marginTop: 10, position: 'relative' }}>
      <Text style={{marginBottom:2}}>{props.label}</Text>
      <TouchableOpacity
        onPress={handlePress}
        style={{
          borderRadius: 5,
          backgroundColor: '#ECF6FF',
          width: '100%',
          paddingHorizontal: 10,
          paddingVertical: 7,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text>{props.value}</Text>
        <Icon name="copy-outline" type="ionicon" />
      </TouchableOpacity>
      {renderCopiedMessage()}
    </View>
  );
};


const styles = StyleSheet.create({
  notesStyles: {
    marginVertical:10,
    textAlign: 'left',
  },
});
export default Templates;
