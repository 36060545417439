import React from "react";
import { Text, View } from "react-native";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";

const NameAdministrator = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const [data, setData] = React.useState({
        name: affiliate?.name || '',
        admin: affiliate?.admin || '',
        adminEmail: affiliate?.adminEmail || ''
    })
    
    const updateAffiliate = useUpdateAffiliate();
    
    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Name</Text>
            <CustomInput titleStyle={{marginBottom:0}} value={data.name} onChangeText={e => setData({...data, name: e})} /> 
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20}}>Administrator</Text>
            <CustomInput value={data.admin} onChangeText={e => setData({...data, admin: e})}  /> 
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20}}>Administrator E-mail</Text>
            <CustomInput value={data.adminEmail} onChangeText={e => setData({...data, adminEmail: e})} /> 

            <CustomButton
            onPress={() => updateAffiliate(data)}
            width={'30%'} 
            title={'Save'} 
            style={{marginTop:'auto',alignSelf:'flex-end',marginBottom:10,marginRight:10}}
            />

        </View>
    )
}

export default NameAdministrator;