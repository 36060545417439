import axios from "axios";
import { MakeConfig } from "./MakeConfig";

export const getMessages = async ({member: member, offset: offset, limit: limit}) => {
    const body = {
        action: 'get_messages',
        member: member,
        offset: offset,
        limit: limit,
    };

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const sendTextMessage = async ({params: params}) => {

    const body = {
        action: 'send_text_message',
        type: params.type,
        text: params.text
    }

    if(params.mediaId) body.mediaId = params.mediaId;
    if(params.members) body.members = params.members;
    if(params.distributionLists) body.distributionLists = params.distributionLists;

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const initiateCall = async ({toNumber: toNumber}) => {
    const body = {
        action: 'initiate_call',
        toNumber: toNumber
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const getConsent = async ({member: member}) => {
    const body = {
        action: 'get_consent',
        member: member
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const sendConsent = async ({distributionList: distributionList, id: id}) => {
    const body = {
        action: 'send_consent'
    }
    if(distributionList) body.distributionList = distributionList;
    if(id) body.id = id;

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const getMember = async ({id: id}) => {
    const body = {
        action: 'get_member',
        id: id
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const getMemberPhoneNumbers = async ({member: member}) => {
    const body = {
        action: 'get_member_phone_numbers',
        member: member
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}