import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import SimpleBulletList from "../../Components/SimpleBulletList";
import getQuery from "../getQuery";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/index.web";
import ComponentAccordion from "../../Components/ComponentAccordion";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import SimpleDropdown from "../../Components/SimpleDropdown";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";

const ArchiveLayout = ({tenant}) => {
    const Tenant = tenant?.data?.tenant;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const queryBody = {
        action: "get_archive_layout"
    }
    if(Tenant){
        queryBody.tenantId = Tenant.id;
    } else {
        queryBody.affiliateId = affiliate.id;
    }

    const { refetch, data, isLoading, isError } = getQuery(queryBody)

    if (isLoading) return <Loader backgroundColor={'transparent'} />

    if (!data) return null;

    const bulletPoints = [
        'Title - included at the top or in a ‘subject’ field if one exists.',
        'Data to include - the type of data in addition to text to include.',
        'From/To - included in a from/to field if one exists (like email).',
        'Header - display at the top of each ‘rollup’ - message_per_contact will show a header at the top per contact.',
        'Footer - display at the bottom of each ‘rollup’ - message_per_contact will show a footer at the bottom per contact.',
        'Message - what to show for each message. MMS display is based on what is configured in layout MMS',
        'Metadata - Additional key/value pairs. Typically added as email headers.'
    ]

    const RenderComponent = ({item}) => {
        const [layout, setLayout] = React.useState({
            name: item?.name || '',
            includeCall: item?.includeCall || false,
            includeMms: item?.includeMms || false,
            includeRecording: item?.includeRecording || false,
            includeTranscript: item?.includeTranscript || false,
            layoutFormat: item?.layoutFormat || 'html',
            layoutRollup: item?.layoutRollup || 'message_per_contact',
            layoutMms: item?.layoutMms || 'mms_attached',
            title: item?.title || '',
            from: item?.from || '',
            to: item?.to || '',
            header: item?.header || '',
            message: item?.message || '',
            footer: item?.footer || '',
            metadata: item?.metadata || '',
        })

        const isPresetCheck = 
        item?.name === 'Default HTML Archive Layout' ||
        item?.name === 'Default Office365 Layout (HTML)' ||
        item?.name === 'Default Smarsh Layout (HTML)' ||
        item?.name === 'Default TXT Archive Layout'

        const [isPreset,setIsPreset] = React.useState(isPresetCheck);

        const handleSave = async () => {
            const body = {
                action: 'create_archive_layout',
                ...layout,
            }

            if(layout?.id)body.action='update_archive_layout';

            if(Tenant){
                body.tenantId = Tenant.id;
            } else {
                body.affiliateId = affiliate.id;
            }

            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
            }

        }

        const handleRemove = async () => {
            const body = {
                action: 'delete_archive_layout',
                id: item?.id
            }

            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
            }
        }

        return(
            <>
              <Text style={{fontFamily:AppFonts.Roboto_Bold}}>
                Name
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, name: text})}
                value={layout.name}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Data to Include
              </Text>
              <SimpleCheckbox onChange={val => setLayout({...layout, includeCall: val })} value={layout.includeCall} label={'Call'}/>
              <SimpleCheckbox 
                onChange={val => setLayout({...layout, includeMms: val })}
                label={'Media'}  
                value={layout.includeMms}
              />
              <SimpleCheckbox 
                onChange={val => setLayout({...layout, includeRecording: val })}
                value={layout.includeRecording}
              label={'Recording'}
              />
              <SimpleCheckbox 
              value={layout.includeTranscript}
              onChange={val => setLayout({...layout, includeTranscript: val })}
              label={'Transcript'}/>


              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Format
              </Text>
              <SimpleDropdown 
              onChange={val => setLayout({...layout, layoutFormat: val })}
              value={layout.layoutFormat}
              data={[
                {label: 'HTML', value: 'html'},
                {label: 'Text', value: 'text'}
                ]}  
              />


              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Rollup
              </Text>

              <SimpleDropdown 
              value={layout.layoutRollup}
            onChange={val => setLayout({...layout, layoutRollup: val })}
              data={[
                {label: 'Per Contact', value: 'message_per_contact'},
                {label: 'Per Period', value: 'message_per_period'}
                ]}  
              />

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
              MMS
            </Text>
            <SimpleDropdown
            value={layout.layoutMms}
            onChange={val => setLayout({...layout, layoutMms: val })}
                data={[
                    {label: 'Embedded', value: 'mms_embedded'},
                    {label: 'Attached', value: 'mms_attached'},
                    {label: 'Linked', value: 'mms_linked'},
                    {label: 'Metadata', value: 'mms_metadata'}
                    ]}
            />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Title
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, title: text})}
                value={layout.title}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                From
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, from: text})}
                value={layout.from}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                To
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, to: text})}
                value={layout.to}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Header
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, header: text})}
                value={layout.header}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Message
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, message: text})}
                value={layout.message}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Footer
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, footer: text})}
                value={layout.footer}
              />
              <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Metadata
              </Text>
              <CustomInput
                disabled={isPreset}
                onChangeText={text => setLayout({...layout, metadata: text})}
                value={layout.metadata}
              />
              <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-end',marginTop:10,gap:10}}>
                {
                isPreset ?
                <CustomButton title="Copy" onPress={() => {
                    setIsPreset(false);
                    setLayout({...layout, name: layout.name + ' Copy'})
                }} width={'20%'} />
                :
                <>
                {item?.id &&
                <CustomButton onPress={handleRemove} outline title='Remove' width={'20%'} />
                }
                <CustomButton onPress={handleSave} title='Save' width={'20%'} />
                </>

                }
              </View>
            </>
        )
    }

    return (
        <ScrollView style={{flex:1}}>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:10}}>
            For most users, the default layout provides all the details that they need in their archive. However, you can create your own layout and when you do, you will have complete control of the layout of your archive.
            {'\n'} {'\n'}
            A layout defines what the archive looks like, is html or text, defines how its rolled up (by contact or by period), and how MMS messages are handled.
            {'\n'} {'\n'}
            Text attributes - all substitution variables are allowed. HTML is allowed for HTML format.
        </Text>
        <SimpleBulletList style={{marginBottom:20}} data={bulletPoints}/>
        <ComponentAccordion
        data={data.layouts}
        renderComponent={item => <RenderComponent item={item}/>}
        renderListItem={item => [item.name]}
        columns={['Layout']}
        />

        </ScrollView>
    )
}

export default ArchiveLayout;