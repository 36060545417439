import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    data: [],
    blocked_contacts: [],
  },
  reducers: {
    setContacts: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
      };
    },
    setBlockedContacts: (state, action) => {
      return {
        ...state,
        blocked_contacts: action.payload.data,
      };
    },
  },
});

export const { setContacts, setBlockedContacts } = contactSlice.actions;

export const loadAllContactsThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_all_members',
        format: 'json',
        blocked: false,
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        //removing group contacts for now because it breaks the app in places we use contacts and we dont want to add to scope to fix everywhere it breaks stuff
        const filteredData = result.data.members.filter((contact) => !contact?.isGroup);
        dispatch(setContacts({ data: filteredData }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const loadAllBlockedContactsThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_all_members',
        format: 'json',
        blocked: true,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        //removing group contacts for now because it breaks the app in places we use contacts and we dont want to add to scope to fix everywhere it breaks stuff
        const filteredData = result.data.members.filter((contact) => !contact?.isGroup);

        dispatch(setBlockedContacts({ data: filteredData }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default contactSlice.reducer;
