import React from 'react';
import { ScrollView, SectionList, Text, View } from 'react-native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import BigList from 'react-native-big-list';

const NewAlphabetList = ({ data, RenderItem, ListHeaderComponent, sortBy, listViewProps }) => {
    let result = [];
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let sortedData = [...data]

    let sort;

    if (sortBy) {
        sort = sortBy.includes('-') ? sortBy.split('-')[0] : sortBy;

        if (sort === 'status') {
            sortedData.sort((a, b) => {
                // First priority: consented is true and stopped is false
                if (a.consented && !a.stopped && (!b.consented || b.stopped)) {
                    if(sortBy.includes('desc')){
                        return 1;
                    }  
                    return -1;
                }
                if (b.consented && !b.stopped && (!a.consented || a.stopped)) {
                    if(sortBy.includes('desc')){
                        return -1;
                    }
                  return 1;
                }
              
                // Second priority: consented is true and stopped is true
                if (a.consented && a.stopped && (!b.consented || !b.stopped)) {
                    if(sortBy.includes('desc')){
                        return 1;
                    }
                  return -1;
                }
                if (b.consented && b.stopped && (!a.consented || !a.stopped)) {
                    if(sortBy.includes('desc')){
                        return -1;
                    }
                  return 1;
                }
              
                // Third priority: consented is false and stopped is true
                if (!a.consented && a.stopped && b.consented && !b.stopped) {
                    if(sortBy.includes('desc')){
                        return -1;
                    }
                  return 1;
                }
                if (!b.consented && b.stopped && a.consented && !a.stopped) {
                    if(sortBy.includes('desc')){
                        return 1;
                    }
                  return -1;
                }
              
                // Last priority: consented is false and stopped is false
                // Here, we don't need to do anything as the default case covers it.
              
                return 0; // Default case, no change in order
              });
              
                                            
        } else if (sortBy.includes('desc')) {
            sortedData.sort((a, b) => !a[sort] - !b[sort] || b[sort].localeCompare(a[sort]));
        } else {
            sortedData.sort((a, b) => !a[sort] - !b[sort] || a[sort].localeCompare(b[sort]));
        }
    } else {
        sort = 'fname'
    }

    sortedData.forEach(e => {
        if(sort !== 'status'){

            let firstLetterOfName = e[sort].charAt(0).toUpperCase();

            if (!letters.includes(firstLetterOfName)) {
                firstLetterOfName = '*';
            }

            const indexOfTitle = result.findIndex(ee => ee[0]?.label === firstLetterOfName);

            if (indexOfTitle === -1) {
                result.push([{ label: firstLetterOfName, ...e }])
            } else {
                result[indexOfTitle].push({ ...e });
            }
        } else {
            const indexOfConsented = result.findIndex(ee => ee[0]?.label === '✓');
            const indexOfStopped = result.findIndex(ee => ee[0]?.label === '✕');
            const indexOfNeither = result.findIndex(ee => ee[0]?.label === '');

            if(e.consented && !e.stopped){
                if (indexOfConsented === -1) {
                    result.push([{ label: '✓', ...e }])
                } else {
                    result[indexOfConsented].push({ ...e });
                }
            } else if(e.stopped){
                if (indexOfStopped === -1) {
                    result.push([{ label: '✕', ...e }])
                } else {
                    result[indexOfStopped].push({ ...e });
                }
            } else {
                if (indexOfNeither === -1) {
                    result.push([{ label: '', ...e }])
                } else {
                    result[indexOfNeither].push({ ...e });
                }
            }
        }

    })

    return (
        <Content ListHeaderComponent={ListHeaderComponent} RenderItem={RenderItem} data={result} listViewProps={listViewProps} />
    )
}



const Content = ({ RenderItem, data, ListHeaderComponent, hideShortcuts, listViewProps  }) => {

    let sectionRefs = React.useRef(null);
    return (
        <View style={{ flex: 1, flexDirection: 'row', marginTop: 20 }}>
            <BigList
                ref={sectionRefs}
                style={{ flex: 1 }}
                // ListEmptyComponent={<Text>asd</Text>}
                // renderSectionHeader={(label) => <Text style={{color:'white', backgroundColor:AppColor.PrimaryColor, fontFamily: AppFonts.Roboto_Regular,maxWidth:50,maxHeight:20,textAlign:'center',justifyContent:'center',alignItems:'center'}}>{data[label][0].label}</Text>}
                // sectionHeaderHeight={20}
                hideHeaderOnEmpty={false}
                renderHeader={ListHeaderComponent}
                headerHeight={25}
                renderItem={RenderItem}
                itemHeight={70}
                sections={data}
                onEndReached={listViewProps?.onEndReached || null}
                onEndReachedThreshold={ listViewProps?.onEndReachedThreshold || null}
            />
            {!!data?.length &&
                <View style={{ alignItems: 'center', justifyContent: 'center', height: '100%', marginLeft: 10 }}>
                    {
                        data.map((e, i) => {
                            return (
                                <Text onPress={() => { sectionRefs.current.scrollToIndex({ index: 0, section: i, animated: true }) }} style={{ fontSize: 16, marginBottom: 5, fontWeight: '600', fontFamily: AppFonts.Roboto_Regular, margin: '0em 0em .2em 0em', padding: 0, color: 'black' }}>{e[0]?.label}</Text>
                            )
                        })
                    }
                </View>
            }
        </View>
    )

}


export default NewAlphabetList;
