import React from 'react';
import { ScrollView, View } from 'react-native';

const InfoBar = props => {
    return(
        <ScrollView style={{height:'100%',flex:1,backgroundColor:'white',padding:15,borderLeftColor:'#116AB4',borderLeftWidth:1,paddingBottom:100}}>
            {props.children}
        </ScrollView>
    )
}

export default InfoBar;