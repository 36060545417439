import React from "react";
import { Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CountryCodeSelector from "../../Components/CountryCodeSelector";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import { useToast } from "native-base";
import RequestMaker from "../../Middleware/ApiCaller";

const PhoneNumberConsent = ({tenant}) => {
    const Tenant = tenant.data.tenant;
    const Toast = useToast();


    const [data, setData] = React.useState({
        tenant: Tenant.id,
        contact: 0,
        isoCode: "US",
        longCode: '',
        action: "create_consent"
    })

    const handleSave = async () => {

        const result = await RequestMaker(data);

        if(result.data.success){
            Toast.show({
                title: 'Successfully granted consent to phone number(s)',
                status: 'success'
            })
        }
    }


    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>
                If you use positive consent in your group and someone replied to a consent message with a word other than the keyword ACCEPT but in a way that satisfies your consent, you can manually grant consent to that phone number here. 
                Just enter in the phone number below and click the Grant Consent button. 
                You can grant consent of multiple numbers by separating each 10-digit phone number with a comma or whitespace.
            </Text>
            <View style={{flexDirection:'row',alignItems:'center',gap:5}}>
                <CountryCodeSelector onChange={e => setData({...data, isoCode: e.isoCode})} value={data.isoCode} />
                <CustomInput maxLength={10} value={data.longCode} onChangeText={e => setData({...data, longCode: e})} containerStyle={{marginTop:0,flex:1}} placeholder="10-digit Number(s) Only or Comma Separted Values"/>
            </View>
            <CustomButton title="Grant Consent" onPress={handleSave} width={'25%'} style={{marginTop:20,alignSelf:'flex-end'}}/>
        </View>
    )
}

export default PhoneNumberConsent;