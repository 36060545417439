import React from 'react';
import { SafeAreaView, ScrollView, Text, View } from 'react-native';
import { MembersList } from './MembersList';
import CustomButton from '../../Components/Button';
import { useSelector } from 'react-redux';
import { createFullChatChannel, getChatUsers } from '../../Api/ten4';
import Header from '../../Components/Header';

const AddPrivateChannel = ({navigation, route}) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [allUsers, setAllUsers] = React.useState([]);
    const [addedUsers, setAddedUsers] = React.useState([]);


    React.useEffect(()=>{
        const getAvailableUsers = navigation.addListener('focus', () => {
            handleGetUsers();
        })

        return getAvailableUsers;

    },[navigation])
    
    const handleGetUsers = () => {
        getChatUsers({affiliate: affiliate.id})
        .then(result => {
            if(result.data.success){
                setAllUsers(result.data.users);
            }
        })
    }
    
    const handleSelectUser = id => {
        if(!addedUsers.includes(id)){
            setAddedUsers(users => [...users, id])
        } else {
            const copy = [...addedUsers];
            const index = copy.indexOf(id);
            if(index > -1){
                copy.splice(index, 1);
                setAddedUsers(copy);
            }
        }
    }

    const handleSave = () => {
        const params = {
            affiliate: affiliate.id,
            members: addedUsers,
        }

        createFullChatChannel({params: params})
        .then( result => {
            if(result.data.success){
                navigation.goBack();
            }
        })
    }

    return(
        <SafeAreaView style={{flex:1}}>
            <Header
                title='Add Private Channel'
                onPress={() => navigation.goBack()}
                back={true}
            />
            <ScrollView style={{flex:1}}>
                <View>
            <MembersList onPress={id => handleSelectUser(id)} selectedUsers={addedUsers} allUsers={allUsers} />
            <CustomButton title="Create" onPress={handleSave} style={{marginTop:20}}/>
            </View>
            </ScrollView>
            
        </SafeAreaView>
    )
}

export default AddPrivateChannel;