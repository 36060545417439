import React from 'react';
import { Text, TouchableOpacity, View, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';
import RequestMaker from '../../Middleware/ApiCaller';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import moment from 'moment';

const TermOfUse = ({ navigation }) => {
  const data = ['There are no recorded terms of use acceptance events for you'];
  const [tous, setTous] = React.useState([]);
  const [toua, setToua] = React.useState([]);

  React.useEffect(() => {
    getTermOfUSer();
  }, []);

  const getTermOfUSer = async () => {
    try {
      let body = {
        action: 'get_tous',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log(result.data);
      if (result.data.success) {
        console.log(result.data.toua)
        setToua(result.data.toua)
        setTous(result.data.tous);
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const getTouDate = id => {
    if(toua.length){
      const touaObject = toua.find(e => e.tou === id)
      
      if(touaObject){
        const timestamp = touaObject.acceptanceDate;
        const dateString = moment(timestamp).format("MM/DD/YYYY");
        return dateString;
      }
    }
    return '';
  }

  function openNewTab(url) {
    // Add "https://" to the beginning of the URL if it's not there already
    if (!url.startsWith("https://") && !url.startsWith("http://")) {
      url = "https://" + url;
    }
    // Open the URL in a new tab
    window.open(url, "_blank");
  }
  

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
          <View style={{ width: '100%', alignSelf: 'center', padding: 15 }}>
            {tous.length == 0
              ? data.map((x) => {
                  return (
                    <>
                      <Text
                        style={{
                          fontFamily: AppFonts.Roboto_Regular,
                          fontSize: 18,
                          lineHeight: 25,
                          marginHorizontal: 5,
                          color: AppColor.Dark,
                        }}>
                        {x}
                      </Text>
                    </>
                  );
                })
              : tous.map((x) => {
                  return (
                    <>
                      <Text
                      onPress={ () => openNewTab(x.text)}
                        style={{
                          fontFamily: AppFonts.Roboto_Regular,
                          fontSize: 18,
                          lineHeight: 25,
                          marginHorizontal: 5,
                          textDecorationLine:'underline',
                          cursor:'pointer',
                          color: AppColor.BLUE100,
                        }}>
                        {x.text}
                      </Text>
                      <Text
                        style={{
                          fontFamily: AppFonts.Roboto_Regular,
                          fontSize: 18,
                          lineHeight: 25,
                          marginHorizontal: 5,
                          color: AppColor.Dark,
                          marginBottom:20
                        }}>
                        Accepted { getTouDate(x.id) }
                      </Text>
                    </>
                  );
                })}                

          </View>
    </SafeAreaView>
  );
};
export default TermOfUse;
