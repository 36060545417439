import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Logins from "./Logins";
import Login from "./Login";


const Stack = createStackNavigator();

const LoginsNav = () => {

    return (
        <Stack.Navigator screenOptions={{headerShown:false}}  initialRouteName="Logins">
            <Stack.Screen name="Logins" component={Logins} />
            <Stack.Screen name="Login" component={Login} />
        </Stack.Navigator>
    );
}

export default LoginsNav;