import React, { useEffect, useState } from 'react';
import { View, StyleSheet,Text } from 'react-native';
import Alert from 'Alert';
import { useDispatch } from 'react-redux';
import AppFonts from '../../../../Constants/Fonts';
import IntegrationComponent from './IntegrationComponent';
import CustomButton from '../../../../Components/Button';
import CustomInput from '../../../../Components/CustomInput';
import Loader from '../../../../Components/Loader';

import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api/index';

import { setAttributes } from '../../../../Redux/integrationData';

export default function RedtailComponent({
  integration,
  provider,
  createIntegration,
  updateIntegration,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [guidtoken, setGuidToken] = useState(null);
  const [type, setType] = useState('create');

  useEffect(() => {
    if (integration?.token) {
      dispatch(setAttributes({ token: integration.token }));
      setGuidToken(integration?.token);
      setType('update')
    } else {
      setType('create')
      setGuidToken(null);
      setUserName('');
      setPassword('');
    }
  }, [integration]);

  const handleLogin = async () => {
    if (
      username == null ||
      username.trim() == '' ||
      password == null ||
      password.trim() == ''
    ) {
      Alert.alert('Error', 'You must enter your Redtail username/password.');
      return;
    }
    const body = {
      action: 'get_redtail_key',
      username: username,
      password: password,
    };
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        setGuidToken(result.data.token);
        dispatch(setAttributes({ token: result.data.token })); //set token attribute so it can be saved upstream
        if (type === 'update') {
          updateIntegration(result.data.token);
        } else if(type === 'create') {
          createIntegration(result.data.token);
        }
      } else {
        Alert.alert('Error', result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.content}>
      <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'flex-start',marginBottom:20,width:'95%'}}>
      MyRepChat can send messages to contacts from your Redtail account. Imported Redtail contacts will have notes added to them for all the messages you send/receive to these contacts through MyRepChat. Contacts will be imported by incoming phone calls (via phone number) or searching for name/phone in Messaging. Only 20 results can be imported at a time.
      </Text>

      {guidtoken ? (
        <CustomInput title="GUID token" value={guidtoken} disabled={true} />
      ) : (
        <>
          <CustomInput
            title="Username"
            value={username}
            onChangeText={(val) => setUserName(val)}
          />
          <CustomInput
            title="Password"
            value={password}
            onChangeText={(val) => setPassword(val)}
            secure={true}
          />
          <CustomButton
            title="connect"
            onPress={() => {
              handleLogin();
            }}
          />
        </>
      )}
      <IntegrationComponent
        provider={provider}
        integration={integration}
        allowContactEdits={true}
      />
      {loading && <Loader />}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    padding: 30,
  },
});
