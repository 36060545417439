const AppColor = {
  PrimaryColor: '#2569B4',
  SecondaryColor: '#ffffff',
  Dark: '#6F7070',
  danger: '#dc3545',
  BlackColor: '#000000',
  SuccessColor: '#28a745',
  Black200: '#404040',
  BLUE100: '#0000EE',
  BLUE200: '#53c4da',
  GREEN200: '#73d1ad',
  WHITE200: '#f0f8ff',
  WHITE400: '#F6F6F6',
  GREY100: '#a1a1a1',
  GREY200: '#e6e6e6',
};
export default AppColor;
