import React from "react";
import WebView from "react-native-webview";
import End_Points from "../../Constants/Api";
import { Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { View } from "react-native";
import { Icon } from "react-native-elements";
import Clipboard from "@react-native-clipboard/clipboard";
import { useToast } from "native-base";
const HTML = require('../../../assets/status.html');

const SystemStatus = () => {
    const Toast = useToast();
    return(
        <>
        {/* <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-end',gap:10,marginRight:15}}>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,padding:15}}>
            {
                End_Points.Base_url + 'dart/web/status.html'
            }
        </Text>
        <Icon name='copy' type='feather' onPress={() => {
            Clipboard.setString(End_Points.Base_url + 'dart/web/status.html');
            Toast.show({status:'success',title:'Copied to clipboard'});
        }}/>
        </View> */}
        <WebView containerStyle={{flex:1}} source={{html: HTML}}/>
        </>
    )
}

export default SystemStatus;