import React from "react";
import { Icon } from "react-native-elements"

const DrawerIcon = props => {
    return (
        <Icon
            name={props.iconName}
            type={props.iconType}
            size={20}
            color={props.focused ? 'white' : 'black'}
        />
    )
}

export default DrawerIcon;