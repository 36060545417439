import React from 'react';
import { ScrollView } from 'react-native';
import AppColor from '../../Constants/Color';
import NotificationStatus from './NotificationStatus';
import NotificationTest from './NotificationTest';
import MobileNotifications from './MobileNotifications';

const Notifications = ({ navigation }) => {
  

  return (
    <ScrollView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <NotificationTest/>
      <MobileNotifications/>
      <NotificationStatus/>
    </ScrollView>
  );
};
export default Notifications;
