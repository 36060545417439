import React from "react";
import { Modal, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import CountryCodeSelector from "../../Components/CountryCodeSelector";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import SimpleBulletList from "../../Components/SimpleBulletList";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";
import { useDispatch } from "react-redux";

const PhoneNumbers = ({ tenant }) => {
    const Tenant = tenant.data.tenant;
    const [releaseModal, setReleaseModal] = React.useState(false);
    const [releaseConsent, setReleaseConsent] = React.useState('');
    const [number, setNumber] = React.useState(Tenant.tw_number);
    const [voiceNumber, setVoiceNumber] = React.useState(Tenant.phone)
    React.useEffect(() => {
        
        setNumber(Tenant.tw_number)
        setVoiceNumber(Tenant.phone)
    }, [Tenant.tw_number, Tenant.phone])

    const Toast = useToast();
    const dispatch = useDispatch();

    const handleRelease = async () => {
        try{
        if(releaseConsent.toLowerCase() !== 'i understand'){
            return
        }
        const body = {
            "action": "release_number",
            "tenant": Tenant.id,
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                status: 'success',
                title: 'Number Released Successfully',
            })
            setReleaseModal(false)
            tenant.refetch();
            
        } else if(result.data.errorMessage){
            Toast.show({
                status: 'error',
                title: result.data.errorMessage,
            })
        }
        } catch(e){
            Toast.show({
                status: 'error',
                title: 'An error occurred while releasing the number',
            })
        }
            
    }

    const handleSave = async () => {
        try{
        const body = {
            action: 'update_number',
            tenant: Tenant.id,
            number: number
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                status: 'success',
                title: 'Number Updated Successfully',
            })
        } else if(result.data.errorMessage){
            Toast.show({
                status: 'error',
                title: result.data.errorMessage,
            })
        }

        tenant.refetch()
        } catch(e) {
            Toast.show({
                status: 'error',
                title: 'An error occurred while updating the number',
            })
        }
    }

    const handleSaveVoiceNumber = async () => {
        try{
        const body = {
            ...Tenant,
            action: 'update_tenant',
            tenant: Tenant.id,
            features: Tenant.features.map(e => e.id),
            phone: voiceNumber,
        }
        const result = await RequestMaker(body);
        if (result.data.success) {
            
            tenant.refetch();
        } else if(result.data.errorMessage){
            Toast.show({
                status: 'error',
                title: result.data.errorMessage,
            })
        }
        } catch(e){
            Toast.show({
                status: 'error',
                title: 'An error occurred while updating the voice number',
            })
        }
    }


    return (
        <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>MyRepChat Number</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>
                This is the number that the account will use for sending/receiving MyRepChat text messages.
                It is important that the number be in correct format (e.g. +16129998876).
                Beginning with a plus symbol, the country code, and the remaining digits for the number, no other characters.
            </Text>
            <CustomInput onChangeText={e => {
                if(!Tenant.tw_number){
                    setNumber(e)
                }
            }} 
            value={number} 
            />
            <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginTop: 10, alignItems: 'center', gap: 10 }}>
                <CustomButton width={'20%'} onPress={() => setReleaseModal(true)} title={'Release'} outline />
                <CustomButton width={'20%'} onPress={handleSave} title={'Save'} />
            </View>

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 20 }}>Voice Number</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 10 }}>
                The voice number that the account uses for voice calls.
            </Text>
            <View style={{flexDirection:'row',alignItems:'center',gap:5}}>
                <CountryCodeSelector value={Tenant.isoCountry}/>
                <CustomInput containerStyle={{marginTop:0}} value={voiceNumber} onChangeText={e => setVoiceNumber(e)} maxLength={10} />
            </View>
            <CustomButton onPress={handleSaveVoiceNumber} title={'Save'} width={'20%'} style={{alignSelf:'flex-end',marginTop:10}}/>
            { releaseModal &&
            <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={releaseModal} transparent={true}>
                <ModalWrapper>
                    <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Release Number</Text>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 16, textAlign: 'left', marginTop: 10 }}>You are about to release the number for this account. If you proceed:</Text>
                    <SimpleBulletList
                    style={{marginVertical:10}}
                    data={[
                        'Incoming text messages to this number will no longer be captured.',
                        'Incoming phone calls will be rejected.',
                        'The account users will not be able to send text messages.',
                        'The account users will no longer be able to use this number for voice.',
                        'The next time a user logs into this account they will be forced to setup a new number.',
                        'The number can be re-purchased for $50 for up to 10 days after its release upon request.'
                    ]}/>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 16, textAlign: 'left', marginVertial: 10 }}>This is an extremely disruptive action to the account. In order to proceed type "I understand" into the text box below.</Text>
                    <CustomInput onChangeText={e => setReleaseConsent(e)} placeholder='Type here...'/>
                    <View style={{flexDirection:'row',marginTop:10,alignItems:'row',gap:10,justifyContent:'flex-end'}}>
                        <CustomButton width={'20%'} outline title={'Cancel'} onPress={()=>setReleaseModal(false)}/>
                        <CustomButton width={'20%'} onPress={handleRelease} title={'Proceed'} />
                    </View>
                </ModalWrapper>
            </Modal>}
        </View>

    )
}

export default PhoneNumbers;