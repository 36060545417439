import React from 'react';
import { View } from 'react-native';

export const Bubbles = props => {
  return (
    <View style={{ height: '90%', flex: 1, ...props.style }}>
      <View style={{ backgroundColor: '#F9F9F9', width: '85%', height: '70%', left: '15%', position: 'absolute', borderTopLeftRadius: 150, borderBottomRightRadius: 150 }} />
      <View style={{ borderColor: 'white', borderWidth: 15, padding: 15, alignItems: 'center', justifyContent: 'center', backgroundColor: '#ECF6FF', height: '65%', width: '80%', top: '35%', position: 'absolute', borderTopLeftRadius: 150, borderTopRightRadius: 150, borderBottomRightRadius: 150 }}>
        {props.children}
      </View>

    </View>
  );
};
