import { Menu, Switch, useToast } from 'native-base';
import React from 'react';
import { useState } from 'react';
import { Icon, Input } from 'react-native-elements';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Alert from 'Alert';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomModal from '../../Components/DropDown';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import { useSelector } from 'react-redux';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { useDispatch } from 'react-redux';
import { loadAllAutoResponseThunk } from '../../Redux/auto_response';
import CustomInput from '../../Components/CustomInput';
import useLogEvent from '../../Middleware/useLogEvent';

const AutoResponseForm = props => {
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [request, setRequest] = React.useState(props?.data?.request ?? 'incomingSms');
  const [response, setResponse] = React.useState(props?.data?.response ?? 'replyWith');
  const [active, setActive] = useState(props?.data?.active ?? false);
  const [requestValue, setRequestValue] = React.useState(props?.data?.requestValue ?? '');
  const [responseValue, setResponseValue] = React.useState(props?.data?.responseValue ?? '');
  const [previousId, setPreviousId] = React.useState(props?.data?.previousId ?? null);
  const [id, setId] = React.useState(props?.data?.id ?? null);
  const [type, setType] = React.useState(props?.data?.id ? 'create' : 'update');
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const logEvent = useLogEvent();

  const [placeholderMessage, setPlaceholderMessage] = React.useState('sms');
  const requests = [
    {
      label: 'Incoming SMS',
      value: 'incomingSms',
    },
    {
      label: 'Incoming SMS from a new number',
      value: 'incomingSmsNumber',
    },
  ]

  if(!tenant?.voice){
    requests.push({
      label: 'Incoming call',
      value: 'incomingVoice',
    })
    requests.push({
      label: 'Incoming call from any number',
      value: 'incomingVoiceAny',
    })
  }

  const [res, setResponses] = React.useState([]);
  const Toast = useToast();

  React.useEffect(() => {
      if (props?.data?.id) {
        
        setPreviousId(props.data.previousId);
        setResponseValue(props.data.responseValue);
        setRequestValue(props.data.requestValue);
        setActive(props.data.active);
        setResponse(props.data.response);
        setRequest(props.data.request);
        setId(props.data.id);
        setType('update');
      } else {
        setPreviousId(null);
        setResponseValue('');
        setRequestValue('');
        setActive(false);
        setResponse('replyWith');
        setRequest('incomingSms');
        setId(null);
        setType('create');

      }
  }, [props?.data]);


  React.useEffect(() => {
    if (request == 'incomingSms' || request == 'incomingSmsNumber') {
      setResponses([
        {
          label: 'Reply with',
          value: 'replyWith',
        },
        {
          label: 'Send email To',
          value: 'email',
        },
      ]);
    } else {
      setResponses([
        {
          label: 'Forward to',
          value: 'forwardTo',
        },
        {
          label: 'Say message',
          value: 'sayMessage',
        },
      ]);
    }
  }, [request]);
  React.useEffect(() => {
    if (response == 'replyWith') {
      setPlaceholderMessage('Type message');
    } else if (response == 'email') {
      setPlaceholderMessage('Type email');
    } else if (response == 'forwardTo') {
      setPlaceholderMessage('Type phone number');
    } else if (response == 'sayMessage') {
      setPlaceholderMessage('Type message');
    }
  }, [response]);
  const handleSave = async () => {
    setLoading(true);
    try {
      let reqVal = requestValue;
      if ('incomingVoice' == requestValue) {
        // TODO: need to properly implement country code
        reqVal = { countryCode: 'US', phone: requestValue };
      }
      let body = {
        request,
        response,
        requestValue: reqVal,
        responseValue,
        active,
        ordinal: 0,
      };
      if (type == 'update') {
        body['action'] = 'update_workflow';
        body['id'] = id;
        body['previousId'] = previousId;
      } else {
        body['action'] = 'create_workflow';
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        if(type === 'create') {
          logEvent('auto_response_created');
        }

        dispatch(loadAllAutoResponseThunk());
        if (id == null) {
          Alert.alert('Created!', 'Your new auto response has been saved.');
        } else {
          Alert.alert('Updated', 'Your auto response has been updated.');
        }
        props.onSave();
      } else {
        Toast.show({status:'error',title:result?.data?.errorMessage || 'Something went wrong, please try again later.'})

      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const _handleChange = (e) => {
    e.target.style.height = 0
    e.target.style.height = `${e.target.scrollHeight}px`
  };


  return (
      <ScrollView style={{ flex: 1, padding: 30 }}>
        <Text
          style={{
            width: '100%',
            alignSelf: 'center',
            fontSize: 15,
            marginBottom:10
          }}>
          When An
        </Text>
        <Menu w={500} placement='bottom left'  trigger={triggerProps => {
      return( 
        <TouchableOpacity
        style={{backgroundColor:'#F6F6F6',alignItems:'center',justifyContent:'space-between',padding:10,paddingLeft:20,flexDirection:'row'}}
        onPress={e =>  triggerProps.onPress() }
        {...triggerProps}
        > 
         <Text>{
          requests.find(e => e.value === request).label
          }</Text> 
          <Icon color={AppColor.PrimaryColor} name='chevron-down' type='entypo' size={24}/>
        </TouchableOpacity>
      )
      }}>
        {
          requests.map(e => {
            return(
              <Menu.Item onPress={() => setRequest(e.value)}>
              <Text>{e.label}</Text>
            </Menu.Item>
            )
          })
        }
        </Menu>
        
        <View style={{ height: 20 }}></View>
        <View style={{ width: '100%', alignSelf: 'center',marginBottom:10 }}>
          <CustomInput
            value={requestValue}
            onChangeText={(t) => setRequestValue(t)}
            title={'Equals'}
            labelStyle={{
              fontFamily: AppFonts.Roboto_Bold,
              color: AppColor.PrimaryColor,
            }}
            placeholder={'Leave blank for all'}
          />
        </View>
        <Text
          style={{
            width: '100%',
            alignSelf: 'center',
            fontSize: 15,
            marginBottom:10
          }}>
          Then..
        </Text>
        <View style={{ width: '100%', alignSelf: 'center' }}>
        <Menu w={500} placement='bottom left'  trigger={triggerProps => {
      return( 
        <TouchableOpacity
        style={{backgroundColor:'#F6F6F6',alignItems:'center',justifyContent:'space-between',padding:10,paddingLeft:20,flexDirection:'row'}}
        onPress={e =>  triggerProps.onPress() }
        {...triggerProps}
        > 
         <Text>{
          res.find(e => e.value === response)?.label
          }</Text> 
          <Icon color={AppColor.PrimaryColor} name='chevron-down' type='entypo' size={24}/>
        </TouchableOpacity>
      )
      }}>
        {
          res.map(e => {
            return(
              <Menu.Item onPress={() => setResponse(e.value)}>
              <Text>{e.label}</Text>
            </Menu.Item>
            )
          })
        }
        </Menu>
          {/* <CustomModal
            place={'Select Here'}
            value={response}
            fun={(v) => setResponse(v)}
            data={res}
          /> */}
        </View>
        <View style={{ height: 20 }}></View>
        <View style={{ width: '100%', alignSelf: 'center' }}>
        <CustomInput
          textInputStyle={{maxHeight:400,minHeight:200}}
          value={responseValue}
          onChangeText={(t) => setResponseValue(t)}
          title={'Message*'}
          multiline={true}
          onChange={_handleChange}

          placeholder={`${placeholderMessage}`}

        />
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            marginTop: 20,
            alignItems: 'flex-start',
            alignSelf: 'center',
          }}>
          <Text
            style={{ marginRight: 10 }}>
            {active ? 'Enabled' : 'Disabled'}
          </Text>
          <Switch
            onToggle={() => setActive(!active)}
            isChecked={active}
            trackColor={{ false: 'red', true: 'green' }}
          />
        </View>
        <View style={{ flexDirection:'row',alignItems:'center',justifyContent: 'flex-end',columnGap:10,marginTop:20 }}>
        <CustomButton
          onPress={props.onCancel}
          outline
          title={'Cancel'}
          style={{ width: '50%' }}
        />
        <CustomButton
          onPress={() => handleSave()}
          title={'Save'}
          style={{ width: '50%' }}
        />
        </View>
      </ScrollView>
  );
};
export default AutoResponseForm;
