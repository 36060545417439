import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import DateTimePicker from 'react-datetime-picker';
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";
import moment from "moment";
import CustomButton from "../../Components/Button/index.web";
import CustomInput from "../../Components/CustomInput/index.web";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import ComponentAccordion from "../../Components/ComponentAccordion";

const AccountActivity = ({tenant}) => {
    const Tenant = tenant.data.tenant;

    const [date, setDate] = React.useState(new Date());
    const [reportType, setReportType] = React.useState('');
    
    function convertDateToRange(dateString) {
        const date = moment(dateString);
    
        const from = date.clone().startOf('day').valueOf();
    
        const to = date.clone().endOf('day').valueOf();
    
        return { from: from, to: to };
    }
    const todayToFrom = convertDateToRange(new Date());


    const {data, isLoading, isError, refetch} = getQuery({
        action: 'get_data_source',
        tenant: Tenant.id,
        ...todayToFrom
    })
    
    if(isLoading) return <Loader backgroundColor="transparent"/>
    if(!data) return null;

    const handleLoadByDate = () => {
        const {from,to} = convertDateToRange(date);

        refetch({
            action: 'get_data_source',
            tenant: Tenant.id,
            from,
            to
        })
    }

    const handleLoadByType = () => {
        refetch({
            action: 'get_data_source',
            tenant: Tenant.id,
            event: reportType
        })
    }


    const RenderComponent = ({item}) => {
        return(
            <Text>
                {item.detail}
            </Text>
        )
    }

    return(
        <ScrollView style={{flex:1}}>
            <Text style={{fontFamily: AppFonts.Roboto_Regular,marginBottom:10}}>
                View all recorded activity for this account. Choose the date you are interested in the activity for and click the load activity button.
            </Text>
            <View
            style={{
              width: '100%',
              zIndex: 9,
            }}>
            <DateTimePicker
              calendarType="US"
              onChange={setDate}
              value={date}
              calendarIcon={
                <Icon
                accessibilityRole='button'
                accessible={true}
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
          </View>
          <CustomButton onPress={handleLoadByDate} title="Load" width={'20%'} style={{alignSelf:'flex-end',marginTop:10}}/>
              
          <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20,fontSize:18}}>OR</Text>
          <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
          View activity for this account by the activity type. Type in the activity type (e.g. 'LOGIN') and click the load activity button. (partial names ok). This will only search for activities in the last 90 days.
          </Text>
          <CustomInput onChangeText={e => setReportType(e)} value={reportType} placeholder="Activity Type" containerStyle={{marginTop:10}}/>
          <CustomButton onPress={handleLoadByType} title="Load" width={'20%'} style={{alignSelf:'flex-end',marginTop:10}}/>

          <ComponentAccordion
            columns={['Event', 'Date']}
            data={data.data}
            renderListItem={item => [item.event, moment(item.date).format('MM/DD/YYYY')]}
            renderComponent={item => <RenderComponent item={item}/>}
          />

        </ScrollView>
    )
}

export default AccountActivity;