import React from 'react';
import {
  Text,
  View,
  Image,
  Platform,
  TouchableOpacity,
  Pressable,
  ScrollView,
  Picker
} from 'react-native';
import { useToast } from 'native-base';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Modal from 'modal-react-native-web';
import { Avatar, Badge, Button } from 'react-native-elements';
import { useSelector } from 'react-redux';
import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import Loader from '../../../Components/Loader';
import AntDesign from 'react-native-vector-icons/AntDesign';

import * as ImagePicker from 'expo-image-picker';

const Vcard = ({ navigation }) => {
  const [data, setData] = React.useState({});
  const [imageData, setImageData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [vCards, setVcards] = React.useState([]);
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const group = useSelector((state) => state.User)?.profile?.group;

  const Toast = useToast();
  const [inputFieldData, setInputFieldData] = React.useState({
    name: '',
    company: '',
    title: '',
    city: '',
    state: '',
    zip_code: '', 
    email: '',
    mobile: '',
    phone: '',
    note: '',
    address: '',
    status: '',
    image: ''
  });


  const loadVCard = async () => {
    try {
      const body = {
        action: 'get_vcards',
        format: 'json',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        console.log("\r\n\r\nCARD:",result.data,"\r\n\r\n");
        setData(result.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const saveVCardData = async (contents) => {
    
    setLoading(true);
    try {
      let body = {
        contents: contents,
      };

      if((vCards.length === 0 || (inputFieldData.status === 'Approved' && group?.vcardApproval)) && vCards.length < 2){
        body['action'] = 'create_vcard';
      } else if((inputFieldData.status === 'Pending' && group?.vcardApproval) || (vCards.length > 0 && !group?.vcardApproval) || vCards.length === 2 ){
        body['action'] = 'update_vcard';
        body['id'] = inputFieldData.id;
      }
      
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        if (body.id) {
          Toast.show({
            duration: 2000,
            title: 'Your V Card has been saved.',
            status: 'success',
          });
        } else {
          Toast.show({
            duration: 2000,
            title: 'Your V Card has been submitted for approval.',
            status: 'success',
          });
        }
        navigation.goBack();
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    
  };

  const permissionsMobiles = async () => {
    if (Platform.OS !== 'web') {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  };

  React.useEffect(() => {
    const loadCards = navigation.addListener('focus', () => {
      setLoading(true);
      loadVCard();
    })

    return loadCards
  }, [navigation])

  React.useEffect(()=>{
    setLoading(true);
    loadVCard();
  },[tenant?.name])

  React.useEffect(() => {
    if(data?.vcards?.length > 0){
      setVcards([]);
      data.vcards.forEach(e => {
        console.log("CARD:", e);
        if(!(e.status >= 3)){
          let card = renderVCard(e.contents);
          card.status = e.status === 1 ? 'Pending' : 'Approved';
          card.id = e.id;
          setVcards(prevData => [...prevData, card]);
        }
        
      })
    }
  }, [data]);


  React.useEffect(()=>{
    if(vCards?.length > 0){
      const acceptedCard = vCards.find(e => e.status === 'Approved')
      if(acceptedCard){
        setInputFieldData(acceptedCard)
      } else {
      setInputFieldData(vCards[0]);
      }
    }
  }, [vCards])

  const renderVCard = card => {
    let result = {};
    let contents = card;
    let toIdx = 0;
    let fromIdx = 0;
    while ((toIdx = contents.indexOf('\n', fromIdx)) !== -1) {
      let line = contents.substring(fromIdx, toIdx);
      fromIdx = toIdx + 1;
      let valueIndex = line.indexOf(':') + 1;
      if (line.startsWith('FN')) {
        result.name = line.substring(valueIndex)
      } else if (line.startsWith('TITLE')) {
        result.title = line.substring(valueIndex);
      } else if (line.startsWith('ORG')) {
        result.company = line.substring(valueIndex);
      } else if (line.startsWith('EMAIL')) {
        result.email = line.substring(valueIndex);
      } else if (line.startsWith('TEL')) {
        if (line.indexOf('CELL') > -1) {
          result.mobile = line.substring(valueIndex);
        } else {
          result.phone = line.substring(valueIndex);
        }
      } else if (line.startsWith('PHOTO')) {
        let vcardPhoto = line.substring(valueIndex);
        result.image = vcardPhoto;
      } else if (line.startsWith('ADR')) {
        let adrToIdx = 0;
        let adrFromIdx = 0;
        let adrIdx = 0;
        while ((adrToIdx = line.indexOf(';', adrFromIdx)) !== -1) {
          let adrPart = line.substring(adrFromIdx, adrToIdx);
          //Log.p("    " + adrPart);
          switch (adrIdx) {
            case 3:
              result.address = adrPart;
              break;
            case 4:
              result.city = adrPart
              break;
            case 5:
              result.state = adrPart
              break;
            case 6:
              result.zip_code = adrPart
              break;
          }
          adrFromIdx = adrToIdx + 1;
          ++adrIdx;
        }
      } else if (line.startsWith('NOTE')) {
        result.note = line.substring(valueIndex)
      }
    }
    return result;
  }

  const handleChangeText = (key, value) => {
    setInputFieldData((prevdata) => ({ ...prevdata, [key]: value }));
  };

  const saveVCard = () => {
    let contents = 'BEGIN:VCARD\n';
    contents += 'VERSION:3.0\n';
    contents += 'PRODID:-//ionlake//' + 'MyRepChat' + '//EN\n';
    contents += 'N:;' + inputFieldData.name + ';;;\n';
    contents += 'FN:' + inputFieldData.name + '\n';
    contents += 'TITLE:' + inputFieldData.title + '\n';
    if (imageData != null) {
      contents += 'PHOTO;ENCODING=b;TYPE=PNG:' + imageData + '\n';
    }
    contents += 'ORG:' + inputFieldData.company + '\n';
    contents +=
      'ADR;type=WORK:;;' +
      inputFieldData.address +
      ';' +
      inputFieldData.city +
      ';' +
      inputFieldData.state +
      ';' +
      inputFieldData.zip_code +
      ';\n';
    contents += 'EMAIL;type=INTERNET:' + inputFieldData.email + '\n';
    contents += 'TEL;type=WORK;type=VOICE:' + inputFieldData.phone + '\n';
    contents += 'TEL;type=CELL;type=VOICE:' + inputFieldData.mobile + '\n';
    contents += 'NOTE:' + inputFieldData.note + '\n';
    contents += 'REV:' + '2019-10-18T11:03Z' + '\n';
    contents += 'END:VCARD';
    saveVCardData(contents);
  };

  const handleImagePicker = async (type) => {
    setModalVisible(false);
    if (type == 'library') {
      let res = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
        quality: 0.7,
      });
      console.log(res);
      if (!res.cancelled) {
        setImageData(res.base64);
        setInputFieldData({...inputFieldData,image: res.base64})
      }
    } else {
      let res = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
        quality: 0.7,
      });
      console.log(res);
      if (!res.cancelled) {
        setImageData(res.base64);
        saveVCard(); 
      }
    }
  };

  const changeVcard = e => {
    const index = vCards.findIndex(ee => ee.id == e);
    console.log(index, e, vCards)
    if (index > -1){
      setInputFieldData(vCards[index]);
    }
  }

  const deleteVcardImage = () => {
    setInputFieldData(
      {
        ...inputFieldData,
        image: ''
      }
    );
    setImageData(null);
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        style={{
          flex: 1,
          width: '100%',
          alignSelf: 'center',
          paddingHorizontal: 30,
        }}>
        <Picker onValueChange={e => changeVcard(e)} selectedValue={inputFieldData.id} style={{height: 40,
    maxWidth: 300,
    borderRadius: 5,
    borderWidth: 1,
    width: '100%',
    alignSelf:'center',marginTop:20}}
>
          {
            vCards.map(e => {
              return(
                <Picker.Item label={`${e.name} (${e.status})`} value={e.id} />
              )
            })
          }
        </Picker>

        <TouchableOpacity
          accessibilityRole='button'
          accessibilityLabel='image'
          accessibilityHint='Add a vcard profile photo.'
          onPress={() => setModalVisible(true)}
          style={{
            width: widthPercentageToDP(80),
            marginTop: 16,
            alignSelf: 'center',
            alignItems: 'center',
          }}>
          {!inputFieldData.image ? (
            <>
            <img
              src={require('../../../Assets/Images/add.png')}
              alt="Add VCard Profile photo."
              style={{ width: 200, height: 150, objectFit: 'contain' }}
            />
            <Text>Click to add photo (optional)</Text>
            </>
          ) : (
            <View>
              <Avatar
                source={{ uri: 'data:image/*;base64,' + inputFieldData.image }}
                rounded
                size="xlarge"
              />
              <Badge
                status="success"
                value={'+'}
                containerStyle={{ position: 'absolute', top: 10, right: 10 }}
              />
              <TouchableOpacity onPress={deleteVcardImage} style={{left:'98%',position:'absolute',justifyContent:'center',borderRadius:100,alignItems:'center',padding:5,backgroundColor:'white'}}>
                <AntDesign name='delete' size={16} color='red' />
              </TouchableOpacity>
            </View>
          )}
        </TouchableOpacity>

        
        <CustomInput
          value={inputFieldData.name}
          onChangeText={(t) => {
            handleChangeText('name', t);
          }}
          placeholder={'Enter First and Last Name'}
          title={'First and Last Name'}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.company}
          placeholder={'Enter Branch'}
          title={'Company or Branch'}
          onChangeText={(t) => {
            handleChangeText('company', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.title}
          placeholder={'Enter Title'}
          title={'Title'}
          onChangeText={(t) => {
            handleChangeText('title', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.address}
          placeholder={'Enter Address'}
          title={'Address'}
          onChangeText={(t) => {
            handleChangeText('address', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.city}
          placeholder={'Enter City'}
          title={'City'}
          onChangeText={(t) => {
            handleChangeText('city', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.state}
          placeholder={'Enter State'}
          title={'State'}
          onChangeText={(t) => {
            handleChangeText('state', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.zip_code}
          placeholder={'Enter Zip'}
          title={'Zip Code'}
          onChangeText={(t) => {
            handleChangeText('zip_code', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.email}
          placeholder={'Enter Email'}
          title={'Email'}
          onChangeText={(t) => {
            handleChangeText('email', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.mobile}
          placeholder={'Enter Mobile Number'}
          title={'Mobile No'}
          onChangeText={(t) => {
            handleChangeText('mobile', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.phone}
          placeholder={'Enter Work Phone Number'}
          title={'Work Phone No'}
          onChangeText={(t) => {
            handleChangeText('phone', t);
          }}
        />
        <View style={{ height: 10 }} />
        <CustomInput
          value={inputFieldData.note}
          placeholder={'Note'}
          title={'Note'}
          onChangeText={(t) => {
            handleChangeText('note', t);
          }}
        />
      </ScrollView>
      <View style={{ height: 20 }} />
      <CustomButton title={'Save'} style={{alignSelf:'flex-end',marginRight:30}} width={'20%'} onPress={() => saveVCard()} />
      <View style={{ height: 20 }} />
      <Modal
        visible={modalVisible}
        transparent={true}
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        backdropOpacity={0.7}>
        <Pressable
          onPress={() => setModalVisible(false)}
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Pressable
            style={{
              width: '80%',
              maxWidth: 500,
              paddingHorizontal: 20,
              paddingVertical: 20,
              backgroundColor: 'white',
              borderWidth: 1,
              borderColor: AppColor.PrimaryColor,
              borderRadius: 10,
            }}>
            <Button
              accessible={true}
              accessibilityLabel='Choose from library'
              title="Choose from library"
              containerStyle={{ marginTop: 10 }}
              onPress={() => handleImagePicker('library')}
            />
          </Pressable>
        </Pressable>
      </Modal>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default Vcard;
