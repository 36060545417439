import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  Switch,
  SafeAreaView,
  Linking
} from 'react-native';
import CustomButton from '../../../../Components/Button';
import AppColor from '../../../../Constants/Color';
import AppFonts from '../../../../Constants/Fonts';
import IntegrationComponent from './IntegrationComponent';
import { useSelector, useDispatch } from 'react-redux';
import { setAttributes, setEnabled } from '../../../../Redux/integrationData';
import Loader from '../../../../Components/Loader';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api';
import { loadIntegrationsThuunk } from '../../../../Redux/integrations';
import CustomInput from '../../../../Components/CustomInput';
import { useToast } from 'native-base';
import getData from '../../../../AsyncUtils/GetData';

const Office365 = ({integration, provider, groupId}) => {
    const dispatch = useDispatch();
    const Toast = useToast();
    const [isActive, setIsActive] = useState(integration?.enabled);
    const [email, setEmail] = useState('');
    const [folderName, setFolderName] = useState('');
    const [isForlderHidden, setIsFolderHidden] = useState(false);
    const [loading, setLoading] = useState(false);
    const tenant = useSelector((state) => state.User)?.profile?.tenant;
    console.log(integration, provider)

    useEffect(() => {
        if (integration) {
          console.log(integration);
          setIsActive(integration.enabled);
          setEmail(integration?.defaultUserEmail)
          setIsFolderHidden(integration?.isForlderHidden)
          setFolderName(integration?.folderName)
        }
      }, [integration]);
    

  const handleSave = async () => {
    let body = {
      action: 'create_integration',
      defaultUserEmail: email,
      provider: 'officearchive',
      folderName: folderName,
      isForlderHidden: isForlderHidden,
      active: isActive,
      type: 'archive',
      tenant: tenant?.id,
    };
    if (integration?.id) {
      body.action = 'update_integration';
      body.id = integration.id;
    }
    if(groupId) body.group = groupId;
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'This email address has been Saved',
          status: 'success',
        });
        if (body.action === 'create_integration') await createSchedule();
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({ duration: 2000, title: err.message, status: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const createSchedule = async () => {
    const body = {
      action: 'create_archive_schedule',
      frequencyHours: 24,
      layoutId: 2,
      locked: false,
      provider: 'officearchive',
      startTimestamp: new Date().setHours(0, 0, 0),
      state: 'active',
      tenantId: tenant?.id,
    };
    if(groupId) body.affiliateId = groupId;
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        dispatch(loadIntegrationsThuunk());
        Alert.alert(
          'A default schedule for your archive has been created using a default layout. To modify your archive configuration, look at Archive Layout and Archive Schedules. More information is available in our support section.'
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const connect = async () => {
      const body = {
        action: "get_oauth_connect",
        page: "index.html",
        provider: "officearchive"
      }

    setLoading(true);
    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
    };
    let result = await RequestMaker(Config);

    if (result.data.success) {
        Linking.openURL(result.data.url);
    }
            

    }

    
    return(
        <SafeAreaView style={{flex:1}}>
            <View style={{flex:1,maxWidth:'80%',alignSelf:'center'}}>
            <Text style={{color: AppColor.Black200,fontFamily: AppFonts.Roboto_Regular,fontSize: 20}}>
                MyRepChat can send your data to Microsoft Office 365 for archival. To get started, click the Connect button below. This will redirect you to Office 365 login page and ask you to log in there and ask to allow MyRepChat to access users, mailboxes, and offline access so MyRepChat can create a mailbox and add messages to that mailbox after hours.
            </Text>

            <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 10,
        }}>
        <Text
          style={{
            marginRight: 5,
            fontSize: 18,
            color: AppColor.Black200,
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          Enable
        </Text>
        <Switch
          value={isActive}
          trackColor={{ false: 'red', true: 'green' }}
          onValueChange={(v) => {
            setIsActive(e => {return !e})
          }}
        />
      </View>

      <CustomInput
            title="FOLDER NAME"
            value={folderName}
            onChangeText={(val) => setFolderName(val)}
            style={{marginTop:10}}
          />

<View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 10,
        }}>
        <Text
          style={{
            marginRight: 5,
            fontSize: 18,
            color: AppColor.Black200,
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          HIDE FOLDER
        </Text>
        <Switch
          value={isForlderHidden}
          trackColor={{ false: 'red', true: 'green' }}
          onValueChange={(v) => {
            setIsFolderHidden(e => {return !e})
          }}
        />
      </View>

      <Text style={{color: AppColor.Black200,fontFamily: AppFonts.Roboto_Regular,fontSize: 20,marginBottom:10}}>
      Enter the folder name to place archive messages. MyRepChat will create this folder if it doesn't exist. If 'Hide Folder' is selected, the folder will be created hidden. Once created, the folder cannot be hidden/unhidden unless it is deleted and recreated.
      </Text>
        
      <CustomInput
            title="DEFAULT USER EMAIL (OPTIONAL)"
            value={email}
            onChangeText={(val) => setEmail(val)}
            style={{marginTop:10}}
          />
                <Text style={{color: AppColor.Black200,fontFamily: AppFonts.Roboto_Regular,fontSize: 20}}>

If no user is found that matches the account email, the archive data will be added to the 'Default User'.{'\n'}

After connecting Office 365, add an archive schedule to specify when archives are sent and to identify the layout of the messages.
          </Text>



            <CustomButton onPress={connect} title="Connect" style={{marginTop:20,marginBottom:20}} />
            <CustomButton onPress={handleSave} title="Save" />

            {
                loading && <Loader />
            }
            </View>
        </SafeAreaView>
    )
}

export default Office365;