import React from "react";
import { Modal, Text, View } from "react-native";
import {Content, SideBar, Wrapper } from "../../Components/Layout";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import { TitleCard } from "../../Screens/Dashboard/TitleCard";
import BoxButton from "../../Components/BoxButton";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import DateTimePicker from 'react-datetime-picker';
import AppColor from "../../Constants/Color";
import AppFonts from "../../Constants/Fonts";
import { Icon } from "react-native-elements";
import CustomButton from "../../Components/Button/index.web";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import End_Points from "../../Constants/Api";

const Billing = () => {
    const {data, isLoading} = getQuery({action: 'get_billing_dashboard'});
    const [billingAuditModal, setBillingAuditModal] = React.useState(false);

    const [billingAudit, setBillingAudit] = React.useState({
        from: new Date(),
        to: new Date(),
        failuresOnly: false
    });

    const [billingRebateModal, setBillingRebateModal] = React.useState(false);
    const [billingRebate, setBillingRebate] = React.useState({
        from: new Date(),
        to: new Date(),
    });

    const [autoEmailModal, setAutoEmailModal] = React.useState(false);
    const [autoEmail, setAutoEmail] = React.useState({
        from: new Date(),
        to: new Date(),
    });

    const [monthlyCountsModal, setMonthlyCountsModal] = React.useState(false);
    const [monthlyCounts, setMonthlyCounts] = React.useState({
        from: new Date(),
        to: new Date(),
    });

    if(isLoading) return <Loader backgroundColor='transparent'/>
    if(!data) return null;

    let numAccounts = 0;
    if(data.groups?.length){
        data.groups.forEach(e => {
            if(e.accounts?.length) numAccounts = numAccounts + e.accounts.length
        })

        numAccounts = numAccounts + data.accounts.length;
    }

    let numGroups = data?.groups?.length ?? 0;

    const total = Intl.NumberFormat("en-US").format(data.grandTotal.toFixed(2));

    const handleRunBillingAuditReport = async () => {
        const token = localStorage.getItem('token').replace(/"/g, '');
        const from = billingAudit.from.getTime();
        const to = billingAudit.to.getTime();
        window.open(End_Points.Base_url + "/dart/core?action=run_billing_audit&from=" + from + "&to=" + to + "&format=csv&failures=" + billingAudit.failuresOnly + `&token=${token}`);
    }

    const handleRunBillingRebateReport = () => {
        const token = localStorage.getItem('token').replace(/"/g, '');
        const from = billingRebate.from.getTime();
        const to = billingRebate.to.getTime();
        window.open(End_Points.Base_url + "/dart/core?action=run_billing_rebate&from=" + from + "&to=" + to + "&format=csv" + `&token=${token}`);
    }

    const handleRunAutoEmailReport = () => {        
        const token = localStorage.getItem('token').replace(/"/g, '');
        const from = autoEmail.from.getTime();
        const to = autoEmail.to.getTime();
        window.open(End_Points.Base_url + "/dart/core?action=run_autoemail_audit&from=" + from + "&to=" + to + "&format=csv" + `&token=${token}`);
    }

    const handleRunMonthlyCountsReport = () => {    
        const token = localStorage.getItem('token').replace(/"/g, '');
        const from = monthlyCounts.from.getTime();
        const to = monthlyCounts.to.getTime();
        window.open(End_Points.Base_url + "/dart/core?action=run_monthly_count_report&from=" + from + "&to=" + to + "&format=csv" + `&token=${token}`);
    }


    const getDashboardHandler = (resp) => {
        let count = 0;
        if (resp.success) {
            resp.groups.forEach(group => {
                if (group.accounts) {
                    group.accounts.forEach(account => {
                        if (account.reseller) {
                            count++;
                        }
                    });
                }
            });
            return count;
        } else {
            console.log("Error: " + resp.errorMessage);
        }
    };

    const resellerCount = getDashboardHandler(data);



    return(
        <Wrapper>
            <SideBar>
                <View style={{flex:1,padding:15}}>
                <TitleCard 
                    type='row'
                    title='Accounts'
                    style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                    subtitle={numAccounts}
                />
                <TitleCard 
                    type='row'
                    title='Groups'
                    style={{ borderTopLeftRadius:0,borderTopRightRadius:0,borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                    subtitle={numGroups}
                />
                <TitleCard 
                    type='row'
                    title='Resellers'
                    style={{ borderTopLeftRadius:0,borderTopRightRadius:0,borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                    subtitle={resellerCount}
                />

                <TitleCard 
                    type='row'
                    title='Grand Total'
                    style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    subtitle={`$${total}`}
                />

                </View>
            </SideBar>
            <Content>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 15,padding:15}}>

                    <BoxButton containerStyle={{padding:5}} onPress={() => setBillingAuditModal(true)} title={'Export Billing Audit Report'}  iconName={'export'} iconType={'antdesign'} />
                    <BoxButton containerStyle={{padding:5}} onPress={() => setBillingRebateModal(true)} title={'Export Billing Rebate Report'}  iconName={'export'} iconType={'antdesign'} />
                    <BoxButton containerStyle={{padding:5}} onPress={() => setAutoEmailModal(true)} title={'Export Auto Email Report'} iconName={'export'} iconType={'antdesign'} />
                    <BoxButton containerStyle={{padding:5}} onPress={() => setMonthlyCountsModal(true)} title={'Export Monthly Counts Report'} iconName={'export'} iconType={'antdesign'} />
                </View>

            </Content>

            {billingAuditModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={billingAuditModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 18, textAlign: 'center' }}>Billing Audit Report</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, textAlign: 'left', marginTop: 10 }}>Choose the date range to use for stats for billing audit report..</Text>
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                From
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setBillingAudit({ ...billingAudit, from: val })}
                                    value={billingAudit.from}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>                        
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                To
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setBillingAudit({ ...billingAudit, to: val })}
                                    value={billingAudit.to}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>
                        <SimpleCheckbox value={billingAudit.failuresOnly} onChange={val => setBillingAudit({ ...billingAudit, failuresOnly: val })} label={'Failures Only'} />

                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton onPress={() => setBillingAuditModal(false)} outline width={'30%'} title={'Cancel'} />
                            <CustomButton onPress={handleRunBillingAuditReport} width={'30%'} title={'Run'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }


            {billingRebateModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={billingRebateModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 18, textAlign: 'center' }}>Billing Rebate Report</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, textAlign: 'left', marginTop: 10 }}>Choose the date range to use for stats for billing rebate report.</Text>
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                From
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setBillingRebate({ ...billingRebate, from: val })}
                                    value={billingRebate.from}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>                        
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                To
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setBillingRebate({ ...billingRebate, to: val })}
                                    value={billingAudit.to}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton onPress={() => setBillingRebateModal(false)} outline width={'30%'} title={'Cancel'} />
                            <CustomButton onPress={handleRunBillingRebateReport} width={'30%'} title={'Run'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }

            {autoEmailModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={autoEmailModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 18, textAlign: 'center' }}>Auto Email Report</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, textAlign: 'left', marginTop: 10 }}>Choose the date range to use for auto email report.</Text>
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                From
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setAutoEmail({ ...autoEmail, from: val })}
                                    value={autoEmail.from}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>                        
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                To
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setAutoEmail({ ...autoEmail, to: val })}
                                    value={autoEmail.to}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton onPress={() => setAutoEmailModal(false)} outline width={'30%'} title={'Cancel'} />
                            <CustomButton onPress={handleRunAutoEmailReport} width={'30%'} title={'Run'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }

            {monthlyCountsModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={monthlyCountsModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 18, textAlign: 'center' }}>Monthly Counts Report</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, textAlign: 'left', marginTop: 10 }}>Choose the date range to use for monthly report..</Text>
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                From
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setMonthlyCounts({ ...monthlyCounts, from: val })}
                                    value={monthlyCounts.from}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>                        
                        <View>
                            <Text
                                style={{
                                    width: '100%',
                                    alignSelf: 'center',
                                    fontFamily: AppFonts.Roboto_Regular,
                                    marginVertical: 10,
                                }}>
                                To
                            </Text>
                        </View>
                        <View style={{ alignItems: 'center', zIndex: 9 }}>
                            <View
                                style={{
                                    width: '100%',
                                    zIndex: 9,
                                }}>
                                <DateTimePicker
                                    calendarType="US"
                                    onChange={val => setMonthlyCounts({ ...monthlyCounts, to: val })}
                                    value={monthlyCounts.to}
                                    calendarIcon={
                                        <Icon

                                            accessibilityRole='button'
                                            accessible={true}
                                            color={AppColor.PrimaryColor}
                                            name={'calendar'}
                                            type={'antdesign'}
                                        />
                                    }
                                    clearIcon={null}
                                    disableClock={true}
                                    format="MM/dd/y"
                                />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton onPress={() => setMonthlyCountsModal(false)} outline width={'30%'} title={'Cancel'} />
                            <CustomButton onPress={handleRunMonthlyCountsReport} width={'30%'} title={'Run'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }






        </Wrapper>
    )
}

export default Billing;