/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useEffect } from 'react';
import { StyleSheet, Image, StatusBar } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppColor from '../../Constants/Color';

const Splash = ({ navigation }) => {
  return (
    <>
      <StatusBar backgroundColor="white" />
      <SafeAreaView style={styles.conatiner}>
        <img
          alt='MyRepChat logo'
          src={require('../../Assets/Images/splashlogo.png')}
          style={{ width: 100, height: 100, resizeMode: 'contain' }}
        />
      </SafeAreaView>
    </>
  );
};

const styles = StyleSheet.create({
  conatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
  },
});

export default Splash;
