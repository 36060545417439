import React from "react";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";
import { Switch, Text } from "react-native";

const Intune = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    return(
    <>

        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            This controls whether this group is allowed to configure use of Intune for their entire group or individual tenants.
        </Text>
        <Switch style={{marginTop:10}} value={affiliate.intuneAllowed} onValueChange={() => updateAffiliate({intuneAllowed: !affiliate.intuneAllowed }) } />
    </>
    )
}

export default Intune;