import React from 'react';
import { Text, View } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import AudioPlayer from '../../Components/AudioPlayer';

export function MultiPreviews({ data }) {
  const images = [
    {
      url: data.url,
      props: {},
    },
  ];
  switch (data.type) {
    case 'image':
      return (
        <View style={{ height: 500 }}>
          <ImageViewer useNativeDriver={true} imageUrls={images} />
        </View>
      );
    case 'video':
      return (
        <View>
          <Text>video</Text>
        </View>
      );
    case 'audio':
      return (
        <View
          style={{
            height: 500,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <AudioPlayer url={data.url} />
        </View>
      );
    case '': {
      return null;
    }
    default:
      return (
        <View>
          <Text>default</Text>
        </View>
      );
  }
}
