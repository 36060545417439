import React from 'react';
import { DrawerContentScrollView, DrawerItemList, createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from '@react-navigation/stack';
import { Image, Modal, Pressable, StyleSheet, Text, View } from 'react-native';
import DrawerLabel from '../Components/Layout/DrawerLabel';
import DrawerIcon from '../Components/Layout/DrawerIcon';
import AppFonts from '../Constants/Fonts';
import WhiteMRCLogo from '../Assets/Images/mrc-logo-white.png';
import AccountsDropDownMenu from '../Drawer/AccountsDropDownMenu';
import Dashboard from './Dashboard';
import Settings from './Settings';
import Accounts from './Accounts';
import AccountDetails from './Accounts/index';
import Disclosures from './Disclosures';
import Monitoring from './Monitoring';
import RequestMaker from '../Middleware/ApiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfile, setProfile, setToken } from '../Redux';
import { RemovePhoneModal } from '../Screens/Contacts/modals';
import getQuery from './getQuery';
import GroupAccounts from './Reseller/GroupsAccounts';
import Loader from '../Components/Loader/index.web';
import ResellerDashboard from './Reseller/ResellerDashboard';
import ResellersNav from './SysAdmin/ResellersNav';
import LoginsNav from './SysAdmin/LoginsNav';
import './drawerStyles.css'
import Billing from './SysAdmin/Billing';
import { CommonActions } from '@react-navigation/native';

const Drawerscreen = createDrawerNavigator();

const Stack = createStackNavigator();

const GroupAdminDrawer = ({ navigation }) => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.User);
    const [logoutModal, setLogoutModal] = React.useState(false);

    const { data, isLoading, isError } = getQuery({
        action: 'authorize'
    })
    if (isLoading) return <Loader backgroundColor="transparent" />;
    if (!data) return null;

    const confirmLogout = async () => {
        setLogoutModal(false);
        const body = {
            action: 'logout'
        }
        let result = await RequestMaker(body);
        if (result.status == 200) {
            dispatch(
                setToken({
                  authenticated: false,
                  token: null,
                })
              );
            
              
        }

    }

    const accountType = profile?.user?.permissions[0]?.label

    return (
        <>
            <Drawerscreen.Navigator
                initialRouteName="Dashboard"
                drawerPosition="left"
                drawerType='permanent'
                overlayColor="transparent"

                drawerStyle={styles.drawerStyle}
                drawerContent={(props) => {
                    return (
                        <>
                            <View style={styles.logoHeader}>
                                <Image style={styles.logoHeaderImage} source={WhiteMRCLogo} />
                            </View>

                            <DrawerContentScrollView nativeID='hide-scrollbar' {...props}>
                                <DrawerItemList {...props} />
                            </DrawerContentScrollView>

                            <View style={{ marginTop: 'auto' }}>

                            </View>
                        </>
                    )
                }}
                screenOptions={{
                    headerShown: true,
                    headerLeft: () => null,
                    headerRight: () => <AccountsDropDownMenu handleLogout={() => setLogoutModal(true)} />,
                    headerTitleStyle: { color: 'white', fontWeight: 400, fontSize: 20, fontFamily: AppFonts.Roboto_Regular },
                    headerStyle: { height: 70, backgroundColor: '#2D6AB2', paddingLeft: 5, paddingRight: 20 }

                }}

                drawerContentOptions={{
                    activeTintColor: 'white',
                    activeBackgroundColor: '#3F3F3F',
                    labelStyle: {
                        color: 'white',
                        fontFamily: AppFonts.Roboto_Regular,
                    },
                    contentContainerStyle: {
                        paddingTop: 0,
                    },
                    itemStyle: styles.itemStyle,
                }}
            >
                {
                    accountType === 'System Administrator' &&
                    <>
                        <Drawerscreen.Screen
                            name="ResellerAccountsNav"
                            initialParams={{ reseller: data.reseller, sysadmin: true}}
                            component={ResellerAccountsNav}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Accounts'} />,
                                title: 'Accounts',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='ionicon' iconName='person' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="Resellers"
                            initialParams={{ reseller: data.reseller }}
                            component={ResellersNav}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Resellers'} />,
                                title: 'Resellers',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='font-awesome' iconName='building-o' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="Logins"
                            initialParams={{ reseller: data.reseller }}
                            component={LoginsNav}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Logins'} />,
                                title: 'Logins',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='ionicon' iconName='people' focused={focused} />
                            }}
                        />
                        {/* <Drawerscreen.Screen
                            name="HostedSMS"
                            initialParams={{ reseller: data.reseller }}
                            component={HostedSMSNav}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Hosted SMS'} />,
                                title: 'Hosted SMS',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='font-awesome' iconName='phone' focused={focused} />
                            }}
                        /> */}

                        <Drawerscreen.Screen
                            name="Billing"
                            initialParams={{ type: '' }}
                            component={Billing}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel label='Billing' focused={focused}/>,
                                title: 'Billing',
                                drawerIcon: ({ focused }) => <DrawerIcon focused={focused} iconType='antdesign' iconName='contacts' />
                            }}
                        />


                    </>
                }

                {
                    (accountType === 'Organization Administrator' || accountType === 'Reseller') &&
                    <>
                        <Drawerscreen.Screen
                            name="ResellerDashboard"
                            component={ResellerDashboard}
                            initialParams={{ reseller: data.reseller }}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Dashboard'} />,
                                title: 'Dashboard',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='MaterialIcons' iconName='dashboard' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="ResellerAccountsNav"
                            initialParams={{ reseller: data.reseller }}
                            component={ResellerAccountsNav}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Accounts'} />,
                                title: 'Accounts',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='ionicon' iconName='person' focused={focused} />
                            }}
                        />

                    </>
                }
                {
                    accountType === 'Group Administrator' &&
                    <>

                        <Drawerscreen.Screen
                            name="Dashboard"
                            component={Dashboard}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Dashboard'} />,
                                title: 'Dashboard',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='MaterialIcons' iconName='dashboard' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="Accounts"
                            component={AccountsNav}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Accounts'} />,
                                title: 'Accounts',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='ionicon' iconName='person' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="Disclosures"
                            component={Disclosures}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Disclosures'} />,
                                title: 'Disclosures',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='foundation' iconName='clipboard-pencil' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="Monitoring"
                            component={Monitoring}
                            options={{
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Monitoring (BETA)'} />,
                                title: 'Monitoring',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='antdesign' iconName='eye' focused={focused} />
                            }}
                        />
                        <Drawerscreen.Screen
                            name="Settings"
                            component={Settings}

                            options={{
                                unmountOnBlur: true,
                                drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Settings'} />,
                                title: 'Settings',
                                drawerIcon: ({ focused }) => <DrawerIcon iconType='ionicon' iconName='settings' focused={focused} />
                            }}
                        />

                    </>
                }

            </Drawerscreen.Navigator>
            {
                logoutModal &&
                <Modal style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }} visible={logoutModal} transparent={true}>
                    <RemovePhoneModal
                        title="Log Out"
                        continueLabel='Log Out'
                        message="Are you sure you want to log out?"
                        handleRemove={confirmLogout}
                        setRemoveModal={setLogoutModal}
                    />
                </Modal>
            }

        </>
    )
}



const ResellerAccountsNav = ({ route }) => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, initialRouteName: 'GroupAccounts' }}>
            <Stack.Screen
                name='GroupAccounts'
                initialParams={{ reseller: route.params.reseller}}
                component={GroupAccounts}
            />
            <Stack.Screen
                name='Settings'
                component={Settings}
            />
            <Stack.Screen
                name='AccountDetails'
                initialParams={{ reseller: route.params.reseller, sysadmin: route.params.sysadmin }}
                component={AccountDetails}
            />
            <Drawerscreen.Screen
                name="Dashboard"
                component={Dashboard}
                options={{
                    drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Dashboard'} />,
                    title: 'Dashboard',
                    drawerIcon: ({ focused }) => <DrawerIcon iconType='MaterialIcons' iconName='dashboard' focused={focused} />
                }}
            />
            <Drawerscreen.Screen
                name="Disclosures"
                component={Disclosures}
                options={{
                    drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Disclosures'} />,
                    title: 'Disclosures',
                    drawerIcon: ({ focused }) => <DrawerIcon iconType='foundation' iconName='clipboard-pencil' focused={focused} />
                }}
            />
            <Drawerscreen.Screen
                name="Monitoring"
                component={Monitoring}
                options={{
                    drawerLabel: ({ focused }) => <DrawerLabel focused={focused} label={'Monitoring (BETA)'} />,
                    title: 'Monitoring',
                    drawerIcon: ({ focused }) => <DrawerIcon iconType='antdesign' iconName='eye' focused={focused} />
                }}
            />
        </Stack.Navigator>
    )

}

const AccountsNav = () => {
    return (
        <Stack.Navigator screenOptions={{ headerShown: false, initialRouteName: 'AccountsList' }}>
            <Stack.Screen
                name='AccountsList'
                component={Accounts}
            />
            <Stack.Screen
                name='AccountDetails'
                component={AccountDetails}
            />


        </Stack.Navigator>
    )
}

const styles = StyleSheet.create({
    drawerStyle: {
        maxWidth: 250,
        maxHeight: '100vh',
        paddingTop: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: 0
    },
    itemStyle: {
        marginLeft: 0,
        width: '100%',
        borderColor: '#598527',
        marginVertical: 0,
        borderRadius: 0,
        paddingBottom: 0,
        paddingLeft: 18,
        alignItems: 'flex-start',
    },
    logoHeader: {
        backgroundColor: '#20508A',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        height: 70,
    },
    logoHeaderImage: {
        width: 200,
        height: 45,
        resizeMode: 'contain'
    },
    expandableMenuItem: {
        paddingLeft: '52%',
        paddingVertical: 12, 
        backgroundColor: 'white', 
        width: '260%', 
        marginLeft: '-72%'
    }
})



export default GroupAdminDrawer;