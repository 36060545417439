import { DrawerActions } from '@react-navigation/routers';
import { Card, Icon } from 'react-native-elements';

import React from 'react';
import { Text, TouchableOpacity, View, FlatList, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import { Bubbles } from '../Authentication/Login/Bubbles';
import { useSelector } from 'react-redux';
import WebView from 'react-native-webview';
import End_Points from '../../Constants/Api';
import useLogEvent from '../../Middleware/useLogEvent';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const Calculators = ({ navigation }) => {
  const [calculator, setCalculator] = React.useState(null)
  const token = useSelector((state) => state.User)?.Token;
  const [loading, setLoading] = React.useState(false);
  const logEvent = useLogEvent();
  const {isWebCompact} = useResponsiveLayout();

  const data = [
    {
      name: 'Annuity',
      iconName: 'dollar',
      type: 'font-awesome',
      url: 'calc/annuity.html',
    },
    {
      name: 'Billionaire',
      iconName: 'bank',
      type: 'font-awesome',

      url: 'calc/billionaire.html',
    },
    {
      name: 'College Savings',
      iconName: 'graduation-cap',
      type: 'entypo',
      url: 'calc/collegesavings.html',
    },
    {
      name: 'Inflation',
      iconName: 'line-graph',
      type: 'entypo',
      url: 'calc/inflation.html',
    },
    {
      name: 'Investment',
      iconName: 'area-graph',
      type: 'entypo',
      url: 'calc/investment.html',
    },
    {
      name: 'Millionaire',
      iconName: 'plane',
      type: 'font-awesome',
      url: 'calc/millionaire.html',
    },
    {
      name: 'Present Value',
      iconName: 'user',
      type: 'entypo',
      url: 'calc/presentvalue.html',
    },
    {
      name: 'Retirement',
      iconName: 'piechart',
      type: 'antdesign',
      url: 'calc/retirement.html',
    },
    {
      name: 'Savings',
      iconName: 'home-currency-usd',
      type: 'material-community',
      url: 'calc/savings.html',
    },
    {
      name: 'Years of Savings',
      iconName: 'calendar',
      type: 'entypo',
      url: 'calc/years.html',
    },
  ];
  const renderListView = (item) => {
    return (
      <Card
        style={{
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginBottom: 5,
        }}>
        <TouchableOpacity
        accessibilityRole='button'
          onPress={() => {
            logEvent('calculators_used');  
            setCalculator(item.url)
          }}
          style={{
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '100%',
          }}>
          <View
            style={{
              justifyContent: 'center',
              width: 50,
              alignItems: 'center',
            }}>
            <Icon
              type={item.type}
              name={item.iconName}
              color={AppColor.PrimaryColor}
            />
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 20,
                marginHorizontal: 10,
                color: 'black',
              }}>
              {item.name}
            </Text>
          </View>
        </TouchableOpacity>
      </Card>
    );
  };


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Wrapper>
        {  (!isWebCompact || !calculator) &&
      <SideBar>
      <ScrollView style={{ width: '97%', alignSelf: 'center' }}>
        <FlatList
          showsVerticalScrollIndicator={false}
          data={data}
          scrollEnabled={false}
          key={(item) => item.name}
          style={{}}
          renderItem={(item) => {
            return renderListView(item.item);
          }}
        />
      </ScrollView>
      </SideBar>}
      {( !isWebCompact || (isWebCompact && calculator)) &&
       <Content>
          {
            calculator ? 
            <WebView
            onLoadStart={(e) => {
              setLoading(true);
            }}
            onLoadEnd={(e) => {
              setLoading(false);
            }}
            source={{
              uri: End_Points.Base_url + calculator,
              headers: {
                'x-dart-token': token,
              },
            }}
            style={{ flex: 1 }}
          />
    
            :
            <Bubbles/>
          }
      {loading && <Loader />}

      </Content>}
      </Wrapper>
    </SafeAreaView>
  );
};
export default Calculators;
