import React from 'react';
import { Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';

const text =
  'Imported contacts will be uploaded to ionlake cloud servers to use within MyRepChat.' +
  ' You can learn more abouthow ionlake uses your information in' +
  'our privacy policy(https://wwww.ionlake.com/privacy-policy/)' +
  'By Clicking I Agree, you are agreeing to the ionlake privacy policy';

const Contacts = ({ navigation }) => {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Import Contacts'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <Text
        style={{
          marginVertical: 20,
          textAlign: 'center',
          color: AppColor.Dark,
          fontFamily: AppFonts.Roboto_Regular,
          lineHeight: 20,
          width: '90%',
          alignSelf: 'center',
          lineHeight: 20,
          fontSize: 16,
        }}>
        {text}
      </Text>
      <CustomButton
        title="I Agree"
        onPress={() => navigation.navigate('ContactFromPhone')}
        style={{ width: '50%' }}
      />
    </SafeAreaView>
  );
};
export default Contacts;
