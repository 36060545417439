import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Resellers from "./Resellers";
import Reseller from "./Reseller";


const Stack = createStackNavigator();

const ResellersNav = () => {

    return (
        <Stack.Navigator screenOptions={{headerShown:false}}  initialRouteName="Resellers">
            <Stack.Screen name="Resellers" component={Resellers} />
             <Stack.Screen name="Reseller" component={Reseller} /> 
        </Stack.Navigator>
    );
}

export default ResellersNav;