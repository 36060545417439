import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, TouchableOpacity, FlatList, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { useToast } from 'native-base';

import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { deleteDistribution, loadAllDistributionsThunk } from '../../Redux/distributions';
import Loader from '../../Components/Loader';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

const DistributionLists = ({ navigation }) => {
  const Toast = useToast();
  const contactData = useSelector((state) => state.distributions).data;
  const [search, setSearch] = React.useState('');
  const [allContactData, setAllContactData] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  React.useEffect(()=>{

    const subscribe = navigation.addListener('focus', () => {
      dispatch(loadAllDistributionsThunk())
    })

    return subscribe;
  },[navigation])

  React.useEffect(() => {
    if (search.trim().toLowerCase() !== '') {
      const searchedData = contactData.filter((x) =>
        x.text.toLowerCase().includes(search.trim().toLowerCase())
      );
      setAllContactData(searchedData);
    } else {
      setAllContactData(contactData);
    }
  }, [search]);

  React.useEffect(() => {
    setAllContactData(contactData);
    console.log(contactData);
  }, [contactData]);

  const handleDelete = (id) => {
    setLoading(true);
    dispatch(deleteDistribution(id)).then((x) => {
      setLoading(false);
      if (!x.error) {
        Toast.show({
          duration: 2000,
          title: 'Successfully deleted !!',
          status: 'success',
        });
      }
    });
  };

  const renderListView = ({ item }) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '95%',
          alignSelf: 'center',
        }}>
        <TouchableOpacity
        accessibilityRole='button'
          onPress={() =>
            navigation.navigate('DistributionDetail', { dist: item })
          }
          style={{
            flex: 1,
            flexDirection: 'row',
            height: 60,
            marginBottom: 10,
            alignItems: 'center',
          }}>
          <AntDesign name="tags" size={40} color={AppColor.PrimaryColor} />
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
              lineHeight: 60,
            }}>
            {item.text}
          </Text>
        </TouchableOpacity>
        <AntDesign
          accessible={true}
          accessibilityRole='button'
          accessibilityLabel='Trash'
          accessibilityHint='Delete distribution list.'
          onPress={() => handleDelete(item.id)}
          name="delete"
          size={25}
          color={AppColor.danger}
        />
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Distribution Lists'}
        back={true}
        onPress={() => navigation.goBack()}
        chatIcon={
          <MaterialIcons
          name="add"
          onPress={() => navigation.navigate('AddDL')}
          color='black'
          size={22}/>
        }
      />
      <Search onChangeText={(t) => setSearch(t)} />
      <FlatList
        data={allContactData}
        keyExtractor={(item, index) => item.id + '' + index}
        renderItem={renderListView}
      />
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default DistributionLists;
