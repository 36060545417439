import React from "react";
import SimpleDropdown from "../../../Components/SimpleDropdown";
import CountryCodeSelector from "../../../Components/CountryCodeSelector";
import { Text, View } from "react-native";
import AppFonts from "../../../Constants/Fonts";
import CustomInput from "../../../Components/CustomInput/index.web";

const MultiFactorAuthentication = ({user, setUser}) => {
    const mfaTypes = [
        {
            value: 'mobile',
            label: 'Mobile'
        },
        {
            value: 'email',
            label: 'Email'
        }
    ]

    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
        We require multi-factor authentication for all users. You can set up a user to either receive a text message with a verification code, or to use their login email address. Leave this set to mobile with a blank mobile number and the user will be promoted to choose how to handle this.
        </Text>

        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:10}}>MFA Type</Text>
        <SimpleDropdown
        style={{maxWidth:'100%'}}
        data={mfaTypes}
        onChange={val => setUser({...user, mfaType: val})}
        value={user.mfaType}
        />
        <View style={{flexDirection:"row",alignItems:'flex-end',gap:10,marginTop:10}}>
            <CountryCodeSelector 
            value={user.mobilecc}
            onChange={value => setUser({...user, mobilecc: value})}
            />
            <CustomInput value={user.mobile} onChangeText={e => setUser({...user, mobile: e})} placeholder={'Mobile Number'} />
        </View>
        </>
    )
}

export default MultiFactorAuthentication;