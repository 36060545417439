import React from "react";
import { View, Text, FlatList, TouchableOpacity, Modal, ScrollView } from "react-native";
import { Content, SideBar, Wrapper } from "../../Components/Layout";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import Search from "../../Components/Search";
import { Icon } from "react-native-elements";
import AppFonts from "../../Constants/Fonts";
import { useDispatch } from "react-redux";
import { setAffiliate } from "../../Redux";
import NewAlphabetList from "../../Screens/Contacts/NewAlphabetList";
import { formatNumber } from "../../Middleware/helpers";
import AppColor from "../../Constants/Color";
import ListHeader from "../../Components/AlphabetList/ListHeader";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { Menu, useToast } from "native-base";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import SimpleDropdown from "../../Components/SimpleDropdown";
import CSVImportModal from "../../Screens/Contacts/CSVImport";

const GroupAccounts = ({ route, navigation }) => {
    const Toast = useToast();
    const [groupModal, setGroupModal] = React.useState(false);
    const [createAccountModal, setCreateAccountModal] = React.useState(false);
    const [isCSVVisible, setIsCSVVisible] = React.useState(false);
    const [accountFilter, setAccountFilter] = React.useState('all');
    const [importData, setImportData] = React.useState(null);
    const [isGroupSelectVisible, setIsGroupSelectVisible] = React.useState(false);

    const { data, isLoading, isError, refetch: refetchGroups } = getQuery({
        action: 'get_affiliates',
        reseller: route.params?.reseller
    })

    const { data: tenants, isLoading: isLoadingTenants, isError: isErrorTenants, refetch } = getQuery({
        action: "get_tenants",
        reseller: route.params?.reseller,
        limit: 100,
        offset: 0
    })
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [toDelete, setToDelete] = React.useState(null);
    const [search, setSearch] = React.useState('');
    const [tenantSearch, setTenantSearch] = React.useState('');

    const dispatch = useDispatch();

    const [group, setGroup] = React.useState(null)
    const [newGroupData, setNewGroupData] = React.useState({
        name: '',
        admin: '',
        adminEmail: '',
        active: true,
    })
    const defaultNewAccountState = {
        name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        contact: '',
        email: '',
        isoCountry: '',
        timezone: '',
        status: 1,
        crd: '',
        affiliate: null
    }
    const [newAccountData, setNewAccountData] = React.useState(defaultNewAccountState)
    const [page, setPage] = React.useState(1);
    const [cachedTenants, setCachedTenants] = React.useState([]);

    React.useEffect(()=> {
        if(tenants?.tenants){
            setCachedTenants([...cachedTenants ,...tenants.tenants]);
        }
    },[tenants])

    const Countries = getQuery({ action: 'get_countries' });
    const Timezones = getQuery({ action: 'get_timezones' });
    const countryData = Countries.data?.countries
        ? Countries.data.countries.map(e => ({ label: e.label, value: e.isoCode }))
        : [];

    const timezoneData = Timezones.data?.timezones
        ? Timezones.data.timezones.map(e => ({ label: e.label, value: e.id }))
        : [];

        const ACCOUNT_STATES = [
            {
                value: 1,
                label: 'Active'
            },
            {
                value: 2,
                label: 'Inactive'
            },
            {
                value: 3,
                label: 'Review'
            },
            {
                value: 4,
                label: 'Trial'
            },
            {
                value: 5,
                label: 'Default'
            },
            {
                value: 90,
                label: 'Comp'
            }
        ]
    
    
    // if (isLoading) return <Loader backgroundColor="transparent" />
    if (!data) return null;


    const renderGroups = ({ item }) => {

        const selectedStyle = {
            backgroundColor: group?.id === item.id ? '#ECF6FF' : 'transparent',
            borderColor: group?.id === item.id ? '#116AB4' : 'transparent',
            borderTopWidth: group?.id === item.id ? 1 : 0,
            borderBottomWidth: group?.id === item.id ? 1 : 0,
        }

        const handleDelete = () => {
            setDeleteModal(true);
            setToDelete(item);
        }

        return (
            <TouchableOpacity onPress={() => {
                setGroup(item);
                setPage(1);
                setCachedTenants([]);
                refetch({
                    action: "get_tenants",
                    reseller: route.params?.reseller,
                    limit: 100,
                    offset: 0,
                    group: item.id
                })
            }} style={[{ flexDirection: 'row', padding: 10 }, selectedStyle]}>
                <Icon type="materialicons" size={26} name={'groups'} />
                <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginLeft: 5 }}>{item.name} </Text>
                <View style={{ marginLeft: 'auto', gap: 5, flexDirection: 'row' }}>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                        ({item.tenantTotal}/{item.tenantLimit})
                    </Text>
                    <Icon onPress={() => {
                        dispatch(setAffiliate(item))
                        navigation.navigate('Settings', {reseller: route.params.reseller, sysadmin: true, group: item.name})
                    }} type="materialicons" size={22} name={'edit'} />
                    <Icon onPress={handleDelete} type="feather" size={22} name={'trash'} color={'red'} />
                </View>
            </TouchableOpacity>
        )
    }

    const renderList = ({ item }) => {
        const STATUS_TEXT = {
            1: "Pending",
            2: "Approved",
            3: "Rejected",
            4: "Retired",
            90: "Deleted",
        }
        const ACCOUNT_STATUS = {
            1: "Active",
            2: "Inactive",
            3: "Review",
            4: "Trial",
            5: "Default",
            9: "Deleted",
            90: "Comp",
        }

        return (
            <TouchableOpacity onPress={() => {
                    navigation.navigate('AccountDetails', { tenant: item.id })
            }} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name='user' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} />
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.name}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.contact}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'center', fontFamily: AppFonts.Roboto_Regular, backgroundColor: AppColor.PrimaryColor, paddingVertical: 2, paddingHorizontal: 5, borderRadius: 5, width: 100, color: 'white' }}>{ACCOUNT_STATUS[item.status] || 'Unknown'}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{ STATUS_TEXT[item.vcardStatus] || 'No Vcards'}</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{formatNumber(item.tw_number) || 'No MRC Number'}</Text>
                </View>

            </TouchableOpacity>
        )
    }
    const handleConfirmDelete = async () => {
        const body = {
            action: 'delete_affiliate',
            group: toDelete.id,
        }
        try{
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                title: "Group deleted successfully",
                status: 'success',
            })
            setDeleteModal(false);
            refetchGroups();
        } else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: 'error',
            })
        }
        } catch(err){
            Toast.show({
                title: err.message,
                status: 'error',
            })
        }
    }

    const handleCreateGroup = async () => {
        try{
        const body = {
            name: newGroupData.name,
            admin: newGroupData.admin,
            adminEmail: newGroupData.adminEmail,
            active: newGroupData.active,
            reseller: route.params.reseller,
            action: "create_affiliate"
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                title: "Group created successfully",
                status: 'success',
            })
            setGroupModal(false);
            refetchGroups();
        }  else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: 'error',
            })
        }
        } catch(err){
            Toast.show({
                title: err.message,
                status: 'error',
            })
        }

    }

    const handleCreateAccount = async () => {
        if(!newAccountData.affiliate){
            Toast.show({
                title: 'Please select a group',
                status: 'error'
            })
            return;
        }
        const body = {
            action: 'create_tenant',
            features: [8],
            phone: '',
            reseller: route.params.reseller,
            ...newAccountData,
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                Toast.show({
                    title: 'Account created successfully',
                    status: 'success'
                })
                setNewAccountData(defaultNewAccountState);
                refetch();
                setCreateAccountModal(false);
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch (err) {
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }

    }

    const handleAccountFilter = val => {
        setAccountFilter(val);
        const body = {
            action: 'get_tenants',
            limit: 100,
            offset: 0,
        }

        if(group?.id && val !== 'all'){
            body.group = group.id;
        }
        if(route.params?.reseller){
            body.reseller = route.params.reseller;
        }
        if(val === 'all'){
            setGroup(null);
            setCachedTenants([]);
            setPage(1)
        }

        switch(val){
            case 'active': {
                body.tenantStatus = 1;
                break;
            }
            case 'inactive': {
                body.tenantStatus = 2;
                break;
            }
            case 'review': {
                body.tenantStatus = 3;
                break;
            }
            case 'trial': {
                body.tenantStatus = 4;
                break;
            }
            case 'default': {
                body.tenantStatus = 5;
                break;
            }
            case 'comp': {
                body.tenantStatus = 90;
                break;
            }
            case 'pending': {
                body.vcardStatus = 1;
                break;
            }
            case 'approved': {
                body.vcardStatus = 2;
                break;
            }
            case 'rejected': {
                body.vcardStatus = 3;
                break;
            }
            case 'retired': {
                body.vcardStatus = 4;
                break;
            }
        }


        refetch(body);
    }

    const handleFilterAccounts = () => {
        setPage(1);
        setCachedTenants([]);
        const body = {
            action: "get_tenants",
            reseller: route.params?.reseller,
            limit: 100,
            offset: 0,
            google: tenantSearch
        }
        if(group?.id){
            body.group = group.id;
        }

        refetch(body);
    }

    const clearSearch = () => {
        setPage(1);
        setCachedTenants([]);

        const body = {
            action: "get_tenants",
            reseller: route.params?.reseller,
            limit: 100,
            offset: 0
        }
        if(group?.id){
            body.group = group.id;
        }
        refetch(body)
    }

    const handleImportMembers = async (data) => {
        setImportData(data);
        setIsCSVVisible(false);
        setIsGroupSelectVisible(true);
    }

    const handleSubmitImport = async () => {
        const body = {
            map: importData.map,
            objects: importData.objects,
            group: String(importData.group),
            action: "import_tenants"
        }
        try{
        const result = await RequestMaker(body);
        if(new Object(result.data).hasOwnProperty('imported')){
            const numImported = result.data.imported;
            Toast.show({
                title: `${numImported} accounts imported successfully`,
                status: 'success'
            }) 
            setIsGroupSelectVisible(false);
            refetch();
        } else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: 'error'
            })
        }
        } catch(err){
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }
        setIsGroupSelectVisible(false);

    }

    return (
        <Wrapper>
            <SideBar>
                <Search
                    actionButton={true}
                    actionButtonTitle={'Group'}
                    onPressActionButton={() => setGroupModal(true)}
                    onChangeText={val => setSearch(val)}
                />
                <FlatList
                    data={data.affiliates.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))}
                    renderItem={renderGroups}

                />
            </SideBar>
            <Content>

            <View style={{ width: '100%' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Search onPressSearch={handleFilterAccounts} onClear={clearSearch} onSubmitEditing={handleFilterAccounts} containerStyle={{ flex: 1 }} onChangeText={val => setTenantSearch(val)} />
                        <Menu style={[{ maxHeight: 300, overflowY: 'scroll' }]} trigger={triggerProps => {
                            return (
                                <TouchableOpacity style={{ width: '20%' }} {...triggerProps}>
                                    <CustomButton textStyle={{textTransform: 'none'}} {...triggerProps} icon={
                                        <Icon name='add' type='MaterialIcon' size={18} color={'white'} />
                                    } title={'Account'} />
                                </TouchableOpacity>
                            )
                        }}>
                            <Menu.Item style={{ flexDirection: 'row', gap: 10, justifyContent: 'flex-start' }} onPress={() => setCreateAccountModal(true)}>
                                <Icon name='user' type='font-awesome' size={18} color={'black'} />
                                <Text>Create a new account</Text>
                            </Menu.Item>
                            <Menu.Item style={{ flexDirection: 'row', gap: 10, justifyContent: 'flex-start' }} onPress={() => setIsCSVVisible(true)}>
                                <Icon name='file-import' type='font-awesome-5' size={18} color={'black'} />
                                <Text>Import accounts from CSV</Text>
                            </Menu.Item>
                        </Menu>
                    </View>
                    <SimpleDropdown
                    style={{marginLeft:12}}
                    value={accountFilter}
                    onChange={handleAccountFilter}
                    data={[{
                        label: 'All Accounts',
                        value: 'all'
                    },
                    {
                        label: 'Active Accounts',
                        value: 'active'
                    },
                    {
                        label: 'Inactive Accounts',
                        value: 'inactive'
                    },
                    {
                        label: 'Review Accounts',
                        value: 'review'
                    },
                    {
                        label: 'Trial Accounts',
                        value: 'trial'
                    },
                    {
                        label: 'Default Accounts',
                        value: 'default'
                    },
                    {
                        label: 'Comp Accounts',
                        value: 'comp'
                    },
                    {
                        label: 'Pending vCards',
                        value: 'pending'
                    },
                    {
                        label: 'Approved vCards',
                        value: 'approved'
                    },
                    {
                        label: 'Rejected vCards',
                        value: 'rejected'
                    },
                    {
                        label: 'Retired vCards',
                        value: 'retired'
                    }
                    ]}
                    />

                </View>

                
                
                    <NewAlphabetList
                        listViewProps={
                            {
                                onEndReached: () => {
                                    const body = {
                                        action: "get_tenants",
                                        reseller: route.params?.reseller,
                                        limit: 100,
                                        offset: page * 100,
                                    }
                                    if(group?.id){
                                        body.group = group.id;
                                    }
                                    refetch(body)
                                    setPage(page => page + 1);

                                },
                                onEndReachedThreshold: 0.1
                            }
                        }
                        data={cachedTenants}
                        sortBy={'name'}
                        RenderItem={renderList}
                        ListHeaderComponent={() => <ListHeader sortBy={null} columns={['Account', 'Contact','Status','VCard', 'Phone Number']} />}
                    />
                
            </Content>
            {
                deleteModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={deleteModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Delete Group</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10, textAlign: 'left' }}>
                            You are about to permanently delete the group '{toDelete.name}' and all of the accounts under it.  This action cannot be undone.  Are you sure you want to continue?
                        </Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10, width: '100%', justifyContent: 'center', marginTop: 20 }}>
                            <CustomButton title={'Cancel'} outline onPress={() => setDeleteModal(false)} width={'30%'} />
                            <CustomButton title={'Continue'} onPress={handleConfirmDelete} width={'30%'} />

                        </View>

                    </ModalWrapper>
                </Modal>
            }

            {groupModal && 
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={groupModal} transparent={true}>
                <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                    <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Create Group</Text>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10, textAlign: 'left' }}>
                    A name for the group, as well as a name and email address for the individual who will administer the group is required. The group administrator will receive notifications for group activities. Note that this will not create logins for the group, logins and all other configuration are done by editing the group after creating it.
                    </Text>
                    <CustomInput 
                        titleStyle={{marginTop:20}}
                        title={'Name*'}
                        placeholder={'64 Characters Max.'}
                        value={newGroupData.name}
                        onChangeText={val => setNewGroupData({...newGroupData, name: val})}
                    />
                    <CustomInput 
                        titleStyle={{marginTop:10}}
                        title={'Administrator*'}
                        placeholder={'64 Characters Max.'}
                        value={newGroupData.admin}
                        onChangeText={val => setNewGroupData({...newGroupData, admin: val})}

                    />
                    <CustomInput 
                        titleStyle={{marginTop:10}}
                        title={'Administrator Email*'}
                        placeholder={'128 Characters Max.'}
                        value={newGroupData.adminEmail}
                        onChangeText={val => setNewGroupData({...newGroupData, adminEmail: val})}

                    />
                    <SimpleCheckbox
                    label={'Active'}
                    onChange={val => setNewGroupData({...newGroupData, active: val})}
                    value={newGroupData.active}
                    />

                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10, width: '100%', justifyContent: 'flex-end', marginTop: 20 }}>
                        <CustomButton title={'Cancel'} outline onPress={() => setGroupModal(false)} width={'30%'} />
                        <CustomButton title={'Continue'} onPress={handleCreateGroup} width={'30%'} />

                    </View>

                </ModalWrapper>
            </Modal>
            }
            {createAccountModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={createAccountModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <ScrollView contentContainerStyle={{ flex: 1 }}>
                            <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Create a new account</Text>
                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 10 }}>Please provide the basic account information. After the account is created you can access the rest of its settings by choosing it from the account list.</Text>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10 }}>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.name}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, name: e })}
                                        title={'Customer/Business Name'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.address1}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, address1: e })}

                                        title={'Address Line 1'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.address2}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, address2: e })}
                                        title={'Address Line 2'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        onChangeText={e => setNewAccountData({ ...newAccountData, city: e })}
                                        value={newAccountData.city}
                                        title={'City'} />
                                </View>

                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        onChangeText={e => setNewAccountData({ ...newAccountData, state: e })}
                                        value={newAccountData.state}
                                        title={'State/Region'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        onChangeText={e => setNewAccountData({ ...newAccountData, zip: e })}
                                        value={newAccountData.zip}
                                        title={'Zip/Postal Code'} />
                                </View>

                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.contact}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, contact: e })}
                                        title={'Contact Person'} />
                                </View>
                                <View style={{ width: '45%' }}>
                                    <CustomInput
                                        value={newAccountData.email}
                                        onChangeText={e => setNewAccountData({ ...newAccountData, email: e })}
                                        title={'Contact Person Email'} />
                                </View>
                            </View>
                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Country</Text>
                            <SimpleDropdown
                                value={newAccountData.isoCountry}
                                data={countryData}
                                onChange={e => setNewAccountData({ ...newAccountData, isoCountry: e })}
                            />
                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Timezone</Text>
                            <SimpleDropdown
                                value={newAccountData.timezone}
                                data={timezoneData}
                                onChange={e => setNewAccountData({ ...newAccountData, timezone: e })}
                            />

                            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Account State</Text>
                            <SimpleDropdown onChange={e => setNewAccountData({ ...newAccountData, status: e })} value={newAccountData.status} data={ACCOUNT_STATES} />
                            <CustomInput
                                value={newAccountData.cdr}
                                onChangeText={e => setNewAccountData({ ...newAccountData, cdr: e })}
                                title={'CRD Number'} titleStyle={{ marginTop: 10 }} />
                            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>Group</Text>
                            <SimpleDropdown 
                            value={newAccountData.affiliate}
                            data={data.affiliates.map(e => ({label: e.name, value: e.id}))}
                            onChange={e => setNewAccountData({...newAccountData, affiliate: e})}
                            />
                        </ScrollView>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                            <CustomButton
                                onPress={() => setCreateAccountModal(false)}
                                title={'Cancel'} outline width={'30%'} />
                            <CustomButton onPress={handleCreateAccount} title={'Save'} width={'30%'} />
                        </View>
                    </ModalWrapper>
                </Modal>
            }

{
            isCSVVisible && 
            <CSVImportModal
                customFields={
                    [
                        {
                          id: 'name',
                          label: 'Name',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'address1',
                          label: 'Address 1',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'address2',
                          label: 'Address 2',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'city',
                          label: 'City',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'state',
                          label: 'State',
                          defaults: ['all'],
                          hint: '128 max',
                        },
                        {
                          id: 'zip',
                          label: 'Zip Code',
                          defaults: ['all'],
                          hint: '64 max',
                        },
                        {
                          id: 'isoCountry',
                          label: 'Country',
                          defaults: ['all'],
                          hint: '32 max',
                        },
                        {
                          id: 'timezone',
                          label: 'Timezone',
                          defaults: ['all'],
                          hint: '16 max',
                        },
                        {
                          id: 'contact',
                          label: 'Contact',
                          defaults: ['all'],
                          hint: '32 max',
                        },
                        {
                          id: 'phone',
                          label: 'Phone',
                          defaults: ['all'],
                          hint: '24 max',
                        },
                        {
                          id: 'email',
                          label: 'E-Mail',
                          defaults: ['all'],
                          hint: '24 max',
                        },
                        {
                          id: 'mrcNumber',
                          label: 'MyRepChat Number',
                          defaults: ['all'],
                          hint: '128 max',
                        },
                        {
                          id: 'uuid',
                          label: 'Unique ID',
                          defaults: ['complete'],
                          hint: 'uuid',
                        },
                      ]
                }
                visible={isCSVVisible}
                setVisible={setIsCSVVisible}
                handleImportMembers={handleImportMembers}
            />

            }


            {
                isGroupSelectVisible &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={isGroupSelectVisible} transparent={true}>
                <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Select group for account(s)</Text>
                <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 10, textAlign: 'left' }}>
                Select the group you would like these accounts to be imported under.
                </Text>
                <SimpleDropdown 
                value={importData?.group || 'none'}
                data={[{label: 'None', value:'none'},...data.affiliates.map(e => ({label: e.name, value: e.id}))]}
                onChange={e => {
                    setImportData({...importData, group: e})
                }}
                />
                <CustomButton title={'Continue'} onPress={() => {
                    handleSubmitImport()
                }} width={'30%'} style={{alignSelf:'flex-end',marginTop:20}}
                />
                </ModalWrapper>
                </Modal>

            }

        </Wrapper>
    )
}

export default GroupAccounts