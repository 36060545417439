import React from "react";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";
import { Switch, Text } from "react-native";

const EnableIntune = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    return(
    <>

        <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:20 }}>
            Enable Intune for all users in your group.
        </Text>
        <Switch style={{marginTop:10}} value={affiliate.intuneEnabled} onValueChange={() => updateAffiliate({intuneEnabled: !affiliate.intuneEnabled }) } />
    </>
    )
}

export default EnableIntune;