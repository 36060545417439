import React from "react";
import { ScrollView, Switch, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import ComponentAccordion from "../../Components/ComponentAccordion";
import getQuery from "../getQuery";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { CheckBox } from "react-native-elements";
import { useToast } from "native-base";

const AddOnSetup = () => {
    const user = useSelector((state) => state.User.profile)?.user;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const {data,isLoading,isError,refetch} = getQuery({
        action: 'get_group_addons',
        affiliate: affiliate.id
    })
    const {data: billingData} = getQuery({
        affiliate: affiliate.id,
        action: "get_billing_addons"
    })

    const Toast = useToast();

    if(isLoading) return <Loader backgroundColor="transparent"/>;
    if(!data) return null;


    function findAddonByTypeId(typeId) {
        const addons = data.addons;
        const addon = addons.find(addon => addon.type === typeId);
        return addon ? addon : null;
    }
    
    const updateAddon = async (item, key) => {
        const TYPES_LABELS = {
            'account': 1,
            'archive': 2,
            'login': 3,
            'voice': 4,
            'record': 5,
            'transcribe': 6,
            'tendlc': 8,
            'groupsms': 9
        }
        const addon = findAddonByTypeId(item?.id);

        const body = {
            action: addon ? 'update_group_addon' : 'create_group_addon',
            available: addon?.available || key === 'available',
            enabled: addon?.enabled || key === 'enabled',
            mutable: addon?.mutable || key === 'mutable',
            centralized: addon?.centralized || key === 'centralized',
            type: TYPES_LABELS[key],
            affiliate: affiliate.id,
            id: addon?.id
        }
        body['type'] = TYPES_LABELS[item.type];

        if(addon) body[key] = !addon[key];

        try{
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch()
            } else if(result.data.errorMessage){
                Toast.show({
                    status:'error',
                    title: result.data.errorMessage
                })
            }
        } catch(err){
            Toast.show({
                status:'error',
                title: err.message
            })
        
        }

    }
    const isSysadmin = user?.permissions?.some(e => e.label === 'System Administrator');

    
    return (
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text
            style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:10,marginBottom:20}}>
                Add-ons are optional features that the accounts in your group can use to enhance their experience. You can manage what add-ons are available, if they are enabled by default, if the account user can enable or disable them, and if you want to have the group pay for the add-on, or if you want to let the account user pay for the add-on.
                {'\n'} {'\n'} 
                The account and additional account logins are controlled through the "Account" and "Login" add-on configurations.
                {'\n'} {'\n'}
                Note: By default, all accounts can see, enable and disable, and pay for add-ons themselves.
            </Text>
            <ComponentAccordion
            data={data.types.filter(e => !isSysadmin ? e?.id !== 9 : true)}
            renderListItem={item => {
                const billingAmount = billingData?.addons?.find(billing => billing?.type === item?.type)?.amount || billingData?.types?.find(billing => billing?.type === item?.type)?.initialAmount;
                const alwaysActive = item?.type === 'account' || item?.type === 'tendlc' || item?.type === 'login';                
                return [
            `${item.label} ${billingAmount ? `($${billingAmount})` : ''}`, 
            <CheckBox disabled={item?.type === 'account' || item?.type === 'tendlc'} style={{margin:0,padding:0}} onPress={() => updateAddon(item, 'available')} checked={ alwaysActive || findAddonByTypeId(item.id)?.available} />,
            <CheckBox disabled={item?.type === 'account' || item?.type === 'login' || item?.type === 'tendlc'} style={{margin:0,padding:0}} onPress={() => updateAddon(item, 'enabled')} checked={ alwaysActive || findAddonByTypeId(item.id)?.enabled}/>, 
            <CheckBox disabled={item?.type === 'account' || item?.type === 'login' || item?.type === 'tendlc'} style={{margin:0,padding:0}} onPress={() => updateAddon(item, 'mutable')} checked={alwaysActive || findAddonByTypeId(item.id)?.mutable}/>, 
            <CheckBox style={{margin:0,padding:0}} onPress={() => updateAddon(item, 'centralized')} checked={findAddonByTypeId(item.id)?.centralized}/> 
        ]}
    }

            columns={['Type', 'Available', 'On by Default','Can be changed', 'Group will pay for']}
            />
        </ScrollView>
    )
}
//            renderListItem={item => [item.label, findAddonByTypeId(item.id)?.available || false, findAddonByTypeId(item.id)?.mutable || false]}

export default AddOnSetup;