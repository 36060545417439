import { DrawerActions } from '@react-navigation/routers';
import { Container, Icon, Switch } from 'native-base';
import React from 'react';
import { useState } from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Keyboard,
  Dimensions,
} from 'react-native';
import Alert from 'Alert';

import { set } from 'react-native-reanimated';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomInput from '../../Components/CustomInput';
import CustomButton from '../../Components/Button';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import { useDispatch } from 'react-redux';
import { loadSavedMessagesThunk } from '../../Redux/saved_messages';
import { Content } from '../../Components/Layout';
import useLogEvent from '../../Middleware/useLogEvent';

const TemplateForm = (props) => {
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [id, setId] = React.useState(null);
  const [type, setType] = React.useState('create');
  const [loading, setLoading] = React.useState(false);
  const logEvent = useLogEvent();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props?.item && typeof props.item !== 'string') {
      const { item } = props;
      setName(item.name);
      setMessage(item.message);
      setId(item.id);
      setType('update');
    } else {
        setName('');
        setMessage('');
        setId(null);
        setType('create');
    }
  }, [props?.item]);

  const handleSave = async () => {
    Keyboard.dismiss();
    setLoading(true);
    try {
      let body = {
        name: name,
        message: message,
      };
      if (type == 'create') {
        body['action'] = 'create_saved_message';
      } else {
        body['action'] = 'update_saved_message';
        body['id'] = id;
      }
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        if(body.action === 'create_saved_message'){
          logEvent('template_created')
        }
        
        dispatch(loadSavedMessagesThunk());
        if (id == null) {
          alert('Created!\n' + 'Your new template has been saved.');
        } else {
          alert('Updated\n' + 'Your Template has been updated.');
        }
        props.onClose();
        // navigation.goBack();
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const _handleChange = (e) => {
    e.target.style.height = 0
    e.target.style.height = `${e.target.scrollHeight}px`
  };
  

  return (
      <View style={{ alignSelf: 'center',flex:1,height:'100%',width:'100%',padding:15,}}>
        <View style={{width:'100%'}}>
        <CustomInput
          value={name}
          onChangeText={(t) => setName(t)}
          title={'Name Template*'}
          placeholder={'Enter Name'}
        />
        <View style={{ height: 20 }}></View>
        <CustomInput
          textInputStyle={{maxHeight:600,minHeight:300}}
          value={message}
          onChange={_handleChange}
          onChangeText={(t) => setMessage(t)}
          title={'Message*'}
          placeholder={'Type Message here'}
          multiline={true}
        />
        <Content.Row style={{
          justifyContent: 'flex-end',
          gap:20,
          marginTop:20}}>
        <CustomButton
          onPress={() => props.onClose()}
          style={{ width: '45%' }}
          title={'Cancel'}
          outline
        />

        <CustomButton
          onPress={handleSave}
          title={'Save'}
          style={{ width: '45%' }}
        />
        </Content.Row>
        </View>
        {loading && <Loader />}

      </View>

  );
};
const styles = StyleSheet.create({
  notesStyles: {
    fontSize: 17,
    textAlign: 'center',
  },
});
export default TemplateForm;
