import React from "react";
import { Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import DateTimePicker from 'react-datetime-picker';
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";
import CustomButton from "../../Components/Button/index.web";
import moment from "moment";
import End_Points from "../../Constants/Api";

const RunReport = ({tenant}) => {
    const Tenant = tenant.data.tenant;

    const [fromDate, setFromDate] = React.useState(new Date());
    const [toDate, setToDate] = React.useState(new Date());

    const handleRunReport = () => {
        const start = moment(fromDate).valueOf();
        const end = moment(toDate).valueOf();
        
        window.open(End_Points.Base_url + "/dart/core?action=generate_report&from=" + start + "&to=" + end + "&format=" + 'text' + "&tenantUUID=" + Tenant.uuid + "&token=" + String(localStorage.getItem('token')), '_blank')
    }

    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>You can run reports for this account.</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10,marginBottom:5}}>Start Date</Text>
            <DateTimePicker
              calendarType="US"
              onChange={setFromDate}
              value={fromDate}
              calendarIcon={
                <Icon
                accessibilityRole='button'
                accessible={true}
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10,marginBottom:5}}>End Date</Text>

            <DateTimePicker
              calendarType="US"
              onChange={setToDate}
              value={toDate}
              calendarIcon={
                <Icon
                accessibilityRole='button'
                accessible={true}
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />

            <CustomButton onPress={handleRunReport} title="Run" width={'20%'} style={{alignSelf:'flex-end',marginTop:20}} />

        </View>
    )
}

export default RunReport;