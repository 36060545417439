import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const recentMessageSlice = createSlice({
  name: 'recent_message',
  initialState: {
    data: [],
    loading: false,
    activeChatId: null,
  },
  reducers: {
    setRecentMessage: (state, action) => {
      const { data } = action.payload;
      const { activeChatId } = state;

      // Map existing messages by a unique identifier (e.g., message ID)
      const existingMessagesMap = new Map(state.data.map(msg => [msg.id, msg]));

      data.forEach(newMsg => {
        if (existingMessagesMap.has(newMsg.id)) {
          // Update the existing message
          existingMessagesMap.set(newMsg.id, { ...existingMessagesMap.get(newMsg.id), ...newMsg });
        } else {
          // Add the new message
          existingMessagesMap.set(newMsg.id, newMsg);
        }
      });

      // Convert the map back to an array and maintain order if necessary
      state.data = Array.from(existingMessagesMap.values());

      // Update unread messages for the active chat
      if (activeChatId !== null) {
        state.data = state.data.map(chat => {
          if (chat.id === activeChatId) {
            return { ...chat, unreadmessages: 0 };
          }
          return chat;
        });
      }
    },
    clearUnreadMessagesByID: (state, action) => {
      const messageToClear = state.data.find(e => e.id === action.payload.id);

      if (messageToClear) {
        messageToClear.unreadmessages = 0;
      }
    },
    setActiveChat: (state, action) => {
      state.activeChatId = action.payload.id;
    },
  },
});

export const { setRecentMessage, clearUnreadMessagesByID, setActiveChat } = recentMessageSlice.actions;

export const loadRecentMessagesThunk = (limit = 100, offset = 0) => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_recent_member_message',
        days: 90,
        offset: offset,
        filter: "all",
        limit: limit,
      };

      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);

      const noDuplicateData = [];

      if (result.data.members.length) {
        result.data.members.forEach(e => {
          const index = noDuplicateData.findIndex(ee => ee.id === e.id);
          if (index === -1) {
            noDuplicateData.push(e);
          }
        });
      }

      dispatch(setRecentMessage({ data: noDuplicateData }));

    } catch (err) {
      console.log(err);
    }
  };
};

export default recentMessageSlice.reducer;
