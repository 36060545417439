import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import AppColor from "../Constants/Color";
import AppFonts from "../Constants/Fonts";

const BoxButton = ({onPress, key, containerStyle, iconName, iconType, count, title}) => {
    return (
        <TouchableOpacity
          accessibilityRole='button'
          activeOpacity={0.6}
          onPress={onPress}
          key={key}
          style={{
            backgroundColor: '#F9F9F9',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: 175,
            borderWidth: 1,
            borderColor: '#DEDCD9',
            paddingVertical: 30,
            borderRadius:10,
            cursor: onPress ? 'pointer' : 'default',
            ...containerStyle
          }}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 0,
            }}>
            <Icon
              size={32}
              color={AppColor.PrimaryColor}
              name={iconName}
              type={iconType}
            />
            <Text
              style={{
                textAlign: 'center',
                marginTop: 20,
                color: AppColor.PrimaryColor,
                fontFamily: AppFonts.Roboto_Regular,
              }}>
              {title}
            </Text>
          </View>
  { typeof count === 'number' &&  <View
            style={{
              position: 'absolute',
              top: 5,
              backgroundColor:'#FEC781',
              borderRadius:100,
              right: 5,
            }}>
            <Text
              style={{
                width: 30,
                height: 30,
                borderRadius: 15,
                justifyContent: 'center',
                alignItems: 'center',
                lineHeight: 30,
                color: AppColor.PrimaryColor,
                textAlign: 'center',
              }}>
              {count}
            </Text>
          </View>
  }      </TouchableOpacity>
      );
}

export default BoxButton;