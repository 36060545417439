import axios from 'axios';
import End_Points from '../../Constants/Api';
import getData from '../../AsyncUtils/GetData';

const RequestMaker = async (body) => {
  const { action } = body;

  try {
    if (action) {
      const device = await getData('@device_id');
      const headers = { 'x-device': device };

      const payload = {
        method: 'post',
        url: `${End_Points.Base_url}${End_Points.End}`,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      
      return await axios(payload);
    } else {
      return await axios(body);
    }
  } catch (error) {
    return {
      data: { success: false, errorMessage: error?.message || 'Server Error' },
    };
  }
};

export default RequestMaker;
