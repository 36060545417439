import React, { useState } from 'react';
import {
  ScrollView,
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  Text,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'modal-react-native-web';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import EndPoints from '../../Constants/Api';

import { providers, integrations_types } from '../IntegrationProvider';
import CustomButton from '../../Components/Button';
import { setCompletedStep } from '../../Redux/singupwizard';

const Crm = ({ navigation }) => {
  const [visible, setVisible] = useState(false);
  const crms = useSelector((state) => state.integrations).integrations;
  const integration_providers = useSelector(
    (state) => state.integrations.providers
  );
  const dispatch = useDispatch();
  const redirectCRMS = ['salesforce','salentica', 'capsule', 'dynamics', 'zoho', 'hubspot', 'infusionsoft', 'office', 'practifi', 'redtail','clientworks', 'junxure' ]
  const [warningModalVisible, setWarningModal ] = React.useState(false);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        onPress={() => navigation.goBack()}
        back={true}
        title={'Connect to CRM'}
      />
      <ScrollView>
        <View
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginTop: 10,
          }}>
          {Object.entries(providers)
            .filter(
              (x) =>
                x[1].type.name == integrations_types.CRM.name &&
                integration_providers.includes(x[1].name)
            )
            .map((i, index) => {
              let b_color = AppColor.Dark;
              let b_w = 1;
              const isExisted = crms.find((x) => x.provider == i[1].name);
              if (isExisted) {
                b_color = AppColor.PrimaryColor;
                b_w = 3;
              }
              console.log(i[1].name);
              return (
                <TouchableOpacity
                  onPress={() => {
                    if(!redirectCRMS.includes(i[1].name)){

                    
                    dispatch(setCompletedStep({ crm: true }));
                    navigation.navigate('Integrations', {
                      provider: i[1],
                      type: 'crm',
                    });
                    } else {
                      setWarningModal(true);
                    }
                  }}
                  style={{
                    alignItems: 'center',
                    borderWidth: b_w,
                    marginVertical: 5,
                    borderColor: b_color,
                    width: 200,
                    height: 150,
                    padding: 15,
                  }}
                  key={`crm${index}`}>
                  <img
                    alt={`${i[1].name} Logo.`}
                    style={{
                      height: 45,
                      width: 160,
                      marginHorizontal: 10,
                      objectFit:'contain'
                    }}
                    src={i[1].fullImageUrlPath}
                  />
                  {isExisted && (
                    <View
                      style={{
                        marginTop: 30,
                      }}>
                      <MaterialIcons
                        name="check-circle"
                        size={30}
                        color={AppColor.SuccessColor}
                      />
                    </View>
                  )}
                </TouchableOpacity>
              );
            })}
        </View>
      </ScrollView>
      <CustomButton
        title="skip"
        onPress={() => setVisible(true)}
        width={100}
        style={{
          margin: 10,
          alignSelf: 'end',
        }}
      />
      <ShowConfirmModal
        visible={visible}
        closeModal={() => {
          setVisible(false);
          dispatch(setCompletedStep({ crm: true }));
          navigation.navigate('SignupArchive');
        }}
      />
      {warningModalVisible && 
        <WarningModal 
        visible={warningModalVisible}
        closeModal={() => {
          setWarningModal(false);
        }}
        />
      }
    </SafeAreaView>
  );
};


const WarningModal = ({ visible, closeModal }) => {
  return (
    <Modal
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      visible={visible}
      transparent={true}
      onDismiss={closeModal}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <Text style={styles.description}>
            Please finish set up of your account and visit the "Settings - Connect to CRM" page to connect this CRM.
          </Text>
          <CustomButton title="Ok" onPress={closeModal} width={100} />
        </View>
      </View>
    </Modal>
  );
};


const ShowConfirmModal = ({ visible, closeModal }) => {
  return (
    <Modal
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      visible={visible}
      transparent={true}
      onDismiss={closeModal}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <Text style={styles.description}>
            To manage your CRM integrations, go to your account settings after
            you are logged in.
          </Text>
          <CustomButton title="Ok" onPress={closeModal} width={100} />
        </View>
      </View>
    </Modal>
  );
};

export default Crm;

const styles = StyleSheet.create({
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginBottom: 30,
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: 15,
    borderRadius: 5,
    borderWidth: 1,
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
  },
});
