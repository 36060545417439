import React from "react";
import { Content, SideBar, Wrapper } from "../../Components/Layout";
import { Text, View } from "react-native";
import { Icon } from "react-native-elements";
import AppFonts from "../../Constants/Fonts";
import Accordion from "../../Components/Accordion";
import NameAndEmail from "./Logins/NameAndEmail";
import Password from "./Logins/Password";
import MultiFactorAuthentication from "./Logins/MultiFactorAuthentication";
import Status from "./Logins/Status";
import Permissions from "./Logins/Permissions";
import Accounts from "./Logins/Accounts";
import PushKey from "./Logins/PushKey";
import Group from "./Logins/Group";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const Login = ({ route, navigation }) => {
    const Toast = useToast();

    const [user, setUser] = React.useState({
        ...route.params.user
    });

    const [screen, setScreen] = React.useState('Account Information');


    const loginSettings = [
        {
            title: 'Name and Email',
            onPress: () => setScreen('Name and Email')
        },
        {
            title: 'Password',
            onPress: () => setScreen('Password')
        },
        {
            title: 'Multi-Factor Authentication',
            onPress: () => setScreen('Multi-Factor Authentication')
        },
        {
            title: 'Status',
            onPress: () => setScreen('Status')
        },
        {
            title: 'Permissions',
            onPress: () => setScreen('Permissions')
        },
        {
            title: 'Accounts (View Only)',
            onPress: () => setScreen('Accounts (View Only)')
        },
        {
            title: 'Group',
            onPress: () => setScreen('Group')
        },
        {
            title: 'Push Key',
            onPress: () => setScreen('Push Key')
        }

    ]

    const KEY_COMPONENT = {
        'Name and Email': <NameAndEmail user={user} setUser={setUser} />,
        'Password': <Password user={user} setUser={setUser} />,
        'Multi-Factor Authentication': <MultiFactorAuthentication user={user} setUser={setUser} />,
        'Status': <Status user={user} setUser={setUser} />,
        'Permissions': <Permissions user={user} setUser={setUser} />,
        'Accounts (View Only)': <Accounts user={user} setUser={setUser} />,
        'Group': <Group user={user} setUser={setUser} />,
        'Push Key': <PushKey user={user} setUser={setUser} />
    }

    const handleSaveUser = async () => {
        try {
            const body = {
                name: user.name,
                email: user.email,
                password: user.password,
                mobile: user.mobile,
                mobilecc: user.mobilecc,
                status: String(user.status),
                mfaType: user.mfaType,
                permissions: user.permissions.map(e => e?.id ? e.id : e),
                affiliate: String(user.affiliate),
            }
            if (user.user_id) {
                body.userId = user.user_id;
                body.action = 'update_user';
            } else {
                body.action = 'create_user'
            }

            const result = await RequestMaker(body);

            if (result.data.success) {
                Toast.show({ title: 'User saved', status: 'success' })
            } else {
                throw new Error(result.data?.errorMessage ?? 'Failed to save user');
            }
        } catch (err) {
            Toast.show({ title: new Error(err).message, status: 'error' })
        }


    }


    return (
        <Wrapper>
            <SideBar>
                <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,.25)' }}>
                    <Icon style={{ padding: 10 }} onPress={() => navigation.pop()} name='chevron-left' type={'ionicons'} size={24} />
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{user?.name || 'New Account'}</Text>
                </View>
                <Accordion
                    data={loginSettings}
                />

            </SideBar>
            <Content>
                {
                    screen &&
                    <View style={{ flex: 1 }}>

                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginBottom: 0, borderBottomColor: 'rgba(0,0,0,.25)', borderBottomWidth: 1, paddingBottom: 10, paddingTop: 10, paddingLeft: 10 }}>{screen}</Text>
                        <View style={{ flex: 1, padding: 10 }}>
                            {KEY_COMPONENT[screen]}
                            <CustomButton onPress={handleSaveUser} style={{ alignSelf: 'flex-end', marginTop: 'auto' }} width={'20%'} title={'Save'} />
                        </View>
                    </View>
                }

            </Content>
        </Wrapper>
    )
}

export default Login;