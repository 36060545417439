import React from "react";
import { Content, SideBar, Wrapper } from "../Components/Layout";
import Accordion from "../Components/Accordion";
import { Text, View } from "react-native";
import AppFonts from "../Constants/Fonts";
import NameAdministrator from "./Settings/NameAdministrator";
import GroupOptions from "./Settings/GroupOptions";
import SignupOptions from "./Settings/SignupOptions";
import PositiveConsent from "./Settings/PositiveConsent";
import WelcomeEmail from "./Settings/WelcomeEmail";
import PortalOptions from "./Settings/PortalOptions";
import AddOnManagement from "./Settings/AddOnManagement";
import CustomSupport from "./Settings/CustomSupport";
import LoginReview from "./Settings/LoginReview";
import VcardManagement from "./Settings/VcardManagement";
import ChangePassword from "./Settings/ChangePassword";
import TermsOfUseManagement from "./Settings/TermsOfUseManagement";
import IntegrationProviders from "./Settings/IntegrationProviders";
import ContentIntegrations from "./Settings/ContentIntegrations";
import CRMIntegrations from "./Settings/CRMIntegrations";
import AdministratorLogins from "./Settings/AdministratorLogins";
import SAMLSetup from "./Settings/SAMLSetup";
import AddOnSetup from "./Settings/AddOnSetup";
import ConnectArchiver from "./Settings/ConnectArchiver";
import ArchiveLayout from "./Settings/ArchiveLayout";
import ArchiveSchedule from "./Settings/ArchiveSchedule";
import ArchiveAudit from "./Settings/ArchiveAudit";
import AccountsReport from "./Settings/AccountsReport";
import AccountManager from "./Settings/AccountManager";
import UploadFile from "./Settings/UploadFile";
import BillingManagement from "../Screens/AddBillings/BillingManagement";
import { Icon } from "react-native-elements";
import OauthSetup from "./Settings/OauthSetup";
import ChangeProvider from "./Settings/ChangeProvider";
import Billing from "./Settings/Billing";
import GroupEmailSettings from "./Settings/GroupEmailSettings";
import EnableIntune from "./Settings/EnableIntune";
import { useDispatch, useSelector } from "react-redux";
import Intune from "./Settings/Intune";
import LoginEmails from "./Settings/LoginEmails";
import { clearAffiliate, setAffiliate } from "../Redux";

const Settings = ({ route, navigation }) => {
    const [screen, setScreen] = React.useState(null);
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const dispatch = useDispatch();

    const settingsScreens = [
        {
            title: 'Profile',
            subPages: [
                {
                    title: 'Name & Administrator',
                    onPress: () => setScreen('Name & Administrator')
                },
                {
                    title: 'Change Password',
                    onPress: () => setScreen('Change Password')
                },
                {
                    title: 'Group Options',
                    onPress: () => setScreen('Group Options')

                },
                {
                    title: 'Signup Options',
                    onPress: () => setScreen('Signup Options')

                },
                {
                    title: 'Positive Consent',
                    onPress: () => setScreen('Positive Consent')

                },
                {
                    title: 'Account Manager',
                    onPress: () => setScreen('Account Manager')
                }
            ]
        },
        {
            title: 'Accounts',
            subPages: [
                {
                    title: 'Welcome Email',
                    onPress: () => setScreen('Welcome Email')
                },
                {
                    title: 'Portal Options',
                    onPress: () => setScreen('Portal Options')
                },
                {
                    title: 'Vcard Management',
                    onPress: () => setScreen('Vcard Management')

                },
                {
                    title: 'Add-On Management',
                    onPress: () => setScreen('Add-On Management')

                },
                {
                    title: 'Terms of Use Management',
                    onPress: () => setScreen('Terms of Use Management')

                },
                {
                    title: 'Custom Support',
                    onPress: () => setScreen('Custom Support')
                },
                {
                    title: 'Login Review',
                    onPress: () => setScreen('Login Review')
                },
                {
                    title: 'Upload File',
                    onPress: () => setScreen('Upload File')
                }
            ]
        },
        {
            title: 'Integrations',
            subPages: [
                {
                    title: 'Integration Providers',
                    onPress: () => setScreen('Integration Providers')
                },
                {
                    title: 'CRM Integrations',
                    onPress: () => setScreen('CRM Integrations')

                },
                {
                    title: 'Content Integrations',
                    onPress: () => setScreen('Content Integrations')
                },
            ]
        },
        {
            title: 'Authentication',
            subPages: [
                {
                    title: 'Administrator Logins',
                    onPress: () => setScreen('Administrator Logins')
                },
                {
                    title: 'SAML Setup',
                    onPress: () => setScreen('SAML Setup')
                },
                {
                    title: 'OAuth Setup',
                    onPress: () => setScreen('Oauth Setup')
                },
            ]
        },
        {
            title: 'Billing',
            subPages: [
                {
                    title: 'Add-On Setup',
                    onPress: () => setScreen('Add-On Setup')
                },
                {
                    title: 'Billing Management',
                    onPress: () => setScreen('Billing Management')
                },
            ]
        },
        {
            title: 'Archives and Reports',
            subPages: [
                {
                    title: 'Connect to Archiver',
                    onPress: () => setScreen('Connect to Archiver')

                },
                {
                    title: 'Archive Layout',
                    onPress: () => setScreen('Archive Layout')

                },
                {
                    title: 'Archive Schedule',
                    onPress: () => setScreen('Archive Schedule')
                },
                {
                    title: 'Archive Audit',
                    onPress: () => setScreen('Archive Audit')
                },
                {
                    title: 'Accounts Report',
                    onPress: () => setScreen('Accounts Report')
                }
            ]

        },
        
    ]
    if(route.params?.reseller || route.params?.sysadmin){
        settingsScreens.push(
            {
            title: 'Administrator',
            subPages: [
                {
                    title: 'Dashboard',
                    onPress: () => navigation.navigate('Dashboard', {reseller: true})
                },
                {
                    title: 'Disclosures',
                    onPress: () => navigation.navigate('Disclosures', {reseller: true})
                },
                {
                    title: 'Monitoring',
                    onPress: () => navigation.navigate('Monitoring', {reseller: true})
                },

            ]
        })
    }

    if(route.params?.sysadmin){
        settingsScreens.push(
            {
            title: 'System Administrator',
            subPages: [
                {
                    title: 'Billing',
                    onPress: () => setScreen('Billing')
                },

                {
                    title: 'Account Manager',
                    onPress: () => setScreen('Account Manager')
                },
                {
                    title: 'Change Provider',
                    onPress: () => setScreen('Change Provider')
                },
                {
                    title: 'Group Email Settings',
                    onPress: () => setScreen('Group Email Settings')
                },
                {
                    title: 'Intune',
                    onPress: () => setScreen('Intune')
                },

                
            ]
        })

    }

    if(affiliate?.intuneAllowed){
        settingsScreens[3].subPages.push({
            title: 'Intune',
            onPress: () => setScreen('EnableIntune')
        })
    }


    const KEY_COMPONENT = {
        'Name & Administrator': <NameAdministrator />,
        'Group Options': <GroupOptions />,
        'Signup Options': <SignupOptions />,
        'Positive Consent': <PositiveConsent />,
        'Account Manager': <AccountManager />,
        'Welcome Email': <WelcomeEmail />,
        'Portal Options': <PortalOptions />,
        'Add-On Management': <AddOnManagement />,
        'Custom Support': <CustomSupport />,
        'Login Review': <LoginReview />,
        'Vcard Management': <VcardManagement />,
        'Change Password': <ChangePassword />,
        'Terms of Use Management': <TermsOfUseManagement />,
        'Integration Providers': <IntegrationProviders />,
        'Content Integrations': <ContentIntegrations />,
        'CRM Integrations': <CRMIntegrations />,
        'Administrator Logins': <AdministratorLogins />,
        'SAML Setup': <SAMLSetup />,
        'Oauth Setup': <OauthSetup/>,
        'Add-On Setup': <AddOnSetup />,
        'Connect to Archiver': <ConnectArchiver group={route?.params?.group} />,
        'Archive Layout': <ArchiveLayout />,
        'Archive Schedule': <ArchiveSchedule />,
        'Archive Audit': <ArchiveAudit />,
        'Accounts Report': <AccountsReport />,
        'Upload File': <UploadFile />,
        'Billing Management': <BillingManagement />,
        'Change Provider': <ChangeProvider/>,
        'Billing': <Billing />,
        'Group Email Settings': <GroupEmailSettings />,
        'EnableIntune': <EnableIntune />,
        'Intune': <Intune />,

    }

    const showHeader = route?.params?.group || route?.params?.name

    const goBack = () => {
        navigation.pop();
        dispatch(clearAffiliate())
    }

    return (
        <Wrapper>
            <SideBar>
                { !!showHeader &&
                    <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,.25)' }}>
                        <Icon style={{ padding: 10 }} onPress={goBack} name='chevron-left' type={'ionicons'} size={24} />
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{route.params?.group || route.params?.name}</Text>
                    </View>
                }

                <Accordion data={settingsScreens} />
            </SideBar>
            <Content>

                {
                    screen &&
                    <View style={{ flex: 1 }}>

                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginBottom: 0, borderBottomColor: 'rgba(0,0,0,.25)', borderBottomWidth: 1, paddingBottom: 10, paddingTop: 10, paddingLeft: 10 }}>{screen}</Text>
                        <View style={{ flex: 1, paddingLeft: 15 }}>
                            {KEY_COMPONENT[screen]}
                        </View>
                    </View>
                }
            </Content>
        </Wrapper>
    )
}

export default Settings;