import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const recentMediaSlice = createSlice({
  name: 'recent_media',
  initialState: {
    data: [],
  },
  reducers: {
    setRecentMedia: (state, action) => {
      return {
        data: action.payload.data,
      };
    },
  },
});

export const { setRecentMedia } = recentMediaSlice.actions;

export const loadRecentMediaThunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_media',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setRecentMedia({ data: result.data.media }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export default recentMediaSlice.reducer;
