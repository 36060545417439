import React from 'react';
import { SafeAreaView, View, TouchableOpacity, Image, ScrollView } from 'react-native';
import CustomInput from '../../Components/CustomInput';
import Header from '../../Components/Header';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import { launchImageLibrary } from 'react-native-image-picker';
import { useSelector } from 'react-redux';
import { createFullChatChannel, getChatUsers, saveFile } from '../../Api/ten4';
import CustomButton from '../../Components/Button';
import AppColor from '../../Constants/Color';
import { MembersList } from './MembersList';
import * as DocumentPicker from 'expo-document-picker';
import Loader from '../../Components/Loader';

const AddPublicChannel = ({navigation, route}) => {
    
    const tenant = useSelector((state) => state.User.profile)?.tenant;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [avatarPath, setAvatarPath] = React.useState('');
    const [users, setUsers] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [channelName, setChannelName] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        const fetchChatUsers = navigation.addListener('focus', () => {
            handleGetUsers()
        })

        return fetchChatUsers;
    },[navigation])

    const handleGetUsers = () => {
        getChatUsers({affiliate: affiliate.id})
        .then(result => {
            if(result.data.success){
                console.log(result.data.users);
                setUsers(result.data.users);
            }
        })
    }

    const addChannelAvatar = async () => {
        const res = await DocumentPicker.getDocumentAsync({
            type: 'image/*',
        });
        if(res.type === 'success'){
            if (res.uri && res.file.size < 500000) {
                uploadFile(res.uri);
              } else {
                  alert('Please try a smaller image.')
              }
        }

    }

    const uploadFile = (uri) => {
        setLoading(true)
        saveFile({base64Data: uri, tenant: tenant.id})
        .then(result => {
            if(result?.data?.success){
                setAvatarPath(result.data.fileUrl);
            }
            setLoading(false)
        })
    }

    const handleAddMember = id => {
        if(!members.includes(id)){
            setMembers(members => [...members, id])
        } else {
            const copy = [...members];
            const index = copy.indexOf(id);
            if(index > -1){
                copy.splice(index, 1);
                setMembers(copy);
            }
        }
    }
    
    const handleSave = () => {
        const params = {
            active: true,
            affiliate: affiliate.id,
            avatar: avatarPath,
            members: members,
            name: channelName
        }

        createFullChatChannel({params: params})
        .then( result => {
            if(result.data.success){
                navigation.goBack();
            }
        })
    }
    

    return(
        <SafeAreaView style={{flex:1}}>
            <Header
            title='Add Public Channel'
            back={true}
            onPress={() => navigation.goBack()}
            />
            <ScrollView contentContainerStyle={{flex:1,justifyContent:'space-evenly'}}>

            {
            !avatarPath ? 
            <MaterialIcons onPress={addChannelAvatar} name='add-a-photo' color={'rgba(0,0,0,.5)'} size={42} style={{alignSelf:'center',margin:20}}/>
            :
            <TouchableOpacity onPress={addChannelAvatar}>
                <img alt="Channel Avatar." src={avatarPath} style={{objectFit:'contain',width:75,height:75,borderRadius:100,alignSelf:'center',margin:20}}/>
            </TouchableOpacity>
            }
            <CustomInput
            title='Name'
            value={channelName}
            containerStyle={{width:'80%',alignSelf:'center'}}
            titleStyle={{width:'80%',alignSelf:'center'}}

            onChangeText = {e => setChannelName(e)}
            />
            <MembersList onPress = {id => handleAddMember(id)} allUsers={users} selectedUsers={members} />
            <CustomButton title='Save' onPress={handleSave} style={{marginTop:0}} />
            </ScrollView>
            {
                loading && <Loader />
            }
        </SafeAreaView>
    )
}


export default AddPublicChannel;