import { Menu } from "native-base";
import React from "react";
import { Text, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import AppFonts from "../Constants/Fonts";

const SimpleDropdown = ({data, value, onChange, style}) => {
    const defaultValue = data.find(e => e.value === value)?.label || null;

    return(
        <Menu style={[{maxHeight:300,overflowY:'scroll'}]} trigger={triggerProps => {
            return (
                <TouchableOpacity {...triggerProps} style={{alignItems:'center',maxWidth:450,backgroundColor:'transparent',borderRadius:5,border:'1px solid black',padding:5,fontFamily:AppFonts.Roboto_Regular,flexDirection:'row',justifyContent:'space-between', ...style}}>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{defaultValue}</Text>
                    {/* {ACCOUNT_STATE[data.newAccountState]} */}
                    <Icon name='angle-down' type='font-awesome'/>
                </TouchableOpacity>
            )
            }}>
                {
                    data.map(e => {
                        return(
                            <Menu.Item onPress={() => onChange(e.value)}>
                                <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{e.label}</Text>
                            </Menu.Item>
                        )
                    })
                }
                {/* <Menu.Item onPress={() => setData({...data, newAccountState: 3 })}>
                    <Text>Review - Limited use until approved by you.</Text>
                </Menu.Item>
                <Menu.Item onPress={() => setData({...data, newAccountState: 4 })}>
                    <Text>Trial - 14-day trial period where all features work.</Text>
                </Menu.Item>
                <Menu.Item onPress={() => setData({...data, newAccountState: 1 })}>
                    <Text>Active - No limitations, everything works.</Text>
                </Menu.Item>
                <Menu.Item onPress={() => setData({...data, newAccountState: 2 } )}>
                    <Text>Inactive - Not active.</Text>
                </Menu.Item> */}
    </Menu>

    )
}

export default SimpleDropdown;