import React from "react";
import { Text } from "react-native";
import AppFonts from "../../../Constants/Fonts";

const Accounts = ({user, setUser}) => {
    return(
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>
            A listing of all the accounts that this user has access to.
            </Text>
            {
                    user.accounts.map(e => {
                        return(
                            <Text>{e.name}</Text>
                        )
                    })
            }

        </>
    )
}

export default Accounts;