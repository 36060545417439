/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  StatusBar,
  Image,
  TextInput,
  Keyboard,
  TouchableOpacity,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'Alert';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import Modal from 'modal-react-native-web';

import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, useToast } from 'native-base';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';

import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';

import { setSignUpData } from '../../../Redux/signupdata';

const CompanyDetail = ({ navigation }) => {
  const Toast = useToast();
  const [data, setData] = React.useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [phoneModal, setPhoneModal] = React.useState(false);

  const validateFields = () => {
    if (data.name.length == 0) {
      Toast.show({
        duration: 2000,
        title: 'Company name is a required field',
        status: 'warning',
      });
      return false;
    } else if (data.address1.length == 0) {
      Toast.show({
        duration: 2000,
        title: 'Address is a required field',
        status: 'warning',
      });
      return false;
    } else if (data.city.length == 0) {
      Toast.show({
        duration: 2000,
        title: 'City is a required field',
        status: 'warning',
      });
      return false;
    } else if (data.state.length == 0) {
      Toast.show({
        duration: 2000,
        title: 'State/Region is a required field',
        status: 'warning',
      });
      return false;
    } else if (data.zip == 0) {
      Toast.show({
        duration: 2000,
        title: 'Postal code is a required field',
        status: 'warning',
      });
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateFields()) {
      setPhoneModal(true);
      dispatch(setSignUpData({ data: data }));
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <Container
        style={{
          flex: 1,
          width: '100%',
          maxWidth: 767,
          paddingHorizontal: 15,
        }}>
        <View
          style={{
            flex: 1,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              width: '100%',
            }}>
            <img
              style={{
                maxWidth: 300,
                alignSelf: 'center',
                resizeMode: 'contain',
              }}
              alt='MyRepChat logo.'
              src={require('../../../Assets/Images/logo-fullcolor@2x.png')}
            />
          </View>
          <View style={{ width: '100%' }}>
            <CustomInput
              value={data.name}
              onChangeText={(t) => setData((state) => ({ ...state, name: t }))}
              title={'Company Name*'}
            />
            <CustomInput
              value={data.address1}
              onChangeText={(t) =>
                setData((state) => ({ ...state, address1: t }))
              }
              title={'Address*'}
            />
            <CustomInput
              value={data.address2}
              onChangeText={(t) =>
                setData((state) => ({ ...state, address2: t }))
              }
              title={'Address 2'}
            />
            <CustomInput
              value={data.city}
              onChangeText={(t) => setData((state) => ({ ...state, city: t }))}
              title={'City*'}
            />
            <CustomInput
              value={data.state}
              onChangeText={(t) => setData((state) => ({ ...state, state: t }))}
              title={'State/Region*'}
            />
            <CustomInput
              value={data.zip}
              onChangeText={(t) => setData((state) => ({ ...state, zip: t }))}
              title={'Post Code*'}
              keyboardType={'numeric'}
            />
          </View>
          <CustomButton
            onPress={() => {
              handleNext();
            }}
            title={'Next'}
          />
        </View>
        <PhoneModal
          visible={phoneModal}
          setVisible={setPhoneModal}
          navigation={navigation}
        />
      </Container>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: AppColor.SecondaryColor,
    marginBottom: 40,
  },
});

export default CompanyDetail;


const PhoneModal = ({ visible, setVisible, navigation }) => {
  const [phone, setPhone] = React.useState('');
  const [isVerified, setIsVerified] = React.useState(false);
  const [verficationSent, setVerficationSent] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const [type, setType] = React.useState('sms');
  const dispatch = useDispatch();

  const sendPhoneCode = async (sms) => {
    Keyboard.dismiss();
    let body = {
      action: 'verify_phone',
      phone: phone,
      sms: sms,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    let result = await RequestMaker(Config);
    if (result.data.success) {
      if (!result.data.verified) {
        setVerficationSent(true);
        if (sms) {
          setType('sms');
          Alert.alert(
            'Important',
            'Heads up! We just sent a text message with a verification code to ' +
              phone +
              '. Please get the code from the text and enter it into the verification code field to continue.'
          );
        } else {
          setType('call');
          Alert.alert(
            'Important',
            'Heads up! You should receive a call in the next few seconds, get your code from the message and enter it into the verification code field to continue.'
          );
        }
      } else {
        setVerficationSent(true);
        setIsVerified(true);
        Alert.alert(
          'Important',
          'Great News! This phone number has already been verified. Click continue to proceed to the next step.'
        );
      }
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
  };

  const handleContinue = async () => {
    if (!isVerified) {
      let body = {
        action: 'verify_phone',
        phone: phone,
        code: otp,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
      };
      // setLoading(true)
      let result = await RequestMaker(Config);
      if (result.data.success) {
        if (result.data.verified) {
          setIsVerified(true);
          setVisible(false);
          dispatch(
            setSignUpData({
              data: {
                phone: {
                  phone: phone,
                  countryCode: '+1',
                },
              },
            })
          );
          navigation.navigate('EmailPassword');
        }
      } else {
        Alert.alert('Error', result.data.errorMessage);
      }
    } else {
      dispatch(
        setSignUpData({
          data: {
            phone: {
              phone: phone,
              countryCode: '+1'
            },
          },
        })
      );
      setVisible(false);
      navigation.navigate('EmailPassword');
    }
  };

  return (
    <Modal
      visible={visible}
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '100%',
            maxWidth: 767,
            borderRadius: 8,
            backgroundColor: 'white',
            alignSelf: 'center',
            padding: 30,
          }}>
          <ScrollView>
            <Text style={{ textAlign: 'center', fontSize: 20 }}>
              Verify Cell Phone Number
            </Text>
            <View style={{ marginHorizontal: 20 }}></View>
            <Text style={{ margin: 10, fontSize: 15 }}>
              Please enter your personal cell phone number and click "Call" or "Text" to receive a confirmation code (standard message rates apply).
            </Text>
            <CustomInput
              keyboardType={'phone-pad'}
              value={phone}
              onChangeText={(t) => setPhone(t)}
              title={''}
              placeholder="Phone Number Only"
              textInputStyle={{ paddingHorizontal: 5 }}
            />
            {verficationSent && (
              <View>
                {!isVerified && (
                  <CustomInput
                    value={otp}
                    onChangeText={(t) => setOtp(t)}
                    title={''}
                    placeholder="Verfication Code"
                    textInputStyle={{ paddingHorizontal: 5 }}
                  />
                )}
                <CustomButton
                  style={{ marginTop: 10 }}
                  title={'Change Number'}
                  onPress={() => {
                    setIsVerified(false);
                    setVerficationSent(false);
                  }}
                />
                {!isVerified && (
                  <CustomButton
                    style={{ marginTop: 10 }}
                    title={'Resend Code'}
                    onPress={() => {
                      if (type == 'sms') {
                        sendPhoneCode(true);
                      } else {
                        sendPhoneCode(false);
                      }
                    }}
                  />
                )}
              </View>
            )}
          </ScrollView>
          {!verficationSent ? (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 5,
              }}>
              <CustomButton
                style={{ width: '30%' }}
                title="Call"
                onPress={() => {
                  sendPhoneCode(false);
                }}
              />
              <CustomButton
                style={{ width: '30%', marginVertical: 10 }}
                title="Text"
                onPress={() => {
                  sendPhoneCode(true);
                }}
              />
              <CustomButton
                style={{ width: '30%' }}
                title="Cancel"
                onPress={() => setVisible(false)}
              />
            </View>
          ) : (
            <View style={{ margin: 5 }}>
              <CustomButton title="Continue" onPress={() => handleContinue()} />
              <CustomButton
                style={{ marginTop: 10 }}
                title="Cancel"
                onPress={() => setVisible(false)}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};
