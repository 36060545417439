const End_Points = {
  // Base_url:"http://localhost:3000/",
  Base_url:"https://dart.ionlake.com/",
  // Base_url: 'https://dart.ionlake.com/',
  //Base_url: 'https://charlie.myrepchat.com/',
  //Base_url:"  https://8c2c-2600-6c4a-437f-e2e1-6148-2942-9799-6093.ngrok.io",
  End: 'dart/core',
  IMGSERVER: 'dart/upload',
  MEDIA_URL: 'https://static.myrepchat.com/'
};

export default End_Points;
