import React, { useState } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Alert,
  Pressable,
  TouchableOpacity,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'modal-react-native-web';
import { useToast } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import Loader from '../../../Components/Loader';
import AppFonts from '../../../Constants/Fonts';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import { loadAccountHealthThunk } from '../../../Redux/account_health';
import AppColor from '../../../Constants/Color';

const SelectMobileModal = ({
  visible,
  setVisible,
  selectedNumber,
  handleSetNumber,
}) => {
  const [loading, setLoading] = useState(false);
  const Toast = useToast();

  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.User.profile)?.tenant;

  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={visible}
      transparent={true}
      onDismiss={() => {
        setVisible(false);
      }}
      ariaHideApp={false}
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        border: 'none',
      }}
      onBackdropPress={() => {
        setVisible(false);
      }}>
      {loading && <Loader backgroundColor="transparent" />}
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={styles.modal}>
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <TouchableOpacity onPress={() => setVisible(false)}>
              <EvilIcons name="close" size={30} />
            </TouchableOpacity>
          </View>
          <Text style={styles.label}>
            Are you sure you want {selectedNumber} for your dedicated MyRepChat
            messaging number? This cannot be changed.
          </Text>
          <View style={styles.button}>
            <TouchableOpacity onPress={() => setVisible(false)}>
              <Text style={styles.cancel}>Cancel</Text>
            </TouchableOpacity>
            <CustomButton title="Ok" onPress={handleSetNumber} width={100} />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SelectMobileModal;

const styles = StyleSheet.create({
  button: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cancel: {
    color: AppColor.PrimaryColor,
    marginRight: 30,
  },
  modal: {
    backgroundColor: '#ffffff',
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
    alignItems: 'center',
    maxWidth: 500,
    width: '100%',
  },
  phoneInput: {
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginVertical: 20,
  },
  input: { alignSelf: 'center' },
  label: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
    marginBottom: 20,
  },
});
