import React from "react";
import { ScrollView, Switch, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import ComponentAccordion from "../../Components/ComponentAccordion";
import Loader from "../../Components/Loader/index.web";
import getQuery from "../getQuery";
import { useSelector } from "react-redux";
import SimpleDropdown from "../../Components/SimpleDropdown";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";

const CRMIntegrations = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const { refetch, data, isLoading, isError } = getQuery({
        type: "crm",
        group: affiliate.id,
        action: "get_integrations"
    })

    if (isLoading) return <Loader backgroundColor={'transparent'} />

    if (!data) return null;

    const RenderComponent = ({ item }) => {
        const [data, setData] = React.useState({
            group: affiliate.id,
            provider: item?.provider || 'dynamics',
            host: item?.host || '',
            active: item?.active || false,
            locationCall: item?.locationCall === 'true',
            locationTask: item?.locationTask === 'true',
            locationEmail: item?.locationEmail === 'true',
            locationNotes: item?.locationNotes === 'true',
            messagesNever: item?.messagesNever === 'true',
            messagesDaily: item?.messagesDaily === 'true',
            messagesRealtime: item?.messagesRealtime === 'true',
            syncConsent: item?.syncConsent === 'true',
            syncConsentFieldName: item?.syncConsentFieldName || '',
            useappendquery: item?.useappendquery === "true",
            appendquery: item?.appendquery || '',
        })
    
        const handleSave = async () => {
            let body = {
                ...item,
                ...data,
            }
            if(item?.id){
                body.action = 'update_integration';
            } else {
                body.action = 'create_integration';
            }
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
            }
        }

        const handleRemove = async () => {
            const body = {
                action: 'delete_integration',
                group: affiliate.id,
                id: item.id
            }
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
            }
        }



        return (
            <>
                <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Integration Type</Text>
                <SimpleDropdown
                    value={data.provider}
                    onChange={val => setData({ ...data, provider: val })}
                    data={[{ value: 'salesforce', label: 'Salesforce' }, { value: 'dynamics', label: 'Dynamics' }]}
                />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Host</Text>
                <CustomInput placeholder={'ex: hosted.crmdomain.com'} value={data.host} onChangeText={e => setData({ ...data, host: e })} />
                <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Active</Text>
                <Switch value={data.active} onValueChange={e => setData({ ...data, active: e })} />

                {
                    data.provider === 'salesforce' &&
                    <>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Note Location</Text>
                        <SimpleCheckbox onChange={e => setData({ ...data, locationTask: e })} value={data.locationTask} label={'Activity - Task'} />
                        <SimpleCheckbox onChange={e => setData({ ...data, locationCall: e })} value={data.locationCall} label={'Activity - Call'} />
                        <SimpleCheckbox onChange={e => setData({ ...data, locationEmail: e })} value={data.locationEmail} label={'Activity - Email'} />
                        <SimpleCheckbox onChange={e => setData({ ...data, locationNotes: e })} value={data.locationNotes} label={'Notes'} />

                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Send Messages to CRM</Text>
                        <SimpleCheckbox onChange={e => setData({ ...data, messagesNever: e })} value={data.messagesNever} label={'Never'} />
                        <SimpleCheckbox onChange={e => setData({ ...data, messagesDaily: e })} value={data.messagesDaily} label={'Daily'} />
                        <SimpleCheckbox onChange={e => setData({ ...data, messagesRealtime: e })} value={data.messagesRealtime} label={'Realtime'} />

                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Sync Consent</Text>
                        <SimpleCheckbox onChange={e => setData({ ...data, syncConsent: e })} value={data.syncConsent} label={'Enabled'} />
                        <CustomInput value={data.syncConsentFieldName} onChangeText={e => setData({ ...data, syncConsentFieldName: e })} titleStyle={{ paddingLeft: 15, marginTop: 5 }} containerStyle={{ paddingLeft: 15 }} title={'API Field Name'} />
                        
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginVertical: 10 }}>Custom Query</Text>
                        <SimpleCheckbox onChange={e => setData({ ...data, useappendquery: e })} value={data.useappendquery} label={'Use Custom Query'} />
                        <CustomInput value={data.appendquery} onChangeText={e => setData({ ...data, appendquery: e })} titleStyle={{ paddingLeft: 15, marginTop: 5 }} containerStyle={{ paddingLeft: 15 }} title={'Query'} />

                    </>
                }

                <View style={{ justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center', gap: 10, flex: 1 }}>
{                   item?.id && 
                    <CustomButton
                        title='Remove'
                        outline
                        width={'20%'}
                        style={{ alignSelf: 'flex-end', marginTop: 20 }}
                        onPress={handleRemove}
                    />
}                    <CustomButton
                        title='Save'
                        width={'20%'}
                        style={{ marginTop: 20 }}
                        onPress={handleSave}
                    />
                </View>



            </>
        )
    }

    return (
        <ScrollView contentContainerStyle={{ flex: 1 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 10 }}>
                This is optional. Some companies have hosted integration solutions (like a companywide CRM host) whose settings they want to ensure are correctly set for all accounts within the company. Here you can set these up.
            </Text>

            <ComponentAccordion
                allowAdd
                columns={['Active', 'Provider', 'Host']}
                data={data.integrations}
                renderListItem={item => [item.active, item.provider, item.host]}
                renderComponent={item => <RenderComponent item={item} />}
            />
        </ScrollView>

    )
}

export default CRMIntegrations;