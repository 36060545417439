import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast } from 'native-base';

import CustomButton from '../../../../Components/Button';
import CustomInput from '../../../../Components/CustomInput';
import Loader from '../../../../Components/Loader';
import AppColor from '../../../../Constants/Color';
import getData from '../../../../AsyncUtils/GetData';
import RequestMaker from '../../../../Middleware/ApiCaller';
import End_Points from '../../../../Constants/Api/index';
import AppFonts from '../../../../Constants/Fonts';

const FMGSuite = ({ provider, integration, loadProviderData }) => {
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState('');

  const Toast = useToast();

  useEffect(() => {
    if (integration) {
      setCustomerId(integration.customerId);
    } else {
      setCustomerId('');
    }
  }, [integration]);

  const handleSave = async () => {
    let body = {
      action: 'create_integration',
      customerId: customerId,
      provider: provider.name,
      active: true,
      type: provider.type.name,
    };
    if (integration && integration != null) {
      body = {
        action: 'update_integration',
        customerId: customerId,
        provider: provider.name,
        active: true,
        type: provider.type.name,
        id: integration.id,
      };
    }
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result.data);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'FMG Suite settings saved',
          status: 'success',
        });
        loadProviderData(null, provider, null);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
      Toast.show({
        duration: 2000,
        title: err.message,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          <View style={styles.topComment}>
            <Text style={styles.text}>
              Connect MyRepChat to FMG Suite by providing your Customer ID and
              clicking the Save button below. Once connected you can access
              links to saved articles in FMG Suite and easily send them to
              clients.
            </Text>
            <Text style={styles.text}>
              Note: You will need to log out and log back into the mobile app to see
              this setting change.
            </Text>
          </View>
          <CustomInput
            title="Customer ID"
            value={customerId}
            onChangeText={(val) => setCustomerId(val)}
          />
          <View style={styles.buttonWrapper}>
            <CustomButton
              title="SAVE"
              onPress={handleSave}
              width={'20%'}
            />
          </View>
        </View>
      </SafeAreaView>
      {loading && <Loader backgroundColor="transparent" />}
    </>
  );
};

export default FMGSuite;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    padding: 30,
  },
  topComment: {
    marginBottom: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 30,
  },
});
