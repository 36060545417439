import { useToast } from 'native-base';
import React from 'react';
import {
  Text,
  View,
  Image,
  Keyboard,
  FlatList,
  ScrollView,
  Linking,
  ActivityIndicator,
} from 'react-native';
import Alert from 'Alert';

import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import CustomButton from '../../Components/Button';
import { Input } from 'react-native-elements';
import { TouchableOpacity } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { CheckBox } from 'react-native-elements';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { providers } from '../IntegrationProvider';
import Modal from 'modal-react-native-web';
import { useDispatch } from 'react-redux';
import { loadAllContactsThunk } from '../../Redux/contacts';
import useLogEvent from '../../Middleware/useLogEvent';

const Contacts = ({ navigation }) => {
  const Toast = useToast();
  const [visible, setVisible] = React.useState(false);
  const [visible1, setVisible1] = React.useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [members, setMembers] = React.useState([]);
  const [phoneModalVisible, setPhoneModalVisible] = React.useState(false);
  const [phoneNumbers, setPhoneNumbers] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const logEvent = useLogEvent();

  const [dls, setDLs] = React.useState([]);
  const [data, setData] = React.useState({
    action: 'search',
    includeDistributionList: false,
    includeContacts: true,
    type: 'crm_and_local',
    import: false,
    filters: {
      scope: 'full',
    },
  });

  const handleDone = (d) => {
    setData(d);
  };

  // search begin
  const beginsearch = async () => {
    if (search.trim().length < 2) {
      Toast.show({
        duration: 2000,
        title: 'Search text must be atleast 2 characters',
        status: 'warning',
      });
      return;
    }
    // Keyboard.dismiss()
    try {
      setSearchLoading(true);
      const body = { ...data };
      body.filters['google'] = search;
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        const filteredData = result.data.contacts.filter(
          (contact) => !contact?.isGroup,
        );
        setMembers(filteredData);
        setDLs(result.data.distributionLists);
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSearchLoading(false);
    }
  };

  // create quick contact and goto chat window on create
  const handleQuickContact = async (data) => {
    Keyboard.dismiss();
    try {
      setLoading(true);
      const body = {
        action: 'create_member',
        fname: data.firstName,
        mname: '',
        lname: data.lastName,
        address: '',
        city: '',
        state: '',
        zip: '',
        email_address: '',
        birth_date: null,
        consented: false,
        spam: false,
        distributionLists: [],
        phoneNumbers: [
          {
            isoCode: 'US',
            countryCode: '+1',
            longCode: data.phoneNo,
            type: 1,
            messaging: true,
          },
        ],
        source: '',
        sourceId: '',
        comments: '',
        email_newsletter: false,
        list_in_directory: false,
        notify: false,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        dispatch(loadAllContactsThunk());
        loadMemberNumbers(result.data.memberId);
        setPhoneModalVisible(true);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // after click on searched contact check weather it in already coantact or not else add and goto chat
  const handleCrmSearchClick = async (item) => {
    try {
      setLoading(true);
      if (!item.id || item.id == 0 || item.id == null) {
        const body = {
          action: 'create_member',
          fname: item.fname ?? '',
          mname: item.mname ?? '',
          lname: item.lname ?? '',
          address: item.address ?? '',
          city: item.city ?? '',
          state: item.state ?? '',
          zip: item.zip ?? '',
          email_address: item.email_address ?? '',
          birth_date: item.birth_date ?? null,
          consented: item.consented ?? false,
          spam: item.spam ?? false,
          distributionLists: [],
          phoneNumbers: item.phoneNumbers,
          source: item.source ?? '',
          sourceId: item.sourceId ?? '',
          comments: item.comments ?? '',
          email_newsletter: false,
          list_in_directory: false,
          notify: false,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('result', result);
        if (result.data.success) {
          dispatch(loadAllContactsThunk());
          loadMemberNumbers(result.data.memberId);
          setPhoneModalVisible(true);
        } else {
          Toast.show({
            duration: 2000,
            title: result.data.errorMessage,
            status: 'error',
          });
        }
      } else {
        loadMemberNumbers(item.id);
        setPhoneModalVisible(true);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const handleCall = async (phoneNumber) => {
    try {
      const body = {
        action: 'initiate_call',
        toNumber: phoneNumber,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        logEvent('call_placed');
        Toast.show({
          duration: 2000,
          title: 'Call initiated, Use your phone to compelete the call',
          status: 'success',
        });
        setTimeout(() => {
          Linking.openURL(`tel:${phoneNumber}`);
        }, 2000);
      } else {
        alert('Fail to Send Message \n' + result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadMemberNumbers = async (id) => {
    try {
      const body = {
        action: 'get_member_phone_numbers',
        member: id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        setPhoneNumbers(result.data.phonenumbers);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderListView = (item) => {
    let image = require('../../Assets/Images/splashlogo.png');
    let imageAlt = 'MyRepChat logo.';
    let provider = Object.values(providers).find((x) => x.name == item.source);
    if (provider) {
      image = provider.smallImageUrlPath;
      imageAlt = `${provider.name} Logo.`
    }

    return (
      <TouchableOpacity
        onPress={() => handleCrmSearchClick(item)}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <img src={image} alt={imageAlt} style={{ height: 40, width: 40 }} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.phone1 !== '' ? item.phone1 : item.phone2}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 20,
          alignItems: 'center',
          width:'100%',
        }}>
        <Search onSubmitEditing={beginsearch} containerStyle={{flex:1}} value={search} onChangeText={(t) => setSearch(t)} />

        <TouchableOpacity onPress={() => setVisible(true)}>
          <AntDesign name="filter" size={30} color={AppColor.PrimaryColor} />
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end',paddingRight:'5%',gap:20 }}>
          <CustomButton
            outline
            title="Quick Create"
            width={'20%'}
            onPress={() => setVisible1(true)}
          />
           <CustomButton
            title="Search"
            width={'20%'}
            onPress={() => beginsearch()}

          />
      </View>

      {/* <CustomButton style={{ marginVertical: 10 }} title="Begin Search" onPress={beginsearch} /> */}
      {searchLoading && (
        <ActivityIndicator
          size={'large'}
          style={{ marginVertical: 10, alignSelf: 'center' }}
          color={AppColor.PrimaryColor}
        />
      )}
      <ScrollView style={{ paddingBottom: 10 }}>
        <FlatList
          data={members}
          keyExtractor={(item, index) => item.id + '' + index}
          ListEmptyComponent={
            !searchLoading && (
            <Text style={{ textAlign: 'center' }}>No contact matches</Text>
            )
          }
          renderItem={(item) => {
            return renderListView(item.item);
          }}
        />
      </ScrollView>
      <FilterModal
        visible={visible}
        setVisible={setVisible}
        handleDone={handleDone}
      />
      <QuickCreateModal
        visible={visible1}
        setVisible={setVisible1}
        handleQuickContact={handleQuickContact}
      />
      {loading && <Loader />}
      <Modal visible={phoneModalVisible} transparent={true}>
        <RenderPhoneModal
          handleCall={handleCall}
          phoneNumbers={phoneNumbers}
          setPhoneModalVisible={setPhoneModalVisible}
        />
      </Modal>
    </SafeAreaView>
  );
};

//filter modal for searching contacts
const FilterModal = ({ visible, setVisible, handleDone }) => {
  const [includeDistributionList, setIncludeDistributionList] =
    React.useState(false);
  const [includeContacts, setIncludeContacts] = React.useState(true);
  const [searchCRM, setSeacrchCRM] = React.useState(true);
  const [searchMRC, setSeacrchMRC] = React.useState(true);
  const [allContacts, setAllContacts] = React.useState(true);
  const [myContacts, setMyContacts] = React.useState(false);

  const handleSave = () => {
    let type = 'crm_and_local';
    let scope = 'full';
    if (searchCRM && searchMRC) {
      type = 'crm_and_local';
    } else {
      if (searchCRM) {
        type = 'crm_only';
      }
      if (searchMRC) {
        type = 'local_only';
      }
    }
    if (myContacts) {
      scope = 'user';
    }
    if (allContacts) {
      scope = 'full';
    }

    const data = {
      action: 'search',
      includeDistributionList: includeDistributionList,
      includeContacts: includeContacts,
      type: type,
      import: false,
      filters: {
        scope: scope,
      },
    };
    handleDone(data);
    setVisible(false);
  };
  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View style={{ width: '90%', backgroundColor: 'white', padding: 20 }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Filter
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>

          <View style={{ marginVertical: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={includeContacts}
                onPress={() => setIncludeContacts(!includeContacts)}
                title="Search Contact"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={includeDistributionList}
                onPress={() =>
                  setIncludeDistributionList(!includeDistributionList)
                }
                title="Search DLs"
                containerStyle={{ flex: 1 }}
              />
            </View>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={searchCRM}
                onPress={() => setSeacrchCRM(!searchCRM)}
                title="Search CRM"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={searchMRC}
                onPress={() => setSeacrchMRC(!searchMRC)}
                title="Search MRC"
                containerStyle={{ flex: 1 }}
              />
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: AppColor.PrimaryColor,
                marginVertical: 10,
              }}></View>
            <View style={{ flexDirection: 'row' }}>
              <CheckBox
                checked={allContacts}
                onPress={() => setAllContacts(!allContacts)}
                title="All Contacts"
                containerStyle={{ flex: 1 }}
              />
              <CheckBox
                checked={myContacts}
                onPress={() => setMyContacts(!myContacts)}
                title="My Contacts"
                containerStyle={{ flex: 1 }}
              />
            </View>
          </View>
          <CustomButton title="Done" onPress={() => handleSave()} />
        </View>
      </View>
    </Modal>
  );
};

// quick create  modal
const QuickCreateModal = ({ visible, setVisible, handleQuickContact }) => {
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNo, setPhone] = React.useState('');
  const handleSave = () => {
    if (
      firstName.trim() != '' &&
      (lastName.trim() != '') & (phoneNo.trim() != '')
    ) {
      setVisible(false);
      handleQuickContact({ firstName, lastName, phoneNo });
    } else {
      Alert.alert('Message', 'All field with * are required.');
    }
  };
  React.useEffect(() => {
    if (visible) {
      setFirstName('');
      setLastName('');
      setPhone('');
    }
  }, [visible]);

  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0008',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '90%',
            maxWidth: 575,
            backgroundColor: 'white',
            padding: 20,
          }}>
          <Text
            style={{
              textAlign: 'center',
              color: AppColor.PrimaryColor,
              fontSize: 20,
              fontFamily: AppFonts.Roboto_Bold,
            }}>
            Create Contact
          </Text>
          <View
            style={{
              height: 1,
              backgroundColor: AppColor.PrimaryColor,
              marginVertical: 10,
            }}></View>
          <View
            style={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
            }}>
            <Input
              value={firstName}
              onChangeText={(t) => setFirstName(t)}
              placeholder="First Name*"
              style={{ fontSize: 15 }}
            />
            <Input
              value={lastName}
              onChangeText={(t) => setLastName(t)}
              placeholder="Last Name*"
              style={{ fontSize: 15 }}
            />
            <Input
              value={phoneNo}
              onChangeText={(t) => setPhone(t)}
              placeholder="Phone Number*"
              style={{ fontSize: 15 }}
              keyboardType={'phone-pad'}
            />
          </View>
          <View style={{ marginVertical: 10 }}></View>
          <View
            style={{ flexDirection: 'row', justifyContent: 'flex-end',gap:20 }}>
            <CustomButton
            outline
              width={'30%'}
              title="Cancel"
              onPress={() => setVisible(false)}
            />
            <CustomButton
              width={'30%'}
              title="Ok"
              onPress={() => handleSave()}
            />

          </View>
        </View>
      </View>
    </Modal>
  );
};

export default Contacts;

const RenderPhoneModal = (props) => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <View
        style={{
          height: 270,
          paddingHorizontal: 30,
          backgroundColor: 'white',
          marginBottom: 48,
          borderRadius: 20,
          borderColor: AppColor.PrimaryColor,
          borderWidth: 1,
        }}>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 20,
            textAlign: 'center',
            marginTop: 10,
          }}>
          Call Contact
        </Text>
        <View
          style={{
            height: 1,
            backgroundColor: AppColor.PrimaryColor,
            marginVertical: 10,
          }}></View>
        <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontSize: 15 }}>
          Choose the number you would like to call
        </Text>
        <ScrollView style={{ marginVertical: 10 }}>
          {props.phoneNumbers.map((x) => {
            return (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 20,
                    color: AppColor.PrimaryColor,
                  }}>
                  {x.countryCode}
                  {x.longCode}
                  {` (${x.typeLabel})`}
                </Text>
                <MaterialIcons
                  onPress={() => {
                    props.setPhoneModalVisible(false);
                    props.handleCall(x.countryCode + x.longCode);
                  }}
                  name="phone"
                  size={30}
                  color={AppColor.PrimaryColor}
                />
              </View>
            );
          })}
        </ScrollView>
        <TouchableOpacity
          onPress={() => props.setPhoneModalVisible(false)}
          style={{
            padding: 10,
            backgroundColor: AppColor.PrimaryColor,
            borderRadius: 5,
            marginBottom: 5,
          }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.SecondaryColor,
              fontSize: 20,
              textAlign: 'center',
            }}>
            Cancel
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
