import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Linking,
  Modal,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { useFocusEffect } from '@react-navigation/native';
import {
  loadAllBookMarksThunk,
  deleteBookmarkThunk,
} from '../../Redux/bookmarks';
import { useDispatch, useSelector } from 'react-redux';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import { Bubbles } from '../Authentication/Login/Bubbles';
import InfoBar from '../../Components/Layout/InfoBar';
import AddBookmarks from './AddBookmarks';
import Search from '../../Components/Search';
import { RemovePhoneModal } from '../Contacts/modals';
import useUndo from '../../Middleware/useUndo';
import RequestMaker from '../../Middleware/ApiCaller';
import Loader from '../../Components/Loader/index.web';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const Bookmarks = ({ navigation }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bookmarks).data;
  const [filteredData, setFilteredData] = React.useState(data);
  React.useEffect(()=>{setFilteredData(data)},[data])
  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const [bookmark, setBookmark] = React.useState();
  const [removeModal, setRemoveModal] = React.useState(false);
  const [toDelete, setToDelete] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {isWebCompact} = useResponsiveLayout();

  const undo = useUndo();

  useFocusEffect(
    React.useCallback(() => {
      dispatch(loadAllBookMarksThunk());
    }, [])
  );

  React.useEffect(()=>{
    dispatch(loadAllBookMarksThunk())
  },[tenant])

  const handleRecreateBookmark = async () => {
    const body = {
      action: 'create_bookmark',
      label: toDelete.label,
      url: toDelete.url,
      useembedded: false,
    }
    setLoading(true);
    const result = await RequestMaker(body);
    if(result.data.success){
      dispatch(loadAllBookMarksThunk())
    }
    setLoading(false);
  }

  const handleDelete = (id) => {
    dispatch(deleteBookmarkThunk(id));
    undo(handleRecreateBookmark);
  };

  function validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  const openLink = (url) => {
    Linking.canOpenURL(url)
    .then(result => {
      if(result){
        window.open(url, '_blank').focus();
      }
    })
  }

  

  const renderListView = (item) => {
    const focused = item.id === bookmark?.id;

    return (
      <TouchableOpacity
        onPress={() => setBookmark(item)}
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          marginTop: 10,
          borderTopColor: '#116AB4',
          borderTopWidth: focused ? 1 : 0,
          borderBottomColor: '#116AB4',
          borderBottomWidth: focused ? 1 : 0,
          paddingHorizontal:7.5,
          backgroundColor: focused ? '#ECF6FF' : 'transparent'


        }}>
        <View style={{ justifyContent: 'center' }}>
          <Icon
            type={'antdesign'}
            name={'book'}
            color={AppColor.PrimaryColor}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Bold,
              fontSize: 17,
              marginHorizontal: 10,
              color: 'black',
            }}>
            {item.label}
          </Text>
          <Text
          numberOfLines={1}
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              lineHeight: 20,
              marginHorizontal: 10,
              color: AppColor.Dark,
              textOverflow:'ellipsis'
            }}>
            {item.url}
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
            {/* */}
          <TouchableOpacity
            onPress={ () => navigation.navigate('SelectBookContact', { url: item.url })}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'message1'} type={'antdesign'} color={'black'} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={ () => openLink(item.url)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'external-link'} type={'feather'} color={'black'} />
          </TouchableOpacity>


          <TouchableOpacity
            onPress={() => {
              setRemoveModal(true);
              setToDelete(item)
            }}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon name={'delete'} type={'antdesign'} color={AppColor.danger} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  };

  const handleSearch = query => {
    if(query === ''){
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(e => e.url.includes(query) || e.label.includes(query)))
    }
  }


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Wrapper>
        { (!isWebCompact || !bookmark) && <SideBar>
        <View style={{ flex: 1,  }}>
        <Search 
        actionButton
        onPressActionButton={() => setBookmark('create')}
        actionButtonTitle='Bookmark'
        onChangeText={handleSearch} />

        <FlatList
          data={filteredData}
          key={(item) => item.name}
          style={{flex:1}}
          ListEmptyComponent={<Text style={{marginTop:20,textAlign:'center'}}>No bookmarks found.</Text>}
          renderItem={(item) => {
            return renderListView(item.item);
          }}
        />
      </View>
      <View style={{ alignItems: 'center' }}>
      </View>
        </SideBar>}
            { (!isWebCompact || bookmark ) &&
              <Content>
          <Content.Row style={{height:'100%'}}>
            <View style={{flex:2,height:'100%'}}>
            {!bookmark ? 
                <Bubbles style={{width:'140%'}}>
                  <Text style={{color:'#116AB4',fontSize:32,width:'70%'}}>
                    Select a bookmark to edit or create a new one.
                  </Text>
                </Bubbles>
                :
                <AddBookmarks onSave={() => {
                  dispatch(loadAllBookMarksThunk());
                  setBookmark(null);
                }} onClose={()=>setBookmark(null)} item={bookmark}/>
            }
            </View>
            {!isWebCompact && <BookmarkInfo/>}
          </Content.Row>
          </Content>}

      </Wrapper>
      {
        removeModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={removeModal} transparent={true}>
          <RemovePhoneModal
          title="Delete Bookmark"
          message="Are you sure you'd like to permanently delete this bookmark?"
            handleRemove={() => {
              handleDelete(toDelete.id)
              setRemoveModal(false);
            }}
            setRemoveModal={setRemoveModal}
          />
        </Modal>
      }
      {loading && <Loader/>}
    </SafeAreaView>
  );
};

const BookmarkInfo = () => {
  return(
    <InfoBar>
              <Text style={{fontSize:18,marginVertical:10,textAlign:'center'}}>Bookmark Guide</Text>
              <Text style={{marginTop:10}}>Bookmarks help you save useful links that you can quickly send to your contacts.</Text>
               <Text style={{marginTop:10}}>Make sure that you have written the correct URL by clicking the button to test your URL. You can send a link to a contact by clicking the button and choosing the contact you wish to send the link to.</Text>


    </InfoBar>
  )
}

export default Bookmarks;
