// Add Memeber Modal

import React from 'react';
import { View, TouchableOpacity, Text, FlatList, Image } from 'react-native';
import { useSelector } from 'react-redux';
import Modal from 'modal-react-native-web';

import Search from '../../Components/Search';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { CheckBox } from 'react-native-elements';
import CustomButton from '../../Components/Button';

export default function MembersModal({ visible, setVisible, handleAddMember, members }) {
  const contactData = useSelector((state) => state.contacts).data;
  const [selectedMembers, setSelectedMembers] = React.useState([]);

  const formatData = () => {
    if (contactData.length > 0) {
      let arr = [];
      for (let x of contactData) {
        const index = members.findIndex(e => e.id === x.id)
        if(index === -1){
          if (x.phoneNumbers.length > 0) {
            for (let phone of x.phoneNumbers) {
              if (phone.messaging) {
                let data = { ...x };
                data['mobile'] = phone.longCode;

                arr.push(data);
              }
            }
          }
      }
      }
      arr.sort((a,b) => a.fname.localeCompare(b.fname))
      return arr;
    }
  }

  const [allContactData, setAllContactData] = React.useState(formatData());
    
  const setSearch = search => {
    if (search !== '') {
      const searchedData = contactData.filter(
        (x) =>
      (`${x.fname.toLowerCase()} ${x.lname.toLowerCase()}`).includes(search.toLowerCase())
      && 
      members.findIndex(e => e.id === x.id) === -1
      )
      
      setAllContactData(searchedData);
    } else {
      const data = formatData();
      setAllContactData(data);
    }
  }

  
  const renderListView = ({ item }) => {

    return (
      <TouchableOpacity
        accessibilityRole='button'
        onPress={() => {
          const index = selectedMembers.findIndex((e => e.id === item.id))
          if( index > -1){
            setSelectedMembers(members => {
              const arr = [...members]
              arr.splice(index, 1);
              return arr;
            })
          } else {
            setSelectedMembers(members => [...members, item])
          }
        }}
        style={{
          width: '90%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          marginBottom: 5,
          alignItems:'center',
        }}>
          <CheckBox 
          containerStyle={{pointerEvents:'none'}}
          checked={selectedMembers.findIndex((e => e.id === item.id)) > -1}
          />
        <View style={{ justifyContent: 'center' }}>
          <img
          
            src={
              item.source == 'wealthbox'
                ? require('../../Assets/Images/wealthbox.png')
                : require('../../Assets/Images/splashlogo.png')
            }
            alt={
              item.source == 'wealthbox'
              ? 'Wealthbox logo.'
              : 'MyRepChat logo.'
            }
            
            style={{ height: 40, width: 40, objectFit:'contain' }}
          />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.name}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 12,
              marginHorizontal: 10,
              color: AppColor.Dark,
            }}>
            {item.mobile}
          </Text>
        </View>
        {item.consented && <Text style={{fontFamily:AppFonts.Roboto_Regular,backgroundColor:'green',color:'white',padding:5,borderRadius:5}}>Consented</Text>}
      </TouchableOpacity>
    );
  };


  return (
    <Modal visible={visible} transparent={true}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            height: '70%',
            width: '90%',
            maxWidth: 767,
            backgroundColor: 'white',
          }}>
          <Header
          iconName={'close'}
            title={'Add Contacts'}
            back={true}
            onPress={() => setVisible(false)}
          />
          <Search onChangeText={(t) => setSearch(t)} />
          <FlatList
            extraData={selectedMembers}
            data={allContactData}
            keyExtractor={(item, index) => item.id + '' + index}
            renderItem={renderListView}
          />
          <CustomButton onPress={() => handleAddMember(selectedMembers)} title={`Add ${selectedMembers.length} Contacts`} style={{marginRight:20,alignSelf:'flex-end',maxWidth:300,marginVertical:10}}/>
        </View>
      </View>
    </Modal>
  );
}
