import React from 'react';
import { Text, View, Switch, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { useToast } from 'native-base';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Entypo from 'react-native-vector-icons/Entypo';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import IntegrationComponentFactory from './IntegrationComponentFactory';
import { EnumFrequency, EnumLocation } from '../../IntegrationProvider';

import End_Points from '../../../Constants/Api';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import Alert from 'Alert';
import { loadIntegrationsThuunk } from '../../../Redux/integrations';
import { setAttributes } from '../../../Redux/integrationData';
import useLogEvent from '../../../Middleware/useLogEvent';

export default function CrmView({ provider, groupIntegration, from }) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const data = useSelector((state) => state.integrationData);
  const group = useSelector((state) => state.User)?.profile?.group;
  const [integration, setIntegration] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isCreatedCrm, setCreatedCrm] = React.useState(false);
  const logEvent = useLogEvent();

  const Toast = useToast();
  const loadProviderData = async (type, provider, groupId) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_integrations',
      };
      if (type != null) {
        body['type'] = type.name;
      }
      if (provider) {
        body['provider'] = provider.name;
      }
      if (groupId != null) {
        body['group'] = provider.groupId;
      }
      
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        console.log(result)
        if (result.data.integrations.length > 0) {
          console.log(result.data.integrations);
          setIntegration(result.data.integrations[0]);
        } else {
          setIntegration(null);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadProviderData(null, provider, null);
  }, [provider]);

  const handleSave = () => {
    if (integration && integration != null) {
      console.log(integration,"INTAGRATION");
      updateIntegration();
    } else {
      createIntegration();
    }
  };

  const updateIntegration = async (updatedToken = null) => {
    const body = {
      action: 'update_integration',
      provider: provider.name,
      type: provider.type.name,
      enabled: data.enabled,
      active: data.frequency != EnumFrequency.NEVER.key,
      realtimeNotes: data.frequency == EnumFrequency.REALTIME.key,
      createcontact: data.createcontact,
      updatecontact: data.updatecontact,
      calendarsync: String(data.calendarsync),
      id: integration.id,
    };
    if(data.location){
      body['noteLocation'] = data.location;
    }

    if (data.attributes != null && data.attributes) {
      for (let attr of Object.keys(data.attributes)) {
        body[attr] = data.attributes[attr];
      }
    }
    if (updatedToken) {
      body.token = updatedToken;
    }
    try {
      Object.assign(integration, body); //overlay body with what was already saved.

      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: integration,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Successfully updated integration',
          status: 'success'
        })

        dispatch(loadIntegrationsThuunk());
        if (from === 'settings') return;
        setCreatedCrm(true);
      } else {
        Toast.show({
          duration: 2000,
          title: 'Something went wrong updating this integration. Please try again later',
          status:'error'
        })
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const createIntegration = async (updatedToken = null) => {
    const body = {
      action: 'create_integration',
      provider: provider.name,
      type: provider.type.name,
      enabled: data.enabled,
      active: data.frequency != EnumFrequency.NEVER.key,
      realtimeNotes: data.frequency == EnumFrequency.REALTIME.key,
      createcontact: data.createcontact,
      updatecontact: data.updatecontact,
      calendarsync: data.calendarsync
    };
    if (data.attributes != null && data.attributes) {
      for (let attr of Object.keys(data.attributes)) {
        body[attr] = data.attributes[attr];
      }
    }
    if(data.location){
      body['noteLocation'] = data.location;
    }

    if (updatedToken) {
      body.token = updatedToken;
    }
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        logEvent('crm_setup');

        Toast.show({
          duration: 2000,
          title: 'Integration successfully created.',
          status:'success'
        })

        loadProviderData(null, provider, null);
        dispatch(loadIntegrationsThuunk());
        if (from === 'settings') return;
        setCreatedCrm(true);
      } else {
        setIntegration(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const testCRM = async () => {
    if (!integration && integration == null) {
      Toast.show({
        duration: 2000,
        title: 'No Integration, Save integration before testing.',
        status: 'info',
      });
      return;
    }
    const body = {
      action: 'test_integration',
      provider: provider.name,
      type: provider.type.name,
      id: integration.id,
    };
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: provider.label + ' Test Successful',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: 'The server returned an error:\n' + result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteCrm = async () => {
    if (!integration && integration == null) {
      Toast.show({
        duration: 2000,
        title: 'No Integration, Save integration before delete.',
        status: 'info',
      });
      return;
    }
    const body = {
      action: 'delete_integration',
      provider: provider.name,
      type: provider.type.name,
      id: integration.id,
    };
    try {
      setLoading(true);
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'Successfully deleted integration..',
          status: 'success',
        });
  
        dispatch(setAttributes(null));
        dispatch(loadIntegrationsThuunk());
        setIntegration({enabled: false});
        navigation.goBack();
      } else {
        Toast.show({
          duration: 2000,
          title: 'The server returned an error:\n' + result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    if (from === 'settings') {
      navigation.navigate('Settings');
    } else {
      navigation.navigate('SignupArchive');
    }
  };

  return (
    <>
      {isCreatedCrm ? (
        <View style={styles.successContent}>
          <FontAwesome name="thumbs-o-up" size={60} />
          <Text style={[styles.text, styles.title]}>
            Your CRM setup is complete! Click "Continue" to continue.
          </Text>
          <Text style={[styles.text, styles.comment]}>
            To manage your crm setup, go to your account settings after you are
            logged in.
          </Text>
          <CustomButton title="continue" onPress={handleContinue} />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <ScrollView style={{flex:1}}>
          <IntegrationComponentFactory
            provider={provider}
            groupIntegration={groupIntegration}
            integration={integration}
            createIntegration={createIntegration}
            updateIntegration={updateIntegration}
            loadProviderData={loadProviderData}
          />
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap:10,
              marginBottom:10,
              marginHorizontal: 20,
            }}>
            <CustomButton
              outline
              title="delete"
              width={'20%'}
              onPress={deleteCrm}
            />
            <CustomButton
            title="Test"
            width={'20%'}
            onPress={testCRM}
          />

            {provider.name !== 'fmgsuite' &&
            provider.name !== 'marketingpro' &&
            provider.name !== 'riskalyze' &&
            provider.name !== 'smartoffice' && (
              <>
                <CustomButton
                  outline
                  title="Cancel"
                  width={'20%'}
                  onPress={() => navigation.goBack()}
                />
                <CustomButton
                  title="Save"
                  width={'20%'}
                  onPress={handleSave}
                />
              </>
            )}
          </View>

        </View>
      )}

      {loading && <Loader />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 767,
    padding: 30,
  },
  successContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topComment: {
    padding: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  setupPaneTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 24,
    marginTop: 50,
  },
  title: {
    fontSize: 28,
    marginVertical: 25,
  },
  comment: {
    marginBottom: 20,
  },
});
