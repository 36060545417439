import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Pressable,
} from 'react-native';
import Modal from 'modal-react-native-web';
import { ScrollView } from 'react-native-gesture-handler';
import AppFonts from '../../Constants/Fonts';
import { heightPercentageToDP } from 'react-native-responsive-screen';

const CustomModal = (props) => {
  const [modalvisible, setVisible] = useState(false);
  return (
    <View style={{}}>
      <TouchableOpacity
      accessibilityRole='button'
        activeOpacity={1}
        onPress={() => setVisible((v) => !v)}
        style={{
          height: 40,
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#F6F6F6',
          position: 'relative',
          ...props.style
        }}>
        <Text
          style={{
            color: props.value ? 'black' : '#A2A2A2',
            fontSize: 16,
            marginLeft: 20,
            fontFamily: AppFonts.Roboto_Regular,
          }}>
          {props.data.find((x) => x.value == props.value)?.label}
        </Text>
        <img
          src={require('../../Assets/Images/dropdown.png')}
          alt='Open Dropdown'
          style={{
            objectFit: 'contain',
            width: 30,
            height: 20,
            marginRight: 15,
          }}
        />
      </TouchableOpacity>
      <Modal visible={modalvisible} animationType="fade" transparent={true}>
        <Pressable
          onPress={() => setVisible(false)}
          style={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            height: heightPercentageToDP(100),
            backgroundColor: '#0008',
          }}>
          <Pressable
            style={{
              maxHeight: heightPercentageToDP(60),
              width: '80%',
              alignSelf: 'center',
              backgroundColor: 'white',
            }}>
            <ScrollView>
              {props.data.map((i, index) => {
                return (
                  <TouchableOpacity
                    key={`type_${index}`}
                    activeOpacity={1}
                    onPress={() => {
                      props.fun(i.value);
                      setVisible(false);
                    }}
                    style={{
                      width: '100%',
                      height: 40,
                      justifyContent: 'center',
                      marginLeft: 20,
                    }}>
                    <Text
                      style={{
                        fontFamily: AppFonts.Roboto_Regular,
                        textAlignVertical: 'center',
                        fontSize: 16,
                      }}>
                      {i.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </Pressable>
        </Pressable>
      </Modal>
      {/* } */}
    </View>
  );
};
const styles = StyleSheet.create({});

export default CustomModal;
