import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';

export const TitleCard = props => {
  switch(props.type){
    case 'row': {
      return (
        <View style={[styles.rowCardContainer,{...props.style} ]}>
          <Text style={styles.rowCardTitleText}>{props.title}</Text>
          <Text style={styles.rowCardSubtitleText}>{props.subtitle}</Text>
        </View>
      );
    }
    case 'block': {
      return(
        <View style={[styles.blockCardContainer,{...props.style} ]}>
          <Text style={styles.blockCardTitleText}>{props.title}</Text>
          <Text style={styles.blockCardSubtitleText}>{props.subtitle}</Text>
        </View>
      )
    }
  }

};

const styles = StyleSheet.create({
  rowCardContainer: {
    flex: 1,
    alignItems: 'center', 
    backgroundColor: 'white', 
    flexDirection:'row',
    justifyContent: 'space-between', 
    paddingHorizontal:10,
    maxHeight:50,
    borderRadius:7,
    borderWidth:1,
    borderColor:'rgba(0,0,0,.25)'
  },
  rowCardTitleText: {
    fontWeight: 300, 
    fontFamily: AppFonts.Roboto_Regular,
  },
  rowCardSubtitleText: {
    fontFamily: AppFonts.Roboto_Regular
  },
  blockCardContainer: {
    flex:1,
    backgroundColor: 'white', 
    borderRadius:7,
    borderWidth:1,
    borderColor:'rgba(0,0,0,.25)',
    alignItems:'center',
    justifyContent:'center',
    maxHeight:125,
    paddingVertical:20
  },
  blockCardTitleText: {
    marginBottom:10,
    fontFamily:AppFonts.Roboto_Regular
  },
  blockCardSubtitleText: {
    fontFamily:AppFonts.Roboto_Regular,
    color: AppColor.PrimaryColor,
    fontSize:24,
    fontWeight:100
  }
  
})