import React from "react";
import getQuery from "../../getQuery";
import Loader from "../../../Components/Loader/index.web";
import AppFonts from "../../../Constants/Fonts";
import SimpleDropdown from "../../../Components/SimpleDropdown";
import { Text } from "react-native";

const Group = ({user, setUser}) => {
    const {data, isLoading} = getQuery({
        action: "get_affiliates"
    })

    if(isLoading) return <Loader backgroundColor={'transparent'}/>
    if(!data) return null;

    const groupData = data.affiliates.map(e => ({label: e.name, value: e.id}))
    
    return(
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:20}}>
            If this user has the group administrator permission then you are required to choose the group that they are the administrator of.
            </Text>

            <SimpleDropdown style={{maxWidth:'100%'}} value={user.affiliate} data={[{label:'None', value: 0}, ...groupData]} onChange={e => setUser({...user, affiliate: e})} />
        </>
    )
}

export default Group;