import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
  Dimensions,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Alert from 'Alert';

import CustomButton from '../../Components/Button';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import AppColor from '../../Constants/Color';
import keyword from '../../Constants/Keywords';
import { setCompletedStep } from '../../Redux/singupwizard';
import { setProfile, setToken } from '../../Redux';
import AppFonts from '../../Constants/Fonts';
import getData from '../../AsyncUtils/GetData';
import storeData from '../../AsyncUtils/StoreData';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api/index';

const Finished = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFinished = async () => {
    setLoading(true);
    try {
      const body = {
        action: 'authorize',
        newToken: true,
        // "pushKey":true //wait until work with push notifiaciotn
        expiration: 1000 * 60 * 60 * 24 * 7,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
      };
      let result = await RequestMaker(Config);
      if (result.status == 200) {
        dispatch(setProfile(result.data));
        if (result.headers && result.headers['x-dart-token']) {
          dispatch(
            setToken({
              authenticated: true,
            })
          );
          dispatch(setCompletedStep({ finished: true }));
          navigation.navigate('Drawer');
        }
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const openLink = (url) => {
    window.open(url, '_blank');
  };


  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header title={'Congratulations, your MyRepChat account is setup!'} />
        <View style={styles.body}>
          <View style={styles.content}>
            <Text style={styles.text}>
              You have completed your MyRepChat account setup.
            </Text>
            <Text style={[styles.text, styles.setupPaneTitle]}>
              Ready... Set... Go!
            </Text>
            <Text style={styles.text}>
              Subscribe to our YouTube Channel for tips and other useful short
              helpful videos
            </Text>
            <TouchableOpacity
              onPress={() =>
                openLink(
                  'https://www.youtube.com/channel/UCD0C2SUgR0NOUUWa2Ifr7-g'
                )
              }>
              <View>
                <img
                  alt="MyRepChat Minute on Youtube."
                  src={require('../../Assets/Images/mrcMinYoutubePic.png')}
                  style={{width: '100%', height: 300, objectFit: 'contain'}}
                />
              </View>
            </TouchableOpacity>
            <Text style={styles.text}>
              Resources for learning the system are available in the
              <TouchableOpacity
                onPress={() =>
                  openLink('https://ionlake.zendesk.com/hc/en-us')
                }>
                <Text>
                  {' '}
                  support area.
                  <Ionicons
                    name="open-outline"
                    size={20}
                    color={AppColor.PrimaryColor}
                  />
                </Text>
              </TouchableOpacity>
            </Text>
            <Text style={styles.text}>
              Contact our support team if you have any issues or questions.
            </Text>
            <TouchableOpacity
              onPress={() => openLink('mailto:support@ionlake.com')}>
              <Text style={{ color: AppColor.PrimaryColor }}>
                support@ionlake.com
              </Text>
            </TouchableOpacity>
            <View>
              <Text style={styles.text}>
                -{' '}
                <TouchableOpacity
                  onPress={() =>
                    openLink('https://vimeo.com/348356614')
                  }>
                  <Text>
                    {' '}
                    Watch a crash course video .
                    <Ionicons
                      name="open-outline"
                      size={20}
                      color={AppColor.PrimaryColor}
                    />
                  </Text>
                </TouchableOpacity>
              </Text>
            </View>
            <View>
              <Text style={styles.text}>
                Scan the below code with your phone camera to download the
                MyRepChat App from your app store
              </Text>
              <View style={styles.buttom}>
                <View style={styles.contactInfo}>
                  <Text style={styles.text}>Apple Store</Text>
                  <img
                    alt="Apple Logo."
                    src={require('../../Assets/Images/appleiconlogo.png')}
                    style={{width: 30,height: 30, objectFit:'contain'}}
                  />
                  <img
                    alt="QR Code link to MyRepChat on the App Store."
                    src={require('../../Assets/Images/qrAppleStore.png')}
                    style={{width: 200 ,height: 200,objectFit:'contain'}}
                  />
                </View>
                <View style={styles.contactInfo}>
                  <Text style={styles.text}>Android/Google Store</Text>
                  <img
                    alt="Android Logo."
                    src={require('../../Assets/Images/androidiconlogo.png')}
                    style={{width: 30,height: 30, objectFit:'contain'}}
                  />
                
                  <img
                    alt="QR Code link to MyRepChat on the Play Store."
                    src={require('../../Assets/Images/qrGoogleStore.png')}
                    style={{width: 200 ,height: 200,objectFit:'contain'}}
                  />
                </View>
              </View>
            </View>
          </View>
          <CustomButton title="finished" onPress={handleFinished} width={250} />
        </View>
      </SafeAreaView>
      {loading && <Loader />}
    </>
  );
};

export default Finished;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: Dimensions.get('screen').height,
    overflow: 'scroll',
  },
  body: {
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 767,
    padding: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
    marginVertical: 5,
  },
  setupPaneTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 24,
    marginTop: 30,
  },
  icon: {
    width: 30,
    height: 30,
  },
  image: {
    width: '100%',
    height: 300,
  },
  qrIcon: {
    width: 200,
    height: 200,
  },
  buttom: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contactInfo: {
    alignItems: 'center',
    marginTop: 30,
  },
});
