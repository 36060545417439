import React from 'react';
import { View, ScrollView, TextInput,Keyboard, Text, TouchableOpacity, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { createChatMessage, getChannelMembers, getChatMessages, getChatUsers, saveFile, updateChatActivity } from '../../Api/ten4';
import Ten4Message from '../../Components/Ten4/Ten4Message';
import Entypo from 'react-native-vector-icons/Entypo';
import Ionicons from 'react-native-vector-icons/Ionicons'
import AppColor from '../../Constants/Color';
import * as DocumentPicker from 'expo-document-picker';
import Loader from '../../Components/Loader';
import AppFonts from '../../Constants/Fonts';
import { Icon } from 'react-native-elements';
import EmojisModal from '../../Components/Modals/EmojisModal';
import GifModal from '../../Components/Modals/gifModal';
import ImagePreviewModal from '../../Components/Modals/imagePreviewModal';
import { useSocket } from '../../Middleware/useSocket';
import AttachmentPreviewModal from '../../Components/Modals/AttachmentPreviewModal';
import Avatar from '../../Components/Ten4/Avatar';
import { useToast } from 'native-base';
const Ten4Chat = (props) => {

    const user = useSelector((state) => state.User.profile)?.user;
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const tenant = useSelector((state) => state.User.profile)?.tenant;
    const [messages, setMessages] = React.useState([]);
    const chatRef = React.useRef();
    const [message, setMessage] = React.useState('');
    const [mediaName, setMediaName] = React.useState('');
    const [contentType, setContentType] = React.useState('');
    const [mediaUrl, setMediaUrl] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [emojiModalVisible, setEmojiModalVisible] = React.useState(false);
    const [gifModalVisible, setGifModalVisible] = React.useState(false);
    const textInputRef = React.useRef(null);
    const [offsetCount, setOffsetCount] = React.useState(1);
    const [contentHeight, setContentHeight] = React.useState(0);
    const [typing, setTyping] = React.useState([]);
    const [imagePreviewVisible, setImagePreviewVisible] = React.useState(false);
    const [imageDetails, setImageDetails] = React.useState();
    const [file, setFile] = React.useState(null);
    const [attachmentPreviewVisible, setAttachmentPreviewVisible] = React.useState(false);
    const [isMentionsShown, setIsMentionsShown] = React.useState(false);
    const [members, setMembers] = React.useState([]);

    const socket = useSocket();

    React.useEffect(()=>{

        setOffsetCount(1);
        setTyping([]);
        if(props.channel?.id){   
            fetchChatMessages();
            getChatUserDetails();
        }
                
    },[props.channel]);

    const onMessage = React.useCallback(({data}) => {
        const parsed = JSON.parse(data);
        if((parsed.message.senderName === `#${props.channel.name}` && parsed.message.subject === 'New channel message') || props?.channel?.members?.filter(e => e.name === parsed.message.senderName)?.length > 0){
            fetchChatMessages();
            
        }
    })

    React.useEffect(()=>{
        if(socket){
            socket.addEventListener("message", onMessage);
            return () => {
                socket.removeEventListener("message", onMessage);
            }
        }
    }, [socket, onMessage])



    // React.useEffect(()=>{
    //     if(socketMessage && props.channel.id){
    //         if((socketMessage.senderName === `#${props.channel.name}` && socketMessage.subject === 'New channel message') || props?.channel?.members?.filter(e => e.name === socketMessage.senderName)?.length > 0){
    //             fetchChatMessages();
    //             setBadgeCountNumber(badgeCountNumber - 1);
    //             const copy = [...typing]
    //                 const name = String(socketMessage.senderName.split('#')[1]);
    //                 const index = copy.indexOf(name);
    //                 if(index > -1){
    //                     copy.splice(index,1)
    //                     setTyping(copy);
    //                 }
    //         }

    //         // if( (socketMessage.senderName === `#${props.channel.name}` || props?.channel?.members?.filter(e => e.name === socketMessage.senderName)?.length > 0) && socketMessage.subject === 'Activity Update'){
    //         //     if(socketMessage.body === 'typing'){
    //         //         const arr = [...typing];
    //         //         const name = String(socketMessage.senderName.split('#')[1]);
    //         //         arr.push(name);
    //         //         setTyping(arr);

    //         //         setTimeout(()=>{
    //         //             const index = arr.indexOf(name);
    //         //             if(index > -1){
    //         //                 setTyping(users => {
    //         //                     if(users?.length){
    //         //                         const copy = [...users]
    //         //                         const name = String(socketMessage.senderName.split('#')[1]);
    //         //                         const index = copy.indexOf(name);
    //         //                         if(index > -1){
    //         //                             copy.splice(index,1)
    //         //                             return copy;
    //         //                         }
    //         //                     }
    //         //                 })
    //         //             }
    
    //         //         }, 20000)

                    

    //         //     } else if(socketMessage.body === 'none'){
    //         //         const copy = [...typing]
    //         //         const name = String(socketMessage.senderName.split('#')[1]);
    //         //         const index = copy.indexOf(name);
    //         //         if(index > -1){
    //         //             copy.splice(index,1)
    //         //             setTyping(copy);
    //         //         }

    //         //     }
    //         // }

    //     }
    // },[socketMessage])

    const fetchChatMessages = () => {
        setIsLoading(true)
        const params = {
            channel: props.channel.id,
            affiliate: affiliate.id,
            user: user.user_id,
            limit: 100,
            offset: 0
        }

        getChatMessages({params: params})
        .then(result => {
            if(result?.data?.success){
                setMessages(result.data.messages);
            }
            setIsLoading(false);
            
            if(chatRef.current) chatRef.current.scrollToEnd({animated:false})
        })
    }

    const getChatUserDetails = () => {
         getChannelMembers({affiliate:affiliate.id, channel: props.channel.id})
        .then(result => {
            if(result.data.success){
                setMembers(result.data.members)
            }
        })
        .catch(err => {
            console.log(err);
        }) 
    }



    const Toast = useToast();

    const sendMessage = (fileUrl, contentType, mediaName) => {
        console.log('send',contentType);
        if(!fileUrl && !message){
            return;
        } else {
        setIsLoading(true);
        const params = {
            affiliate: affiliate.id,
            channel: props.channel.id,
            sender: user.user_id,
            message: message,
        }

        if(fileUrl) params.mediaUrl = fileUrl;
        if(mediaUrl) params.mediaUrl = mediaUrl;
        if(mediaName) params.mediaName = mediaName;
        if(contentType) params.contentType = contentType
        console.log("\r\n\r\n PARAMS:",params,"\r\n\r\n");

        createChatMessage({params: params})
        .then(result => {
            if(result.data.success){
                setMediaName('');
                setContentType('');
                setMessage('');
                setMediaUrl('');
                setIsLoading(false);
                if(textInputRef.current) textInputRef.current.focus();
            } else {
                Toast.show({status:'error', title: result?.data?.errorMessage ?? 'Something went wrong, please try again later.'})
            }
        })
        .catch(err => {
            Toast.show({status:'error', title: new Error(err).message})
        })
        .finally(()=>{
            fetchChatMessages();
            setMessage('');
            setIsLoading(false);
            setMediaName('');
            setContentType('');
            setMediaUrl('');
        })

        Keyboard.dismiss();
        }
    }

    const handleDocumentPicker = async () => {

        const res = await DocumentPicker.getDocumentAsync({
            type: '*/*',
        });

        if(res.type === 'success'){
            if (res.uri && res.file.size < 500000) {
                setFile(res);
                setAttachmentPreviewVisible(true);
                //handleSave(res.uri, res.name);
              } else {
                  alert('Please try a smaller image.')
              }
        }
        
    }

    const handleSave = () => {
        setIsLoading(true);
        saveFile({base64Data: file.uri, tenant: tenant.id })
        .then(result => {
            if(result?.data?.success){
                sendMessage(result.data.fileUrl, result.data?.contentType, file.name);
            } else {
                setMediaName('');
                setContentType('');    
            }
            setIsLoading(false);
        })
    }

    const renderItem = (item) => {
        const onImageClick = () => {
            setImagePreviewVisible(true);
            setImageDetails({mediaUrl: item.mediaUrl, mediaName: item.mediaName})
        }

        if(!item.senderuser){
            item.senderuser = {
                name: 'Missing User'
            }
        }
        return(
        <Ten4Message
                        userId={item.senderuser.user_id}
                        created={item.created}
                        message={item.message}
                        name={item.senderuser.name}
                        media={item.mediaUrl}
                        avatar={item.senderuser.avatar}
                        contentType={item.contentType}
                        mediaName={item.mediaName}
                        mediaUrl={item.mediaUrl}
                        onImageClick={onImageClick}
        />)
    }

    const sendGif = e => {
        setGifModalVisible(false);
        const params = {
            affiliate: affiliate.id,
            channel: props.channel.id,
            sender: user.user_id,
            contentType: 'image/gif',
            message: "",
            mediaUrl: e
        }

        createChatMessage({params: params})
        .then(result => {
            if(result?.data?.success){
                fetchChatMessages();
                // if(chatRef.current)chatRef.current.scrollToEnd();
            }
        })
    }

    const handleScroll = e => {
        const prevSize = e.nativeEvent.contentSize.height;

        const shouldLoadMoreMessages = e.nativeEvent.contentOffset.y === 0 && messages.length % 100 === 0
        if(shouldLoadMoreMessages){
            const params = {
                channel: props.channel.id,
                affiliate: affiliate.id,
                user: user.user_id,
                limit: 100,
                offset: offsetCount * 100
            }

            getChatMessages({params: params})
            .then(result => {
                if(result?.data?.success){
                    setMessages([...result.data.messages, ...messages]);

                    chatRef.current.scrollTo({
                        y: e.nativeEvent.layoutMeasurement.height + contentHeight,
                        x: 0,
                        animated: false,
                    });
                    
                }
            })

            setOffsetCount(offsetCount + 1);

        }
    }
    
  const handleChangeText = (e) => {
    setMessage(e);

    const newText = e;
    const lastChar = newText[newText.length - 1];
    const secondLastChar = newText.length > 1 ? newText[newText.length - 2] : null;
    
    if (lastChar === '@' && (newText.length === 1 || secondLastChar === ' ')) {
      setIsMentionsShown(true);
    } else {
        setIsMentionsShown(false)
    }
  };

  const handleMentionPress = data => {
    const {name, id} = data;
    setMessage(prevMessage => prevMessage + name + ' ');
    setIsMentionsShown(false);
    textInputRef.current.focus();
  }

    console.log('channel!', props.channel);
    return(

        <View style={{flex:1}}>
            <View style={{width:'100%',height:60,alignItems:'center',flexDirection:'row',paddingHorizontal:20}}>
                <View style={{flex:1}}>
                    {props.channel.avatar ?
                <img src={props.channel.avatar} style={{borderRadius:100}} height={40} width={40}/>
                :
                <Icon name='people-circle' style={{height:40,width:40}} type='ionicon' color='black' size={40}/>
                }
                </View>
                <View style={{flex:1,justifyContent:'center',alignItems:'center',}}>
                <Text style={{fontSize:18}}>
                {props.channel.name}
                </Text>
                </View>
                <View style={{flex:1}}/>
            </View>
            <ScrollView contentContainerStyle={{flex:1}} onContentSizeChange={e => setContentHeight(e)} scrollEventThrottle={400} onScroll={handleScroll} ref={chatRef} style={{paddingRight:20}}>
                {messages.map(e => {
                    return renderItem(e)
                })}
                {isMentionsShown && <MentionsModal onPress={handleMentionPress} message={message} users={props.users} members={members}/>}

            </ScrollView>
            
            {
            !!props.channel.id && (
            <View style={{height:'5em'}}>
            <View style={{width:'100%',height:'100%',alignItems:'center',justifyContent:'center',flexDirection:'row',borderTopWidth:1,borderTopColor:'rgba(0,0,0,.2)'}}>
                <TextInput
                    ref={textInputRef}
                    multiline
                    value={message}
                    style={{flex:1,height:'95%',paddingLeft:10,paddingTop:10}}
                    placeholderTextColor='gray'
                    placeholder={`Message #${props.channel.name}`}
                    onChangeText= {handleChangeText}
                    onKeyPress={e => {
                        if(e.keyCode === 13){
                            if(!e.shiftKey) sendMessage();
                        }
                    }}
                />
        
                <View style={{alignItems:'center',marginHorizontal:10,}}>

                    <View style={{flexDirection:'row',marginBottom:10}}>
                    <Entypo onPress={handleDocumentPicker} style={{marginRight:10}} name={'attachment'} color={AppColor.PrimaryColor} size={24} />

                    <Icon type='materialicons' style={{marginRight:10}} onPress={() => setGifModalVisible(true)} name='personal-video' size={26} color={AppColor.PrimaryColor} />

                    <Icon type='feather' style={{marginRight:10}} onPress={() => setEmojiModalVisible(true)} name='smile' size={26} color={AppColor.PrimaryColor} />
                    </View>

                    <TouchableOpacity onPress={() => sendMessage()}  style={{justifyContent:'center',alignItems:'center',backgroundColor:AppColor.PrimaryColor,borderRadius:5,width:'100%',height:35,marginRight:10}}>
                        <Ionicons name='send' size={18} color='white' />
                    </TouchableOpacity>

                </View>

            </View>
            {
            // !!typing?.length &&
            // <Text style={{paddingLeft:10}}>{typing?.length > 1 ? 'Several people are typing...' : `${typing[0]} is typing...`}</Text>
            }
            </View>
            )
            }
            {!!emojiModalVisible && (
            
            <EmojisModal
            onPressEmoji={e => {
                setMessage(message + e);
                setEmojiModalVisible(false);
            }}
            visible={emojiModalVisible}
            setVisible={setEmojiModalVisible}
            />)
            }

            {
                !!gifModalVisible && (
                <GifModal
                visible={gifModalVisible}
                setVisible={setGifModalVisible}
                onPressGif={sendGif}
                />)
            }
            {
                !!imagePreviewVisible && 
                <ImagePreviewModal
                visible={imagePreviewVisible}
                setVisible={setImagePreviewVisible}
                image={imageDetails}
                />
            }
            {
                !!attachmentPreviewVisible && 
                <AttachmentPreviewModal
                visible={attachmentPreviewVisible}
                setVisible={setAttachmentPreviewVisible}
                onPress={() => {
                    handleSave()
                    setAttachmentPreviewVisible(false);
                }}
                file={file}
                />
            }


            {isLoading && <Loader />}

        </View>

    )
}


const MentionsModal = props => {
    const renderItem = ({item}) => {
        const obj = props?.users?.find(e => e.user_id === item.userId );
        const name = obj?.name;
        const split = props.message.split("@");
        const mention = split[split.length - 1];
           
        if(!name || !obj.name.toUpperCase().includes(mention.toUpperCase())) return null;

        return(
            <TouchableOpacity style={{flexDirection:'row',alignItems:'center',marginBottom:15}} onPress={() => props.onPress({name: name, id: item.userId})}>
            <Avatar name={name} avatar={obj?.avatar}/>
            <Text style={{marginLeft:5,fontSize:16,color:AppColor.PrimaryColor}}>
                @{name}
            </Text>
            </TouchableOpacity>
        )
    }

    return(
        <div style={{position:'absolute',width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
            <div style={{width:'100%',alignSelf:'center',minHeight:200,maxHeight:400,paddingLeft:10,overflowY:'scroll',boxShadow:'-10px 0px 5px 0px rgba(0,0,0,.3)'}}>
                <FlatList
                style={{flex:1}}
                data={props.members}
                keyboardShouldPersistTaps={true}
                renderItem={renderItem}
                contentContainerStyle={{paddingTop:15,paddingBottom:20}}
                />

            </div>
        </div>
    )
}



export default Ten4Chat;
