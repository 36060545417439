import * as React from 'react';
import {
  Text,
  View,
  ScrollView,
  Image,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from 'react-native';
import RequestMaker from '../../Middleware/ApiCaller';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDrawerNavigator,
  DrawerItemList,
} from '@react-navigation/drawer';
import { Icon } from 'react-native-elements';
import { DrawerActions } from '@react-navigation/routers';
import BusinessCard from './BusinessCard';
import MyRepChatNum from './MyRepChatNum/MyRepChatNum';
import Payment from './Payment';
import Finished from './Finished';
import CRM from './CRM';
import Archive from './Archive';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { clearProfile } from '../../Redux';
import End_Points from '../../Constants/Api';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk,
} from '../../Redux/integrations';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const Drawerscreen = createDrawerNavigator();

export default function SignupWizard({ navigation }) {
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  const dispatch = useDispatch();
  const { step } = useSelector((state) => state.signupWizard);

  React.useEffect(() => {
    dispatch(loadIntegrationProviderThuunk());
    dispatch(loadIntegrationsThuunk());
  }, []);
  const { isWebCompact } = useResponsiveLayout();

  const permissions = useSelector((state) => state.User.profile?.user?.permissions[0].label);
  if(permissions == "System Administrator" || permissions == "Group Administrator") Linking.openURL(End_Points.Base_url + '/dart/web/index.html');

  const renderItem = (focused, completedStep, label) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: 50,
          paddingHorizontal: 18,
        }}>
        {focused ? (
          <Icon
            name={'east'}
            type={'material'}
            color={AppColor.SecondaryColor}
            size={20}
          />
        ) : (
          completedStep && (
            <Icon
              name={'done'}
              type={'material'}
              color={AppColor.SecondaryColor}
              size={20}
            />
          )
        )}
        <Text
          style={{
            fontSize: 16,
            color: AppColor.SecondaryColor,
            fontFamily: AppFonts.Roboto_Bold,
            marginLeft: 15,
          }}>
          {label}
        </Text>
      </View>
    );
  };

  const CustomSidebarMenu = (props) => {
    return (
      <>
        <View style={{ flex: 1, backgroundColor: AppColor.PrimaryColor }}>
          <img
          alt="MyRepChat Logo."
            src={require('../../Assets/Images/mrc-logo-white.png')}
            style={{maxWidth: 200,
              marginBottom: 50,
              alignSelf:'center',
          resizeMode:'contain'}}
          />
          {renderItem(props.state.index === 0, step.phone, 'MyRepChatNum')}
          {renderItem(
            props.state.index === 1,
            step.card,
            'Virtual Business Card'
          )}
          {renderItem(props.state.index === 2, step.crm, 'CRM')}
          {renderItem(props.state.index === 3, step.archive, 'Archive')}
          {renderItem(props.state.index === 4, step.payment, 'Payment')}
          {renderItem(props.state.index === 5, step.finished, 'Finished')}
        </View>
      </>
    );
  };

  const handleLogout = async () => {
    const body = {
      action: 'logout',
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    let result = await RequestMaker(Config);
    if (result.status == 200) {
      dispatch(clearProfile());
      navigation.dispatch(DrawerActions.closeDrawer());
      navigation.navigate('Login');
    }

  }

  return (
    <Drawerscreen.Navigator
      initialRouteName="MyRepChatNum"
      drawerPosition="left"
      drawerType={isWebCompact ? 'slide' : 'permanent'}
      edgeWidth={10}
      overlayColor="transparent"
      drawerContent={(props) => (
        <>
          <CustomSidebarMenu
            navigation={navigation}
            setDrawerOpen={(x) => setDrawerOpen(x)}
            isdrawerOpen={drawerOpen}
            {...props}
          />
          <View
            style={{
              backgroundColor: AppColor.PrimaryColor,
              paddingBottom: 30,
            }}>
            <TouchableOpacity
              accessible={true}
              accessibilityRole='button'
              accessibilityLabel='Support.'
              onPress={() => navigation.navigate('Support')}
              style={{
                height: 35,
                flexDirection: 'row',
                marginHorizontal: 20,
              }}>
              <View style={{ justifyContent: 'center' }}>
                <Icon
                  style={{ color: AppColor.SecondaryColor }}
                  color={AppColor.SecondaryColor}
                  name={'help-outline'}
                  type={'MaterialIcons'}
                />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  flex: 1,
                  alignItems: 'flex-start',
                }}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 16,
                    color: AppColor.SecondaryColor,
                    marginLeft: 15,
                  }}>
                  Support
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              accessible={true}
              accessibilityRole='button'
              accessibilityLabel='Log out.'
              onPress={handleLogout}
              style={{
                height: 35,
                flexDirection: 'row',
                marginHorizontal: 20,
              }}>
              <View style={{ justifyContent: 'center' }}>
                <Icon
                  style={{ color: AppColor.SecondaryColor }}
                  color={AppColor.SecondaryColor}
                  name={'logout'}
                  type={'MaterialCommunityIcons'}
                />
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  flex: 1,
                  alignItems: 'flex-start',
                }}>
                <Text
                  style={{
                    fontFamily: AppFonts.Roboto_Regular,
                    fontSize: 16,
                    color: AppColor.SecondaryColor,
                    marginLeft: 15,
                  }}>
                  Logout
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </>
      )}
      drawerStyle={{
        backgroundColor: AppColor.PrimaryColor,
        width: 300,
        height: '100%',
        elevation: drawerOpen ? 5 : 0,
        shadowColor: '#000',
        shadowOffset: {
          width: drawerOpen ? -5 : 0,
          height: drawerOpen ? 5 : 0,
        },
        shadowOpacity: 0.34,
        shadowRadius: drawerOpen ? 6.27 : 0,
        paddingTop: 50,
      }}
      drawerContentOptions={{
        labelStyle: {
          color: 'white',
          textAlign: 'justify',
          fontSize: 16,
          fontFamily: AppFonts.Roboto_Regular,
        },
        activeTintColor: '#598527',
        itemStyle: {
          backgroundColor: AppColor.PrimaryColor,
          marginLeft: 0,
          width: '100%',
          height: 50,
          borderColor: '#598527',
          marginVertical: 0,
          borderRadius: 0,
          paddingHorizontal: 18,
        },
        contentContainerStyle: {
          backgroundColor: AppColor.PrimaryColor,
        },
        style: {
          backgroundColor: AppColor.PrimaryColor,
        },
      }}>
      <Drawerscreen.Screen
        name={'MyRepChatNum'}
        component={MyRepChatNum}
        key={'MyRepChatNum'}
        options={{
          drawerLabel: ({ focused }) =>
            renderItem(focused, step.phone, 'MyRepChatNum'),
          title: `MyRepChat | MyRepChatNum`,
        }}
      />
      <Drawerscreen.Screen
        name={'BusinessCard'}
        component={BusinessCard}
        options={{
          drawerLabel: ({ focused }) =>
            renderItem(focused, step.card, 'Virtual Business Card'),
          title: `MyRepChat | BusinessCard`,
        }}
      />
      <Drawerscreen.Screen
        name={'CRM'}
        component={CRM}
        options={{
          drawerLabel: ({ focused }) => renderItem(focused, step.crm, 'CRM'),
          title: `MyRepChat | CRM`,
        }}
      />
      <Drawerscreen.Screen
        name={'SignupArchive'}
        component={Archive}
        options={{
          drawerLabel: ({ focused }) =>
            renderItem(focused, step.archive, 'Archive'),
          title: `MyRepChat | Archive`,
        }}
      />
      <Drawerscreen.Screen
        name={'Payment'}
        component={Payment}
        options={{
          drawerLabel: ({ focused }) =>
            renderItem(focused, step.payment, 'Payment'),
          title: `MyRepChat | Payment`,
        }}
      />
      <Drawerscreen.Screen
        name={'Finished'}
        component={Finished}
        options={{
          drawerLabel: ({ focused }) =>
            renderItem(focused, step.finished, 'Finished'),
          title: `MyRepChat | Finished`,
        }}
      />
    </Drawerscreen.Navigator>
  );
}
