import React from "react";
import { ScrollView, Text, View } from "react-native";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import AppFonts from "../../Constants/Fonts";
import moment from "moment";

const LogDetails = ({log}) => {

    const {data, isLoading} = getQuery({
        action: 'get_flagged_log_details',
        auditId: log.id
    })

    if (isLoading) return <Loader backgroundColor={'transparent'} />;
    if (!data) return null;

    const messageAction = JSON.parse(log.detail).messageAction;
    const capitalizedMessageAction = messageAction.charAt(0).toUpperCase() + messageAction.slice(1);

    const rejectReason = JSON.parse(log.detail)?.reason || null;

    return(
        <ScrollView contentContainerStyle={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>Created at</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{moment(data.flaggedCommunication.created).format('MMMM DD YYYY hh:mm a')}</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Account</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{data.flaggedCommunication.account}</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Pattern</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{data.flaggedCommunication.pattern}</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Action</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{capitalizedMessageAction}</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Admin</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{data.admin.name} ({data.admin.email})</Text>
            {rejectReason &&
            <>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Reason</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{rejectReason}</Text>
            </>
            }
        </ScrollView>
    )
}

export default LogDetails;