import moment from 'moment';
import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import WebView from 'react-native-webview';
import { Content } from '../../Components/Layout';
import CustomButton from '../../Components/Button';
import getData from '../../AsyncUtils/GetData';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import { Icon } from 'react-native-elements';
import AppColor from '../../Constants/Color';

const ViewCalendarEvent = props => {
    const item = props.item;
    let date = moment(item.startTimestamp).format('DD-MM-YY');
    let startTime = moment(item.startTimestamp).format('hh:mm a');
    let endTime = moment(item.endTimestamp).format('hh:mm a');
    const category = props?.categories?.find(e => e?.sourceId === item?.categoryId)?.name;
    const updateCalendarEvent = async () => {
        const body = {
          action:'update_calendarevent',
          ...item,
          isTrusted: false,
          unread: false
        }
        try {
          
          let device = await getData('@device_id');
          const headers = { 'x-device': device };
          let Config = {
            method: 'post',
            url: End_Points.Base_url + End_Points.End,
            data: body,
            headers: headers,
            redirect: 'follow',
          };
          let result = await RequestMaker(Config);
          if (result.data.success) {
            props.onUpdate();
          }
        } catch (err) {
          console.log(err);
        }
    }

    const scheduleCalendarEvent = async () => {
        console.log(props.id);
        const body = {
            action:'convert_calendarevent',
            id: item.id,
          }
          try {
            
            let device = await getData('@device_id');
            const headers = { 'x-device': device };
            let Config = {
              method: 'post',
              url: End_Points.Base_url + End_Points.End,
              data: body,
              headers: headers,
              redirect: 'follow',
            };
            let result = await RequestMaker(Config);
            if (result.data.success) {
              props.onSave();
            }
          } catch (err) {
            console.log(err);
          }  
    }
    

    return(
        <ScrollView style={{padding: 30,flex:1}}>
            <Text style={styles.cellTitle}>Title</Text>
            <Text style={styles.cellText}>{item.title}</Text>
            <Text style={styles.cellTitle}>Category</Text>
            <Text style={styles.cellText}>{category}</Text>
            <Text style={styles.cellTitle}>Invitees</Text>
            {
                item.invitees.map(e => {
                    return(
                        <Text style={styles.cellText}>{e.name}</Text>
                    )
                })
            }
            <Text style={styles.cellTitle}>Date</Text>
            <Text style={styles.cellText}>{date}</Text>
            <Text style={styles.cellTitle}>Start Time</Text>
            <Text style={styles.cellText}>{startTime}</Text>
            <Text style={styles.cellTitle}>End Time</Text>
            <Text style={styles.cellText}>{endTime}</Text>
            { item.body && 
            <>
            <Text style={styles.cellTitle}>Description</Text>
            <WebView style={{backgroundColor:'#f6f6f6'}} originWhitelist={['*']} source={{html: item.body}}/>
            </>
            }
            {item?.Message && 
            <>
            <Text style={styles.cellTitle}>Message</Text>
            <Text style={styles.cellText}>{item.Message}</Text>

            </>
            
            } 
            {
               ( item.unread && !item.scheduled && item.id ) && 
               <Content.Row style={{marginTop:10,padding:5}}>
                <Icon type='material-icon' name='warning' color={AppColor.danger}/>
                <Text style={{marginLeft:10}}>This event has not yet been registered as a scheduled message and will not be delivered until you click 'Schedule'.  To ignore this event, click 'Ignore'. </Text>
               </Content.Row>
            }
            <Content.Row style={{justifyContent:'space-between',marginTop:20}}>
                <CustomButton onPress={scheduleCalendarEvent} disabled={item.scheduled || !item.id} width={'40%'} title='Schedule'/>
                <CustomButton onPress={updateCalendarEvent} disabled={!item.unread} width={'40%'} title='Ignore'/>
            </Content.Row>


        </ScrollView>
    )
}

const styles = StyleSheet.create({
    cellTitle: {
        marginVertical:10
    },
    cellText:{
        marginBottom:10,
        fontSize:16,
        backgroundColor: '#F6F6F6',
        paddingVertical:15,
        paddingLeft:10
    }
})

export default ViewCalendarEvent;