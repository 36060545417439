import React from 'react';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';

const Accessibility = ({ navigation }) => {
    const [isLoading, setIsLoading] = React.useState(true);

    return(
        <>
        
        <iframe onLoad={()=>setIsLoading(false)} src="https://customer-portal.audioeye.com/accessibility-statement.html?domain=dart.ionlake.com" title="Accessibility Statement" style={{width:'100%',height:'4700px'}} frameborder={1}>
        </iframe>


        {isLoading && <Loader/>}
        </>

    )
}

export default Accessibility;
