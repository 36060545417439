import React from "react";
import { Switch, Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import useUpdateAffiliate from "../useUpdateAffiliate";
import CustomButton from "../../Components/Button/index.web";

const LoginReview = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const [data, setData] = React.useState({
        reviewAccountLogins: affiliate?.reviewAccountLogins || false
    })

    return(
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>If you want to have additional logins that are added to the account placed into a review state, then you can turn on the login review feature here. The group admin will receive an email when a new login is added to an account and will need to login to this admin portal, locate the account, review the login, and approve the login before it can be used.</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Login Review</Text>
            <Switch
                style={{marginTop:10}}
                onValueChange={e => setData({ ...data, reviewAccountLogins: e })}
                value={data.reviewAccountLogins}
            />

            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end', marginBottom:10,marginRight:10 }}
            />

        
        </>
    )
}

export default LoginReview;