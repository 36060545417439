import React from "react";
import SimpleDropdown from "../../Components/SimpleDropdown";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import CustomButton from "../../Components/Button/index.web";
import End_Points from "../../Constants/Api";
import { useSelector } from "react-redux";

const ExportContacts = ({tenant}) => {

    const Tenant = tenant.data.tenant;
    const contactTypes = [
        {
            label: "All Active",
            value: 'complete'
        },
        {
            label: "New Since Last Month",
            value: 'new'
        },
        {
            label: 'Expiring After the First of Last Month',
            value: 'expiring'
        },
        {
            label: 'List for Newsletter',
            value: 'newsletter'
        },
        {
            label: 'Newsletter Mailing',
            value: 'newsletter_mail'
        },
        {
            label: 'Newsletter E-mailing',
            value: 'newsletter_email'
        }
    ]

    const exportFields = [
        {id: "id", label: "Id", defaults: ["complete", "new", "expiring", "newsletter_mail", "newsletter_email"], hint: "number"},
        {id: "fname", label: "First Name", defaults: ["all"], hint: "64 max"},
        {id: "lname", label: "Last Name", defaults: ["all"], hint: "64 max"},
        {id: "address", label: "Address", defaults: ["complete", "new", "expiring", "newsletter_mail"], hint: "128 max"},
        {id: "city", label: "City", defaults: ["complete", "new", "expiring", "newsletter_mail"], hint: "64 max"},
        {id: "state", label: "State", defaults: ["complete", "new", "expiring", "newsletter_mail"], hint: "32 max"},
        {id: "zip", label: "Zip Code", defaults: ["complete", "new", "expiring", "newsletter_mail"], hint: "16 max"},
        {id: "blocked", label: "Blocked", defaults: [], hint: "24 max"},
        {id: "messaging", label: "Messaging Number", defaults: [], hint: "24 max"},
        {id: "consented", label: "Consented", defaults: [], hint: "24 max"},
        {id: "mobile", label: "Mobile", defaults: [], hint: "24 max"},
        {id: "home", label: "Home", defaults: [], hint: "24 max"},
        {id: "office", label: "Office", defaults: [], hint: "24 max"},
        {id: "fax", label: "Fax", defaults: [], hint: "24 max"},
        {id: "other", label: "Other", defaults: [], hint: "24 max"},
        {id: "email_address", label: "Email", defaults: ["newsletter_email"], hint: "128 max"},
        {id: "join_date", label: "Join Date", defaults: ["new"], hint: "date"},
        {id: "exp_date", label: "Expiration Date", defaults: ["expiring", "newsletter_mail", "newsletter_email"], hint: "date"},
        {id: "email_newsletter", label: "Email Newsletter", hint: "t/f"},
        {id: "mail_newsletter", label: "Mail Newsletter", hint: "t/f"},
        {id: "list_in_directory", label: "List in Newsletter", hint: "t/f"},
        {id: "tags", label: "Tags", defaults: ["complete"], hint: "tag|tag"},
        {id: "source", label: "Source", hint: "redtail"},
        {id: "sourceId", label: "Source Id", hint: "ABC123"}
    ]

    const [selectedFields,setSelectedFields] = React.useState([]);
    const [selectedType, setSelectedType] = React.useState('complete');

    const handleCheck = (id) => {
        if(selectedFields.includes(id)){
            setSelectedFields(selectedFields.filter(e => e !== id))
        } else {
            setSelectedFields([...selectedFields,id])
        }
    }

    const handleExport = () => {
        window.open(End_Points.Base_url + "/dart/core?action=get_all_members&type=" + 'complete' + "&format=csv&columns=" + selectedFields.join() + "&tenantUUID=" + Tenant.uuid + "&token=" + String(localStorage.getItem('token')), '_blank');
    }

    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:10}}>
            Export the contacts within this account so that users can edit them in CSV and re-import or transfer them to another vendor.
            </Text>
            <SimpleDropdown
                data={contactTypes}
                value={selectedType}
                onChange={e => setSelectedType(e)}
            />

            <ScrollView style={{flex:1,marginTop:20,padding:15,borderColor:'black',borderWidth:1,backgroundColor:'white',borderRadius:5}}>
                {
                    exportFields.map(e => {
                        return(
                            <SimpleCheckbox onChange={_ => handleCheck(e.id)} label={e.label} value={selectedFields.includes(e.id)}/>
                        )
                    })
                }
            </ScrollView>

            <CustomButton title={'Export'} width={'20%'} onPress={handleExport} style={{marginTop:10,alignSelf:'flex-end'}}/>
        </View>
    )
}

export default ExportContacts;