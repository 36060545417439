import React from "react";
import { Content, Wrapper } from "../../Components/Layout";
import Loader from "../../Components/Loader/index.web";
import getQuery from "../getQuery";
import { TouchableOpacity, View, Text, Modal } from "react-native";
import Search from "../../Components/Search";
import NewAlphabetList from "../../Screens/Contacts/NewAlphabetList";
import ListHeader from "../../Components/AlphabetList/ListHeader";
import { Icon } from "react-native-elements";
import AppFonts from "../../Constants/Fonts";
import moment from "moment";
import ModalWrapper from "../../Components/Modals/ModalWrapper";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const Resellers = ({ navigation, route }) => {
    const { data, isLoading, refetch } = getQuery({
        action: "get_resellers",
    })

    const Toast = useToast();

    const [createResellerModal, setCreateResellerModal] = React.useState(false);
    const [newReseller, setNewReseller] = React.useState({
        name: '',
        billingEmail: '',
    })
    const [search, setSearch] = React.useState('');


    if (isLoading) return <Loader backgroundColor={'transparent'} />
    if (!data) return null;

    const handleCreateReseller = async () => {
        try {
            const body = {
                action: 'create_reseller',
                name: newReseller.name,
                billingEmail: newReseller.billingEmail,
                addonNotifications: false,
                handlesBilling: false
            }
            const result = await RequestMaker(body);
            if (result.data.success) {
                Toast.show({
                    title: 'Reseller created successfully',
                    status: 'success',
                })
                setCreateResellerModal(false);
                refetch();
            } else {
                throw new Error(result.data?.errorMessage ?? 'Something went wrong');
            }
        } catch (e) {
            Toast.show({
                title: new Error(e).message,
                status: 'error',
            })
        }
    }

    const renderResellersList = ({ item }) => {
        return (
            <TouchableOpacity onPress={() => navigation.navigate('Reseller', { reseller: item })} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name='building-o' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} />
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{item.name}</Text>
                </View>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center', }}>
                    <Text style={{ textAlign: 'left', maxWidth: '80%', fontFamily: AppFonts.Roboto_Regular, }}>{item.billingEmail}</Text>
                </View>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ textAlign: 'left', fontFamily: AppFonts.Roboto_Regular }}>{moment(item.created).format('MMM D YYYY')}</Text>
                </View>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    {item.handlesBilling ? <Icon name='check' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} /> : <Icon name='times' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} />}
                </View>
                <View style={{ width: '20%', flexDirection: 'row', alignItems: 'center' }}>
                    {item.addonNotifications ? <Icon name='check' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} /> : <Icon name='times' type='font-awesome' size={28} style={{ marginLeft: 12, marginRight: 12 }} />}
                </View>



            </TouchableOpacity>

        )
    }

    return (
        <Wrapper>
            <Content>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Search actionButton actionButtonTitle={'Reseller'} onPressActionButton={() => setCreateResellerModal(true)} containerStyle={{ flex: 1 }} onChangeText={val => setSearch(val)} />
                </View>
                <NewAlphabetList
                    sortBy={'name'}
                    data={data.resellers.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))}
                    RenderItem={renderResellersList}
                    ListHeaderComponent={() => <ListHeader sortBy={null} columns={['Reseller', 'Email', 'Created', 'Handles Billing', 'Notifications']} />}
                />
            </Content>
            {createResellerModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={createResellerModal} transparent={true}>
                    <ModalWrapper wrapperStyle={{ maxHeight: 600 }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Create a new reseller</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginVertical: 20 }}>A name for the reseller, as well as an email address for billing and notifications. Note that this will not create logins for the reseller, logins and all other configuration are done by editing the reseller after creating it.</Text>
                        <CustomInput value={newReseller.name} onChangeText={e => setNewReseller({ ...newReseller, name: e })} title={'Reseller/Organization Name*'} />
                        <CustomInput value={newReseller.billingEmail} onChangeText={e => setNewReseller({ ...newReseller, billingEmail: e })} titleStyle={{ marginTop: 10 }} title={'Email Adress*'} />
                        <View style={{ justifyContent: 'flex-end', flexDirection: 'row', marginTop: 20, alignItems: 'center', gap: 10 }}>
                            <CustomButton width={'30%'} outline title={'Cancel'} onPress={() => setCreateResellerModal(false)} />
                            <CustomButton width={'30%'} title={'Save'} onPress={handleCreateReseller} />

                        </View>
                    </ModalWrapper>
                </Modal>

            }
        </Wrapper>
    )
}

export default Resellers;