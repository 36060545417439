import { useToast } from 'native-base';
import React from 'react';
import {
  Text,
  View,
  Image,
  FlatList,
  PermissionsAndroid,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import Search from '../../Components/Search';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import Contacts from 'react-native-contacts';
import CustomButton from '../../Components/Button';
import { useDispatch } from 'react-redux';
import { loadAllContactsThunk } from '../../Redux/contacts';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import Loader from '../../Components/Loader';
import AlphabetList from 'react-native-flatlist-alphabet';

const ContactFromPhone = ({ navigation }) => {
  const Toast = useToast();
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');
  const [allContactData, setAllContactData] = React.useState([]);
  const [contactsData, setContactsData] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const data = {
    action: 'import_members',
    objects: [],
    map: {
      fname: 'fname',
      lname: 'lname',
      address: 'address',
      city: 'city',
      state: 'state',
      zip: 'zip',
      mobile: 'mobile',
      phone: 'phone',
    },
  };

  React.useEffect(() => {
    console.log(Platform.OS);
    // if(Platform.OS=="web"){

    // }else{
    //     if(Platform.OS=="ios"){
    //         Contacts.getAll().then(contacts => {
    //             console.log(contacts)
    //             setContactsData(contacts.filter(x => x.phoneNumbers?.length > 0).map(x=>{
    //                 x.displayName=x.givenName+" "+x.middleName+" "+x.familyName
    //                 return x
    //             }))
    //         })
    //         return
    //     }
    //     PermissionsAndroid.request(
    //         PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
    //         {
    //             'title': 'Contacts',
    //             'message': 'This app would like to view your contacts.',
    //             'buttonPositive': 'Please accept bare mortal'
    //         }
    //     )
    //         .then(() => {
    //             Contacts.getAll().then(contacts => {
    //                 setContactsData(contacts.filter(x => x.phoneNumbers?.length > 0))
    //             })
    //         })
    // }
  }, []);

  React.useEffect(() => {
    setAllContactData(contactsData);
  }, [contactsData]);

  React.useEffect(() => {
    setAllContactData(
      contactsData.filter((x) =>
        x.displayName.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search]);

  const importSelected = async () => {
    if (selected.length > 0) {
      const arr = selected.map((x) => {
        let obj = {
          fname: '',
          lname: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          mobile: '',
          phone: '',
        };
        for (let ph of x.phoneNumbers) {
          if (ph.label == 'mobile') {
            obj['mobile'] = ph.number.match(/\d+/g).join('');
          } else {
            obj['phone'] = ph.number.match(/\d+/g).join('');
          }
        }
        obj.fname = x.givenName;
        obj.lname = x.familyName;
        return obj;
      });
      data.objects = arr;
      try {
        setLoading(true);
        const body = data;
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        console.log('result', result);
        if (result.status == 200) {
          setSelected([]);
          Toast.show({
            duration: 2000,
            title: result.data.message,
            status: 'success',
          });
          dispatch(loadAllContactsThunk());
        } else {
          Toast.show({
            duration: 2000,
            title: result.data.errorMessage,
            status: 'error',
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };
  const renderListView = ({ item }) => {
    const index = selected.findIndex((x) => x.displayName == item.displayName);
    const backgroundColor = index == -1 ? '#fff' : '#5B84B1FF';
    const textColor = index == -1 ? AppColor.Dark : 'white';
    const iconColor = index == -1 ? AppColor.PrimaryColor : 'white';
    return (
      <TouchableOpacity
        onPress={() => {
          if (index != -1) {
            let arr = [...selected];
            arr.splice(index, 1);
            setSelected(arr);
          } else {
            setSelected((state) => [...state, item]);
          }
        }}
        style={{
          width: '100%',
          paddingHorizontal: 20,
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 60,
          backgroundColor: backgroundColor,
        }}>
        <View style={{ justifyContent: 'center' }}>
          <MaterialCommunityIcons name="contacts" color={iconColor} size={20} />
        </View>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 17,
              marginHorizontal: 10,
              color: textColor,
            }}>
            {item?.displayName}
          </Text>
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 14,
              marginHorizontal: 10,
              color: textColor,
            }}>
            {item?.phoneNumbers?.[0].number}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Header
        title={'Import Contacts'}
        back={true}
        onPress={() => navigation.goBack()}
      />
      <Search onChangeText={(t) => setSearch(t)} placeholder="Filter by name" />
      {allContactData.length == 0 && (
        <View>
          <Text style={{ textAlign: 'center', flex: 1 }}>
            This feature is not available in web.
          </Text>
        </View>
      )}
      <View style={{ flex: 1, paddingVertical: 15, paddingHorizontal: 30 }}>
        <AlphabetList
          data={allContactData.map((x, i) => ({
            value: x.displayName,
            item: x,
            key: x.id + '' + i,
          }))}
          renderItem={renderListView}
          renderSectionHeader={(d) => {
            return (
              <View style={{ marginHorizontal: 10, marginVertical: 5 }}>
                <Text
                  style={{
                    backgroundColor: AppColor.PrimaryColor,
                    width: 20,
                    textAlign: 'center',
                    color: 'white',
                  }}>
                  {d.title}
                </Text>
              </View>
            );
          }}
          sectionHeaderHeight={40}
          indexLetterColor={AppColor.PrimaryColor}
          letterItemStyle={{ marginTop: 15 }}
          indexLetterSize={18}
        />
      </View>
      {/* </Content> */}
      <View style={{ marginVertical: 10 }}>
        {/* <CustomButton title="Import Selected" onPress={importSelected} /> */}
      </View>
      {loading && <Loader />}
    </SafeAreaView>
  );
};
export default ContactFromPhone;
