import axios from "axios";
import getData from "../AsyncUtils/GetData";
import End_Points from "../Constants/Api";
import { MakeConfig } from "./MakeConfig";

export const generateSSHKeys = async () => {
    const body = {
        action: 'generate_ssh_keys'
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}

export const getProviderData = async (provider, group) => {

      const body = {
        action: 'get_integrations',
      };

      if (provider) {
        body.provider = provider.name;
      }
      if (group) {
        body.group = group;
      }

      
      let device = await getData('@device_id');

      const headers = { 'x-device': device };

      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };

      return axios(Config);
      
};

export const updateIntegrationData = async ({provider: provider, integration: integration, tenant: tenant, attributes: attributes, group: group}) => {
    const body = {
        action: 'update_integration',
        provider: provider.name,
        type: provider.type.name,
        id: integration.id,
        frequency: 1,
      };
      
    if(tenant){
        body.tenant = tenant.id;
    }

    if(group){
        body.group = group;
    }
  
    if (attributes) {
        for (let attr of Object.keys(attributes)) {
            body[attr] = attributes[attr];
        }
    }

    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
    };
    return axios(Config);
}

export const createIntegrationData = async ({provider: provider, tenant: tenant, attributes: attributes, group: group}) => {
    const body = {
        action: 'create_integration',
        provider: provider.name,
        type: provider.type.name,
        frequency: 1,
    };
    if(tenant){
        body.tenant = tenant.id;
    }
    if(group){
        body.group = group;
    }
    if (attributes) {
        for (let attr of Object.keys(attributes)) {
            body[attr] = attributes[attr];
        }
    }

    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
    };

    return axios(Config);
}


export const createIntegration = async ({params: params}) => {
    const body = {
        action: 'create_integration',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);

}


export const createArchiveSchedule = async ({provider: provider, tenant: tenant, attributes: attributes, affiliateId: affiliateId}) => {

    const body = {
        action: 'create_archive_schedule',
        provider: provider.name,
        type: provider.type.name,
        email: attributes?.email,
        active: attributes?.active,
        frequency: 1,
        frequencyHours: 24,
        layoutId: 2,
        locked: false,
        startTimestamp: new Date().setHours(0, 0, 0),
        state: 'active',
    };

    if (affiliateId) {
        body.affiliateId = affiliateId;
    }
    if (tenant) {
        body.tenantId = tenant.id;
    }

    
    let device = await getData('@device_id');

    const headers = { 'x-device': device };

    let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
    };

    return axios(Config);
}

export const getIntegrationProviders = async () => {

    const body = {
        action: 'get_integration_providers',
        type: 'archive',
    };

    
    let device = await getData('@device_id');

    const headers = { 'x-device': device };

    let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
    };

    return axios(Config);

}