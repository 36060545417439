
 export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }


  export function formatNumber(phoneNumber) {
    if (typeof phoneNumber === 'undefined' || !phoneNumber) return;
    
    const cleanedNumber = ('' + phoneNumber).replace(/\D/g, ''); // Remove all non-digit characters
    const country = cleanedNumber[0] === '1' ? '1' : ''; // Check if the first digit is '1', set as the country code
    const mainNumber = country === '1' ? cleanedNumber.slice(1) : cleanedNumber;
    const areaCode = mainNumber.slice(0, 3);
    const localPrefix = mainNumber.slice(3, 6);
    const localSuffix = mainNumber.slice(6, 10);
  
    return `+${country}(${areaCode}) ${localPrefix}-${localSuffix}`;
  }