import React from "react";
import { ScrollView, Switch, Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import useUpdateAffiliate from "../useUpdateAffiliate";

const PortalOptions = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const [data, setData] = React.useState({
        showTenantArchive: affiliate?.showTenantArchive || false,
        showTenantExport: affiliate?.showTenantExport || false,
        allowMmsOutbound: affiliate?.allowMmsOutbound || false,
        allowMmsInbound: affiliate?.allowMmsInbound || false,
        mmsAutoReply: affiliate?.mmsAutoReply || '',
        allowMmsLinks: affiliate?.allowMmsLinks || false,
        mobileCalling: affiliate?.mobileCalling || false,
        allowMobileContacts: affiliate?.allowMobileContacts || false,
        allowMobileMedia: affiliate?.allowMobileMedia || false,
        allowCreateContactsInCRM: affiliate?.allowCreateContactsInCRM || false,
        allowSyncContactsInCRM: affiliate?.allowSyncContactsInCRM || false,
        allowSyncCalendarEvents: affiliate?.allowSyncCalendarEvents || false,
        appCalculators: affiliate?.appCalculators || false,
        allowInactivityDisable: affiliate?.allowInactivityDisable || false,
        inactivityTimeout: affiliate?.inactivityTimeout || 10,
        allowApiAccess: affiliate?.allowApiAccess || false,
        show_articals: affiliate?.show_articals || false,
        show_videos: affiliate?.show_videos || false,
        show_myrepchatMinute: affiliate?.show_myrepchatMinute || false,
        show_systemstatus: affiliate?.show_systemstatus || false,
        show_notificationTests: affiliate?.show_notificationTests || false,
        show_tos: affiliate?.show_tos || false,
        show_contactUs: affiliate?.show_contactUs || false,
        show_accessibility: affiliate?.show_accessibility || false,
    })

    const updateAffiliate = useUpdateAffiliate();

    return (
        <ScrollView contentContainerStyle={{ flex: 1, gap: 10, paddingTop: 15 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Display Archive Settings</Text>
            <Text>
                By default, each account has the ability to set up their own archive settings. {'\n'}
                If you do not want the account users to see and manage their own archive settings, you can disable the settings panel from appearing.
            </Text>
            <Switch
                value={data.showTenantArchive}
                onValueChange={e => setData({ ...data, showTenantArchive: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Display Conversation Export</Text>
            <Text>
                By default, each account can export their conversations by using a date range. {'\n'}
                If you do not want the account users to be able to export, you can disable the export option from appearing.
            </Text>
            <Switch
                value={data.showTenantExport}
                onValueChange={e => setData({ ...data, showTenantExport: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Outbound Media</Text>
            <Text>
                Here you can disable or enable the ability for users to send media attachments.
            </Text>
            <Switch

                value={data.allowMmsOutbound}
                onValueChange={e => setData({ ...data, allowMmsOutbound: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Inbound Media</Text>
            <Text>
                Here you can disable or enable the ability for users to receive media attachments. If receiving inbound media is turned off the following auto reply will be sent.
            </Text>
            <Switch
                value={data.allowMmsInbound}
                onValueChange={e => setData({ ...data, allowMmsInbound: e })}
            />
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Media Auto-Reply</Text>
            <CustomInput
                containerStyle={{ marginTop: 0 }}
                value={data.mmsAutoReply}
                multiline
                textInputStyle={{ minHeight: 150, maxHeight: 300 }}
                onChange={e => setData({ ...data, mmsAutoReply: e.target.value })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Media Storage Links</Text>
            <Text>
                Here you can disable or enable the ability for MyRepChat to convert media to links and expose those links for clients to access.{'\n'}Since many carriers disallow media over 1MB in size, enabling this feature allows the user to access content over that size.{'\n'}This link is hidden but is accessible externally without authentication.
            </Text>
            <Switch
                value={data.allowMmsLinks}
                onValueChange={e => setData({ ...data, allowMmsLinks: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Mobile Calling</Text>
            <Text>
                Here you can disable or enable the ability for users to call contacts from their mobile phones.
            </Text>
            <Switch
                value={data.mobileCalling}
                onValueChange={e => setData({ ...data, mobileCalling: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Mobile Contacts</Text>
            <Text>
                Here you can disable or enable the ability for users to import contacts from their mobile phones.
            </Text>
            <Switch
                value={data.allowMobileContacts}
                onValueChange={e => setData({ ...data, allowMobileContacts: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Mobile Media</Text>
            <Text>
                Here you can disable or enable the ability for users to send media from the camera or photo gallery from their mobile phones.
            </Text>
            <Switch
                value={data.allowMobileMedia}
                onValueChange={e => setData({ ...data, allowMobileMedia: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Create Contacts in CRM</Text>
            <Text>
                Some CRM integrations allow the creation of contacts created in MRC to be created in the CRM.
                {'\n'}
                You can disable the ability for the MyRepChat user to enable this feature here.
            </Text>
            <Switch
                value={data.allowCreateContactsInCRM}
                onValueChange={e => setData({ ...data, allowCreateContactsInCRM: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Sync Contacts in CRM</Text>
            <Text>
                Some CRM integrations allow the syncing of contacts changes between MRC and the CRM.
                {'\n'}
                You can disable the ability for the MyRepChat user to enable this feature here.
            </Text>

            <Switch
                value={data.allowSyncContactsInCRM}
                onValueChange={e => setData({ ...data, allowSyncContactsInCRM: e })}
            />


            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Sync Calendar from CRM</Text>
            <Text>
                Some CRM integrations allow the syncing of calendar events from the CRM.
                {'\n'}
                You can disable the ability for the MyRepChat user to enable this feature here.
            </Text>
            <Switch
                value={data.allowSyncCalendarEvents}
                onValueChange={e => setData({ ...data, allowSyncCalendarEvents: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Calculators</Text>
            <Text>
                Enable or disable the calculators from appearing in the app.
            </Text>
            <Switch
                value={data.appCalculators}
                onValueChange={e => setData({ ...data, appCalculators: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Browser Inactivity Timeout</Text>
            <Text>
                The browser inactivity timeout is an automatic logout timer that occurs after the defined number of minutes of inactivity in the web browser. By Default account users can disable this timeout, allowing them to stay logged in all day so that they can reliably receive browser notifications for new incoming SMS messages, just like a new email notification.
                {'\n'}{'\n'}
                WE STRONGLY DISCOURAGE DISABLING THIS!!!
                {'\n'}{'\n'}
                Changes to this take effect after next login for the account.
            </Text>
            <CustomInput value={data.inactivityTimeout} onChangeText={e => setData(e => ({ ...data, inactivityTimeout: e }))} title={'Timeout (seconds)'} keyboardType={'number'} />
            <Switch
                value={data.allowInactivityDisable}
                onValueChange={e => setData({ ...data, allowInactivityDisable: e })}
            />


            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>API Access</Text>
            <Text>
                Enable or disable allowing accounts to create API Keys for their account. These API keys can be used to send/receive messages for their accounts programmatically.
            </Text>
            <Switch
                value={data.allowApiAccess}
                onValueChange={e => setData({ ...data, allowApiAccess: e })}
            />

            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Support Links</Text>

            <Text>
                Enable or disable these Support links from appearing in the app.
            </Text>
            <Text>Show Articles?</Text>
            <Switch
                value={data.show_articals}
                onValueChange={e => setData({ ...data, show_articals: e })}
            />
            <Text>Show Videos?</Text>
            <Switch
                value={data.show_videos}
                onValueChange={e => setData({ ...data, show_videos: e })}
            />
            <Text>Show MyRepChat Minute?</Text>
            <Switch
                value={data.show_myrepchatMinute}
                onValueChange={e => setData({ ...data, show_myrepchatMinute: e })}
            />
            <Text>Show System Status?</Text>
            <Switch
                value={data.show_systemstatus}
                onValueChange={e => setData({ ...data, show_systemstatus: e })}
            />
            <Text>Show Notification Tests?</Text>
            <Switch
                value={data.show_notificationTests}
                onValueChange={e => setData({ ...data, show_notificationTests: e })}
            />
            <Text>Show Terms of Service?</Text>
            <Switch
                value={data.show_tos}
                onValueChange={e => setData({ ...data, show_tos: e })}
            />
            <Text>Show Contact Us?</Text>
            <Switch
                value={data.show_contactUs}
                onValueChange={e => setData({ ...data, show_contactUs: e })}
            />
            <Text>Show Accessibility?</Text>
            <Switch
                value={data.show_accessibility}
                onValueChange={e => setData({ ...data, show_accessibility: e })}
            />





            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end', marginTop: 20,marginBottom:10,marginRight:10 }}
            />



        </ScrollView>

    )
}

export default PortalOptions;