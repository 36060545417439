import React from 'react';
import { ScrollView, Text, TouchableOpacity } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';

export const MembersList = props => {



    return (
        <>
            <Text style={{ width: '80%', alignSelf: 'center', marginTop: 20, opacity: .7, marginBottom: 5 }}>Channel Members</Text>
            <ScrollView style={{flex:1}}>
            {props.allUsers.map(e => {
                return (
                    <TouchableOpacity onPress={() => props.onPress(e.user_id)} style={{ marginTop: 10, alignSelf: 'center', width: '80%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: 18 }}>
                            {e.name}
                        </Text>
                        <Entypo name={props.selectedUsers.includes(e.user_id) ? 'minus' : 'plus'} size={24} />
                    </TouchableOpacity>
                );
            })}
            </ScrollView>
        </>
    );
};
