import React from "react";
import { View, Text, ScrollView } from "react-native";
import CustomInput from "../../../Components/CustomInput/index.web";
import AppFonts from "../../../Constants/Fonts";
import AppColor from "../../../Constants/Color";
import DateTimePicker from 'react-datetime-picker'
import { CheckBox, Icon, Switch } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../../Components/Button";
import getData from "../../../AsyncUtils/GetData";
import RequestMaker from "../../../Middleware/ApiCaller";
import End_Points from "../../../Constants/Api";
import { setCompletedStep } from "../../../Redux/singupwizard";
import Loader from "../../../Components/Loader";
import Alert from 'Alert'
import WebView from "react-native-webview";

const LOAForm = ({navigation, hostedNumber}) => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.User);
    const [info, setInfo] = React.useState({
        contact_name: "",
        business_name: "",
        contact_title: "",
        contact_phone_number: "",
        contact_email: profile?.tenant?.email,
        street: "",
        city: "",
        state: "",
        postal_code: "",
        hosted_phone_number: hostedNumber,
        agreement_text: `By signing below, You verify that You are, the above-named service customer, authorized to SMS-enable the telephone number(s) listed, and at least 18 years of age. The name and address You have provided is the name and address on record with your local telephone company for each telephone number listed. You authorize Bandwidth Inc. or its designated agent to act on Your behalf and SMS enable Your landline or toll free phone number(s) listed in this LOA, and to obtain any information Bandwidth Inc. deems necessary to make the carrier change(s), including, carrier or customer identifying information, and billing addresses. You represent and warrant that You have full authority and approval to SMS-enable the telephone number(s) in conjunction with the services provided by Bandwidth Inc. and that Your use of such telephone number(s) is not in violation of any third party licenses, terms, conditions, laws, rules and/or regulations respecting the use of such telephone number(s), including any company or workplace policies regarding the use of such telephone number(s).`,
        has_agreed : false,
        date: new Date(),
    });
    const [loading, setLoading] = React.useState(true);

    const handleSubmit = async () => {
        if(!info.business_name){ alert('Please enter a valid business name'); return;}
        if(!info.contact_name){ alert('Please enter a valid contact name'); return;}
        if(!info.contact_phone_number){ alert('Please enter a valid contact phone number'); return;}
        if(!info.contact_email){alert('Please enter a valid contact email'); return;}
        if(!info.street){ alert('Please enter a valid street address'); return;}
        if(!info.city){ alert('Please enter a valid city'); return;}
        if(!info.state){ alert('Please enter a valid state'); return;}
        if(!info.postal_code){ alert('Please enter a valid postal code'); return;}
        if(!info.has_agreed){ alert('Please agree to the terms of conditions.'); return;}

        setLoading(true);

        let body = {
            action: 'Submit_LOA_Request',
            contact_name: info.contact_name,
            business_name: info.business_name,
            contact_title: info.contact_title,
            contact_phone_number: info.contact_phone_number,
            contact_email: info.contact_email,
            street: info.street,
            city: info.city,
            state: info.state,
            postal_code: info.postal_code,
            hosted_phone_number: info.hosted_phone_number,
            agreement_text: info.agreement_text,
            has_agreed : info.has_agreed,
            date: info.date.getTime()

        };

        
        let device = await getData('@device_id');

        const headers = { 'x-device': device };
        let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        setLoading(false);
        if (result.data.success) {
            dispatch(setCompletedStep({ phone: true }));
            navigation.navigate('BusinessCard', {number: info.hosted_phone_number});
        } else {
            Alert.alert('Error', result?.data?.errorMessage || 'Something went wrong. Please try again later.')
        }
    }

  const handleContinue = async () => {
      try{
      setLoading(true);

      let body = {
          action: 'get_hosted_loa_status',
          tw_number: `+1${hostedNumber}`
      };

      
      let device = await getData('@device_id');

      const headers = { 'x-device': device };
      let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      setLoading(false);
      if (result.data.success) {
        if(result.data.hosted_loa_status){
          dispatch(setCompletedStep({ phone: true }));
          navigation.navigate('BusinessCard', {number: info.hosted_phone_number});    
        } else {
          Alert.alert('Not finished signing.','Please complete signing and click Submit at the bottom of the form and then Continue. If you are still having issues, please wait a second and click Continue again.')
        }
      }
    } catch(err){
      Alert.alert('Error', new Error(err).message)
    }

  }


    return(
        <View style={{flex:1,height:'100%'}}>
            <WebView onLoad={()=>setLoading(false)} onLoadEnd={()=>setLoading(false)} onNavigationStateChange={(navState) => {alert(1);console.log('navigation state change', navState)}} containerStyle={{flex:1}} source={{uri:'https://ionlake.com/letter-of-authorization/'}}/>
            
            {/*
            <Text style={{fontFamily:AppFonts.Roboto_Regular,fontSize:24,fontWeight:700,marginBottom:15}}>
                Letter of Authorization Form
            </Text>

            <Text style={{marginBottom:20, fontSize:22}}>
                Customer Name, exactly as it appears on your telephone bill:
            </Text>
            <CustomInput value={info.business_name} onChange={e => setInfo({...info, business_name: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Business Name'/>
            <CustomInput value={info.contact_name} onChange={e => setInfo({...info, contact_name: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Authorized Contact Name'/>

            <CustomInput value={info.contact_title} onChange={e => setInfo({...info, contact_title: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Authorized Contact Title'/>

            <CustomInput value={info.contact_phone_number} onChange={e => setInfo({...info, contact_phone_number: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Contact phone number'/>
            <CustomInput value={info.contact_email} onChange={e => setInfo({...info, contact_email: e.currentTarget.value })} titleStyle={{marginTop:10}} title='Contact email'/>


            <Text style={{ marginTop:20, fontSize:22}}>
                Service Address on file with your current carrier
            </Text>
            <Text style={{marginBottom:20}}>*Please note, this must be a physical location and cannot be a PO Box</Text>

            <CustomInput value={info.street} onChange={e => setInfo({...info, street: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Street Address'/>
            <CustomInput value={info.city} onChange={e => setInfo({...info, city: e.currentTarget.value})} titleStyle={{marginTop:10}} title='City'/>
            <CustomInput value={info.state} onChange={e => setInfo({...info, state: e.currentTarget.value})} titleStyle={{marginTop:10}} title='State'/>
            <CustomInput value={info.postal_code} onChange={e => setInfo({...info, postal_code: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Postal Code'/>

            <Text style={{ marginTop:20, fontSize:22}}>
            List the Telephone Number which you authorize Bandwidth to SMS-enable with the
            respective SPID.
            </Text>
            <CustomInput maxLength={10} value={info.hosted_phone_number} onChange={e => setInfo({...info, hosted_phone_number: e.currentTarget.value})} titleStyle={{marginTop:10}} title='Phone Number'/>

            <Text style={{marginTop:20}}>
            {info.agreement_text}
            </Text>
            <View style={{flexDirection:'row',alignItems:'center'}}>
            <CheckBox onPress={() => setInfo({...info, has_agreed: !info.has_agreed})} checked={info.has_agreed} />
            <Text>I Agree</Text>
            </View>
            <View style={{width:'25%',marginTop:10}}>
            <DateTimePicker
              onChange={e => setInfo({...info, date: e})}
              value={info.date}
              calendarIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'calendar'}
                  type={'antdesign'}
                />
              }
              clearIcon={null}
              disableClock={true}
              format="MM/dd/y"
            />
            </View>

            <CustomButton onPress={handleSubmit} title="Submit" style={{marginTop:20,marginBottom:20}}/>


            */}
            <CustomButton style={{alignSelf:'center',width:'50%'}} title={'Continue'} onPress={handleContinue}/>

            {/* <CustomButton style={{alignSelf:'center',width:'50%'}} title={'Continue'} onPress={()=>{
                
                Alert.alert('Done Signing?', 'By clicking \'Confirm\' you are confirming that you have completely finished the signing process and understand that there may be a delay in setting up your account if not.', [
                    {
                      text: 'Cancel',
                      onPress: () => null,
                      style: 'cancel',
                    },
                    {
                      text: 'Confirm',
                      onPress: () => {
                        dispatch(setCompletedStep({ phone: true }));
                        navigation.navigate('BusinessCard', {number: info.hosted_phone_number});                        
                      },
                    },
                  ]);

            }}/> */}

        {loading && <Loader />}

        </View>
    )
}

export default LOAForm;