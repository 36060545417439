import { Menu } from "native-base";
import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import AppColor from "../Constants/Color";

const CountryCodeSelector = ({onChange, value}) => {

    class Country {
        constructor(label, isoCode, iddPrefix, countryCode, voice, sms, mms) {
          this.label = label;
          this.isoCode = isoCode;
          this.iddPrefix = iddPrefix;
          this.countryCode = countryCode;
          this.voice = voice;
          this.sms = sms;
          this.mms = mms;
        }
        getLabel() {
          return '+' + this.countryCode;
        }
        getIsoCode() {
            return this.isoCode;
        }
      }
      
    const countries = [
        new Country('United States', 'US', '011', 1, true, true, true),
        new Country('Australia', 'AU', '011', 61, true, true, false),
        new Country('Austria', 'AT', '011', 43, false, true, false),
        new Country('Belgium', 'BE', '011', 32, false, true, false),
        new Country('Canada', 'CA', '011', 1, true, true, true),
        new Country('Chile', 'CL', '011', 56, false, true, false),
        new Country('Czech Republic', 'CZ', '011', 420, false, true, false),
        new Country('Estonia', 'EE', '011', 372, false, true, false),
        new Country('France', 'FR', '011', 33, true, true, false),
        new Country('Germany', 'DE', '011', 49, true, true, false),
        new Country('Hong Kong', 'HK', '011', 852, false, true, false),
        new Country('Hungary', 'HU', '011', 36, false, true, false),
        new Country('Ireland', 'IE', '011', 353, false, true, false),
        new Country('Israel', 'IL', '011', 972, true, true, false),
        new Country('Latvia', 'LV', '011', 371, false, true, false),
        new Country('Lithuania', 'LT', '011', 370, false, true, false),
        new Country('Milaysia', 'MY', '011', 60, false, true, false),
        new Country('Mexico', 'MX', '011', 52, false, true, true),
        new Country('Netherlands', 'NL', '011', 31, false, true, false),
        new Country('Norway', 'NO', '011', 47, false, true, false),
        new Country('Poland', 'PL', '011', 48, false, true, false),
        new Country('Portugal', 'PT', '011', 351, false, true, false),
        new Country('Puerto Rico', 'PR', '', 1, true, true, false),
        new Country('Spain', 'ES', '011', 34, false, true, false),
        new Country('Sweden', 'SE', '011', 46, false, true, false),
        new Country('Switzerland', 'CH', '011', 41, false, true, false),
        new Country('United Kingdom', 'UK', '011', 44, true, true, false),
      ];
      
    return(
        <Menu w={500} placement='bottom left' trigger={triggerProps => {
            return (
              <TouchableOpacity
                style={{ minWidth: 100, backgroundColor: '#F6F6F6', alignItems: 'center', justifyContent: 'space-between', padding: 10, paddingLeft: 20, flexDirection: 'row' }}
                onPress={e => triggerProps.onPress()}
                {...triggerProps}
              >
                <Text>+{
                  countries.find(e => e.getIsoCode() === value)?.countryCode
                } </Text>
                <Icon color={AppColor.PrimaryColor} name='chevron-down' type='entypo' size={24} />
              </TouchableOpacity>
            )
          }}>
            <View style={{ maxHeight: 400, overflowY: 'scroll' }}>
              {
                countries.map((e, i) => {
                  return (
                    <Menu.Item onPress={() => onChange(e)}>
                      <Text>+{e.countryCode} {e.label}</Text>
                    </Menu.Item>
                  )
                })
              }
            </View>
          </Menu>
      
    )

}

export default CountryCodeSelector;