import React from "react";
import { Switch, Text, View } from "react-native";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import useUpdateAffiliate from "../useUpdateAffiliate";
import { useSelector } from "react-redux";
import AppFonts from "../../Constants/Fonts";

const GroupOptions = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const [data, setData] = React.useState({
        groupMsg: affiliate?.groupMsg || false,
        groupLimit: affiliate?.groupLimit || 0,
        ionlakeCrm: affiliate?.ionlakeCrm || false,
        purgeDays: affiliate?.purgeDays || 0,
    })
    
    const updateAffiliate = useUpdateAffiliate();
    
    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Group Text Messaging
            </Text>
            <Switch
            onValueChange={e => {setData({...data, groupMsg: e})}}
            value={data.groupMsg}
            style={{marginBottom:20,marginTop:10}}
            />

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Group Size Limit
            </Text>

            <CustomInput style={{marginTop:20}} keyboardType={'numeric'} value={data.groupLimit} onChangeText={e => setData({...data, groupLimit: e})} /> 
            

            <Text style={{fontFamily:AppFonts.Roboto_Bold, marginTop:20}}>
                Account Messaging 
            </Text>

            <Switch
            style={{marginBottom:20,marginTop:10}}
            onValueChange={e => {setData({...data, ionlakeCrm: e})}}
            value={data.ionlakeCrm}
            />
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Purge Days
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                Note: Leaving purge days set to 0 will keep all messages indefinitely.
            </Text>


            <CustomInput style={{marginTop:20}} keyboardType={'numeric'} value={data.purgeDays} onChangeText={e => setData({...data, purgeDays: e})} /> 

            <CustomButton
            onPress={() => updateAffiliate(data)}
            width={'30%'} 
            title={'Save'} 
            style={{marginTop:'auto',alignSelf:'flex-end',marginBottom:10,marginRight:10}}
            />

        </View>
    )
}

export default GroupOptions;