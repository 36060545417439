import React from "react";
import { Text, TextInput } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import { useToast } from "native-base";
import RequestMaker from "../../Middleware/ApiCaller";


const DeleteConsent = ({tenant}) => {
    const Tenant = tenant.data.tenant;
    const [phone, setPhone] = React.useState('');
    const Toast = useToast();

    const handleClearConsent = async () => {
        try{
        const body = {
            action: "delete_consent",
            tenant: Tenant.id,
            long_code: phone,
            country_code: "+1"
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                title: "Consent Cleared",
                status: "success"
            })
        } else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: "error"
            })
        }
    } catch(e){
        Toast.show({
            title: Error(e).message,
            status: "error"
        })
    }
    }

    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:10}}>
            Clear consent at the tenant-level for the number provided below.
        </Text>
        <CustomInput
        placeholder={'10-Digit Phone Number'}
        value={phone}
        onChangeText={setPhone}
        maxLength={10}
        />
        <CustomButton title="Submit" 
        onPress={handleClearConsent}
        width={'20%'}
        style={{alignSelf:'flex-end',marginTop:10}}
        
        />
        </>



    )

}

export default DeleteConsent;