import React from "react";
import { Text, View } from "react-native";
import { Icon } from "react-native-elements";
import AppFonts from "../../Constants/Fonts";
import moment from "moment";
import { useSelector } from "react-redux";

const ConsentIndicator = ({ consented, stopped, consent, consentSent, isGroup }) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    let msg = '';

    if (consent !== null) {
        if (!consent && affiliate?.consentResponseRequired && consentSent) {
            msg = `Consent pending: this contact has not accepted the required consent message.`;
        } else {
            msg = 'Sending a consent message is required before starting a conversation. This message cannot be changed.';
        }
        if (consentSent && !consent && affiliate?.blockoutboundconsent && affiliate?.blockoutboundconsentmessage) {
            msg =  affiliate.blockoutboundconsentmessage;
        }      
    }

    if(!affiliate?.consentEnabled) return null;

    if (stopped) {
        return (
            <View style={{ backgroundColor: '#FAE0E0', alignItems: 'center', flexDirection: 'row', gap: 10, padding: 10 }}>
                <Icon name='warning' type='font-awesome' />
                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                    {
                    !isGroup ? 
                    "You cannot send messages because this contact has opted out of receiving sms messages from you."
                    :
                    "One or more recipients have opted out from receiving messages. Further messages will be rejected."
                    }
                </Text>
            </View>
        )
    }

    if(consented) return null;


    if (msg) {
        return (
            <View style={{ backgroundColor: '#FFE5C3', alignItems: 'center', flexDirection: 'row', gap: 10, padding: 10 }}>
                <Icon name='warning' type='font-awesome' />
                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{msg}</Text>
            </View>
        )
    }

    return null;
}



export default ConsentIndicator;