import React from 'react';
import { View, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import { Input } from 'react-native-elements';

import IntegrationComponent from './IntegrationComponent';
import AppColor from '../../../../Constants/Color';
import { setAttributes } from '../../../../Redux/integrationData';
import AppFonts from '../../../../Constants/Fonts';

export default function AttributeComponent({
  provider,
  integration,
  attributes,
  allowContactEdits,
  allowLocation,
  allowCalendarSync
}) {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    let z = {};
    for (let x of attributes.getArray()) {
      z[x[0]] = '';
      if (integration != null && integration[x[0]]) {
        z[x[0]] = integration[x[0]];
      }
    }
    setData(z);
  }, [integration]);

  return (
    <View style={{ marginHorizontal: 15 }}>
      {provider?.name === 'allclientscrm' && <Text style={{fontFamily:AppFonts.Roboto_Regular, alignSelf:'center',marginBottom:20,width:'75%'}}>MyRepChat can import contacts from your AllClients CRM account. Imported AllClients CRM contacts will have notes added to them for all the messages you send/receive to these contacts through MyRepChat. To get started, you will need to set the Account ID and API Key from AllClients CRM which can be found in your AllClients portal. If you use a white labeled version replace the Hostname to be the hostname you use to log in.</Text>}
      {provider?.name === 'copper' && <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',marginBottom:20,width:'75%'}}>MyRepChat can import contacts from your Copper CRM account. Imported Copper contacts will have notes added to them for all the messages you send/receive to these contacts through MyRepChat. To get started, you will need to set the username that you use to access Copper, as well as an access key, which can be found in your Copper portal.</Text>}
      {provider?.name === 'wealthbox' && <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',marginBottom:20,width:'95%'}}>
        
        MyRepChat can send messages to contacts from your Wealthbox account. Imported Wealthbox contacts will have notes added to them for all the messages you send/receive to these contacts through MyRepChat. To get started, you will need to:
        {'\n'}{'\n'}
        1. Go to your access token page in Wealthbox.{'\n'}
        2. Create a new access token for MyRepChat.{'\n'}
        3. Copy and paste the token into the text box here.{'\n'}
        4. Click the save button.{'\n'}{'\n'}
        Once the token has been saved, you can click the Test button to test the connection to Wealthbox.
       </Text>}
       {provider?.name === 'lessannoying' && <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',marginBottom:20,width:'95%'}}>
        {'\n'}MyRepChat can send messages to contacts from your Less Annoying CRM account and create notes for all the messages that you send/receive with contacts from Less Annoying CRM. To get started you will need to:
        {'\n'}{'\n'}
        1. Log in to your Less Annoying CRM account.{'\n'}
        2. Go to Settings - View All Settings Options.{'\n'}
        3. Under the More Features section, select Programmer API.{'\n'}
        4. Click the Generate a new API Token button.{'\n'}
        5. Copy and paste the User Code and the API Token into the appropriate spaces below{'\n'}
        6. Click the save button{'\n'}
        7. Once the token has been saved, you can click the Test button to test the connection to Less Annoying CRM.{'\n'}
        </Text>}  
       {provider?.name === 'basecrm' && <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',marginBottom:20,width:'95%'}}>{'\n'}MyRepChat can import contacts from your Base CRM account. Imported Base CRM contacts will have notes added to them for all the messages you send/receive to these contacts through MyRepChat. To get started, you will need to set the access key, this can be found in your Base CRM portal.</Text>}

       {provider?.name === 'vtiger' && <Text style={{fontFamily:AppFonts.Roboto_Regular,alignSelf:'center',marginBottom:20,width:'95%'}}>{'\n'}MyRepChat can send messages to contacts from your vtiger account. Imported vtiger contacts will have notes added to them for all the messages you send/receive to these contacts through MyRepChat. To get started, you will need to set the host and username that you use to access access vtiger, as well as an access key, which can be found in your vtiger portal.</Text>}



      {attributes.getArray().map((x, index) => {
        return (
          <Input
            label={x[1]}
            value={data[x[0]]}
            labelStyle={{ color: AppColor.PrimaryColor }}
            errorStyle={{ height: 0 }}
            inputStyle={{ fontSize: 15 }}
            containerStyle={{ flex: 1 }}
            inputContainerStyle={{ borderColor: AppColor.PrimaryColor }}
            onChangeText={(t) => {
              setData((state) => ({ ...data, [x[0]]: t }));
              dispatch(setAttributes({ ...data, [x[0]]: t }));
            }}
            key={`attribute_${index}`}
          />
        );
      })}
      <IntegrationComponent
        provider={provider}
        integration={integration}
        allowContactEdits={allowContactEdits}
        allowLocation={allowLocation}
        allowCalendarSync={allowCalendarSync}
      />
    </View>
  );
}
