import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import TimePicker from 'rc-time-picker';
import { Icon } from 'react-native-elements';
const moment = require('moment');

import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';

const TimeBtn = ({ label, onChange, selectedTime, minuteStep = 15 }) => {
  return (
    <View style={styles.switchWrapper}>
      <Text style={styles.label}>{label}</Text>
      <TimePicker
        value={moment(selectedTime)}
        onChange={onChange}
        showSecond={false}
        minuteStep={minuteStep}
        use12Hours={true}
        showSecond={false}
        inputIcon={
          <Icon
            color={AppColor.PrimaryColor}
            name={'back-in-time'}
            type={'entypo'}
          />
        }
        clearIcon={null}
      />
    </View>
  );
};

export default TimeBtn;

const styles = StyleSheet.create({
  switchWrapper: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    width: '100%',
  },
  hideBtn: {
    alignItems: 'center',
    marginVertical: 10,
  },
  label: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 14,
    marginRight: 15,
    color: AppColor.PrimaryColor,
    minWidth: 100,
  },
  text: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 14,
    color: AppColor.PrimaryColor,
  },
});
