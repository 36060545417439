import { useEffect } from "react";

function TabFocusChecker(props) {
    useEffect(() => {
      function handleVisibilityChange() {
        if (!document.hidden) {  // if the tab is refocused
          props.onTabRefocused && props.onTabRefocused();
        }
      }
  
      // Attach the event listener
      document.addEventListener("visibilitychange", handleVisibilityChange);
  
      // Cleanup: remove the event listener when the component is unmounted
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }, [props]);
  
    return null;  // This component does not render anything to the DOM
  }
  
export default TabFocusChecker;
