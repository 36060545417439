import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";

const OauthSetup = () => {

    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const { refetch, data, isLoading, isError } = getQuery({
        groupId: affiliate.id,
        action: "get_oauthidp"
    })

    const [authData, setAuthData] = React.useState(
        {
            client_secret: data?.oauthIdp?.client_secret|| "",
            client_id: data?.oauthIdp?.client_id || "",
            additional_params: data?.oauthIdp?.additional_params || "",
            id: data?.oauthIdp?.id || null
        }

    )
    React.useEffect(()=>{
        setAuthData({
            client_secret: data?.oauthIdp?.client_secret|| "",
            client_id: data?.oauthIdp?.client_id || "",
            additional_params: data?.oauthIdp?.additional_params || "",
            id: data?.oauthIdp?.id || null
        })
    
    }, [data])

    if (isLoading) return <Loader backgroundColor={'transparent'} />

      
    const handleSave = async () => {
        const body = {
            action: "create_oauthidp",
            groupId: affiliate.id,
            "client_id": authData.client_id,
            "client_secret": authData.client_secret,
            additional_params: authData.additional_params,
        }
        console.log(body, authData);
        if(authData.id) body.action = 'update_oauthidp';
        console.log(body, authData);

        const result = await RequestMaker(body);

        if(result.data.success){
            refetch();
        }
    }


    const handleDelete = async () => {
        const body = {
            id: authData.id,
            groupId: affiliate.id,
            action: "delete_oauthidp"
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            setAuthData({
                client_secret: "",
                client_id: "",
                additional_params: ""
            })
            refetch();
        }
    }

    return(
        <ScrollView contentContainerStyle={{flex:1}}>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
            This is where you can configure Oauth for MyRepChat.
        </Text>
        <CustomInput title="Client ID" titleStyle={{marginTop:10}} value={authData.client_id} onChangeText={e => setAuthData({...authData, client_id: e})}/>
        <CustomInput title="Client Secret" titleStyle={{marginTop:10}} value={authData.client_secret} onChangeText={e => setAuthData({...authData, client_secret: e})}/>
        <CustomInput title="Additional Params" titleStyle={{marginTop:10}} value={authData.additional_params} onChangeText={e => setAuthData({...authData, additional_params: e})}/>


        <View style={{flexDirection:'row',alignItems:'center',gap:20,margin:10,justifyContent:'flex-end',marginTop:'auto'}}>
            {
            authData.id &&
            <CustomButton title={'Delete'} width={'20%'} onPress={handleDelete} outline/>
            }
            <CustomButton onPress={handleSave} title={'Save'} width={'20%'}/>
        </View>



        </ScrollView>
    )
}

export default OauthSetup;