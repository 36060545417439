/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { useDispatch } from 'react-redux';
import { CheckBox } from 'react-native-elements';
import Alert from 'Alert';
import CustomInput from '../../../Components/CustomInput';
import CustomButton from '../../../Components/Button';
import AppFonts from '../../../Constants/Fonts';
import AppColor from '../../../Constants/Color';
import End_Points from '../../../Constants/Api';
import keyword from '../../../Constants/Keywords';
import RequestMaker from '../../../Middleware/ApiCaller';
import getData from '../../../AsyncUtils/GetData';
import { setProfile, setToken } from '../../../Redux';
import { Bubbles } from './Bubbles';
import useResponsiveLayout from '../../../Middleware/useResponsiveLayout';

export default function Login({ navigation }) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const [check, setCheck] = React.useState(false);
  const dispatch = useDispatch();
  const [ssoCheck, SetSSOCheck] = React.useState(false);
  const {isWebCompact} = useResponsiveLayout();

  const Validate = () => {
    if (validateEmail(email)) {
      if (password.length < 5) {
        Alert.alert('Password length must be greater than 5');
      } else {
        MakeRequest();
      }
    } else {
      Alert.alert('Invalid Email');
    }
  };

  // sso check
  const checkSSOAsync = async () => {
    let RequestData = {
      action: 'lookup_user',
      username: email,
      toast: false,
    };
    let headers = { 'Content-Type': 'text/plain' };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: RequestData,
      headers: headers,
    };
    setLoader(true);
    let result = await RequestMaker(Config);
    setLoader(false);
    if (result.data.success) {

      if (result.data?.accounttype && result.data.accounttype == 'sso') {
        navigation.navigate('SSOScreen', {
          url: result.data.redirect ?? null,
          email: email,
        });
      } else {
        SetSSOCheck(true);
      }
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
  };

  const MakeRequest = async () => {
    const Device_id = await getData(keyword.Device_id);
    const fcmToken = await getData('fcmToken');

    let RequestData = {
      action: 'login',
      username: email,
      password: password,
      pushKey: fcmToken,
    };
    
    if(check) RequestData['expiration'] = 1209600000;

    let x = { 'Content-Type': 'application/json' };
    if (Device_id) {
      x = { 'X-Device': Device_id };
    }
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: RequestData,
      headers: x,
    };
    setLoader(true);
    try {
      let result = await RequestMaker(Config);
      setLoader(false);
      if (result.data.success) {
         if (Object.keys(result.data).includes('mfa')) {
          navigation.navigate('VerifyEmail', {
            userId: result.data.mfa,
            username: email,
            password: password,
            pushKey: fcmToken,
            check: check,
          });
        } else if (Object.keys(result.data).includes('mobile')) {
          navigation.navigate('MobileAuth', {
            userId: result.data.mobile,
            username: email,
            password: password,
            pushKey: fcmToken,
            check: check,
          });          
        } else if (result.headers && result.headers['x-dart-token']) {
          dispatch(
            setToken({
              authenticated: true,
            })
          );
        } 
        if (result.data) {

          dispatch(setProfile(result.data));
        }
      } else {
        Alert.alert(result.data.errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return(
    <View style={{flex:1}}>
      <View style={{flex:1,backgroundColor:'white',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
        { !isWebCompact && <Bubbles>
        <View>
          <img
            style={{
              maxWidth: 250,
              alignSelf: 'center',
              resizeMode: 'contain',
            }}
            alt={'MyRepChat logo.'}
            src={require('../../../Assets/Images/logo-fullcolor@2x.png')}
          />
          <Text
            style={{
              textAlign: 'right',
              fontFamily: AppFonts.Roboto_Regular,
              color: AppColor.Dark,
              marginTop:20
            }}>
            Version : 5.8.5
          </Text>
        </View>

        </Bubbles>}

        <View style={{flex:1,height:'100%',justifyContent:'center',alignItems:'center'}}>
          <View style={{width:!isWebCompact ? '50%' : '90%'}}>
            {
              isWebCompact &&
              <img
              style={{
                maxWidth: 50,
                alignSelf: 'flex-start',
                marginBottom:20,
                resizeMode: 'contain',
              }}
              alt={'MyRepChat logo.'}
              src={require('../../../Assets/Images/splashlogo.png')}
            />
                
            }
            <Text style={{fontSize:24,fontWeight:300,lineHeight:35,marginBottom:25}}>Login to your {'\r\n'}MyRepChat Account</Text>
            <CustomInput
                onEnterPress={() => {
                  if (!ssoCheck) {
                    checkSSOAsync();
                  }
                }}
                onChangeText={(e) => setEmail(e.trim())}
                value={email}
                textContentType={'emailAddress'}
                keyboardType='email-address'
                title={'Email'}
                autoComplete={'email'}
                placeholder={'Enter Email'}
              />
                <CustomInput
                containerStyle={{display: ssoCheck ? 'flex' : 'none'}}
                titleStyle={{marginTop:25, display: ssoCheck ? 'flex' : 'none'}}
                onEnterPress={() => {
                    if (ssoCheck) {
                      Validate();
                    }
                  }}
                  onChangeText={(e) => setPassword(e)}
                  value={password}
                  title={'Password'}
                  autoComplete={'password'}
                  placeholder={'Enter Password'}
                  secure={true}
                />
              

           <View
            style={{
              marginVertical: 25,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <CheckBox
              checked={check}
              onPress={() => setCheck(!check)}
              title="Remember me"
              containerStyle={{
                backgroundColor: 'transparent',
                borderWidth: 0,
                margin: 0,
                padding: 0,
              }}
              titleProps={{
                style: {
                  fontFamily: AppFonts.Roboto_Regular,
                  color: AppColor.Dark,
                },
              }}
            />
            <Text
              accessible={true}
              accessibilityLabel='Forgot password?'
              accessibilityRole='button'
              onPress={() => navigation.navigate('ForgetPassword')}
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                color: AppColor.PrimaryColor,
              }}>
              Forgot Password?
            </Text>
          </View>
          {ssoCheck ? (
            <CustomButton
            width={'100%'}
              onPress={() => {
                Validate();
              }}
              title={'Login'}
            />
          ) : (
            <CustomButton
            width={'100%'}
              onPress={() => {
                checkSSOAsync();
              }}
              title={'Next'}
            />
          )}
          <Text style={{marginTop:20}}>Don't have an account? <Text onPress={() => {navigation.navigate('GroupSetup')}} style={{cursor:'pointer',color:AppColor.PrimaryColor}}>Sign Up</Text></Text>


          </View>
        </View>

      </View>
    </View>
  )
}
