import React from 'react';
import { Pressable, Text, View, StyleSheet, Image } from "react-native";

import {
    DrawerItemList,
    DrawerContentScrollView,
  } from '@react-navigation/drawer';
import Loader from "../Components/Loader";
import { useSelector } from "react-redux";
import AppColor from "../Constants/Color";
import AppFonts from '../Constants/Fonts';
import WhiteMRCLogo from '../Assets/Images/mrc-logo-white.png';
import CondensedLogo from '../Assets/Images/splashlogo.png';
import './drawerStyles.css';
import useResponsiveLayout from '../Middleware/useResponsiveLayout';

const AccountsPreview = props => {
    const user = useSelector((state) => state.User.profile)?.user;
    const tenant = useSelector((state) => state.User.profile)?.tenant;
    const [loading, setLoading] = React.useState(false);
    const {isWebCompact} = useResponsiveLayout();
    
    let contact_name = ''; 
    let accounts = user?.accounts ?? [];
    for (let x of accounts) {
      if (x.id == tenant?.id) {
        contact_name = x.contact
      }
    }
    
    const focusMainContent = () => {
      const x = document.getElementsByClassName('css-1dbjc4n r-1p0dtai r-6koalj r-13awgt0 r-1d2f490 r-105ug2t r-u8s1d r-zchlnj r-ipm5af')[0]
      if(x){
        x.setAttribute('tabindex', 1);
        x.focus()
      }
    }
   
    

    return(
      <>
      <Pressable onPress={focusMainContent} accessible={true} accessibilityLabel='Skip Navigation Links.' style={(state) => state.focused ? {position:'absolute',top:0,left:0,zIndex:99999,backgroundColor:'white'} : {position:'absolute',top:-1000,left:-1000}}>
      <Text style={{fontWeight:'800',padding:10,color:AppColor.PrimaryColor,fontFamily:AppFonts.Roboto_Regular}}>Skip Navigation Links</Text>
      </Pressable>

      <View style={styles.logoHeader}>
        <Image style={styles.logoHeaderImage} source={!isWebCompact ? WhiteMRCLogo : CondensedLogo} />
      </View>

          <DrawerContentScrollView nativeID='hide-scrollbar' {...props}>
            <DrawerItemList {...props}/>
          </DrawerContentScrollView>

        {loading && <Loader backgroundColor="#fff6" />}
      </>
    )
  }

export default AccountsPreview;

const styles = StyleSheet.create({
  logoHeader: {
    backgroundColor: '#20508A',
    alignItems:'center',
    justifyContent:'center',
    padding:10,
    height:70,
  },
  logoHeaderImage: {
    width:200,
    height:45,
    resizeMode:'contain'
  }
})

/*
            {
              props.state.routes.map(route=> {
                  const icon = props.descriptors[route.key].options.drawerIcon
                  const label = props.descriptors[route.key].options.drawerLabel
                  return <DrawerMenuItem label={label} icon={icon} onPress={()=>navigation.navigate(route.name)} iconType='entypo' iconName='gauge'/>
                }
              )

*/