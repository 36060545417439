import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import AppFonts from "../Constants/Fonts";
import { Icon } from "react-native-elements";

const Accordion = ({data}) => {
    const [selectedItem, setSelectedItem] = React.useState(null);


    const expandItem = (title) => {
        if(selectedItem !== title){
            setSelectedItem(title);
        } else {
            setSelectedItem(null);
        }
    }
    return(
        <View style={{flex:1}}>
            {
                data.map(e => {
                    const isSelected = selectedItem === e.title;
                    
                    return(
                    <View>
                        <TouchableOpacity onPress={() => e.subPages && e.subPages.length ? expandItem(e.title) : e.onPress()} style={styles.container}>
                            <Text  style={{fontFamily:AppFonts.Roboto_Regular}}>{e.title}</Text>
                            {e.subPages && e.subPages.length &&
                            <Icon name={isSelected ? 'chevron-up' : 'chevron-down'} type="entypo" size={22} color="black" />
                            }
                        </TouchableOpacity>

                        { isSelected &&
                            <View style={{backgroundColor:'#f1f1f1'}}>
                            {
                                e.subPages.map(e => (
                                    <Text onPress={e.onPress} style={styles.subpageTitle}>{e.title}</Text>
                                ))
                            }
                            </View>
                        }
                    </View>
                    )
                }
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flexDirection:'row',
        justifyContent:'space-between', 
        padding:10
    },
    subpageTitle: {
        fontFamily:AppFonts.Roboto_Regular,
        marginLeft:20,
        marginTop:5,
        marginBottom:10
    }
})

export default Accordion;