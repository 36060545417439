import { Tooltip } from "native-base";
import React from "react";
import { View } from "react-native";
import AntDesign from 'react-native-vector-icons/AntDesign';
import { useSelector } from "react-redux";
//minuscircleo
//checkcircleo

const ConsentStatusIndicator = ({ status, size }) => {
    const group = useSelector((state) => state.User)?.profile?.group;
    if (group?.consentEnabled === false) return null;

    switch (status) {
        case "stopped": {
            return (
                <Tooltip
                    label="Opted Out"
                    placement="top"
                    hasArrow={true}>
                    <View>
                        <AntDesign name='minuscircleo' size={size} color='red' />
                    </View>
                </Tooltip>
            )
        }
        case "consented": {
            return (
                <Tooltip
                    label="Consented"
                    placement="top"
                    hasArrow={true}
                    openDelay={500}>
                    <View>
                        <AntDesign name='checkcircleo' size={size} color='green' />
                    </View>
                </Tooltip>
            )
        }
        default: {
            return null;
        }
    }
}

export default ConsentStatusIndicator;