import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Content } from 'native-base';

import Header from '../../Components/Header';
import AppFonts from '../../Constants/Fonts';

import { providers } from '../IntegrationProvider';
import { setCompletedStep } from '../../Redux/singupwizard';
import Loader from '../../Components/Loader';
import IntegrationCard from '../../Components/IntegrationCard';

import { getIntegrationProviders } from '../../Api/integrations';
import { ShowConfirmModal } from '../../Components/Modals/ShowConfirmModal';

const Archive = ({ navigation, route }) => {
  const [isGlobalModalVisible, setGlobalModalVisible] = useState(false);
  const [isMicrofocusVisible, setMicrofocusVisible] = useState(false);
  const [isMsjournalVisible, setMsjournalVisible] = useState(false);
  const [archiveProviders, setArchiveProviders] = useState([]);
  const [loading, setLoading] = useState(false);

  const archives = useSelector((state) => state.integrations).integrations;
  const dispatch = useDispatch();
  const filteredArchives = Object.entries(providers).filter((x) => archiveProviders.includes(x[1].name));

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getArchiveProviders();
    })
    return unsubscribe;
  }, [navigation]);


  const getArchiveProviders = async () => {
    setLoading(true);

    getIntegrationProviders()
    .then(result => {
      if(result?.data?.success){
        setArchiveProviders(result?.data?.providers);
      }
      setLoading(false);
    })
  };

  const handlePressIntegration = integration => {
    if (integration.name === 'globalrelay') {
      setGlobalModalVisible(true);
      return;
    }
    if (integration.name === 'microfocus') {
      setMicrofocusVisible(true);
      return;
    }
    if (integration.name === 'msjournal') {
      setMsjournalVisible(true);
      return;
    }

    dispatch(setCompletedStep({ archive: true }));
    
    navigation.navigate('Integrations', {
      provider: integration,
      type: 'archive',
      from: route?.params?.from,
    });
  }

  return (
    <>
      <SafeAreaView style={{ flex: 1 }}>
          <ScrollView style={{flex:1}}>
            <View
              style={{
                paddingVertical: 10,
                paddingHorizontal: 15,
              }}>
              <View>
                <Text style={styles.description}>
                  There are many ways to archive your MyRepChat data, and
                  MyRepChat works with many popular archive providers, set up
                  your data archiving in a way that meets your needs.
                </Text>
                <Text style={[styles.description, { marginVertical: 15 }]}>
                  Archive Information
                </Text>
              </View>
              <View
                style={{
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                {filteredArchives
                  .map((i, index) => {
                    const integration = i[1];
                    const isActive = archives.findIndex(
                      (x) => (x.provider === integration.name && x.active),
                    ) > -1;
                    if(integration.name === 'smtp') return;
                    return (
                      <IntegrationCard
                      onPress={() => handlePressIntegration(integration)}
                      integration={integration}
                      isActive={isActive}
                      />
                    );
                  })}
              </View>
            </View>
            </ScrollView>
      </SafeAreaView>
      <ShowConfirmModal
        visible={
          isGlobalModalVisible || isMicrofocusVisible || isMsjournalVisible
        }
        isGlobalModalVisible={isGlobalModalVisible}
        isMicrofocusVisible={isMicrofocusVisible}
        isMsjournalVisible={isMsjournalVisible}
        setVisible={() => {
          setGlobalModalVisible(false);
          setMicrofocusVisible(false);
          setMsjournalVisible(false);
        }}
      />
      {loading && <Loader />}
    </>
  );
};

export default Archive;

export const styles = StyleSheet.create({
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 5,
  },
  modal: {
    padding: 30,
    borderRadius: 5,
    borderWidth: 1,
    width: '100%',
  },
});
