import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import * as DocumentPicker from 'expo-document-picker';
import Entypo from 'react-native-vector-icons/Entypo';
import { useToast } from 'native-base';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

import CustomButton from '../../Components/Button';
import CustomInput from '../../Components/CustomInput';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import AppColor from '../../Constants/Color';
import getData from '../../AsyncUtils/GetData';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api/index';
import { setCompletedStep } from '../../Redux/singupwizard';
import AppFonts from '../../Constants/Fonts';

const BusinessCard = ({ navigation,route }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [workphone, setWorkphone] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [note, setNote] = useState('');
  const [vcards, setVcards] = useState([]);
  const [imageData, setImageData] = useState(null);
  const { profile } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const Toast = useToast();

  useEffect(() => {
    if (!profile) return;
    setName(profile?.user?.name || '');
    setEmail(profile?.tenant?.email || '');
    setCompanyName(profile?.tenant?.affiliateName || '');
    setTitle(profile?.tenant?.name || '');
    setAddress(profile?.tenant?.address1 || '');
    setCity(profile?.tenant?.city || '');
    setProvince(profile?.tenant?.state || '');
    setPostalCode(profile?.tenant?.zip || '');
    setWorkphone(profile?.tenant?.phone || '');
    if(route?.params?.number){
      setMobileNumber(route.params.number);
    }
  }, [profile]);

  useEffect(() => {
    handleGetVCards();
  }, []);

  const handleGetVCards = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'get_vcards',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        console.log('vcards=====>', result.data);
        setVcards(result.data.vcards);
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleDocumentPicker = async () => {
    try {
      const res = await DocumentPicker.getDocumentAsync({
        type: '*/*',
      });
      if (res.type == 'success') {
        handleUploadMedia(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUploadMedia = async (data) => {
    console.log('data', data);
    setImageData(data.uri);
  };

  const handleCreateVCards = async () => {
    setLoading(true);
    const photo =
      imageData != null
        ? 'PHOTO;ENCODING=b;TYPE=PNG:' + imageData.split(',')[1] + '\n'
        : '';

    var vcard =
      'BEGIN:VCARD\n' +
      'VERSION:3.0\n' +
      'PRODID:-//ionlake//MyRepChat//EN\n' +
      'N:;' +
      String(name).replace(/[:;]/g, '') +
      ';;;\n' +
      'FN:' +
      String(name).replace(/[:;]/g, '') +
      '\n' +
      'TITLE:' +
      String(title).replace(/[:;]/g, '') +
      '\n' +
      photo +
      'ORG:' +
      String(companyName).replace(/[:;]/g, '') +
      '\n' +
      'ADR;type=WORK:;;' +
      String(address).replace(/[:;]/g, '') +
      ';' +
      String(city).replace(/[:;]/g, '') +
      ';' +
      String(province)?.replace(/[:;]/g, '') +
      ';' +
      String(postalCode).replace(/[:;]/g, '') +
      ';\n' +
      'EMAIL;type=INTERNET:' +
      String(email).replace(/[:;]/g, '') +
      '\n' +
      'TEL;type=WORK;type=VOICE:' +
      String(workphone).replace(/[:;]/g, '') +
      '\n' +
      'TEL;type=CELL;type=VOICE:' +
      String(mobileNumber).replace(/[:;]/g, '') +
      '\n' +
      'UID:' +
      new Date().toISOString() +
      '\n' +
      'NOTE:' +
      String(note).replace(/[:;]/g, '') +
      '\n' +
      'END:VCARD';

    try {
      let body = {
        action: 'create_vcard',
        adminMode: true,
        contents: vcard,
        tenant: profile.tenant.id,
        toast: false,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        Toast.show({
          duration: 2000,
          title: 'VCard saved.',
          status: 'success',
        });
        navigation.navigate('CRM');
        dispatch(setCompletedStep({ card: true }));
      } else {
        Toast.show({
          duration: 2000,
          title: result.data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = () => {
    navigation.navigate('CRM');
    dispatch(setCompletedStep({ card: true }));
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header title={'Virtual Business Card Setup'} />
        <ScrollView style={{flex:1}}>
        <View style={styles.topComment}>
          <Text style={styles.text}>
            A vCard (Virtual Contact Card) is a standard format that is used to
            share contact information across devices. Not all devices work with
            the standard, but don't worry, we got you covered. We make the vCard
            available in several formats so your clients can choose what works
            best for them.
          </Text>
          <Text style={[styles.text, styles.setupPaneTitle]}>
            Virtual Business Card Information
          </Text>
        </View>
        <View style={styles.body}>
          <View style={styles.content}>
            {vcards.length > 0 ? (
              <View style={styles.successContent}>
                <FontAwesome name="thumbs-o-up" size={60} />
                <Text style={[styles.text, styles.title]}>
                  Your vCard is setup! Click "Continue" to continue.
                </Text>
                <Text style={[styles.text, styles.comment]}>
                  To manage your vCard, go to your account settings after you
                  are logged in.
                </Text>
                <CustomButton title="continue" onPress={handleContinue} />
              </View>
            ) : (
              <>
              <TouchableOpacity
                  style={{alignSelf:'center'}}
                  onPress={() => {
                    handleDocumentPicker();
                  }}>
                  <Entypo
                    name="documents"
                    size={30}
                    color={AppColor.PrimaryColor}
                  />
                  <Text>Photo</Text>
                </TouchableOpacity>
                
                {imageData && (
                  <img
                    style={{
                      margin: '15px 0px',
                      width: 100,
                      height: 50,
                      alignSelf:'center',
                      objectFit: 'contain',
                    }}
                    alt="Your VCard Profile Photo."
                    src={imageData}
                  />
                )}
                
                
              <View>
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Your name"
                  placeholder="Your name"
                  value={name}
                  onChangeText={(val) => setName(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Your Email"
                  placeholder="Your Email"
                  value={email}
                  onChangeText={(val) => setEmail(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Compay Name"
                  placeholder="Compay Name"
                  value={companyName}
                  onChangeText={(val) => setCompanyName(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Your Title"
                  placeholder="Your Title"
                  value={title}
                  onChangeText={(val) => setTitle(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Address"
                  placeholder="Address"
                  value={address}
                  onChangeText={(val) => setAddress(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="City"
                  placeholder="City"
                  value={city}
                  onChangeText={(val) => setCity(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="State/Province"
                  placeholder="State/Province"
                  value={province}
                  onChangeText={(val) => setProvince(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Postal Code"
                  placeholder="Postal Code"
                  value={postalCode}
                  onChangeText={(val) => setPostalCode(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Work Number"
                  placeholder="Work Number"
                  value={workphone}
                  onChangeText={(val) => setWorkphone(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Mobile/MyRepChat Number"
                  placeholder="Mobile/MyRepChat Number"
                  value={mobileNumber}
                  onChangeText={(val) => setMobileNumber(val)}
                />
                <CustomInput
                  containerStyle={{marginBottom:10}}
                  title="Note"
                  placeholder="Note"
                  value={note}
                  onChangeText={(val) => setNote(val)}
                />
                <CustomButton title="save" onPress={handleCreateVCards} />
              </View>
              </>
            )}
          </View>
        </View>
        </ScrollView>
      </SafeAreaView>
      {loading && <Loader />}
    </>
  );
};

export default BusinessCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: Dimensions.get('screen').height,
    overflow: 'scroll',
  },
  body: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 767,
    padding: 30,
  },
  successContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topComment: {
    padding: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
  },
  setupPaneTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 24,
    marginTop: 50,
  },
  title: {
    fontSize: 28,
    marginVertical: 25,
  },
  comment: {
    marginBottom: 20,
  },
});
