import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const scheduledSlice = createSlice({
  name: 'scheduled_messages',
  initialState: {
    data: [],
  },
  reducers: {
    setScheduledMessages: (state, action) => {
      return {
        data: action.payload.data,
      };
    },
  },
});

export const { setScheduledMessages } = scheduledSlice.actions;

export const loadScheduledMessageThunk = (start, end) => {
  console.log(start, end);
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_scheduled_messages',
        from: start,
        to: end,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setScheduledMessages({ data: result.data.messages }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const deleteScheduledMessageThunk = (id) => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'delete_scheduled_message',
        id: id,
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        // dispatch(loadScheduledMessageThunk())
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export default scheduledSlice.reducer;
