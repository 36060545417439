import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";

const SAMLSetup = () => {

    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const { refetch, data, isLoading, isError } = getQuery({
        groupId: affiliate.id,
        action: "get_samlidp"
    })

    const [configuration, setConfiguration] = React.useState(null);

    const Toast = useToast();

    const [authData, setAuthData] = React.useState(
       data?.samlIdp ? data.samlIdp 
       : 
       {
        emailPattern:'',
        entitySuffix:'',
        authComparison:'',
        authContext:'',
        idpSsoUrl:'',
        idpIssuer:'',
        idpCertificate:'',
        idpMetadataUrl:'',
        idpMetadataXml:''
       }
    )

    React.useEffect(()=>{
        if(data?.samlIdp){
            setAuthData(data.samlIdp)
        }
    },[data])

    if (isLoading) return <Loader backgroundColor={'transparent'} />

    if (!data) return null;

    const samlIdpId = data?.samlIdp?.id ?? null;

    const authenticationContexts = [
        { value: "none", label: "None" },
        { value: "password", label: "Password" },
        { value: "transportpassword", label: "Transport Password" },
        { value: "internetprotocol", label: "Internet Protocol" },
        { value: "internetprotocolpassword", label: "Internet Protocol Password" },
        { value: "kerberos", label: "Kerberos" },
        { value: "mobileonefactorunregistered", label: "Mobile One-Factor Unregistered" },
        { value: "mobiletwofactorunregistered", label: "Mobile Two-Factor Unregistered" },
        { value: "mobileonefactorcontract", label: "Mobile One-Factor Contract" },
        { value: "mobiletwofactorcontract", label: "Mobile Two-Factor Contract" },
        { value: "previoussession", label: "Previous Session" },
        { value: "x509", label: "X.509" },
        { value: "pgp", label: "PGP" },
        { value: "spki", label: "SPKI" },
        { value: "xmldsig", label: "XML Digital Signature" },
        { value: "smartcard", label: "Smartcard" },
        { value: "smartcardpki", label: "Smartcard PKI" },
        { value: "softwarepki", label: "Software PKI" },
        { value: "telephony", label: "Telephony" },
        { value: "nomadtelephony", label: "Nomad Telephony" },
        { value: "personaltelephony", label: "Personal Telephony" },
        { value: "authenticatedtelephony", label: "Authenticated Telephony" },
        { value: "secureremotepassword", label: "Secure Remote Password" },
        { value: "tlsclient", label: "TLS Client" },
        { value: "timesynctoken", label: "Time Sync Token" }
      ];

    const authenticationComparisons = [
        { value: "none", label: "None" },
        { value: "exact", label: "Exact" },
        { value: "minimum", label: "Minimum" },
        { value: "maximum", label: "Maximum" },
        { value: "better", label: "Better" }
    ];
      
    const handleSave = async () => {
        const body = {
            alias: "",
            ...data.samlIdp,
            groupId: affiliate.id,
            emailPattern: authData.emailPattern,
            entitySuffix: authData.entitySuffix,
            authComparison: authData.authComparison,
            authContext: authData.authContext,
            idpSsoUrl: '',
            idpIssuer: '',
            idpCertificate: '',
            idpMetadataUrl: '',
            idpMetadataXml: '',
            action: "create_samlidp"
        }
        if(samlIdpId) body.action = 'update_samlidp';
        
        switch(configuration){
            case 'explicit': {
                body.idpSsoUrl = authData.idpSsoUrl;
                body.idpIssuer = authData.idpIssuer;
                body.idpCertificate = authData.idpCertificate;
                break;
            }
            case 'url': {
                body.idpMetadataUrl = authData.idpMetadataUrl;
                break;
            }
            case 'xml': {
                body.idpMetadataXml = authData.idpMetadataXml;
                break;
            }
        }

        const result = await RequestMaker(body);

        if(result.data.success){
            refetch();
        } else if(result.data.errorMessage) {
            Toast.show({
                title: result.data.errorMessage,
                status: 'error'
            
            })
        }
    
    }


    const handleDelete = async () => {
        try{
        const body = {
            id: samlIdpId,
            action: "delete_samlidp"
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            setAuthData({
                emailPattern:'',
                entitySuffix:'',
                authComparison:'',
                authContext:'',
                idpSsoUrl:'',
                idpIssuer:'',
                idpCertificate:'',
                idpMetadataUrl:'',
                idpMetadataXml:''
            })
            refetch();
        } else if(result.data.errorMessage){
            throw new Error(result.data.errorMessage)
        }
        } catch(err){
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }
    }

    return(
        <ScrollView contentContainerStyle={{flex:1}}>
        <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
            This is optional. If you are a SAML 2.0 Identity provider, and you wish to use SAML for user authentication, this is where you can configure ionlake (SP) to use you (IDP) when authenticating your users.
        </Text>

        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
            Email Pattern
        </Text>
        <CustomInput
        value={authData.emailPattern}
        onChangeText={e => setAuthData({...authData, emailPattern: e})} placeholder="@mycompany.com"/>

        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
            Entity Suffix
        </Text>
        <CustomInput 
        value={authData.entitySuffix}
        onChangeText={e => setAuthData({...authData, entitySuffix: e})}/>

        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
            Authentication Comparison
        </Text>
        <SimpleDropdown
        data={authenticationComparisons}
        value={authData.authComparison}
        onChange={e => setAuthData({...authData, authComparison: e})}
        />
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
            Authentication Context
        </Text>
        <SimpleDropdown
        data={authenticationContexts}
        value={authData.authContext}
        onChange={e => setAuthData({...authData, authContext: e})}
        />
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
            Configuration
        </Text>

        <SimpleCheckbox  label={'Explicit Configuration'} value={configuration === 'explicit'} onChange={e => setConfiguration('explicit')} />
        <SimpleCheckbox label={'Metadata URL Configuration'} value={configuration === 'url'} onChange={e => setConfiguration('url')} />
        <SimpleCheckbox label={'Metadata XML Configuration'} value={configuration === 'xml'} onChange={e => setConfiguration('xml')} />

        {
            configuration === 'explicit' &&
            <>
                <CustomInput value={authData.idpSsoUrl} onChangeText={e => setAuthData({...authData, idpSsoUrl: e }) } placeholder="SSO URL"/>
                <CustomInput value={authData.idpIssuer} onChangeText={e => setAuthData({...authData, idpIssuer: e }) } placeholder="Issuer"/>
                <CustomInput value={authData.idpCertificate} onChangeText={e => setAuthData({...authData, idpCertificate: e })} textInputStyle={{minHeight:150}} multiline placeholder="Certificate"/>
            </>
        }
        {
            configuration === 'url' &&
            <>
            <CustomInput 
            value={authData.idpMetadataUrl}
             onChangeText={e => setAuthData({...authData, idpMetadataUrl: e})} placeholder="Metadata URL"/>
            </>
        }
        {
            configuration === 'xml' &&
            <>
            <CustomInput
            value={authData.idpMetadataXml}
            onChangeText={e => setAuthData({...authData, idpMetadataXml: e })} textInputStyle={{minHeight:150}} multiline placeholder="Metadata XML"/>
            </>
        }
        {
            data.samlSp && data.samlSp['metadataUrl'] &&

        <>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:10}}>
            SP Metadata
        </Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
            {data.samlSp['metadataUrl']}
        </Text>
        </>
        }

        <View style={{flexDirection:'row',alignItems:'center',gap:20,margin:10,justifyContent:'flex-end',marginTop:'auto'}}>
            {
            samlIdpId &&
            <CustomButton title={'Delete'} width={'20%'} onPress={handleDelete} outline/>
            }
            <CustomButton onPress={handleSave} title={'Save'} width={'20%'}/>
        </View>



        </ScrollView>
    )
}

export default SAMLSetup;