import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Alert,
  FlatList,
  TouchableOpacity,
  Linking,
  ScrollView,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { Select, useToast } from 'native-base';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import ReactAudioPlayer from 'react-audio-player';

import Header from '../../../Components/Header';
import Loader from '../../../Components/Loader';
import CustomButton from '../../../Components/Button';
import VoiceMailItem from '../../../Components/VoiceAvailability/VoiceMail';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import getData from '../../../AsyncUtils/GetData';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';

const VoiceMail = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [greetings, setGreetings] = useState([]);
  const [visibleCreation, setVisibleCreation] = useState(false);
  const [selectedType, setSelectedType] = useState('voicemail');
  const [selectedItem, setSelectedItem] = useState(null);
  const Toast = useToast();
  useEffect(() => {
    getGreetings();
  }, []);

  const handleHelp = () => {
    Linking.canOpenURL(
      'https://ionlake.zendesk.com/hc/en-us/articles/360016019092-Greeting-Messages'
    ).then((supported) => {
      if (supported) {
        Linking.openURL(
          'https://ionlake.zendesk.com/hc/en-us/articles/360016019092-Greeting-Messages'
        );
      } else {
        console.log(
          "Don't know how to open URI: ",
          'https://ionlake.zendesk.com/hc/en-us/articles/360016019092-Greeting-Messages'
        );
      }
    });
  };

  const callMe = async () => {
    try {
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let body = {
        action: 'create_greeting',
        greeting: selectedType,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Use your phone to complete recording',
          status: 'success',
        });
        getGreetings();
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const getGreetings = async () => {
    try {
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let body = {
        action: 'get_greetings',
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      setGreetings(data.greetings);
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteGreeting = async () => {
    if (!selectedItem) {
      Alert.alert('Error', 'Please select greeting');
      return;
    }
    try {
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let body = {
        action: 'delete_greeting',
        id: selectedItem.id,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Your greeting has been deleted.',
          status: 'success',
        });
        getGreetings();
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const renderHeader = () => {
    return (
      <View style={styles.listHeader}>
        <Text style={styles.listHeaderTitle}>Type</Text>
        <Text style={styles.listHeaderTitle}>Created</Text>
        <Text style={styles.listHeaderTitle}>Duration</Text>
      </View>
    );
  };
  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={{flex:1}}>
      <View style={styles.content}>
      
        <Text style={styles.comment}>
          Create a custom voice mail message that callers will hear when they are
          prompted to leave a voice mail message for you, or when they call
          during a time that you indicate you are not available.
        </Text>
        <Text style={styles.comment}>
          To create a voice mail message click the
          <Text style={styles.commentBold}> +Create Voice Mail </Text>
          button, select the{' '}
          <Text style={styles.commentBold}>type of voice mail</Text> to record,
          and click the <Text style={styles.commentBold}>Call Me</Text> button.
          Your Mobile Phone will ring, answer it and follow the instructions to
          complete the voice mail. After you have recorded a new voice mail,
          click the refresh button to get the new message in your list of voice mail messages.
        </Text>
        <View style={styles.listHeader}>
          <CustomButton
            title="create voice mail"
            width={'30%'}
            onPress={() => {setVisibleCreation(true); setSelectedItem(null)}}
            style={styles.button}
          />
          <TouchableOpacity onPress={getGreetings}>
            <EvilIcons name="refresh" size={30} />
          </TouchableOpacity>
        </View>
        {greetings?.length > 0 && (
          <View style={styles.listHeader}>
            <FlatList
              data={greetings}
              ListHeaderComponent={renderHeader}
              renderItem={({ item }) => (
                <VoiceMailItem
                  item={item}
                  onPress={(val) => {setSelectedItem(val); setVisibleCreation(null)}}
                />
              )}
            />
          </View>
        )}
        {visibleCreation && (
          <View>
            <Text style={styles.greetingType}>Greeting Type</Text>
            <Select
              style={styles.picker}
              selectedValue={selectedType}
              onValueChange={(itemValue, itemIndex) =>
                setSelectedType(itemValue)
              }>
              <Select.Item label="Voice Mail" value="voicemail" />
              <Select.Item label="Unavailable" value="unavailable" />
            </Select>
            <View style={{marginTop: 30}}>
              <CustomButton
                title="call me"
                onPress={callMe}
                width={'20%'}
                style={styles.callButton}
              />
            </View>
          </View>
        )}
        {selectedItem && (
          <View style={styles.audio}>
            <ReactAudioPlayer
              src={selectedItem.url}
              autoPlay
              controls
            />
            <View style={styles.buttonWrapper}>
              <TouchableOpacity onPress={() => setSelectedItem(null)}>
                <Text style={styles.greetingType}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={deleteGreeting}>
                <Text style={styles.deleteBtn}>Delete</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
      </ScrollView>
      {loading && <Loader />}
    </SafeAreaView>
  );
};

export default VoiceMail;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
  },
  content: {
    width: '100%',
    maxWidth: 767,
    padding: 15,
    marginVertical: 10,
    alignSelf: 'center',
  },
  comment: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginVertical: 5,
    lineHeight: 24,
  },
  commentBold: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Bold,
    lineHeight: 24,
  },
  callButton: {
    width: '30%',
  },
  button: {
    marginVertical: 5,
  },
  listHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },
  listHeaderTitle: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Bold,
    width: widthPercentageToDP(30),
    textAlign: 'center',
    marginVertical: 10,
  },
  flatlist: {
    borderWidth: 1,
  },
  picker: {
    width: '100%',
    marginVertical: 10,
  },
  greetingType: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Bold,
    textAlign: 'center',
    marginVertical: 10,
    color: AppColor.PrimaryColor,
  },
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 30,
    width: '100%'
  },
  deleteBtn: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Bold,
    textAlign: 'center',
    marginVertical: 10,
    color: AppColor.danger,
  },
  help: { alignItems: 'flex-end' },
  audio: {
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 30,
    marginBottom:30,
    width: '100%'
  }
});
