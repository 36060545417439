import React from "react";
import { TextInput, TouchableOpacity, View, Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import AppColor from "../../Constants/Color";
import { Icon } from 'react-native-elements';
import useResponsiveLayout from "../../Middleware/useResponsiveLayout";


const DialpadInput = ({onChange, value, onCall}) => {
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
    const {isWebCompact} = useResponsiveLayout();

    return(

      <View
      style={{
        alignItems: 'center',
        alignSelf: 'center',
        width:isWebCompact ? '80%' : '50%',
        justifyContent:'space-evenly',
        flex:1
      }}>
            <TextInput
              keyboardType='number-pad'
              value={value}
              maxLength={10}
              placeholder='Enter a number'
              onChangeText={e => onChange(e.replace(/[^0-9]/g, ''))}
              style={{
                width: '100%',
                minWidth:'100%',
                fontSize: 24,
                textAlign: 'center',
                marginBottom:20,
                minHeight:50,
                fontFamily: AppFonts.Roboto_Regular,
              }} />
      <View
        style={{
          flexWrap: 'wrap',
          width: '100%',
          flexDirection: 'row',
          alignSelf: 'center',
        }}>
        {numbers.map((i, index) => {
          return (
            <TouchableOpacity
              key={`number_${index}`}
              onPress={() => {
                onChange((o) => o + i);
              }}
              activeOpacity={0.8}
              style={{
                width: '33%',
                height: 90,
                justifyContent: 'center',
                color:'black',
                alignItems: 'center',
              }}>
              <Text
                style={{
                  alignItems: 'center',
                  fontSize: 30,
                  color: 'black',
                  fontFamily: AppFonts.Roboto_Bold,
                }}>
                {i}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          flexDirection: 'row',
          alignSelf: 'center',
        }}>
        <TouchableOpacity
          onPress={() => onChange((o) => ['+', ...o])}
          activeOpacity={0.5}
          style={{
            height: 100,
            justifyContent: 'center',
            alignItems: 'center',
            width: '33%',
            borderRadius: 0,
          }}>
          <Text
            style={{
              fontSize: 30,
              textAlign: 'center',
              color: 'black',
              fontFamily: AppFonts.Roboto_Regular,
            }}>
            +
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={() => onCall()}
          style={{
            height: 100,
            width: '33%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Icon color={AppColor.PrimaryColor} name={'call'} type={'material'} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            let p = [...value];
            p.pop();
            p = p.join('')
            onChange(p);
          }}
          activeOpacity={0.5}
          style={{
            height: 100,
            justifyContent: 'center',
            alignItems: 'center',
            width: '33%',
          }}>
          <Icon
            color={AppColor.danger}
            name={'backspace'}
            type={'font-awesome-5'}
          />
        </TouchableOpacity>
      </View>
    </View>
    )
}

export default DialpadInput;