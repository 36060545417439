
import React from 'react';
import { View, ScrollView, Image, Pressable, Text, TouchableOpacity, } from 'react-native';
import Modal from 'modal-react-native-web';
import Search from '../Search';
import { Icon } from 'react-native-elements';
import CustomButton from '../Button/index.web';
import { giphySearch, giphyTrending } from '../../Api/ten4';
import AppFonts from '../../Constants/Fonts';


const ImagePreviewModal = props => {

    return(
        <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        appElement={document.getElementById('app')} 
        transparent={true}
        visible={props.visible}
        onDismiss={() => {
          props.setVisible(false);
        }}>
        <ScrollView
        
        style={{width:'100vw',height:'100vh',backgroundColor:'rgba(0,0,0,.65)',paddingTop:'5em'}}>
                <View style={{alignSelf:'center',flexDirection:'column',}}>
                    <TouchableOpacity onPress={()=>props.setVisible(false)}  style={{alignSelf:'flex-end',flexDirection:'row',alignItems:'center'}}>
                        <Text style={{fontFamily:AppFonts.Roboto_Regular,color:'white',fontSize:20,fontWeight:'900'}}>Close</Text>
                        <Icon 
                        type='Ionicons'
                        name="close"
                        color='white'
                        size={36}
                        />
                    </TouchableOpacity>

                    <img onClick={()=>{props.setVisible(false)}} src={props.image.mediaUrl} style={{maxWidth:'50vw',alignSelf:'center',cursor:'pointer'}}/>
                </View>

        </ScrollView>
        </Modal>
    )
}

export default ImagePreviewModal;