import React from "react";
import { Modal, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import { Icon } from "react-native-elements";
import RequestMaker from "../../Middleware/ApiCaller";
import CustomButton from "../../Components/Button/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";

const AccountManager = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const {refetch,data,isLoading,isError} = getQuery({
        action: 'get_affiliate_links',
        affiliateId: affiliate.id
    })
    const [resourceModal, setResourceModal] = React.useState(false);


    if(isLoading) return <Loader backgroundColor="transparent"/>;
    if(!data) return null;

    const handleDelete = async (id) => {
        const body = {
            action: 'delete_link',
            linkId: id
        }
        const result = await RequestMaker(body);

        if(result.data.success){
            refetch();
        }
    }


    return(
        <>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:20}}>
            Account Manager Details
            </Text>            

            {
                data.links.map(e => {
                    return(
                        <View style={{marginVertical:10,flexDirection:'row',alignItems:'center',gap:20}}>
                            <Text style={{fontFamily:AppFonts.Roboto_Bold}}>{e.resourceName}</Text>
                            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>- {e.role.label}</Text>
                            <Icon onPress={() => handleDelete(e.id)} name="trash" type="font-awesome" color="red" />
                        </View>
                        )
                })
            }

            <CustomButton
            title={'Add Resource'}
            style={{alignSelf:'flex-start',marginTop:20}}
            width={'20%'}
            onPress={() => {setResourceModal(true)}}
            />

    {
        resourceModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={resourceModal} transparent={true}>
            <ResourceModal setResourceModal={setResourceModal} rerefetch={refetch}/>
        </Modal>
      }
        </>
    )
}

const ResourceModal = ({setResourceModal, rerefetch}) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const {refetch,data,isLoading,isError} = getQuery({
        action: 'get_resources',
    })

    const [resourceData , setResourceData] = React.useState(
        {
            id: data?.resources[0]?.id || null,
            role: "1",
        }
    );



    if(isLoading) return <Loader backgroundColor="transparent"/>;
    if(!data) return null;


    const transformedArray = data.resources.map(item => {
        return { value: item.id, label: item.name };
    });

    const roles = [
        {
            value: "1",
            label: 'Account Manager'
        },
        {
            value: '2',
            label: 'Closing Sales Rep'
        }
    ]

    const handleSave = async () => {
        const body = {
            action: 'create_link',
            affiliateId: affiliate.id,
            resourceId: resourceData.id,
            role: resourceData.role
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            rerefetch();
            setResourceModal(false);
        }
    }
    

    return(
    <View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
        <View style={{width:400,height:150,backgroundColor:'white'}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                Select the person and their role.
            </Text>
            <View style={{flexDirection:'row',alignItems:'center',gap:20}}>
                <SimpleDropdown
                    data={transformedArray}
                    value={resourceData.id}
                    onChange={val => setResourceData({...resourceData, id: val})}
                />

                <SimpleDropdown
                    data={roles}
                    value={resourceData.role}
                    onChange={val => setResourceData({...resourceData, role: val})}
                />
            </View>

            <View style={{flexDirection:'row',justifyContent:'flex-end',gap:10,marginTop:20,alignItems:'flex-end'}}>
                <CustomButton title={'Cancel'} width={'30%'} outline onPress={() => {setResourceModal(false)}}/>
                <CustomButton title={'Save'} width={'30%'} onPress={handleSave}/>

            </View>

        </View>
    </View>
    )


}

export default AccountManager;