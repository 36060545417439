/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import { StyleSheet, Text, View, TextInput, Platform } from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';

const CustomInput = (props) => {
  return (
    <>
      <Text
        style={{
          fontSize: 15,
          color: 'rgba(0,0,0,.8)',
          fontFamily: AppFonts.Roboto_Regular,
          ...props.titleStyle,
        }}>
        {props.title}
      </Text>
      <View
        style={{
          minHeight: 45,
          width: props.width ? props.width : '100%',
          alignSelf: 'center',
          marginTop: 10,
          borderRadius: 4,
          ...props.containerStyle,
        }}>
        <TextInput

          spellCheck
          autoCorrect
          value={props.value}
          accessibilityRole='text'
          accessible={true}
          secureTextEntry={props.secure}
          placeholder={props.placeholder}
          onChangeText={props.onChangeText}
          keyboardType={props.keyboardType}
          multiline={props.multiline}
          type={props.type}
          onChange={e => props.onChange ? props.onChange(e) : null}
          onKeyPress={(e) => {
            if (e.nativeEvent.code == 'Enter' && props.onEnterPress) {
              props.onEnterPress();
            }
          }}
          style={{
            width: '100%',
            paddingVertical: 10,
            paddingHorizontal: 5,
            borderWidth: 1,
            borderColor: 'black',
            borderRadius:5,
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 16,
            backgroundColor: props.disabled ? AppColor.WHITE400 : 'transparent',
            ...props.textInputStyle,
          }}
          editable={!props.disabled}
          maxLength={props.maxLength}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  conatiner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default CustomInput;
