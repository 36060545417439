import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";
import moment from "moment";
import AppFonts from "../../Constants/Fonts";
import LogoContactImage from '../../Assets/Images/splashlogo.png';
import End_Points from "../../Constants/Api";
import { providers } from "../IntegrationProvider";

const CalendarEvent = ({focused, item, onPress}) => {
    let image = LogoContactImage;
    let provider = Object.values(providers).find((i) => i.name == item.source);
    if (provider) {
      image = provider.smallImageUrlPath;
    }
    let title = null;
    if(item?.invitees?.length === 1) title = item.invitees[0].name
    else if (item?.invitees?.length > 1) title = `${item.invitees[0].name} & ${item.invitees.length - 1} more`
    return(
        <TouchableOpacity
        onPress={onPress}
        style={{
          width: '100%',
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          height: 65,
          paddingHorizontal:10,
          marginTop: 10,
          borderTopColor: '#116AB4',
          borderTopWidth: focused ? 1 : 0,
          borderBottomColor: '#116AB4',
          borderBottomWidth: focused ? 1 : 0,
          backgroundColor: focused ? '#ECF6FF' : 'transparent'

        }}>
        <View style={{ justifyContent: 'center' }}>
        <img src={image} style={{ height: 30, width: 30, borderRadius: 20 }} />
        </View>
        <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}>
          
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 17,
                marginHorizontal: 10,
                color: 'black',
              }}
              numberOfLines={1}>
              {title}
            </Text>
            <Text
              style={{
                fontFamily: AppFonts.Roboto_Regular,
                fontSize: 14,
                lineHeight: 20,
                marginHorizontal: 10,
                color: AppColor.Dark,
              }}
              numberOfLines={1}>
              {item?.Message || item.title}
            </Text>
          </View>
        </View>
        
        <View style={{ justifyContent: 'center' }}>
          <Text>{moment(item.startTimestamp).format('MMM D YYYY')}</Text>
          <Text>{moment(item.startTimestamp).format('hh:mm a')}</Text>
        </View>
        {item?.unread === true && <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            marginHorizontal:5
          }}>
            <Icon name={'warning'} type={'material-icon'} color={AppColor.danger} />
        </View>}

</TouchableOpacity>

    )
}

export default CalendarEvent;