import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import Alert from 'Alert';
import { useToast } from 'native-base';

import CustomButton from '../../../Components/Button';
import CustomInput from '../../../Components/CustomInput';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import Loader from '../../../Components/Loader';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import Voip from './Voip';

const SMSEnable = ({ goBack, navigation, setLOA }) => {
  const [areaCode, setAreaCode] = useState('');
  const [prefix, setPrefix] = useState('');
  const [lastFourNum, setLastFourNum] = useState('');
  const [loading, setLoading] = useState(false);
  const [visibieVoip, setVisibieVoip] = useState(false);
  const [verifiedData, setVerifiedData] = useState(null);
  const Toast = useToast();

  const handleVerify = async () => {
    const phone = areaCode + prefix + lastFourNum;
    if (phone?.length !== 10) {
      Alert.alert(
        'Error',
        'Your phone number must be 10 digits in order to be valid. Please correct and try again.'
      );
      return;
    }
    setLoading(true);
    try {
      let body = {
        action: 'number_lookup',
        number: phone,
        toast: false,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        const type = result.data.carrier.type;
        if(type === 'landline' || type === 'voip'){
          setLOA(phone)
        } else {
             Alert.alert(
            'Error',
            "We're sorry, at this time we can only set up landlines for sms. " +
              'Please use the back button and choose to create a new number.'
          );
        }
        // const type = result.data.carrier.type;
        // if (type === 'landline') {
        //   if (result.data.carrier.name.indexOf('Bandwidth') === -1) {
        //   } else {
        //     Alert.alert(
        //       'showDialogMessage',
        //       "We're sorry, this number is hosted by " +
        //         result.data.carrier.name +
        //         '. They are not allowing ' +
        //         'their customers numbers to be enabled for text messaging. We urge you to contact them and tell them you ' +
        //         'are a customer of theirs and that you would like to have your number enabled for Hosted SMS on partner networks.'
        //     );
        //   }
        // } else if (type === 'voip') {
        //   if (result.data.carrier.name.indexOf('Bandwidth') === -1) {
        //     setVisibieVoip(true);
        //     setVerifiedData(result.data);
        //   } else {
        //     Alert.alert(
        //       'showDialogMessage',
        //       "We're sorry, this number is hosted by " +
        //         result.data.carrier.name +
        //         '. They are not allowing ' +
        //         'their customers numbers to be enabled for text messagesing. We urge you to contact them and tell them you ' +
        //         'are a customer of theirs and that you would like to have your number enabled for Hosted SMS on partner networks.'
        //     );
        //   }
        // } else {
        //   Alert.alert(
        //     'showDialogMessage',
        //     "We're sorry, at this time we can only set up landlines for sms. " +
        //       'Please use the back button and choose to create a new number.'
        //   );
        // }
      } else {
        Alert.alert(
          'Error',
          "We're sorry, the number you entered cannot be used. Please check the number and try again."
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        {visibieVoip ? (
          <Voip
            verifiedData={verifiedData}
            handleCancel={goBack}
            areaCode={areaCode}
            prefix={prefix}
            lastFourNum={lastFourNum}
            setLoading={setLoading}
            loading={loading}
            navigation={navigation}
          />
        ) : (
          <View style={styles.content}>
            <Text style={styles.comment}>SMS Enable a landline</Text>

            <Text style={{alignSelf:'flex-start',marginTop:20}}><Text style={{fontSize:18}}>Step 1 </Text> Verify your phone number by entering it below and clicking 'Verify Phone Number'.</Text>
            <Text style={{alignSelf:'flex-start',marginTop:20}}><Text style={{fontSize:18}}>Step 2 </Text> Sign the following Letter of Authorization form and click 'Continue' only once you've been instructed that all signing has been completed. </Text>
            <Text style={{alignSelf:'flex-start',marginTop:20,marginBottom:20}}><Text style={{fontSize:18}}>Step 3 </Text> Finish the remaining steps to complete setup of your MyRepChat account. </Text>

            <CustomInput
              title=""
              placeholder="Area Code*"
              keyboardType="phone-pad"
              value={areaCode}
              onChangeText={(val) => setAreaCode(val)}
              maxLength={3}
            />
            <CustomInput
              title=""
              placeholder="Prefix"
              keyboardType="phone-pad"
              value={prefix}
              onChangeText={(val) => setPrefix(val)}
              maxLength={3}
            />
            <CustomInput
              title=""
              placeholder="Last 4"
              keyboardType="phone-pad"
              value={lastFourNum}
              onChangeText={(val) => setLastFourNum(val)}
              maxLength={4}
            />
            <View style={styles.buttonWrapper}>
              <CustomButton
                title="verify phone number"
                onPress={handleVerify}
              />
            </View>
          </View>
        )}
      </SafeAreaView>
      {loading && <Loader />}
    </>
  );
};

export default SMSEnable;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    width: widthPercentageToDP(90),
    maxWidth: 768,
    alignItems: 'center',
    padding: 15,
  },
  comment: {
    fontSize: 16,
    fontWeight: '600',
    color: AppColor.Black200,
    marginBottom: 10,
    fontFamily: AppFonts.Roboto_Bold,
  },
  buttonWrapper: {
    marginTop: 15,
    width: '100%',
  },
});
