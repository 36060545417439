import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  dl: false,
};
const selectedContactSlice = createSlice({
  name: 'selected_contact',
  initialState: initialState,
  reducers: {
    setSelectedContact: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
        dl: action.payload.dl,
      };
    },
    clearSelectedContact: (state, action) => {
      return initialState;
    },
  },
});

export const { setSelectedContact, clearSelectedContact } =
  selectedContactSlice.actions;

export default selectedContactSlice.reducer;
