import { DrawerActions } from '@react-navigation/routers';
import { Icon } from 'react-native-elements';
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Modal,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import {
  loadAllAutoResponseThunk,
  deleteAutoResponseThunk,
} from '../../Redux/auto_response';
import { useDispatch, useSelector } from 'react-redux';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import InfoBar from '../../Components/Layout/InfoBar';
import { Bubbles } from '../Authentication/Login/Bubbles';
import AutoResponseForm from './AutoResponseForm';
import Search from '../../Components/Search';
import { RemovePhoneModal } from '../Contacts/modals';
import useUndo from '../../Middleware/useUndo';
import Loader from '../../Components/Loader/index.web';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';

const AutoResponse = ({ navigation }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auto_responses).data;
  const [filteredData, setFilteredData] = React.useState(data);
  React.useEffect(()=>{setFilteredData(data)},[data])
  const [autoResponse, setAutoResponse] = React.useState();
  const [toDelete, setToDelete] = React.useState(null);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {isWebCompact} = useResponsiveLayout();

  const undo = useUndo();

  React.useEffect(() => {
    dispatch(loadAllAutoResponseThunk());
  }, []);

  const handleRecreateResponse = async () => {
    const body = {
      action: "create_workflow",
      request: toDelete.request,
      response: toDelete.response,
      requestValue: toDelete.requestValue,
      responseValue: toDelete.responseValue,
      active: toDelete.active,
      ordinal: 0,
    }
    setLoading(true);

    const result = await RequestMaker(body);

    if(result.data.success){
      dispatch(loadAllAutoResponseThunk());
    }

    setLoading(false);
  }

  const handleDeleteAutoResponse = (id) => {
    dispatch(deleteAutoResponseThunk(id));
    undo(handleRecreateResponse);
  };


  const handleReorderUp = async item => {
    let previousId;
    const index = data.findIndex(e => e.id === item.id);
    if(index === 1){
      previousId = null;
    } else if(index === 0){
      return;
    } else if(index > 1){
      previousId = data[index - 2].id
    }
    const body = {
      action: 'update_workflow',
      ...item,
      previousId: previousId
    };
    
    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    let result = await RequestMaker(Config);
    if (result.data?.success) {
      dispatch(loadAllAutoResponseThunk());
    } else {
      alert("Something went wrong. Please try again later.")
    }
  }

  const handleReorderDown = async item => {
    let previousId;
    const index = data.findIndex(e => e.id === item.id);
    if(item.id === data[data.length - 1].id ){
      return;
    } else {
      previousId = data[index + 1].id
    }
    const body = {
      action: 'update_workflow',
      ...item,
      previousId: previousId
    };    
    
    let device = await getData('@device_id');
    const headers = { 'x-device': device };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
      headers: headers,
      redirect: 'follow',
    };
    let result = await RequestMaker(Config);
    if (result.data?.success) {
      dispatch(loadAllAutoResponseThunk());
    } else {
      alert("Something went wrong. Please try again later.")
    }
  }

  const renderListView = (item) => {
    let request = 'message1';
    let response = 'message1';
    let type = 'AntDesign';
    const focused = item.id === autoResponse?.id;

    if (item.request) {
      switch (item.request) {
        case 'incomingSms':
          request = 'message1';
          break;
        case 'incomingSmsNumber':
          request = 'message1';
          break;
        case 'incomingVoice':
          request = 'phone';
          break;
        case 'incomingVoiceAny':
          request = 'phone';
          break;
        default:
          request = 'message1';
      }
    }
    if (item.response) {
      switch (item.response) {
        case 'replyWith':
          response = 'reply';
          type = 'font-awesome';
          break;
        case 'email':
          response = 'email';
          type = 'material-community';
          break;
        case 'forwardTo':
          response = 'mail-forward';
          type = 'font-awesome';
          break;
        case 'sayMessage':
          response = 'volume-2';
          type = 'feather';
          break;
        default:
          response = 'message1';
      }
    }
    return (
      <TouchableOpacity
        onPress={() => setAutoResponse(item)}
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          minHeight: 60,
          marginTop: 10,
          borderTopColor: '#116AB4',
          borderTopWidth: focused ? 1 : 0,
          borderBottomColor: '#116AB4',
          borderBottomWidth: focused ? 1 : 0,
          paddingHorizontal:7.5,
          backgroundColor: focused ? '#ECF6FF' : 'transparent'

        }}>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
            <View>
            <Content.Row>
            <Icon color={AppColor.Dark} name={request} type={'antdesign'} />
            <Text
              numberOfLines={1}
              style={{
                color: 'black',
                fontFamily: AppFonts.Roboto_Bold,
                fontSize: 15,
                marginLeft:10
              }}>
              {item.requestValue}
            </Text>
            </Content.Row>
              <Content.Row>
            <Icon color={AppColor.Dark} name={response} type={type} />
            <Text numberOfLines={1} style={{marginLeft:10, fontSize: 12 }}>
              {item.responseValue}
            </Text>

            </Content.Row>
            </View>
        </View>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
          <TouchableOpacity
            onPress={() => handleReorderUp(item)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              style={{ fontSize: 20, color: AppColor.Dark }}
              name={'arrowup'}
              type={'antdesign'}
              color='black'
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => handleReorderDown(item)}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon
              style={{ fontSize: 20, color: AppColor.Dark }}
              name={'arrowdown'}
              type={'antdesign'}
              
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              // handleDeleteAutoResponse(item.id)
              setRemoveModal(true);
              setToDelete(item);
            }}
            style={{ justifyContent: 'center', marginHorizontal: 5 }}>
            <Icon color={AppColor.danger} name={'delete'} type={'antdesign'} />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    );
  };

  const handleSearch = query => {
    if(query === ''){
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(e => e.responseValue.includes(query) || e.requestValue.includes(query)))
    }
  }
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor, overflow:'hidden' }}>
      <Wrapper>
{       ( !isWebCompact || !autoResponse) &&
        <SideBar>
          <View style={{flex:1,}}>
          <Search 
          actionButton
          onPressActionButton={() => setAutoResponse('create')}
          actionButtonTitle='Auto Response'
          onChangeText={handleSearch}
          />
        <ScrollView style={{width:'100%', flex: 1, }}>
          <FlatList
            data={filteredData}
            ListEmptyComponent={
              <Text
                style={{
                  textAlign: 'center',
                  fontFamily: AppFonts.Roboto_Regular,
                  marginTop:20
                }}>
                No auto responses found.
              </Text>
            }
            scrollEnabled={false}
            renderItem={(item) => {
              return renderListView(item.item);
            }}
          />
          
        </ScrollView>
        </View>
          </SideBar>
}
        { (!isWebCompact || autoResponse ) &&
        <Content>
          <Content.Row style={{height:'100%'}}>
          <View style={{flex:2,height:'100%'}}>
          {(!autoResponse) ? 
                <Bubbles style={{width:'140%'}}>
                  <Text style={{color:'#116AB4',fontSize:32,width:'70%'}}>
                    Select an auto-response to edit or create a new one.
                  </Text>
                </Bubbles>

                :

                <AutoResponseForm data={autoResponse} onSave={()=>setAutoResponse(null)} onCancel={()=>setAutoResponse(null)}/>
          }
          </View>
          { !isWebCompact && <AutoResponseInfo/>}
          </Content.Row>
        </Content>
        }
        </Wrapper>    
        {
          removeModal && 
        <Modal style={{position:'absolute',height:'100%',width:'100%'}} visible={removeModal} transparent={true}>
          <RemovePhoneModal
          title="Delete Auto Response"
          message="Are you sure you'd like to permanently delete this auto response?"
            handleRemove={() => {
              handleDeleteAutoResponse(toDelete.id)
              setRemoveModal(false);
            }}
            setRemoveModal={setRemoveModal}
          />
        </Modal>
      }
      {loading && <Loader/>}
    </SafeAreaView>
  );
};
export default AutoResponse;

const styles = StyleSheet.create({
  notesStyles: {
    fontSize: 17,
    textAlign: 'center',
  },
});

const AutoResponseInfo = () => {
  return (<InfoBar>
    <Text style={{ textAlign: 'left', fontSize: 16, alignSelf: 'center', marginVertical: 10 }}>Auto Response Guide</Text>
    <Text style={{ marginTop: 10 }}>
      An auto response is a way to return an automatic reply to an incoming text message. Only the first auto response that meets the criteria of the incoming text will be used, make sure to order your auto responses to meet your requirements (Eg: if an unknown contact sends a keyword and you have an auto response for unknown contacts AND for the keyword, you need to specify which one should be used first by setting its order higher using the up and down buttons).
    </Text>
    <Text style={{ marginTop: 20 }}>
      You cannot create auto responses for built in keywords, including keywords that are used for adherance to the Telephone Consumer Protection Act (TCPA).
    </Text>
    <img style={{ marginTop: 20 }} src={require('../../Assets/Images/autoresponse_blocked_words.png')} />
  </InfoBar>)
}

