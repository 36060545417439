import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
  Dimensions,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'native-base';
import Alert from 'Alert';
import { Braintree, HostedField } from 'react-braintree-fields';

import CustomButton from '../../Components/Button';
import Header from '../../Components/Header';
import Loader from '../../Components/Loader';
import AppColor from '../../Constants/Color';
import getData from '../../AsyncUtils/GetData';
import RequestMaker from '../../Middleware/ApiCaller';
import End_Points from '../../Constants/Api/index';
import AppFonts from '../../Constants/Fonts';
import { setCompletedStep } from '../../Redux/singupwizard';
import './payment.css';

const Finished = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [creditCards, setCreditCards] = useState([]);
  const [paymentToken, setPaymentToken] = useState(
    'sandbox_g42y39zw_348pk9cgf3bgyw2b'
  );
  const [isBraintreeReady, setIsBraintreeReady] = useState(false);
  const [card, setCard] = useState('');
  const [tokenize, setTokenizeFunc] = useState();
  const [billingAmount, setBillingAmount] = useState(30);

  const { profile } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const Toast = useToast();
  
  useEffect(()=>{
    navigation.addListener('focus', async () => {
      getBillingAmount();
      setLoading(true);
      const body = {
        action: 'get_group_addons',
        affiliate: profile?.tenant?.affiliate
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      setLoading(false);
      if (result?.data?.success) {
        if(result?.data?.addons && result?.data?.addons?.length > 0){
          const centralizedAddon = result.data.addons.find(e => e.type == 1)
          if(centralizedAddon.centralized){
            dispatch(setCompletedStep({ payment: true }));
            navigation.navigate('Finished');
          }
        }
      }

    })
  },[navigation])  

  const getBillingAmount = async () => {
    try{
      const body = {
        action: 'get_billing_amount',
      };

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      setLoading(false);
      if (result?.data?.success) {
        setBillingAmount(result.data.amount);
      }
    } catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    getPaymentMethods();
    getPaymentToken();
  }, []);

  const handleNext = () => {
    console.log("PROFILE",profile)
    if(creditCards.length !== 0){
    dispatch(setCompletedStep({ payment: true }));
    navigation.navigate('Finished');
    } else {
      Toast.show({
        duration: 2000,
        title: 'Please add a valid credit card',
        status: 'error',
      });
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const { nonce } = await tokenize();
      let body = {
        action: 'create_payment_method',
        nonce: nonce,
        tenant: profile.tenant?.id,
        affiliate: profile.tenant?.affiliate,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Payment method saved.' + data.infoMessage,
          status: 'success',
        });
        console.log(data);
        getPaymentMethods();
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDefault = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'make_payment_method_default',
        token: selectedCard.token,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Payment method removed.',
          status: 'success',
        });
        getPaymentMethods();
      } else {
        Toast.show({
          duration: 2000,
          title: 'Unable to update payment method - contact customer support',
          status: 'error',
        });
      }
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'delete_payment_method',
        token: selectedCard.token,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Payment method removed.',
          status: 'success',
        });
        getPaymentMethods();
      } else {
        Toast.show({
          duration: 2000,
          title: 'Unable to update payment method - contact customer support',
          status: 'error',
        });
      }
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'get_payment_methods',
        tenant: profile?.tenant.id,
        affiliate: profile?.tenant.affiliate,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      setPaymentMethodList(data.paymentmethods);
      setCreditCards(data.creditcards);
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const getPaymentToken = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'get_payment_token',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let { data } = await RequestMaker(Config);
      if (data.success) {
        setPaymentToken(data.token);
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const onCardTypeChange = ({ cards }) => {
    setCard({ card: 1 === cards.length ? cards[0].type : '' });
  };

  const onError = (err) => {
    console.warn(err);
  };

  const onAuthorizationSuccess = () => {
    setIsBraintreeReady(true);
  };

  const onDataCollectorInstanceReady = (err, dataCollectorInstance) => {
    console.log('dataCollectorInstance======>', dataCollectorInstance);
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header title={'Payment Setup'} />
        <View style={styles.body}>
          <View style={styles.content}>
            <Text style={[styles.text, styles.setupPaneTitle]}>
              Payment Information
            </Text>
            <Text style={styles.text}>
              Your current monthly billing cost is ${billingAmount}.
            </Text>
            <View style={styles.item}>
              <Text style={styles.text}>
                Set up your method of payment for monthly recurring usage
                charges. Charges on your statement will come from ionlake. If
                your account is a trial account or in default, your credit card
                will be charged to make your account active.
              </Text>
              <CustomButton
                title="+ add credit card"
                width={250}
                style={{ marginVertical: 10 }}
                onPress={() => setVisible(true)}
              />
            </View>
            <View style={{ marginBottom: 15 }}>
              <View style={[styles.row, styles.paymentList]}>
                <Text style={styles.label}>Name</Text>
                <Text style={styles.label}>Number</Text>
                <Text style={styles.label}>Expiration</Text>
              </View>
              {creditCards.length > 0 ? (
                creditCards.map((card) => (
                  <TouchableOpacity onPress={() => setSelectedCard(card)}>
                    <View
                      style={[
                        styles.row,
                        {
                          backgroundColor:
                            selectedCard?.token === card.token
                              ? AppColor.PrimaryColor
                              : AppColor.SecondaryColor,
                          padding: 10,
                        },
                      ]}>
                      <Text style={styles.label}>{card.cardholderName}</Text>
                      <Text style={styles.label}>{card.number}</Text>
                      <Text style={styles.label}>{card.expiration}</Text>
                    </View>
                  </TouchableOpacity>
                ))
              ) : (
                <View
                  style={{
                    backgroundColor: AppColor.SecondaryColor,
                    padding: 15,
                  }}>
                  <Text style={styles.label}>No Payment Methods</Text>
                </View>
              )}
              {isVisible && (
                <Braintree
                  authorization={paymentToken}
                  onAuthorizationSuccess={onAuthorizationSuccess}
                  onDataCollectorInstanceReady={onDataCollectorInstanceReady}
                  onError={onError}
                  onCardTypeChange={onCardTypeChange}
                  getTokenRef={(ref) => setTokenizeFunc(() => ref)}
                  styles={{
                    input: {
                      'font-size': '16px',
                      color: '#3a3a3a',
                      border: '1px solid #000000 !important',
                      'border-radius': '4px',
                      height: '50px',
                    },
                    ':focus': {
                      color: 'black',
                    },
                  }}>
                  <View>
                    <Text style={[styles.text, styles.label]}>
                      Card Holder Name
                    </Text>
                    <HostedField
                      type="cardholderName"
                      className="cardholderName"
                    />
                    <Text style={[styles.text, styles.label]}>
                      Credit Card Number
                    </Text>
                    <HostedField type="number" className="number" />
                    <Text style={[styles.text, styles.label]}>
                      Expiration MM/YYYY
                    </Text>
                    <HostedField
                      type="expirationDate"
                      className="expirationDate"
                    />
                    <Text style={[styles.text, styles.label]}>CVV*</Text>
                    <HostedField type="cvv" className="cvv" />
                    <Text style={[styles.text, styles.label]}>
                      Zip/Postal Code
                    </Text>
                    <HostedField type="postalCode" className="postalCode" />
                  </View>
                  <View style={styles.row}>
                    <CustomButton
                      title="process/save"
                      onPress={handleSave}
                      width={250}
                      style={{ marginVertical: 10 }}
                    />
                    <CustomButton
                      title="cancel"
                      onPress={() => setVisible(false)}
                      width={250}
                      style={{ marginVertical: 10 }}
                    />
                    <CustomButton
                      title="remove"
                      onPress={handleRemove}
                      disabled={!selectedCard}
                      width={250}
                      style={{ marginVertical: 10 }}
                    />
                    <CustomButton
                      title="make Default"
                      onPress={handleDefault}
                      disabled={!selectedCard}
                      width={250}
                      style={{ marginVertical: 10 }}
                    />
                  </View>
                  {!isBraintreeReady && <Loader />}
                </Braintree>
              )}
            </View>

            <View style={styles.item}>
              <Text style={styles.text}>
                If you have any billing questions regarding billing, contact us
                at{' '}
                <TouchableOpacity
                  onPress={() => Linking.openURL('mailto:support@ionlake.com')}>
                  <Text style={{ color: AppColor.PrimaryColor }}>
                    support@ionlake.com
                  </Text>
                </TouchableOpacity>
                <Text>
                  {' '}
                  or 844-402-2401. Support issues are handled Monday-Friday from
                  8am to 5pm CST (excluding holidays). Note: Billing and payment
                  methods cannot be managed in the mobile app.
                </Text>
              </Text>
              <View style={[styles.row, { marginVertical: 15 }]}>
                <img
                  src={
                    require('../../Assets/Images/paymentbraintree.png')
                  }
                  alt='BrainTree by Paypal logo.'
                  style={{    width: '100%',
                  maxWidth: 160,
                  height: 50,
                  objectFit:'contain'
              }}
                />
                <img
                  src={
                    require('../../Assets/Images/credit-card-icons.png')
                  }
                  alt={'Visa, MasterCard, American Express, and Discorver cards accepted.'}
                  style={{width: '100%',
                  maxWidth: 160,
                  height: 50,
                  objectFit:'contain'
              }}
                />
              </View>
              <Text style={styles.text}>Payment History</Text>
              <View style={styles.item}>
                <View style={styles.row}>
                  <Text style={[styles.text, styles.tableLabel]}>ID</Text>
                  <Text style={[styles.text, styles.tableLabel]}>Type</Text>
                  <Text style={[styles.text, styles.tableLabel]}>Status</Text>
                  <Text style={[styles.text, styles.tableLabel]}>Amount</Text>
                  <Text style={[styles.text, styles.tableLabel]}>
                    Payment Method
                  </Text>
                  <Text style={[styles.text, styles.tableLabel]}>Created</Text>
                </View>
              </View>
            </View>
          </View>
          <CustomButton title="done" onPress={handleNext} width={250} />
        </View>
      </SafeAreaView>
      {loading && <Loader />}
    </>
  );
};

export default Finished;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: Dimensions.get('screen').height,
    overflow: 'scroll',
    backgroundColor: '#d1dce6',
  },
  body: {
    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 992,
    padding: 30,
  },
  text: {
    color: AppColor.Black200,
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
    marginVertical: 5,
  },
  setupPaneTitle: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 24,
  },
  image: {
    width: '100%',
    maxWidth: 160,
    height: 50,
    objectFit:'contain'
  },
  tableLabel: {
    fontSize: 14,
  },
  label: {
    color: AppColor.BlackColor,
    fontSize: 16,
  },
  paymentList: {
    marginVertical: 15,
  },
  item: {
    borderRadius: 5,
    borderWidth: 1,
    padding: 15,
    backgroundColor: AppColor.SecondaryColor,
    marginBottom: 30,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});
