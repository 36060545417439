import React from 'react';
import Alert from 'Alert';
import { Text, TouchableOpacity, View, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import TimeZone from 'react-native-timezone';
import { useToast } from 'native-base';
import { useSelector } from 'react-redux';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import TermOfUse from './TermOfUse';
import ContactUS from './ContactUs';
import Accessibility from './Accessibility';
import TrainingVideo from './TrainingVideos';
import { Bubbles } from '../Authentication/Login/Bubbles';
import { Icon } from 'react-native-elements';
import Notifications from './Notifications';
import SystemStatus from './SystemStatus';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';
let ICustomTimezone = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana, Pacific Time',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};

const Calculators = ({ navigation }) => {
  const [timezone, setTimeZone] = React.useState(null);
  const getTimeZone = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  };
  const Toast = useToast();
  React.useEffect(() => {
    getTimeZone();
  }, []);
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;
  const [view, setView] = React.useState();
  const {isWebCompact} = useResponsiveLayout();

  const data = [
    {
      name: 'Support Articles',
      iconName:'bookmark',
      iconType:'feather',
      isOutsideLink: true,
      onPress: () =>
        window.open('https://ionlake.zendesk.com/hc/en-us', '_blank').focus(),
    },
    {
      name: 'Training Videos',
      iconName:'video',
      iconType:'feather',
      onPress: () => {
        setView('Training Videos');
      },
    },
    {
      name: 'MyRepChat Minute',
      iconName:'stopwatch',
      iconType:'fontisto',
      isOutsideLink: true,
      onPress: () =>
        window
          .open('https://www.youtube.com/c/MyRepChatMinute/videos', '_blank')
          .focus(),
    },
    {
      name: 'System Status',
      iconName:'server',
      iconType:'feather',
      onPress: () => {setView('System Status')},
    },
    {
      name: 'Notifications',
      iconName:'bell',
      iconType:'feather',
      onPress: () => {
        setView('Notifications');
      }
    },
    {
      name: 'Terms of Use',
      iconName:'file-text',
      iconType:'feather',
      onPress: () => {
        setView('Terms of Use');
      },
    },
    {
      name: 'Contact Us',
      iconName:'contact-support',
      iconType:'MaterialIcons',
      onPress: () => {
        setView('Contact Us');
      },
    },
  ];
  const renderListView = ( item ) => {
    const focused = item.name === view;
    const [hovered, setIsHovered] = React.useState(false);

    return (
      <TouchableOpacity
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}

        style={{
          backgroundColor: focused || hovered ? '#E1F1FF' : '#F9F9F9',
          borderColor: focused || hovered ? '#20508A' : '#DEDCD9',
          shadowColor: focused || hovered ? "#B7B5B2" : "transparent",
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 175,
          borderWidth: 2,
          paddingVertical: 30,
          borderRadius:10,
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 1,
          shadowRadius: 3,
        }}

        onPress={item.onPress}
        >
          { !!item.isOutsideLink &&       
           <View
          style={{
            position: 'absolute',
            top: 5,
            right: 5,
          }}>

         { item.isOutsideLink && <Icon name={'external-link'} type='feather' size={18} color={focused ? '#20508A' : 'black'}/>}
          </View>
          }
        <Icon name={item.iconName} type={item.iconType} size={26} style={{marginBottom:10}} color={focused ? '#20508A' : 'black'}/>
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            textAlign:'center',
            color: focused ? '#20508A' : 'black'
          }}>
          {item.name}
        </Text>

      </TouchableOpacity>
    );
  };
  

  const KEY_VIEW = {
    'Terms of Use' : <TermOfUse />,
    'Contact Us' : <ContactUS />,
    'Training Videos' : <TrainingVideo />,
    'Notifications' : <Notifications />,
    'System Status': <SystemStatus />
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      <Wrapper>
        { (!isWebCompact || !view) &&
          <SideBar>
      <View style={{ flex: 1, padding: 15 }}>

      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 15,}}>
          {
            data.map(e => {
              if(e.name === 'Support Articles' && affiliate?.show_articals === false) return null;
              if(e.name === 'Training Videos' && affiliate?.show_videos === false) return null;
              if(e.name === 'MyRepChat Minute' && affiliate?.show_myrepchatMinute === false) return null;
              if(e.name === 'System Status' && affiliate?.show_systemstatus === false) return null;

              if(e.name === 'Notification' && affiliate?.show_notificationTests === false) return null;
              if(e.name === 'Terms of use' && affiliate?.show_tos === false) return null;
              if(e.name === 'Contact Us' && affiliate?.show_contactUs === false) return null;
              return(
                renderListView(e)
              )
            })
          }
        </View>
      </View>
      </SideBar>}
      { 
       ( !isWebCompact || view ) &&
        <Content>
          {
            KEY_VIEW[view] ? KEY_VIEW[view] : <Bubbles/>
          }
      </Content>
      }
      </Wrapper>
    </SafeAreaView>
  );
};
export default Calculators;


