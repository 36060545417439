import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import ComponentAccordion from "../../Components/ComponentAccordion";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import SimpleDropdown from "../../Components/SimpleDropdown";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";
import { useSelector } from "react-redux";
import SimpleCheckbox from "../../Components/SimpleCheckbox";
import useUpdateAffiliate from "../useUpdateAffiliate";

const Billing = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const {data, isLoading, isError, refetch} = getQuery({
        affiliate: affiliate.id,
        action: "get_billing_addons"
    })

    const {data:rebate, isLoading:rebateLoading, isError:rebateError, refetch:rebateRefetch} = getQuery({
        affiliateId: affiliate.id,
        action: "get_billing_rebate"
    })

    const {data:billingAmount, isLoading:billingAmountLoading, isError:billingAmountError, refetch:billingAmountRefetch} = getQuery({
        affiliateId: affiliate.id,
        action: "get_billing_amount"
    })

    const {data: resellers, isLoading: resellersLoading, isError: resellersError, refetch: resellersRefetch} = getQuery({
        action: 'get_resellers'
    })

    const Toast = useToast();

    const [rebateData, setRebateData] = React.useState({
        email: rebate?.rebate?.email || '',
        type: rebate?.rebate?.type || 'percentage',
        value: rebate?.rebate?.value || 0
    })

    React.useEffect(()=>{
        if(rebate?.rebate){
            setRebateData({
                email: rebate?.rebate?.email,
                type: rebate?.rebate?.type,
                value: rebate?.rebate?.value
            })
        }
    },[rebate])

    const [adminSettings, setAdminSettings] = React.useState({
        reseller: affiliate?.reseller || '0',
        tenantLimit: affiliate?.tenantLimit || 0,
        invoice: affiliate?.invoice || false,
        invoiceEmail: affiliate?.invoiceEmail || '',
        invoiceAmount: affiliate?.invoiceAmount || 0
    })


    if(isLoading || rebateLoading || resellersLoading) return <Loader backgroundColor={'transparent'}/>
    if(!data || !rebateData || !resellers) return null;


   
    const listItem = item => {
        const index = data.types.find(e => e.key === item.key);
        const label = index.label;
        return [label, `$${item.amount}`, item?.host || '']
    }

    const RenderAddon = ({item}) => {

        const [addonData, setAddonData] = React.useState({
            key: item?.key || 'account_active',
            amount: item?.amount || 0
        })
    
        const addonTypes = data.types.map(item => ({
            label: item.label,
            value: item.key
        }));

        const handleDeleteAddon = async () => {
            try{
            const body = {
                id: item.id,
                action: "delete_billing_addon"
            }
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch()
            } else if(result.data.errorMessage){
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
            } catch(e){
                Toast.show({
                    title: 'An error occurred',
                    status: 'error'
                })
            }
        }

        const handleSaveAddon = async () => {
            try{
            const body = {
                key: addonData.key,
                affiliate: affiliate.id,
                amount:Number(addonData.amount),
                action: "create_billing_addon"
            }
            if(addonData.key.includes('account')){
                body.type = 'account';
            } else {
                body.type = addonData.key
            }

            if(item?.id){
                body.id = item.id;
                body.action = "update_billing_addon";
            }
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch()
                Toast.show({
                    title: 'Addon saved successfully',
                    status: 'success'
                })
            } else if(result.data.errorMessage){
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
            } catch(e){
                Toast.show({
                    title: 'An error occurred',
                    status: 'error'
                })
            }
        }

        return(
            <>
                {
                !item?.id &&
                <>
                <Text style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:10}}>
                    Add-On Type
                </Text>
                <SimpleDropdown 
                data={addonTypes}
                value={addonData.key}
                onChange={value => setAddonData({...addonData, key: value})}
                />
                </>
                }
                <CustomInput
                title='Amount'
                titleStyle={{marginTop:10}}
                value={addonData.amount}
                onChangeText={value => setAddonData({...addonData, amount: value})}
                />

                <View style={{marginTop:10,gap:10,flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                    {item?.id && <CustomButton onPress={handleDeleteAddon} outline width={'20%'} title='Delete' />}
                    <CustomButton onPress={handleSaveAddon} width={'20%'} title='Save' />
                </View>
                
            </>
        )
    }

    const handleDeleteRebate = async () => {
        try{
        const body = {
            action: 'delete_billing_rebate',
            affiliateId: affiliate.id
        }
        const result = await RequestMaker(body);

        if(result.data.success){
            setRebateData({
                email: '',
                type: 'percentage',
                value: 0
            })
            Toast.show({
                title: 'Rebate deleted successfully',
                status: 'success'
            })
        } else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: 'error'
            })
        }
    } catch(e){
        Toast.show({
            title: 'An error occurred',
            status: 'error'
        })
    }
    }

    const handleSaveRebate = async () => {
        try{
        const body = {
            action: 'save_billing_rebate',
            affiliateId: affiliate.id,
            email: rebateData.email,
            type: rebateData.type,
            value: rebateData.value
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                title: 'Rebate saved successfully',
                status: 'success'
            })
        } else if(result.data.errorMessage){
            Toast.show({
                title: result.data.errorMessage,
                status: 'error'
            })
        }
        } catch(e){
            Toast.show({
                title: 'An error occurred',
                status: 'error'
            })
        }
    }

    const resellerOptions = [
        {
            label: 'None',
            value: '0'
        },
        ...resellers.resellers.map(item => ({
        label: item.name,
        value: item.id
    }))];

    return(
        <ScrollView style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>
                Add-On Pricing
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginVertical:10}}>
                Manage the pricing for add-ons for this group. By default, accounts have access to various features (add-ons) and if they use them they can pay for them using the default pricing. Here you can set up group pricing for the add-ons which will override the default pricing.
            </Text>
            <ComponentAccordion 
            allowAdd
            data={data.addons} 
            renderListItem={listItem} 
            renderComponent={(item) => <RenderAddon item={item} /> } 
            columns={['Type', 'Amount', 'Host']}
            />

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20}}>
                Billing Rebates
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                Manage any rebate for this group. Rebates are not sent automatically - Email is for reference only. Use the report on the dashboard to get amounts.
            </Text>
            <SimpleCheckbox 
            disabled
            value={rebate?.rebate}
            label={'Rebate Partner'}
            />
            <CustomInput 
            value={rebateData.email}
            onChangeText={value => setRebateData({...rebateData, email: value})}
            title={'Rebate Payor Email'}
            />
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                Rebate Type
            </Text>
            <SimpleDropdown
            data={
                [{
                label: 'Percentage of total',
                value: 'percentage'
                },
                {
                label: '# of Accounts',
                value: 'account'
                }]
            }
            value={rebateData.type}
            onChange={value => setRebateData({...rebateData, type: value})}
            />
            <CustomInput
            value={rebateData.value}
            onChangeText={value => setRebateData({...rebateData, value: value})}
            titleStyle={{marginTop:10}}
            title={'Rebate Value/Amount'}
            />
            <View style={{justifyContent:'flex-end',flexDirection:'row',alignItems:'center',marginTop:10,gap:10}}>
                <CustomButton
                title={'Delete'}
                onPress={handleDeleteRebate}
                outline
                width={'20%'}
                />
                <CustomButton
                onPress={handleSaveRebate}
                title={'Save'}
                width={'20%'}
                />

            </View>

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20}}>
                Billing Amount
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                The calculated monthly billing amount for the group (includes all account and add-ons that the group is set up to pay for)
            </Text>

            <Text style={{fontSize:20,fontFamily:AppFonts.Roboto_Regular,marginTop:10,alignSelf:'center'}}>
                {`$${billingAmount?.amount}`}
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:20}}>
                Administrator Settings
            </Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                These are settings that only administrators can see.
            </Text>

            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:20}}>
                Reseller/Organization
            </Text>

            <SimpleDropdown
            data={resellerOptions}
            value={adminSettings.reseller}
            onChange={value => setAdminSettings({...adminSettings, reseller: value})}
            />

            <CustomInput
            value={adminSettings.tenantLimit}
            titleStyle={{marginTop:10}}
            onChangeText={value => setAdminSettings({...adminSettings, tenantLimit: value})}
            title={'Account Limit'}
            />
            <SimpleCheckbox
            value={adminSettings.invoice}
            onChange={value => setAdminSettings({...adminSettings, invoice: value})}
            label={'Invoice'}
            />
            <CustomInput
            value={adminSettings.invoiceEmail}
            onChangeText={value => setAdminSettings({...adminSettings, invoiceEmail: value})}
            title={'Invoice Email'}
            />
            <CustomInput
            value={adminSettings.invoiceAmount}
            onChangeText={value => setAdminSettings({...adminSettings, invoiceAmount: value})}
            titleStyle={{marginTop:10}}
            title={'Invoice Amount'}
            />

            <CustomButton 
            onPress={() => updateAffiliate(adminSettings)}
            style={{alignSelf:'flex-end',marginTop:10}}
            width={'20%'}
            title={'Save'}
            />


                

        </ScrollView>
    )
}

export default Billing;