import React from 'react';
import {View, Text, ScrollView} from 'react-native'
import Modal from 'modal-react-native-web';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import { useSelector } from 'react-redux';
import { Icon } from 'react-native-elements';
import CustomButton from '../../../Components/Button';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api';
import Loader from '../../../Components/Loader';

const APIKeysModal = props => {
    const tenant = useSelector((state) => state.User?.profile)?.tenant;
    const [apiKey, setApiKey] = React.useState('');
    const [secKey, setSecKey] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const createAPIKey = async () => {
        setLoading(true);
        try {

            const body = {
                action: 'create_api_keys',
                permissions: [13],
                tenant: props.tenant ? props.tenant : tenant?.id,
            };
      
            
            let device = await getData('@device_id');
            const headers = { 'x-device': device };
            let Config = {
              method: 'post',
              url: End_Points.Base_url + End_Points.End,
              data: body,
              headers: headers,
              redirect: 'follow',
            };
            let result = await RequestMaker(Config);
            if (result.data.success) {
              setApiKey(result.data.apikey)
              setSecKey(result.data.seckey)
            }
            setLoading(false);

          } catch (err) {
            console.log(err);
          }

    }

    const finish = () => {
        if(secKey && apiKey) props.fetch();

        props.setVisible(false);
        
    }

    return(
        <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        appElement={document.getElementById('app')} 
        transparent={true}
        visible={props.visible}
        onDismiss={() => {
          props.setVisible(false);
        }}>
        <View
        style={{position:'absolute',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center'}}>
            <View style={{
            backgroundColor:'white',
            position:'relative',
            width: '50%',
            height:'70%',
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            pointerEvents:'box',
            elevation: 5,
        
            }}>
                <View style={{backgroundColor:AppColor.PrimaryColor, width:'100%',padding:15,alignItems:'center',flexDirection:'row',justifyContent:'space-between'}}>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular,color:'white',fontSize:16,fontWeigth:'900'}}>API Keys</Text>
                    <Icon
                    type='Ionicons'
                    name='close'
                    color='white'
                    onPress={() => finish()}
                    />
                </View>

                <Text style={{padding:15,fontFamily:AppFonts.Roboto_Regular}}>API Keys consist of 2 parts, a public key and a secret key. See our developer documentation to learn how to use API keys with the ionlake platform API.</Text>
                <Text style={{padding:15,fontFamily:AppFonts.Roboto_Regular,color:AppColor.danger,fontWeight:'800'}}>
                IMPORTANT! {'\n\n'}The secret key will only be revealed to you when you click 'GENERATE' for the first time. After that you will not be able to retrieve it. Make sure you save it in a safe place.
                </Text>

                <Text style={{fontFamily:AppFonts.Roboto_Bold, color:AppColor.PrimaryColor,padding:15}}>
                    PUBLIC KEY
                </Text>
                <Text
                style={{padding:15}}
                >
                  {
                    apiKey ? apiKey : 'Click \'Generate\''
                  }
                </Text>

                <Text style={{fontFamily:AppFonts.Roboto_Bold, color: AppColor.PrimaryColor,padding:15,}}>
                    SECRET KEY
                </Text>
                <Text
                style={{padding:15,paddingBottom:0}}
                >
                {
                    secKey ? secKey : 'Click \'Generate\''
                  }
                </Text>

                <View style={{flexDirection:'row',alignItems:'center',flex:1,width:'100%',justifyContent:'space-evenly'}}>
                    <CustomButton disabled={secKey.length && apiKey.length}  width='40%' title="Generate" onPress={() => createAPIKey()} />
                    <CustomButton width='40%' title="Finish" style={{opacity:secKey === '' ? .7 : 1}} disabled={secKey === ''}
                    onPress={() => finish()}
                    />
                </View>

            </View>
            {loading && <Loader/>}
        </View>
        </Modal>
    )
}

export default APIKeysModal;