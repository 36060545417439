import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, TouchableOpacity, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import { Icon } from 'react-native-elements';
import Alert from 'Alert';

import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { loadRecentMessagesThunk } from '../../Redux/recent_message';
import { loadAllContactsThunk } from '../../Redux/contacts';
import { loadRecentMediaThunk } from '../../Redux/recent_media';
import { loadSavedMessagesThunk } from '../../Redux/saved_messages';
import { loadAccountStatThunk } from '../../Redux/account_stat';
import { loadAccountHealthThunk } from '../../Redux/account_health';
import {
  loadIntegrationProviderThuunk,
  loadIntegrationsThuunk,
} from '../../Redux/integrations';
import {
  loadGroupAddOnsThuunk,
  loadBillingDetailsThuunk,
} from '../../Redux/add_ons';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import { formatPhoneNumber } from '../../Middleware/helpers';
import { Content, SideBar, Wrapper } from '../../Components/Layout';
import { TitleCard } from './TitleCard';
import useResponsiveLayout from '../../Middleware/useResponsiveLayout';
//import OrphanConversationModal from './OrphanConversationModal';

const moment = require('moment');

const Dashboard = ({ navigation }) => {
  const dispatch = useDispatch();
  const todayDate = Date.now();
  const sevenDayBefore = todayDate - 7 * 24 * 60 * 60 * 1000;
  const account_stat = useSelector((state) => state.account_stat);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const account_health = useSelector((state) => state.account_health);
  const contactData = useSelector((state) => state.contacts).data;
  const recent_messages = useSelector((state) => state.recent_messages);
  const {isWebCompact} = useResponsiveLayout();
  const [hoveredId, setIsHoveredId] = useState(false);

  const dup_contacts = useSelector(
    (state) => state.duplicate_members
  ).messagingPhone;

  const [msgData, setMsgData] = useState({
    sent: 0,
    receive: 0,
  });

  // loading recent messages
  useFocusEffect(
    useCallback(() => {
      dispatch(loadAccountStatThunk());
      dispatch(loadRecentMessagesThunk());
      dispatch(loadAllContactsThunk());
      dispatch(loadRecentMediaThunk());
      dispatch(loadSavedMessagesThunk());
      dispatch(loadIntegrationProviderThuunk());
      dispatch(loadIntegrationsThuunk());
      dispatch(loadGroupAddOnsThuunk());
      dispatch(loadBillingDetailsThuunk());
      dispatch(loadAllDistributionsThunk());
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      if (tenant?.id) {
        dispatch(loadAccountHealthThunk(tenant?.id));
      }
    }, [tenant])
  );

  useEffect(() => {
    if (account_stat.stats) {
      let x = 0;
      let y = 0;
      for (let i of account_stat.stats) {
        switch (i.type) {
          case "comm.sms.inbound":
            x += i.val;
            break;
          case "comm.sms.outbound":
            y += i.val;
            break;
        }
      }
      setMsgData({ sent: y, receive: x });
    }
  }, [account_stat]);

  const countNoMessagingNumbers = () => {
    let count = 0;

    contactData.forEach(e => {
      let hasNoMessagingNumber = false;

      e.phoneNumbers.forEach(ee => {
        if (ee.messaging) hasNoMessagingNumber = true;
      })

      if (!hasNoMessagingNumber) count += 1
    })

    return count;

  }

  const count = recent_messages.data.reduce((sum, contact) => {
    return sum + contact.unreadmessages;
  }, 0)

  const data = [
    {
      _id: '3',
      name: 'Unread Messages',
      iconname: 'envelope-open-o',
      icontype: 'font-awesome',
      Count: count,
    },
    {
      _id: '4',
      name: 'Templates',
      iconname: 'clipboard',
      icontype: 'feather',
      Count: account_stat.templateCount,
    },
    {
      _id: '1',
      name: 'Auto Response',
      iconname: 'reply-all-outline',
      icontype: 'material-community',
      Count: account_stat.workflowCount,
    },
    {
      _id: '7',
      name: 'Orphan Conversations',
      iconname: 'message-outline',
      icontype: 'material-community',
      Count: account_health.orphans.length,
      data: account_health.orphans,
    },

    {
      _id: '9',
      name: 'CRM Connections',
      iconname: 'disconnect',
      icontype: 'antdesign',
      Count: account_health.crms.filter((x) => x.enabled).length,
    },

    {
      _id: '6',
      name: 'Archive connections',
      iconname: 'inbox',
      icontype: 'feather',
      Count: account_health.archive.length,
    },

    {
      _id: '8',
      name: 'Contacts',
      iconname: 'contacts',
      icontype: 'antdesign',
      Count: account_stat.memberCount,
    },
    {
      _id: '2',
      name: 'Duplicate Contacts',
      iconname: 'people-outline',
      icontype: 'ionicons',
      Count: Object.entries(dup_contacts).length,
    },
    {
      _id: '10',
      name: 'No Messaging Number',
      iconname: 'cellphone-message-off',
      icontype: 'material-community',
      Count: countNoMessagingNumbers(),
    },
    {
      _id: '5',
      name: 'No Phone Number',
      iconname: 'phone-off',
      icontype: 'feather',
      Count: contactData.filter((x) => x.phoneNumbers.length == 0).length,
    },
  ];


  const getTenantStatus = () => {
    switch (tenant?.status) {
      case 1:
        return 'Active'
      case 90:
        return 'Active'
      case 3:
        return "Review"
      case 4:
        return "Trial"
      case 5:
        return "Past Due"
    }
  }

  const renderDashboardItems = (item) => {
    const hovered = hoveredId === item._id;
    return (
      <TouchableOpacity
      onMouseEnter={() => setIsHoveredId(item._id)}
      onMouseLeave={() => setIsHoveredId(item._id)}
        accessibilityRole='button'
        activeOpacity={0.6}
        onPress={() => {
          // if(item.Count>0){
          switch (item._id) {
            case '1':
              navigation.navigate('AutoResponse');
              break;
            case '2':
              navigation.navigate('Contacts', {type: 'Duplicate'});
              break;
            case '3':
              navigation.navigate('Messages', { unread: true });
              break;
            case '4':
              navigation.navigate('Templates');
              break;
            case '5':
              navigation.navigate('AllContacts', { no_phone: true });
              break;
            case '6':
              navigation.navigate('ConnectArchiver');
              break;
            case '10':
              navigation.navigate('Contacts', { filter: true });
              break;
            case '8':
              navigation.navigate('Contacts');
              break;
            case '7':
              // setOrphanData(item);
              // setOrphanModalVisible(true);
              item.Count > 0 ?
                Alert.alert(
                  'Message',
                  `You have messages that are not associated with ${item.Count
                  } phone numbers.\nThe phone numbers are \n${item.data.join(
                    '\n'
                  )} \nAdd these numbers to existing contacts or create new contacts to see these messages.`,
                  [
                    {
                      text: 'ok',
                      onPress: () => { return; },
                    },
                  ]
                )
                :
                Alert.alert('Message', 'You have no orphan messages');
              break;
            case '9':
              navigation.navigate('Crm', { from: 'settings' });
              break;
            default:
              navigation.navigate('dashboard');
          }
          // }
        }}
        key={item._id}
        style={{
          backgroundColor: hovered ? '#E1F1FF' : '#F9F9F9',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 175,
          borderWidth: 2,
          borderColor: hovered ? '#20508A' : '#DEDCD9',
          paddingVertical: 30,
          borderRadius:10,
          shadowColor: hovered ? "#B7B5B2" : "transparent",
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowOpacity: 1,
          shadowRadius: 3,

        }}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 0,
          }}>
          <Icon
            size={32}
            color={ hovered ? '#20508A' : AppColor.PrimaryColor}
            name={item.iconname}
            type={item.icontype}
          />
          <Text
            style={{
              textAlign: 'center',
              marginTop: 20,
              color: AppColor.PrimaryColor,
              fontFamily: AppFonts.Roboto_Regular,
              textDecorationLine: hovered ? 'underline' : 'none'
            }}>
            {item.name}
          </Text>
        </View>
{ !!item.Count &&        <View
          style={{
            position: 'absolute',
            top: 5,
            backgroundColor:'#FEC781',
            borderRadius:100,
            right: 5,
          }}>
          <Text
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: 30,
              color: AppColor.PrimaryColor,
              textAlign: 'center',
            }}>
            {item.Count}
          </Text>
        </View>
}      </TouchableOpacity>
    );
  };


  return (
    <>
      <Wrapper>
        <SideBar>
          <View style={{ flex: 1, paddingVertical: 25, paddingHorizontal: 15 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, fontWeight: 500, fontSize: 18, marginBottom: 20 }}>Profile Details</Text>
            <TitleCard
              type='row'
              title='Account Status'
              style={{ borderBottomColor: 'rgba(0,0,0,.25)', borderBottomStyle: 'solid', borderBottomRightRadius: 0, borderBottomLeftRadius: 0, borderTopLeftRadius: 7, borderTopRightRadius: 7 }}
              subtitle={getTenantStatus().toUpperCase()}
            />

            <TitleCard type='row'
              title='MyRepChat Number'
              style={{ borderTop: 'none', borderBottom: 'none', borderRadius: 0 }}
              subtitle={formatPhoneNumber(account_health.mrcNumber.value.replace('+1', ''))}
            />
            <TitleCard 
            type='row'
            title='Voice / Forwarding Number' 
            style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }} 
            subtitle={formatPhoneNumber(account_health.voice.value)} 
            />

            {tenant?.provider &&
              <TitleCard 
              type='row'
              style={{ marginTop: 20 }} 
              title='Hosted On' 
              subtitle={tenant.provider} />
            }

            <Text style={{ marginTop: 50,fontFamily: AppFonts.Roboto_Regular, fontWeight: 500, fontSize: 18 }}>Message Activity</Text>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,fontWeight:100,marginTop:10,marginBottom:20}}>({moment(sevenDayBefore).format('MMM D YYYY')} -{' '}
              {moment(todayDate).format('MMM D YYYY')})</Text>

            <View style={{ flexDirection: 'row', gap: 20 }}>
              <TitleCard type='block' subtitle={msgData.receive} title='Received' />
              <TitleCard type='block' subtitle={msgData.sent} title='Sent' />

            </View>

          </View>
        </SideBar>

        { !isWebCompact &&       
        <Content>
            <Text style={{ fontSize: 18, marginBottom: 20, fontFamily: AppFonts.Roboto_Regular, fontWeight: 400 }} >Quick Links</Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 15,}}>
              {data.map((i) => {
                return renderDashboardItems(i);
              })}
            </View>

        </Content>
        }      
      </Wrapper>
    </>
  );
};

export default Dashboard;



