import React from "react";
import { ActivityIndicator, View } from "react-native";
import { Icon } from "react-native-elements";
import { useSelector } from "react-redux";
import AppColor from "../Constants/Color";
import useResponsiveLayout from "../Middleware/useResponsiveLayout";

const FeedbackWidget = ({ visible, onClose }) => {
    const [loading, setLoading] = React.useState(true);
    const user = useSelector((state) => state.User.profile)?.user;

    if(!visible) return null;

    return (
        <View onClick={onClose} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 99, justifyContent: 'center', alignItems: 'center' }}>
            <View onClick={e => e.preventDefault()} style={{ overflow: 'hidden',backgroundColor: 'white', padding: 25, borderRadius: 5, boxShadow: '0px 0px 9px 0px rgba(0,0,0,0.75)' }} >
                <Icon onPress={onClose} name='close' size={32} type='ionicons' style={{ alignSelf: 'flex-end' }} />
                <iframe onLoad={() => setLoading(false)} src={`https://widget.elfsig.ht/d217106f-379c-4378-a4dd-3c5031d9bf4f?Email=${user.email}`} width='400' height='500' frameborder='0'></iframe>
                {loading && <View style={{position:'absolute',flex:1,width:'100%',height:'100%',justifyContent:'center',alignItems:'center'}}>
                    <ActivityIndicator size='large' color={AppColor.PrimaryColor} />
                    </View>
                    }
            </View>
        </View>
    )

}

export default FeedbackWidget;