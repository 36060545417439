import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Linking,
  StyleSheet,
} from 'react-native';
import { widthPercentageToDP } from 'react-native-responsive-screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon } from 'react-native-elements';

import CustomButton from '../../../Components/Button';
import Header from '../../../Components/Header';
import AppColor from '../../../Constants/Color';
import useResponsiveLayout from '../../../Middleware/useResponsiveLayout';

const SetupIntro = ({ openLink, handleChooseNum, handleTextEnable }) => {
  const { isWebCompact } = useResponsiveLayout();

  return (
    <>
      <View>
        <Text style={styles.setupInstructions}>
          Your MyRepChat phone number is the number your clients will use to
          exchange text messages with you. Choose to create a new number OR to
          text-enable your Landline. Once your MyRepChat number is set up, it
          cannot be changed.
        </Text>
      </View>
      <View style={[styles.panel, {flexDirection: isWebCompact? 'column' : 'row'}]}>
        <View style={styles.setupPanel}>
          <Text style={styles.setupPaneTitle}>Choose a New Number</Text>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>Find a number in your area code</Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Incoming calls are auto-forwarded to your cell
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Number can be used for making outbound calls*
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Call recording and transcription possible*
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Number ready to use when setup is complete
            </Text>
          </View>
          <Text style={styles.optional}>(*with optional add-on)</Text>
          <CustomButton
            onPress={() => openLink('https://vimeo.com/386996001/2d625740fd')}
            title="More Info"
            style={styles.moreBtn}
            width={180}
            icon={
              <Icon
                name={'videocam'}
                type={'material'}
                color={AppColor.SecondaryColor}
                size={20}
              />
            }
            textStyle={{ marginLeft: 10 }}
          />

          <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            onPress={() => openLink('https://youtu.be/HBSE4mRxvzo')}>
              <Text style={styles.btnText}>
                Selecting the right Phone Number for MyRepChat
              </Text>
          </TouchableOpacity>

          <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            onPress={() => openLink('https://youtu.be/5oe5AmQ_5ws')}>
            <View>
              <Text style={styles.btnText}>
                Getting Your Clients to text your new Number
              </Text>
            </View>
          </TouchableOpacity>

          <CustomButton
            onPress={handleChooseNum}
            title="Get Started"
            style={styles.getStartedBtn}
            textStyle={styles.getStartedBtnText}
            width={200}
          />
        </View>
        <View style={styles.setupPanel}>
          <Text style={styles.setupPaneTitle}>Text Enable Your Landline</Text>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              No need for the client to add or update a new phone number
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Keep your voice with your current provider/carrier
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Use your personal cell for phone calls when away from the office
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Utilize all MyRepChat messaging features
            </Text>
          </View>
          <View style={styles.row}>
            <Icon
              name={'fiber-manual-record'}
              type={'material'}
              color={AppColor.Black200}
              size={8}
            />
            <Text style={styles.comment}>
              Number can be ready to use in a few hours*
            </Text>
          </View>
          <Text style={styles.optional}>(*approx - not guaranteed)</Text>
          <CustomButton
            onPress={() => openLink('https://vimeo.com/386996370/540a00cb4e')}
            title="More Info"
            style={styles.moreBtn}
            width={180}
            icon={
              <Icon
                name={'videocam'}
                type={'material'}
                color={AppColor.SecondaryColor}
                size={20}
              />
            }
            textStyle={{ marginLeft: 10 }}
          />

          <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            onPress={() => openLink('https://youtu.be/XDBmRO-ByI8')}>
            <View>
              <Text style={styles.btnText}>
                Why Can't I Use My Cell Phone Number
              </Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            accessible={true}
            accessibilityRole='button'
            onPress={() => openLink('https://youtu.be/GpgDrMHRmVU')}>
            <View>
              <Text style={styles.btnText}>What Is Hosted SMS</Text>
            </View>
          </TouchableOpacity>

          <CustomButton
            onPress={handleTextEnable}
            title="Get Started"
            style={styles.getStartedBtn}
            textStyle={styles.getStartedBtnText}
            width={200}
          />
        </View>
      </View>
    </>
  );
};

export default SetupIntro;

const styles = StyleSheet.create({
  setupInstructions: {
    fontSize: 18,
    textAlign: 'center',
    color: AppColor.Black200,
    marginTop: 15,
  },
  setupPanel: {
    borderColor: AppColor.Black200,
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: AppColor.WHITE200,
    padding: 15,
    flex:1,
    gap:10
  },
  setupPaneTitle: {
    fontSize: 22,
    borderBottomWidth: 1,
    borderColor: AppColor.BlackColor,
    marginBottom: 30,
    color: AppColor.Black200,
    paddingLeft: 15,
  },
  panel: {
    padding: 15,
    gap:10,
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 10,
  },
  comment: {
    fontSize: 16,
    color: AppColor.Black200,
    marginLeft: 10,
  },
  optional: {
    fontSize: 10,
    color: AppColor.Black200,
    marginBottom: 5,
  },
  moreBtn: {
    backgroundColor: AppColor.BLUE200,
    width: 140,
  },
  btnText: {
    fontSize: 16,
    color: AppColor.BLUE100,
    marginVertical: 10,
    textAlign:'center'
  },
  getStartedBtn: {
    backgroundColor: AppColor.GREEN200,
    width: 200,
  },
  getStartedBtnText: {
    color: AppColor.BlackColor,
  },
  dot: {
    width: 2,
    height: 2,
    borderRadius: 1,
    backgroundColor: AppColor.Black200,
  },
});
