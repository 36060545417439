import { createSlice } from '@reduxjs/toolkit';

const accountInactivity = createSlice({
  name: 'account_active',
  initialState: {
    inactivity: false,
    timeOut: false,
    countTimer: true,
  },
  reducers: {
    setInactivity: (state, action) => {
      return { ...state, inactivity: action.payload };
    },
    setTimeOut: (state, action) => {
      if (state.countTimer) {
        return { ...state, timeOut: action.payload };
      } else {
        return { ...state, timeOut: true };
      }
    },
    setCountTimer: (state, action) => {
      return { ...state, countTimer: action.payload };
    },
  },
});

export const { setInactivity, setTimeOut, setCountTimer } =
  accountInactivity.actions;

export const setAccountInactivityThunk = (inactivity) => {
  return (dispatch) => {
    dispatch(setInactivity(inactivity));
  };
};

export const setTimeOutThunk = (timeOut) => {
  return (dispatch) => {
    dispatch(setTimeOut(timeOut));
  };
};

export const setCountTimerThunk = (countTimer) => {
  return (dispatch) => {
    dispatch(setCountTimer(countTimer));
  };
};

export default accountInactivity.reducer;
