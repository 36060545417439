import React from "react";
import { Modal, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import ComponentAccordion from "../../Components/ComponentAccordion";
import getQuery from "../getQuery";
import Loader from "../../Components/Loader/index.web";
import moment from "moment";
import CustomButton from "../../Components/Button/index.web";
import { RemovePhoneModal } from "../../Screens/Contacts/modals";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";
import AppColor from "../../Constants/Color";
import CustomInput from "../../Components/CustomInput/index.web";
import ModalWrapper from "../../Components/Modals/ModalWrapper";

const VCards = ({ tenant }) => {
    const Tenant = tenant.data.tenant;
    const Toast = useToast();

    const { data, isLoading, refetch } = getQuery({
        action: 'get_vcards',
        tenant: Tenant.id
    })

    const [removeModal, setRemoveModal] = React.useState(false);
    const [emailModal, setEmailModal] = React.useState(false);
    const [approveModal, setApproveModal] = React.useState(false);
    const [rejectModal, setRejectModal] = React.useState(false);

    const [focusedVCard, setfocusedVCard] = React.useState(null);

    const [comment, setComment] = React.useState('');
    const [email, setEmail] = React.useState('');

    if (isLoading) return <Loader backgroundColor="transparent" />
    if (!data) return null;

    function parseVCard(vcardString) {
        const vcardLines = vcardString.split('\n');
        const vcardObject = {};

        for (const line of vcardLines) {
            if (line.startsWith('FN:')) {
                vcardObject.name = line.substring(3);
            } else if (line.startsWith('ORG:')) {
                vcardObject.company = line.substring(4);
            } else if (line.startsWith('TITLE:')) {
                vcardObject.title = line.substring(6);
            } else if (line.startsWith('EMAIL;type=INTERNET:')) {
                vcardObject.email = line.substring(18).split(':')[1];
            } else if (line.startsWith('ADR;')) {
                const addressParts = line.split(';');
                if (addressParts.length >= 7) {
                    vcardObject.address = addressParts[3];
                    vcardObject.city = addressParts[4];
                    vcardObject.state = addressParts[5];
                    vcardObject.zip = addressParts[6];
                }
            } else if (line.startsWith('TEL;type=WORK;type=VOICE:')) {
                vcardObject.workNumber = line.substring(24);
            } else if (line.startsWith('TEL;type=CELL;type=VOICE:')) {
                vcardObject.mobileNumber = line.substring(24);
            } else if (line.startsWith('NOTE:')) {
                vcardObject.note = line.substring(5);
            }
        }

        return vcardObject;
    }

    const VCARD_STATUS = {
        1: 'Pending',
        2: 'Approved',
        3: 'Rejected'
    }

    const RenderComponent = ({ item }) => {
        const vcard = parseVCard(item.contents);
        return (
            <>
                <View style={{ flexDirection: 'row', alignItems: 'center', gap: 20, flexWrap: 'wrap' }}>
                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Name</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.name}</Text>
                    </View>
                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Company or Branch</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.company}</Text>
                    </View>
                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Title</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.title}</Text>
                    </View>

                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>E-mail</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.email}</Text>
                    </View>

                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Address</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.address}</Text>
                    </View>

                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>City</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.city}</Text>
                    </View>


                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>State/Province</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.state}</Text>
                    </View>

                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Zip/Postal Code</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.zip}</Text>
                    </View>

                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Work Number</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.workNumber}</Text>
                    </View>

                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Mobile Number</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.mobileNumber}</Text>
                    </View>
                    <View style={{ width: '30%' }}>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Note</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{vcard.note}</Text>
                    </View>

                    {
                        !!item.reviewerDetail &&
                        <>
                            <View style={{ width: '30%' }}>
                                <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Review Date</Text>
                                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{moment(item.reviewed).format('MM/DD/YYYY')}</Text>
                            </View>

                            <View style={{ width: '30%' }}>
                                <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Reviewed By</Text>
                                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{item.reviewerDetail.name}</Text>
                            </View>
                            <View style={{ width: '30%' }}>
                                <Text style={{ fontFamily: AppFonts.Roboto_Bold }}>Reviewer Comments</Text>
                                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{item.comment}</Text>
                            </View>
                        </>
                    }


                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 10, marginTop: 10 }}>

                    <CustomButton onPress={() => {
                        setEmailModal(true);
                        setfocusedVCard(item);
                    }} title={'Email'} outline width={'20%'} />

                    {
                        VCARD_STATUS[item?.status] === 'Approved' &&
                        <CustomButton onPress={() => {
                            setfocusedVCard(item);
                            setRemoveModal(true);
                        }} title={'Remove'} width={'20%'} />
                    }

                    {VCARD_STATUS[item?.status] === 'Pending' &&
                        <>
                            <CustomButton onPress={() => {
                                setfocusedVCard(item);
                                setRejectModal(true);
                            }} title={'Reject'} outline width={'20%'} />
                            <CustomButton
                                onPress={() => {
                                    setfocusedVCard(item);
                                    setApproveModal(true);
                                }}
                                title={'Approve'} width={'20%'} />
                        </>
                    }
                </View>
            </>

        )
    }

    const handleDelete = async (id) => {
        const body = {
            action: 'delete_vcard',
            id: id
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                refetch();
                Toast.show({
                    title: 'VCard Deleted Successfully',
                    status: 'success'
                })
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch (err) {
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }
    }

    const handleEmail = async () => {
        const body = {
            action: 'email_vcard',
            id: focusedVCard.id,
            emailAddress: email,
            comment: comment,
            tenant: Tenant.id
        }

        try {

            const result = await RequestMaker(body);

            if (result.data.success) {
                Toast.show({
                    title: 'Email Sent Successfully',
                    status: 'success'
                })
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }

        } catch (err) {
            Toast.show({
                title: err.message,
                status: 'error'
            })

        }

        setEmailModal(false)

    }

    const handleReject = async () => {
        const body = {
            action: 'reject_vcard',
            id: focusedVCard.id,
            reason: comment,
            tenant: Tenant.id
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                Toast.show({
                    title: 'VCard Rejected Successfully',
                    status: 'success'
                })
                refetch();
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch (err) {
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }
    }

    const handleApprove = async () => {
        const body = {
            action: 'approve_vcard',
            id: focusedVCard.id,
            comment: comment,
            tenant: Tenant.id
        }
        try {
            const result = await RequestMaker(body);
            if (result.data.success) {
                Toast.show({
                    title: 'VCard Approved Successfully',
                    status: 'success'
                })
                refetch();
            } else if (result.data.errorMessage) {
                Toast.show({
                    title: result.data.errorMessage,
                    status: 'error'
                })
            }
        } catch (err) {
            Toast.show({
                title: err.message,
                status: 'error'
            })
        }


    }

    return (
        <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginBottom: 10 }}>
                This is a list of all the vCards that are under this account.
                The vCard with the * is the current vCard.
                vCards that appear after the one with the asterisk are older and can be deleted.
                Deleting the one with the asterisk will cause the next one to become the active one.
            </Text>
            <ComponentAccordion
                columns={['vCard', 'Date', 'Status']}
                data={data.vcards}
                renderListItem={item => [`Vcard ${item.id}`, moment(item.created).format('MM/DD/YYYY'), VCARD_STATUS[item.status]]}
                renderComponent={item => <RenderComponent item={item} />}
            />

            {removeModal && <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={removeModal} transparent={true}>
                <RemovePhoneModal
                    title="Delete VCard"
                    message="Are you sure you want to delete this VCard?"
                    handleRemove={() => {
                        handleDelete(focusedVCard.id);
                        setRemoveModal(false);
                    }}
                    setRemoveModal={setRemoveModal}
                />
            </Modal>
            }
            {emailModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={emailModal} transparent={true}>
                    <ModalWrapper>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Email VCard</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>Provide an email address and a comment to be included in the email.</Text>
                        <CustomInput onChangeText={val => setComment(val)} titleStyle={{ marginTop: 10 }} title={'Comment'} />
                        <CustomInput onChangeText={val => setEmail(val)} titleStyle={{ marginTop: 10 }} title={'Email'} />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 20 }}>
                            <CustomButton title={'Cancel'} outline onPress={() => setEmailModal(false)} width={'40%'} />
                            <CustomButton title={'Send'} onPress={handleEmail} width={'40%'} />
                        </View>

                    </ModalWrapper>
                </Modal>
            }

            {rejectModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={rejectModal} transparent={true}>
                    <ModalWrapper>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Reject VCard</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>(Optional) Provide a comment. This comment will be included in an email to the main email address for the account.</Text>
                        <CustomInput onChangeText={val => setComment(val)} titleStyle={{ marginTop: 10 }} title={'Comment'} />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 20 }}>
                            <CustomButton title={'Cancel'} outline onPress={() => setRejectModal(false)} width={'40%'} />
                            <CustomButton title={'Confirm'} onPress={handleReject} width={'40%'} />
                        </View>

                    </ModalWrapper>
                </Modal>
            }
            {
                approveModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={approveModal} transparent={true}>
                    <ModalWrapper>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, fontSize: 18, textAlign: 'center' }}>Approve VCard</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10 }}>(Optional) Provide a comment. This comment will be included in an email to the main email address for the account.</Text>
                        <CustomInput onChangeText={val => setComment(val)} titleStyle={{ marginTop: 10 }} title={'Comment'} />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 20, marginTop: 20 }}>
                            <CustomButton title={'Cancel'} outline onPress={() => setApproveModal(false)} width={'40%'} />
                            <CustomButton title={'Confirm'} onPress={handleApprove} width={'40%'} />
                        </View>

                    </ModalWrapper>
                </Modal>

            }

        </View>
    )
}


export default VCards;