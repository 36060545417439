import React from "react";
import { FlatList, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { Icon } from "react-native-elements";
import AppColor from "../../Constants/Color";
import CustomButton from "../../Components/Button/index.web";
import APIKeysModal from "../../Screens/Profile/APIAccess/APIKeysModal";
import Loader from "../../Components/Loader/index.web";
import getQuery from "../getQuery";
import RequestMaker from "../../Middleware/ApiCaller";

const APIAccess = ({tenant}) => {
    const Tenant = tenant.data.tenant;
    const [modalVisible, setModalVisible] = React.useState(false);

    const {data, isLoading, isError, refetch} = getQuery({
        action: 'get_api_keys',
        tenant: Tenant.id,
    })
    if(isLoading) return <Loader backgroundColor="transparent"/>
    if(!data) return null;
    
    const renderItem = ({item}) => {
        
        const handleDelete = async () => {
            const body = {
                action: 'delete_api_key',
                key: item.key,
            }
            const result = await RequestMaker(body);
            if(result.data.success){
                refetch();
            }
        }

        return(
            <View style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',backgroundColor:'transparent',width:'100%'}}>
            <Text key={item.key} style={{marginVertical:10}}>
                {item.key}
            </Text>
            <Icon
            name='trash-o'
            color={AppColor.danger}
            size={24}
            style={{marginLeft:15}}
            type='font-awesome'
            onPress={() => handleDelete(item.key)}
            />
            </View>
        )

    }

    return(
        <View style={{flex:1}}>
            <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
                From here you can manage keys that allow API level access to this account for developing your own solutions to integrate with MyRepChat.
            </Text>
            <FlatList
            ListHeaderComponent={<Text style={{paddingTop:10,paddingBottom:10,fontFamily:AppFonts.Roboto_Regular,color:AppColor.PrimaryColor,fontWeight:'900',fontSize:14,backgroundColor:'white'}}>Public Keys</Text>}
            stickyHeaderIndices={[0]}
            style={{paddingTop:0,maxHeight:300,maxWidth:'50%',backgroundColor:'white',marginTop:20,marginBottom:20}}
            data={data.keys}
            renderItem={renderItem}
            />
            <CustomButton title="Generate API Key" onPress={()=>setModalVisible(true)} width={300} style={{alignSelf:'flex-end',marginLeft:15,marginTop:20}}/>

            {
                !!modalVisible && 
                
                <APIKeysModal
                tenant={Tenant.id}
                visible={modalVisible}
                setVisible={setModalVisible}
                refetch={() => refetch()}
                />
            }


        </View>
    )
}

export default APIAccess;