import React from 'react';
import { FlatList, SafeAreaView, Text, View } from 'react-native';
import Header from '../../../Components/Header';
import AppFonts from '../../../Constants/Fonts';
import { useSelector } from 'react-redux';
import getData from '../../../AsyncUtils/GetData';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api';
import AppColor from '../../../Constants/Color';
import CustomButton from '../../../Components/Button';
import APIKeysModal from './APIKeysModal';
import { Icon } from 'react-native-elements';
import Loader from '../../../Components/Loader';

const APIAccess = ({navigation}) => {

    const tenant = useSelector((state) => state.User?.profile)?.tenant;
    const [keys, setKeys] = React.useState([]);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        const getKeys = navigation.addListener('focus', () => {
            getAPIKeys()
        })

        return getKeys;
    },[navigation])

    const getAPIKeys = async () => {
        setLoading(true);
        try {

            const body = {
                action: 'get_api_keys',
                tenant: tenant?.id,
            };
      
            
            let device = await getData('@device_id');
            const headers = { 'x-device': device };
            let Config = {
              method: 'post',
              url: End_Points.Base_url + End_Points.End,
              data: body,
              headers: headers,
              redirect: 'follow',
            };
            let result = await RequestMaker(Config);
            if (result.data.success) {
              setKeys(result.data.keys);
            }
            setLoading(false);
          } catch (err) {
            console.log(err);
          }
    }

    const deleteAPIKey = async key => {
        setLoading(true);

        try {

        const body = {
            action: 'delete_api_key',
            key: key,
        };
    
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
            method: 'post',
            url: End_Points.Base_url + End_Points.End,
            data: body,
            headers: headers,
            redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.data.success) {
            getAPIKeys();
        }
        setLoading(false);
        } catch (err) {
        console.log(err);
        }
    }

    const renderItem = ({item}) => {
        return(
            <Key item={item}/>
        )
    }

    const Key = ({item}) => {
        const [hover, setHover] = React.useState(false);

        return(
        <View onMouseLeave={()=>setHover(false)} onMouseEnter={()=>setHover(true)} style={{flexDirection:'row',alignItems:'center',justifyContent:'space-between',backgroundColor: hover ? '#f2f2f2' : 'transparent',width:'100%'}}>
        <Text key={item.key} style={{marginVertical:10}}>
            {item.key}
        </Text>
        <Icon
        name='trash-o'
        color={AppColor.danger}
        size={24}
        style={{marginLeft:15}}
        type='font-awesome'
        onPress={() => deleteAPIKey(item.key)}
        />
        </View>)

    }

    return(
        <SafeAreaView style={{flex:1}}>
            <Text style={{padding:15, fontFamily:AppFonts.Roboto_Regular,fontSize:16,marginTop:20}}>
            From here you can manage keys that allow API level access to your for developing your own solutions to integrate with MyRepChat.
            </Text>
            <FlatList
            ListHeaderComponent={<Text style={{paddingTop:10,paddingBottom:10,fontFamily:AppFonts.Roboto_Regular,color:AppColor.PrimaryColor,fontWeight:'900',fontSize:14,backgroundColor:'white'}}>Public Keys</Text>}
            stickyHeaderIndices={[0]}
            style={{marginLeft:15,padding:15,paddingTop:0,maxHeight:300,maxWidth:'50%',backgroundColor:'white',marginTop:20,marginBottom:20}}
            data={keys}
            renderItem={renderItem}
            />
            <CustomButton title="Generate API Key" onPress={()=>setModalVisible(true)} width={300} style={{alignSelf:'flex-start',marginLeft:15,marginTop:20}}/>

            {
                !!modalVisible && 
                
                <APIKeysModal
                visible={modalVisible}
                setVisible={setModalVisible}
                fetch={getAPIKeys}
                />
            }

            {
                loading && <Loader />
            }
            
        </SafeAreaView>
    )
}

export default APIAccess;