import React from "react";
import { Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomInput from "../../Components/CustomInput/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useToast } from "native-base";
import CustomButton from "../../Components/Button/index.web";
import { useSelector } from "react-redux";

const ChangePassword = () => {
    const Toast = useToast();
    const user = useSelector((state) => state.User)?.profile?.user;


    const [data, setData] = React.useState({
        currentPassword: '',
        newPassword: '',
        verfiyNewPassword: '',
    })

    const handleSave = async () => {
        if(data.newPassword !== data.verfiyNewPassword){
            Toast.show({
                status: 'error',
                title: 'Passwords do not match!'
            })
            return;
        }

        const body = {
            action: 'update_user',
            email: user.email,
            name:user.name,
            password: data.verfiyNewPassword,
            toast: false,
            verify: 'on',
            userId: user.user_id
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            Toast.show({
                status: 'success',
                title: 'Successfully updated password!'
            })
        } else {
            Toast.show({
                status: 'error',
                title: result.data.errorMessage || 'Something went wrong! Please try again later.'
            })
        }
    }


    return(
        <View style={{flex:1}}>
           <Text style={{ fontFamily: AppFonts.Roboto_Regular,marginTop:10 }}>Your password must be at least 8 characters long and include a mix of uppercase letters, lowercase letters, numbers, and special characters.</Text>
           <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Current Password</Text>
           <CustomInput 
            onChangeText={e => setData({ ...data, currentPassword: e })}
            value={data.currentPassword}
            secure
            />

           <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>New Password</Text>
           <CustomInput 
            onChangeText={e => setData({ ...data, newPassword: e })}
            value={data.newPassword}
            secure
            />

           <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Verify Password</Text>
            <CustomInput 
            onChangeText={e => setData({ ...data, verfiyNewPassword: e })}
            value={data.verfiyNewPassword}
            secure
            />

            <CustomButton
                onPress={handleSave}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end',marginBottom:10,marginRight:10 }}
            />

        </View>
    )
}

export default ChangePassword;