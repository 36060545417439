import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';

const useResponsiveLayout = () => {
    const {width} = useWindowDimensions();

    const isWebCompact = Platform.OS === 'web' && width < 900; 

    return {
        isWebCompact
    }
}

export default useResponsiveLayout;

