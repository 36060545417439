import React from "react";
import { View } from "react-native";
import AppColor from "../../Constants/Color";


const ModalWrapper = props => {
    return (
        <View
            style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#0005',
                ...props.style
            }}>
            <View
                style={{
                    padding: 30,
                    backgroundColor: 'white',
                    borderRadius: 20,
                    borderColor: AppColor.PrimaryColor,
                    borderWidth: 1,
                    maxWidth: 800,
                    width: '100%',
                    ...props.wrapperStyle
                }}>
                {props.children}

            </View>
        </View>
    )
}

export default ModalWrapper;