import React, { useState } from 'react';
import { Text, TouchableOpacity, View, FlatList, ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Icon } from 'react-native-elements';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Alert from 'Alert';

import CustomInput from '../../Components/CustomInput';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import getData from '../../AsyncUtils/GetData';
import End_Points from '../../Constants/Api';
import RequestMaker from '../../Middleware/ApiCaller';
import { loadAllDistributionsThunk } from '../../Redux/distributions';
import MembersModal from './MemberModal';
import Loader from '../../Components/Loader';

const DistributionDetail = ({ navigation, route }) => {
  const [members, setMembers] = React.useState([]);
  const { dist } = route.params;
  const [visible, setVisible] = React.useState(false);
  const [distName, setDistName] = React.useState(dist.text);
  const [edit, setEdit] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const contactData = useSelector((state) => state.contacts).data;
  const [sortBy, setSortBy] = React.useState('name');

  React.useEffect(()=>{
    const focus = navigation.addListener('focus', async () => {
        await getData('sortBy')
        .then(result => {
          if(!result) return;
          if(result === 'lname') setSortBy('lname');
          if(result === 'fname') setSortBy('name');
        })    
    })

    return focus;
  },[navigation])

  const loadData = async (id) => {
    try {
      setLoading(true);
      const body = {
        action: 'get_distributionlist_members',
        distributionList: id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        console.log(result.data);
        const arr = result.data.members.map(x => {
          const splitName = x.text.split(" ");
          const lname = splitName[splitName.length - 1];
          return {...x, fname: x.text, lname: lname, id: x.member}
        })
        setMembers(arr);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async (item) => {
    setVisible(false)
    UpdateDL([...members, ...item]);
    /*
    if (!members.map((x) => x.member).includes(item.id)) {
      try {
        setLoading(true);
        const body = {
          action: 'create_distributionlist_member',
          member: item.id,
          distributionList: dist.id,
        };
        
        let device = await getData('@device_id');
        const headers = { 'x-device': device };
        let Config = {
          method: 'post',
          url: End_Points.Base_url + End_Points.End,
          data: body,
          headers: headers,
          redirect: 'follow',
        };
        let result = await RequestMaker(Config);
        if (result.data.success) {
          loadData(dist.id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }*/
  };
  const handleDeleteMember = async (mid) => {
    try {
      setLoading(true);
      const body = {
        action: 'delete_distributionlist_member',
        member: mid,
        distributionList: dist.id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        loadData(dist.id);
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const UpdateDL = async (data) => {
    try {
      setLoading(true);
      const body = {
        action: 'update_distributionlist',
        text: distName,
        id: dist.id,
      };
      if(data){
        body['members'] = data.map((x) => x.id)
      } else { 
        body['members'] = members.map((x) => x.id)
      }

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      console.log('result', result);
      if (result.data.success) {
        loadData(dist.id)
        dispatch(loadAllDistributionsThunk());
      } else {
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (dist.id) {
      loadData(dist.id);
      setDistName(dist.text)
    }
  }, [dist]);

  const renderListView = ({ item }) => {
    const contact = contactData.find(e => e.id === item.id);
    let status = '';
    if(contact){
      contact.consented ? status = 'Consented' : contact.stopped ? status = 'Opted-Out' : ''
    }

    console.log( item, contactData);
    return (
      <TouchableOpacity
        onPress={()=>navigation.navigate('ContactEdit', {item: item})}
        style={{
          width: '100%',
          alignSelf: 'center',
          flexDirection: 'row',
          height: 40,
          alignItems: 'center',
          justifyContent: 'space-between',
          marginVertical: 10,
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <AntDesign name="user" size={30} color={AppColor.PrimaryColor} />
          <Text
            style={{
              fontFamily: AppFonts.Roboto_Regular,
              fontSize: 18,
              marginHorizontal: 10,
              color: AppColor.PrimaryColor,
            }}>
            {item.fname}
          </Text>
          <Text
          style={{fontFamily:AppFonts.Roboto_Regular,
          color: status === 'Consented' ? 'green' : status === 'Opted-Out' ? 'red' : ''
          }}
          >
            {status}
          </Text>
        </View>
        <AntDesign
        accessibilityRole='button'
        accessibilityHint='Delete contact from distribution list.'
        accessibilityLabel='Trash.'
          name="delete"
          size={25}
          onPress={() => handleDeleteMember(item.id)}
          color={AppColor.danger}
        />
      </TouchableOpacity>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      {/* <Header
        back={true}
        onPress={() => navigation.goBack()}
        chatIcon={
          <Icon
            color="black"
            name="chatbox"
            type="ionicon"
            onPress={() => {
              Alert.alert(
                'Distribution Message',
                'Do you want to send message to this distribution list?',
                [
                  {
                    text: 'No',
                  },
                  {
                    text: 'Yes',
                    onPress: () => {
                      navigation.navigate('Messages', { dl: dist, memberData: null });
                    },
                  },
                ]
              );
            }}
          />
        }
      /> */}
      {loading && <Loader />}
      <View style={{ flex: 1, padding: 30 }}>
        <CustomInput
          placeholder="Name"
          title="Name*"
          value={distName}
          onChangeText={(t) => setDistName(t)}
          disabled={!edit}
          width="100%"
          titleStyle={{ marginLeft: 0 }}
        />
        {edit ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginVertical: 10,
            }}>
            <TouchableOpacity
              style={{
                backgroundColor: AppColor.PrimaryColor,
                padding: 10,
                borderRadius: 5,
                width: 100,
                alignItems: 'center',
              }}
              onPress={() => {
                setEdit((state) => !state);
                UpdateDL();
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 17,
                  color: AppColor.SecondaryColor,
                }}>
                Save
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setEdit(false);
                setDistName(dist.text);
              }}
              style={{
                marginLeft: 10,
                width: 100,
                alignItems: 'center',
                backgroundColor: AppColor.PrimaryColor,
                padding: 10,
                borderRadius: 5,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 17,
                  color: AppColor.SecondaryColor,
                }}>
                Cancel
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginVertical: 10,
            }}>
            <TouchableOpacity
            accessibilityRole='button'
            accessibilityLabel='Edit Name.'
              style={{
                backgroundColor: AppColor.PrimaryColor,
                padding: 10,
                borderRadius: 5,
              }}
              onPress={() => setEdit((state) => !state)}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 17,
                  color: AppColor.SecondaryColor,
                }}>
                Edit Name
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
            accessibilityLabel='Add Member.'
            accessibilityRole='button'
              onPress={() => setVisible(true)}
              style={{
                marginLeft: 10,
                backgroundColor: AppColor.PrimaryColor,
                padding: 10,
                borderRadius: 5,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 17,
                  color: AppColor.SecondaryColor,
                }}>
                Add Member
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
            accessibilityLabel='Add Member.'
            accessibilityRole='button'
              onPress={() => {
                navigation.navigate('Messages', { dl: dist, memberData: null });
              }}
              style={{
                marginLeft: 10,
                backgroundColor: AppColor.PrimaryColor,
                padding: 10,
                borderRadius: 5,
              }}>
              <Text
                style={{
                  fontFamily: AppFonts.Roboto_Regular,
                  fontSize: 17,
                  color: AppColor.SecondaryColor,
                }}>
                Send Message
              </Text>
            </TouchableOpacity>

          </View>
        )}
        <Text
          style={{
            fontFamily: AppFonts.Roboto_Regular,
            fontSize: 17,
            color: AppColor.Dark,
            textAlign: 'center',
            marginVertical: 15,
          }}>
          Current Members: {members.length}
        </Text>
        <ScrollView>
        <FlatList
          data={members.sort((a,b) => sortBy === 'name' ?  a.fname.localeCompare(b.fname) : a.lname.localeCompare(b.lname))}
          scrollEnabled={false}
          keyExtractor={(item, index) => item.id + '/' + index}
          renderItem={renderListView}
        />
        </ScrollView>
      </View>
      {
        visible &&
      <MembersModal
        members={members}
        visible={visible}
        setVisible={setVisible}
        handleAddMember={handleAddMember}
      />
      }
    </SafeAreaView>
  );
};
export default DistributionDetail;
