import React from "react";
import { Modal, ScrollView, Text, View } from "react-native";
import Loader from "../../Components/Loader/index.web";
import getQuery from "../getQuery";
import { formatNumber } from "../../Middleware/helpers";
import AppFonts from "../../Constants/Fonts";
import moment from "moment";
import CustomButton from "../../Components/Button/index.web";
import CustomInput from "../../Components/CustomInput/index.web";
import RequestMaker from "../../Middleware/ApiCaller";

const FlagDetails = ({flag, onSave}) => {
    const {data, isLoading} = getQuery({
        action: 'get_flagged_details',
        accountId: flag.accountId,
        commId: flag.commId
    })
    const [isRejecting , setIsRejecting] = React.useState(false);
    const [reason, setReason] = React.useState('');

    if (isLoading) return <Loader backgroundColor={'transparent'} />;
    if (!data) return null;

    const updateFlag = async (params) => {
        const body = {
            action: 'update_flagged',
            accountId: flag.accountId,
            commId: flag.commId,
            groupId: flag.groupId,
            id: flag.id,
            ...params
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            setIsRejecting(false);
            onSave();
        }
    }

    return(
    <ScrollView style={{flex:1}} contentContainerStyle={{flex:1}}>
        <Text style={{fontFamily:AppFonts.Roboto_Bold}}>Created at</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{moment(flag.created).format('MMMM DD YYYY hh:mm a')}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Account</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{flag.account}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Pattern</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{flag.pattern}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>From Number</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{formatNumber(data?.communication?.from_number) || null}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>To Number</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{formatNumber(data?.communication?.to_number) || null}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Direction</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{data?.communication?.direction || null}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Holding</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{data?.communication?.holding ? String(data.communication.holding) : null}</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginTop:10}}>Message Contents</Text>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{data?.communication?.messages[0]?.sms ? String(data.communication.messages[0].sms) : null}</Text>
        <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-end',marginTop:20,gap:10}}>
            {
            !data?.communication?.holding &&
                <CustomButton onPress={() => updateFlag({messageAction: 'clear'})} width={'30%'} title={'Clear'} /> 
            }
            { data?.communication?.holding &&
            <>
            <CustomButton width={'30%'} onPress={() => updateFlag({messageAction: 'release'})} outline title={'Release'} />
            <CustomButton width={'30%'} onPress={() => setIsRejecting(true)} title={'Reject'} />
            </>
            }
        </View>
        {isRejecting &&
        <Modal visible={isRejecting} style={{borderColor:'white',position:'absolute',height:'100%', width:'100%',justifyContent:'center',alignItems:'center'}}>
            <View style={{padding:15,backgroundColor:'white',flex:1}}>
                <Text style={{fontFamily:AppFonts.Roboto_Bold,marginBottom:10}}>Reject Message</Text>
                <Text style={{fontFamily:AppFonts.Roboto_Regular,marginBottom:10}}>Provide a reason that this message is being rejected. This reason will be included in an email to the main email address for the account.</Text>
                <CustomInput value={reason} onChangeText={val => setReason(val)} title={'Reason'} />
                <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-end',marginTop:10,gap:10}}>
                    <CustomButton width={'30%'} onPress={() => setIsRejecting(false)} outline title={'Cancel'} />
                    <CustomButton width={'30%'} onPress={() => updateFlag({reason: reason, messageAction: 'reject'})} title={'Continue'} />
                </View>

            </View>
        </Modal>}
    </ScrollView>

    )
}

export default FlagDetails;
