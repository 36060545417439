import axios from "axios";
import { MakeConfig } from "./MakeConfig";

export const getCalendarcategories = async ({params: params}) => {
    const body = {
        action: 'get_calendarcategories',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const getSavedMessages = async ({params:params}) => {
    const body = {
        action: 'get_saved_messages',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const updateCalendarCategory = async ({params:params}) => {
    const body = {
        action: 'update_calendarcategory',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);   
}


export const getCalendarEvents = async ({params:params}) => {
    const body = {
        action: 'get_calendarevents',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);   
}

export const convertCalendarEvent = async ({params: params}) => {
    const body = {
        action: 'convert_calendarevent',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}

export const updateCalendarEvent = async ({params: params}) => {
    const body = {
        action : 'update_calendarevent',
        ...params
    }

    const Config = await MakeConfig(body);

    return axios(Config);
}
