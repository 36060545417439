import React from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'react-native-elements';

import IntegrationComponent from './IntegrationComponent';
import CustomButton from '../../../../Components/Button';
import AppFont from '../../../../Constants/Fonts';
import AppColor from '../../../../Constants/Color';
import { setAttributes } from '../../../../Redux/integrationData';

export default function AttributeComponent({
  provider,
  integration,
  attributes,
  allowContactEdits,
  label,
}) {
  const dispatch = useDispatch();
  const [data, setData] = React.useState({});
  const { attributes: pAttributes } = useSelector(
    (state) => state.integrationData
  );

  React.useEffect(() => {
    let z = {};
    for (let x of attributes.getArray()) {
      z[x[0]] = '';
      if (integration != null && integration[x[0]]) {
        z[x[0]] = integration[x[0]];
      }
    }
    setData(z);
  }, [integration]);

  return (
    <View style={{ marginHorizontal: 15 }}>
      <Text
        style={{
          fontFamily: AppFont.Roboto_Regular,
          fontSize: 20,
          color: AppColor.Black200,
          marginBottom: 20,
        }}>
        MyRepChat can send your data to {label} for archival. In order to set
        this up you need to:
      </Text>
{ 
provider.name === 'erado' ?
<Text 
        style={{
          fontFamily: AppFont.Roboto_Regular,
          fontSize: 20,
          color: AppColor.Black200,
          marginBottom: 20,
          paddingLeft: 10,
}}>

{`1. Contact Erado to get a MyRepChat archiving email address`} {`\r\n2. Enter the email address into the field provided below.\r\n3. Click the save button`}
</Text>
:
provider.name === 'slack' ?  
<Text
        style={{
          fontFamily: AppFont.Roboto_Regular,
          fontSize: 20,
          color: AppColor.Black200,
          marginBottom: 5,
          paddingLeft: 10,
        }}>
        {`1.Create a channel for the messages.\r\n2.Create a webhook for the channel`} <a target="_blank" href='https://get.slack.help/hc/en-us/articles/115005265063-Incoming-WebHooks-for-Slack' style={{color:'blue',cursor:'pointer'}}>{'(Instructions)'}</a> {`\r\n3.Copy and paste the URL for the channel into the webhook field below.\r\n4.Activate archiving by setting the toggle switch to the right.\r\n5.Click the save button`}
      </Text>
:
<>
<Text
        style={{
          fontFamily: AppFont.Roboto_Regular,
          fontSize: 20,
          color: AppColor.Black200,
          marginBottom: 5,
          paddingLeft: 10,
        }}>
        {provider.name === 'mirrorweb' ? '1. Contact MirrorWeb to get a MyRepChat archiving email address, and enter it here.' : '1. Enter your business email address.'}
      </Text>
      <Text
        style={{
          fontFamily: AppFont.Roboto_Regular,
          fontSize: 20,
          color: AppColor.Black200,
          marginBottom: 5,
          paddingLeft: 10,
        }}>
        2. Activate archiving by setting the toggle switch to the right.
      </Text>
      <Text
        style={{
          fontFamily: AppFont.Roboto_Regular,
          fontSize: 20,
          color: AppColor.Black200,
          marginBottom: 50,
          paddingLeft: 10,
        }}>
        3. Click the save button.
      </Text>
      </>
}
      {attributes.getArray().map((x, index) => {
        return (
          <Input
            label={x[1]}
            value={data[x[0]]}
            labelStyle={{ color: AppColor.PrimaryColor }}
            errorStyle={{ height: 0 }}
            inputStyle={{ fontSize: 15 }}
            containerStyle={{ flex: 1 }}
            inputContainerStyle={{ borderColor: AppColor.PrimaryColor }}
            onChangeText={(t) => {
              setData((state) => ({ ...data, [x[0]]: t }));
              dispatch(
                setAttributes({
                  ...data,
                  ...pAttributes,
                  [x[0]]: t,
                })
              );
            }}
            key={`attribute_${index}`}
          />
        );
      })}
      <IntegrationComponent
        provider={provider}
        integration={integration}
        allowContactEdits={allowContactEdits}
      />
    </View>
  );
}
