import React from 'react';
import { useToast } from 'native-base';
import { Text, TouchableOpacity, View } from 'react-native';

function useUndo() {
  const toastRef = React.useRef();
  const Toast = useToast();

  const handleUndo = (cb) => {
    if(toastRef.current){
      Toast.close(toastRef.current);
    }
    cb();
  }

  const showToastWithUndo = (cb) => {
    toastRef.current = Toast.show({
      
      title: 'Undo',
      duration: 5000,
      render: () => 
      <View style={{backgroundColor:'rgb(63, 63, 63)',borderRadius:10,flexDirection:'row',alignItems:'center',gap:40,padding:15}}>
        <Text style={{color:'white'}}>Item Deleted</Text>
        <TouchableOpacity onPress={() => handleUndo(cb)}>
            <Text style={{color:'cyan'}}>
                Undo
            </Text>
        </TouchableOpacity>
      </View>
    });
  };

  return showToastWithUndo;
}

export default useUndo;
