import React from "react";
import { FlatList, SafeAreaView, Text, TouchableOpacity, View } from "react-native";
import Header from "../../Components/Header";
import Search from "../../Components/Search";
import { useDispatch, useSelector } from "react-redux";
import { useFocusEffect } from "@react-navigation/native";
import { loadAllBookMarksThunk } from "../../Redux/bookmarks";
import AppColor from "../../Constants/Color";
import AppFonts from "../../Constants/Fonts";
import { Icon } from "react-native-elements";

const SelectBookmark = ({route, navigation}) => {
    const [search, setSearch] = React.useState('');
    const dispatch = useDispatch();
    const data = useSelector((state) => state.bookmarks).data;
  
    useFocusEffect(
      React.useCallback(() => {
        dispatch(loadAllBookMarksThunk());
      }, [])
    );
  
  
    return(
        <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>

        <Search
          onChangeText={(t) => setSearch(t)}
          placeholder={'Filter by title'}
        />

        <FlatList
            contentContainerStyle={{flex:1}}
            data={data.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
                <TouchableOpacity  onPress={() => {
                        navigation.navigate(route.params.screen, { message: route.params.message, memberData: route.params.memberData, bookmark: item.url });              
                }} style={{flexDirection:'row',padding:10,alignItems:'center'}}>
                    <Icon name="bookmark" type="entypo" size={22} color={AppColor.PrimaryColor}/> 
                    <View style={{marginLeft:10}}>
                        <Text style={{fontFamily:AppFonts.Roboto_Bold,color:AppColor.PrimaryColor}}>{ item.label}</Text>
                        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>{ item.url}</Text>
                    </View>

                    <Icon name='chevron-right' type='feather' size={20} containerStyle={{marginLeft:'auto'}}/>
                </TouchableOpacity>
            )}
        />


        </SafeAreaView>
    )
}

export default SelectBookmark;