import { useToast } from 'native-base';
import RequestMaker from '../Middleware/ApiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { setAffiliate } from '../Redux';

function useUpdateAffiliate() {
  const affiliate = useSelector((state) => state.User.profile)?.affiliate;
  const dispatch = useDispatch();
  const Toast = useToast();

  const updateAffiliate = async (payload) => {
    const body = {
        action: 'update_affiliate',
        id: affiliate.id,
        ...payload
    }
    const result = await RequestMaker(body);

    if(result.data.success){
        dispatch(setAffiliate(payload));

        Toast.show({
            title:'Successfully updated!',
            status:'success'
        })
    } else {
        Toast.show({
            title: result.data.errorMessage || 'Something went wrong! Please try again later.',
            status:'error'
        })
    }

  };

  return updateAffiliate;

}

export default useUpdateAffiliate;
