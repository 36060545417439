import React, { useState, useEffect } from 'react';
import { Text, TouchableOpacity, View, Image, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import TimePicker from 'rc-time-picker';
import { Box, Select, useToast } from 'native-base';
import { Icon } from 'react-native-elements';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import Alert from 'Alert';
import Header from '../../Components/Header';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import EndPoints from '../../Constants/Api';
import CustomButton from '../../Components/Button';
import CustomInput from '../../Components/CustomInput';
import Loader from '../../Components/Loader';

import { providers, integrations_types } from '../IntegrationProvider';
import { loadIntegrationsThuunk } from '../../Redux/integrations';
import RequestMaker from '../../Middleware/ApiCaller';
import getData from '../../AsyncUtils/GetData';
import { FlatList } from 'react-native-gesture-handler';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import 'rc-time-picker/assets/index.css';

const moment = require('moment');

const ArchiveSchedule = ({ navigation, route }) => {
  const [loading, setLoading] = useState(false);
  const [isVisbleAddSchedule, setIsVisbleAddSchedule] = useState(false);
  const [startHours, setStartHours] = useState(moment());
  const [selectedLayout, setSelectedLayout] = useState(2);
  const [selectedState, setSelectedState] = useState('active');
  const [auditEmail, setAuditEmail] = useState('');
  const [selectedProvider, setSelectedProvider] = useState(-1);
  const [layoutList, setLayoutList] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [frequencyHours, setFrequencyHours] = useState(24);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const tenant = useSelector((state) => state.User)?.profile?.tenant;
  const { integrations } = useSelector((state) => state.integrations);
  const integration_providers = useSelector(
    (state) => state.integrations.providers
  );
  const Toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    const date = new Date();
    setStartHours(moment(new Date(date.setHours(0, 0, 0))));
    dispatch(loadIntegrationsThuunk());
    handleGetLayout();
  }, []);

  useEffect(() => {
    if (selectedSchedule) {
      setSelectedLayout(selectedSchedule.layoutId);
      setSelectedState(selectedSchedule.state);
      if (
        selectedSchedule.auditEmail &&
        selectedSchedule.auditEmail !== 'null'
      ) {
        setAuditEmail(selectedSchedule.auditEmail);
      }
      setFrequencyHours(selectedSchedule.frequencyHours);
      setStartHours(moment(new Date(selectedSchedule.startTimestamp)));
    } else {
      setSelectedLayout(2);
      setSelectedState('active');
      setAuditEmail('');
      setFrequencyHours(24);

      const date = new Date();
      setStartHours(moment(new Date(date.setHours(0, 0, 0))));
    }
  }, [selectedSchedule]);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleGetLayout = async () => {
    try {
      let body = {
        action: 'get_archive_layout',
        tenantId: tenant.id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: EndPoints.Base_url + EndPoints.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLayoutList(data.layouts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSchedule = async (provider) => {
    try {
      let body = {
        action: 'get_archive_schedule',
        tenantId: tenant.id,
        provider: provider,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: EndPoints.Base_url + EndPoints.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setSchedules(data.schedules);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSchedule = async () => {
    if (auditEmail && !validateEmail(auditEmail)) {
      Alert.alert('Invalid Email');
      return;
    }
    if (!selectedLayout) {
      Alert.alert('Please select layout');
      return;
    }
    try {
      let body = {
        action: 'create_archive_schedule',
        tenantId: tenant.id,
        provider: selectedProvider,
        auditEmail: auditEmail,
        frequencyHours: frequencyHours,
        layoutId: selectedLayout,
        locked: false,
        startTimestamp: moment(startHours).toDate().getTime(),
        state: selectedState,
      };

      if (selectedSchedule) {
        body = {
          ...body,
          provider: selectedSchedule.provider,
          id: selectedSchedule.id,
          action: 'update_archive_schedule',
        };
      }

      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: EndPoints.Base_url + EndPoints.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      if (data.success) {
        Toast.show({
          duration: 2000,
          status: 'success',
          render: () => {
            return (
              <Box bg="teal.300" px={4} py={3} rounded="md">
                Schedule saved successfully.
              </Box>
            );
          },
        });
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }

      handleGetSchedule(selectedProvider);
      setLoading(false);
      setIsVisbleAddSchedule(false);
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTestSchedule = async () => {
    try {
      let body = {
        action: 'test_archive_schedule',
        tenantId: tenant.id,
        id: selectedSchedule.id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: EndPoints.Base_url + EndPoints.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Test Succeeded',
          status: 'success',
        });
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: AppColor.SecondaryColor }}>
      {loading && <Loader />}
      <View style={styles.container}>
        <View
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            marginTop: 10,
          }}>
          {Object.entries(providers)
            .filter(
              (x) =>
                x[1].type.name == integrations_types.ARCHIVE.name &&
                integration_providers.includes(x[1].name)
            )
            .map((provider, index) => {
              let b_color = AppColor.Dark;
              let b_w = 1;
              let display = 'none';
              if (
                integrations.find(
                  (integration) => integration.provider == provider[1].name
                )
              ) {
                display = 'block';
              }

              return (
                <TouchableOpacity
                  onPress={() => {
                    setSelectedProvider(provider[1].name);
                    handleGetSchedule(provider[1].name);
                    setIsVisbleAddSchedule(false);
                  }}
                  style={[
                    {
                      alignItems: 'center',
                      borderWidth: b_w,
                      margin: 10,
                      borderColor: b_color,
                      width: 200,
                      height: 150,
                      padding: 15,
                      display: display,
                      borderRadius: 5,
                    },
                    selectedProvider === provider[1].name && {
                      borderColor: AppColor.PrimaryColor,
                      borderWidth: 2,
                    },
                  ]}
                  key={`archive${index}`}>
                  {provider[1].name === 'email' ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <MaterialIcons
                        name="email"
                        size={30}
                        color={AppColor.PrimaryColor}
                      />
                      <Text
                        style={{
                          color: AppColor.PrimaryColor,
                          fontSize: 20,
                          marginLeft: 10,
                        }}>
                        Email
                      </Text>
                    </View>
                  ) : (
                    <img
                      style={{
                        height: 45,
                        width: 160,
                        margin: '0px 10px',
                        objectFit:'contain'
                      }}
                      alt={`${provider[1].name} logo.`}
                      src={
                        provider[1].fullImageUrlPath
                      }
                    />
                  )}
                </TouchableOpacity>
              );
            })}
        </View>
        <View>
          <Text style={styles.description}>
            Define schedules that set the frequency and layout for your
            archives, and provide an optional audit email address to receive a
            separate email with an audit of your archive.
          </Text>
        </View>
        <CustomButton
          title="+ ADD Schedule"
          onPress={() => {
            setSelectedSchedule(null);
            setIsVisbleAddSchedule(true);
          }}
          disabled={selectedProvider === -1}
          style={{alignSelf:'flex-end'}}
          width={'20%'}
        />
        <FlatList
          ListHeaderComponent={() => (
            <View style={[styles.row, { backgroundColor: AppColor.GREY200 }]}>
              <Text style={styles.title}>Schedule</Text>
              <Text style={styles.title}>State</Text>
            </View>
          )}
          data={schedules}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={[
                styles.row,
                selectedSchedule?.id === item.id && {
                  backgroundColor: AppColor.PrimaryColor,
                },
              ]}
              onPress={() => {
                setSelectedSchedule(item);
                setIsVisbleAddSchedule(true);
              }}>
              <Text style={styles.content}>{item.provider}</Text>
              <Text style={styles.content}>{item.state}</Text>
            </TouchableOpacity>
          )}
          contentContainerStyle={styles.flatContent}
          style={styles.flatContainer}
        />
        {isVisbleAddSchedule && (
          <View style={styles.addSchedule}>
            <CustomInput
              value={frequencyHours}
              onChangeText={(t) => setFrequencyHours(t)}
              title={'Frequency (in hours)'}
              placeholder=""
              width="100%"
              keyboardType="number-pad"
              disabled={selectedSchedule}
            />
            <Text style={styles.label}>Start Hour</Text>
            <TimePicker
              value={startHours}
              onChange={(val) => setStartHours(val)}
              showSecond={false}
              minuteStep={1}
              inputIcon={
                <Icon
                  color={AppColor.PrimaryColor}
                  name={'back-in-time'}
                  type={'entypo'}
                />
              }
              clearIcon={null}
              disabled={selectedSchedule}
              className={!selectedSchedule && 'custom-time-picker'}
              // style={[
              //   {
              //     backgroundColor: AppColor.SecondaryColor,
              //   },
              //   selectedSchedule && {
              //     backgroundColor: AppColor.WHITE400,
              //   },
              // ]}
            />
            <Text style={styles.label}>Start layout</Text>
            <Select
              style={styles.picker}
              selectedValue={selectedLayout}
              onValueChange={(itemValue, itemIndex) => {
                setSelectedLayout(Number(itemValue));
              }}>
              {layoutList.map((layout) => (
                <Select.Item value={layout.id} label={layout.name} />
              ))}
            </Select>
            <Text style={styles.label}>STATE</Text>
            <Select
              style={styles.picker}
              selectedValue={selectedState}
              onValueChange={(itemValue, itemIndex) =>
                setSelectedState(itemValue)
              }>
              <Select.Item label="Active" value="active" />
              <Select.Item label="Disabled" value="disabled" />
              <Select.Item label="Deleted" value="deleted" />
            </Select>
            <Text style={styles.label}>Audit Email</Text>
            <CustomInput
              value={auditEmail}
              onChangeText={(t) => setAuditEmail(t)}
              title={''}
              placeholder="Optional"
              width="100%"
            />
            <View style={{flexDirection:'row',gap:10,marginVertical:20,justifyContent:'flex-end'}}>
              <CustomButton
                title="TEST"
                outline
                onPress={handleTestSchedule}
                style={styles.btn}
                disabled={!selectedSchedule}
                width={'20%'}
              />
              <CustomButton
                title="Save"
                onPress={handleAddSchedule}
                style={styles.btn}
                width={'20%'}
              />
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};
export default ArchiveSchedule;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    // alignItems: 'center',
    alignSelf: 'center',
    padding: 30,
    height: heightPercentageToDP(100) - 110,
    overflow: 'scroll',
  },
  description: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    lineHeight: 20,
    marginTop: 15,
    marginBottom: 30,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 5,
  },
  title: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 20,
  },
  content: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 20,
    textTransform: 'capitalize',
  },
  flatContainer: {
    width: '100%',
    marginTop: 24,
    maxHeight: 250,
  },
  flatContent: {
    borderColor: AppColor.GREY200,
    borderRadius: 4,
    borderWidth: 1,
  },
  btn: {
    marginVertical: 15,
    maxWidth: 400,
  },
  addSchedule: {
    marginTop: 30,
    flex: 1,
  },
  label: {
    fontSize: 16,
    fontFamily: AppFonts.Roboto_Regular,
    marginTop: 15,
    marginBottom: 5,
    color: AppColor.PrimaryColor,
    textDecorationLine: 'underline',
    textDecorationColor: AppColor.PrimaryColor,
  },
});
