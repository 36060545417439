/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity } from 'react-native';
import AppColor from '../../Constants/Color';
import AppFonts from '../../Constants/Fonts';
import { Icon } from 'react-native-elements';
import CustomButton from '../Button/index.web';

const Search = (props) => {
  const [focused, setFocused] = React.useState(false);
  const [textValue, setTextValue] = React.useState(props?.value ?? '');

  const clearText = () => {
    setTextValue('');
    if(props.onClear) props.onClear();
    if (props.onChangeText) {
      props.onChangeText('');
    }
  }

  React.useEffect(()=>{
    if(typeof props.value === 'string') setTextValue(props.value);
  },[props.value])

  return (
    <View style={[styles.container, {...props.containerStyle}]}>
      <View
        style={[styles.searchContainer, { borderColor: focused ? AppColor.PrimaryColor : '#3F3F3F' }, { ...props.style }]}>

        <TextInput
          value={textValue}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onSubmitEditing={props.onSubmitEditing}
          onKeyPress={props.onKeyPress}
          onChangeText={(text) => {
            setTextValue(text);
            if (props.onChangeText) {
              props.onChangeText(text);
            }
          }}
          secureTextEntry={props.secure}
          placeholder={props.placeholder}
          style={[styles.searchTextInput, { border: 'none', outline: 'none' }]}
        />

        <View style={{flexDirection:'row',alignItems:'center',gap:5}}>

          {textValue.length > 0 &&
          <TouchableOpacity style={{visibility: textValue ? 'visible' : 'hidden'}} onPress={clearText}>
            <Icon name='close' type='MaterialIcon' />
          </TouchableOpacity>
          }

          <View>
            {props.icon ? props.icon :
              <Icon
              onPress={props.onPressSearch}
              color={'#3F3F3F'}
              name={'search'}
              type={'AntDesign'}
            />
      
            }
          </View>

        </View>

      </View>
      {
        props.actionButtonComponent &&
        props.actionButtonComponent
      }
      {(props.actionButton && !props.actionButtonComponent) &&

        <CustomButton
          onPress={props.onPressActionButton}
          icon={<Icon name='plus' size={18} color='white' type='feather' />}
          title={props.actionButtonTitle}
          width={'none'}
          textStyle={{ textTransform: 'none', fontSize: 16 }}
          style={{ borderRadius: 7, gap: 7, fontWeight: 100,paddingHorizontal:10 }} />

      }

    </View>
  );
};

Search.defaultProps = {
  secure: false,
  onChangeText: (t) => {
    console.log(t);
  },
  placeholder: 'Search Here',
};


const styles = StyleSheet.create({

  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
    margin: 10,
    gap: 10,
  },
  searchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 7,
    borderStyle: 'solid',
    borderWidth: 1.5,
    backgroundColor: 'white',
    padding: 10,
    flex:1,
    overflow:'hidden'
  },
  searchTextInput: {
    fontFamily: AppFonts.Roboto_Regular,
    fontSize: 16,
    flex:1
  }

});

export default Search;
