import React from "react";
import { FlatList, Modal, ScrollView, Switch, Text, TouchableOpacity, View } from "react-native";
import { Content, SideBar, Wrapper } from "../../Components/Layout";
import InfoBar from "../../Components/Layout/InfoBar";
import AppFonts from "../../Constants/Fonts";
import AppColor from "../../Constants/Color";
import Search from "../../Components/Search";
import getQuery from "../getQuery";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/index.web";
import { Icon } from "react-native-elements";
import RequestMaker from "../../Middleware/ApiCaller";
import { RemovePhoneModal } from "../../Screens/Contacts/modals";
import CustomInput from "../../Components/CustomInput/index.web";
import CustomButton from "../../Components/Button/index.web";
import SimpleBulletList from "../../Components/SimpleBulletList";
import AddPattern from "./AddPattern";
import NewAlphabetList from "../../Screens/Contacts/NewAlphabetList";
import ListHeader from "../../Components/AlphabetList/ListHeader";
import moment from "moment";
import LogDetails from "./LogDetails";
import SimpleDropdown from "../../Components/SimpleDropdown";
import FlagDetails from "./FlagDetails";
import { setAffiliate } from "../../Redux";
import { useToast } from "native-base";

const Monitoring = ({route, navigation}) => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const [search, setSearch] = React.useState('');
    const [removeModal, setRemoveModal] = React.useState(false);
    const [toDelete, setToDelete] = React.useState(null);
    const [pattern, setPattern] = React.useState(null);
    const [log, setLog] = React.useState(null);
    const [logType, setLogType] = React.useState('all');
    const [flag, setFlag] = React.useState(null);
    const dispatch = useDispatch();
    const Toast = useToast();

    const [monitoringInfo, setMonitoringInfo] = React.useState({
        monitoring: affiliate.monitoring,
        monitoringEmail: affiliate.monitoringEmail
    });

    const { data, isLoading, isError, refetch } = getQuery({
        action: 'get_monitor_patterns',
        groupId: affiliate.id
    })

    const flagged = getQuery({
        action: 'get_flagged',
        groupId: affiliate.id
    })


    const flaggedLogs = getQuery({
        action: 'get_flagged_log',
        groupId: affiliate.id
    })


    if (isLoading || flaggedLogs.isLoading || flagged.isLoading) return <Loader backgroundColor={'transparent'} />
    if (!data || !flaggedLogs.data || !flagged.data) return null;


    const renderPatterns = ({ item }) => {

        const handleDelete = () => {
            setRemoveModal(true);
            setToDelete(item);
        }

        const selectedStyle = {
            backgroundColor: pattern?.id === item.id ? '#ECF6FF' : 'transparent',
            borderColor: pattern?.id === item.id ? '#116AB4' : 'transparent',
            borderTopWidth: pattern?.id === item.id ? 1 : 0,
            borderBottomWidth: pattern?.id === item.id ? 1 : 0,
        }

        return (
            <TouchableOpacity onPress={() => {setPattern(item); setLog(null)}} style={[{ flexDirection: 'row', padding: 10 }, selectedStyle]}>
                <Icon type="antdesign" size={26} name={'filetext1'} />
                <View style={{ marginLeft: 5, gap: 5 }}>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginLeft: 5 }}>{item.name}</Text>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginLeft: 5 }}>{item.pattern}</Text>

                </View>
                <View style={{ marginLeft: 'auto', gap: 5, flexDirection: 'row' }}>
                    <Icon onPress={handleDelete} type="feather" size={22} name={'trash'} color={'red'} />
                </View>
            </TouchableOpacity>
        )
    }

    const handleSave = async () => {
        try{
        const body = {
            action: 'udpate_group_monitoring',
            group: affiliate.id,
            monitoring: monitoringInfo.monitoring,
            monitoringEmail: monitoringInfo.monitoringEmail
        }
        
        const result = await RequestMaker(body);
        if(result.data.success){
            dispatch(setAffiliate(monitoringInfo));
            Toast.show({
                status:'success',
                title:' Successfully updated monitoring settings'
            })
        } else if(result.data.errorMessage) {
            Toast.show({
                status:'error',
                title: result.data.errorMessage
            })
        }
        } catch(err){
            console.log(err);
        }
    }

    const handleDelete = async () => {
        const body = {
            action: 'delete_monitor_pattern',
            groupid: affiliate.id,
            id: toDelete.id
        }

        const result = await RequestMaker(body);
        if (result.data.success) refetch()
    }

    const renderList = ({ item }) => {
        let capitalizedMessageAction = null;

        if(item?.detail){
            const messageAction = JSON.parse(item?.detail)?.messageAction;
            capitalizedMessageAction = messageAction?.charAt(0)?.toUpperCase() + messageAction?.slice(1);
        }
        
        return (
            <TouchableOpacity onPress={() => logType === 'all' ? setFlag(item) : setLog(item)} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
                <View style={{ width: '25%',paddingRight:10 }}>
                    <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                        {item?.tenantName || item.account}

                    </Text>
                </View>
                <View style={{ width: '25%',paddingRight:15 }}>

                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                        {moment(item.date).format('MM/DD/YYYY')}

                    </Text>

                </View>
                <View style={{ width: '25%' }}>
                <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                    {item.pattern}
                </Text>
                </View>

                <View style={{ width: '25%' }}>
                <Text style={{ textAlign: 'center', fontFamily: AppFonts.Roboto_Regular, backgroundColor: AppColor.PrimaryColor, padding: 2, borderRadius: 2, color: 'white' }}>
                        {capitalizedMessageAction || 'Pending Review'}

                    </Text>

                </View>
            </TouchableOpacity>
        )
    }

    return (
        <Wrapper>
            <SideBar>
            {route.params?.reseller &&
                    <View style={{ flexDirection: 'row', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,.25)' }}>
                        <Icon style={{ padding: 10 }} onPress={() => navigation.pop()} name='chevron-left' type={'ionicons'} size={24} />
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{affiliate.name}</Text>
                    </View>
                }

                <Search
                    onChangeText={val => setSearch(val)}
                    actionButton
                    onPressActionButton={() => {
                        setPattern({
                            name: '',
                            pattern: '',
                            holdMessage: false,
                            rejectMessage: false
                        })
                    }

                    }
                    actionButtonTitle={'Pattern'}
                />
                <FlatList
                    data={data.patterns.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))}
                    renderItem={renderPatterns}
                />
            </SideBar>
            <Content>
                <Content.Row style={{ height: '100%' }}>
                    <ScrollView style={{ flex: 2, height: '100%', padding: 15 }}>
                        {
                            flag !== null &&
                            <View style={{flex:1}}>
                            <Icon onPress={() => setFlag(null)} type="ionicon" size={26} name={'chevron-back'} style={{ alignSelf: 'flex-start', marginBottom: 10 }} />
                            <FlagDetails onSave={() => {
                                setFlag(null);
                                flagged.refetch();
                                flaggedLogs.refetch();
                            }} flag={flag} />
                            </View>
                        }

                        {
                            log !== null &&
                            <View style={{flex:1}}>
                            <Icon onPress={() => setLog(null)} type="ionicon" size={26} name={'chevron-back'} style={{ alignSelf: 'flex-start', marginBottom: 10 }} />
                            <LogDetails log={log} />
                            </View>
                        }


                        {
                            pattern !== null &&
                            <>
                                <Icon onPress={() => setPattern(null)} type="ionicon" size={26} name={'chevron-back'} style={{ alignSelf: 'flex-start', marginBottom: 10 }} />
                                <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginBottom: 10 }}>
                                    Use regular expressions to determine if a message matches a pattern that you are concerned with. When creating patterns to use for monitoring, you can use Regex 101 to test out your regular expression patterns.
                                    {'\n'}{'\n'}
                                    Whenever a message pattern is matched on an incoming or outgoing message, you will be notified via email and the message will be:
                                </Text>
                                <SimpleBulletList style={{ paddingLeft: 15 }} data={['Delivered normally - Hold Message off and reject message off', 'Received and held - Hold message on and reject message off', 'Rejected - Reject message on, hold message ignored']} />
                                <Text style={{ fontFamily: AppFonts.Roboto_Regular, marginTop: 10, marginBottom: 20 }}>
                                    When a message is being held, an administrator will need to come here and review the message, then decide to either release or reject the message from the Matches.
                                </Text>
                                <AddPattern onSave={() => {
                                    refetch()
                                    setPattern(null)
                                }} pattern={pattern} />

                            </>
                        }

                        {
                            pattern === null && flaggedLogs.data !== null && log === null && flag === null &&
                            <>
                            <SimpleDropdown value={logType} onChange={val => setLogType(val)} data={[{label: 'All Matches', value: 'all'}, {label: 'Reviewed', value: 'reviewed'}]}  />
                            <NewAlphabetList
                                hideShortcuts={true}
                                sortBy={logType === 'all' ? 'account':'tenantName'}
                                data={ logType === 'all' ? flagged.data.flagged : flaggedLogs.data.audits}
                                RenderItem={renderList}
                                ListHeaderComponent={() => 
                                <ListHeader
                                textStyle={{paddingLeft:0}}
                                columns={['Account', 'Date', 'Pattern', 'Status']}
                                />}
                            />
                            </>

                        }

                    </ScrollView>

                    <InfoBar>
                        <Text style={{ fontFamily: AppFonts.Roboto_Bold, color: AppColor.PrimaryColor, marginBottom: 10 }}>Monitoring Guide</Text>
                        <Text style={{ fontFamily: AppFonts.Roboto_Regular }}>
                            Monitoring the accounts that you manage can be tedious process.
                            To make it easier we have built a monitoring tool that you can use to alert you when a message contains information that you might be interested in, like PII, and allows you to examine the contents of the message to assure adherence to your policies.
                        </Text>
                        <View style={{borderTopWidth:1,borderTopColor:'rgba(0,0,0,.5)',marginTop:20,paddingTop:10}}>
                        <Text style={{ marginTop: 10 }}>Enable Monitoring</Text>
                        <Switch value={monitoringInfo.monitoring} onValueChange={val => setMonitoringInfo({...monitoringInfo, monitoring: val})} style={{ marginTop: 5 }} />
                        <CustomInput value={monitoringInfo.monitoringEmail} onChangeText={val => setMonitoringInfo({...monitoringInfo, monitoringEmail: val})} titleStyle={{ marginTop: 10 }} title="Alert Email Address" />
                        <CustomButton onPress={handleSave} title={'Save'} width={'100%'} style={{ alignSelf: 'flex-end', marginTop: 10 }} />
                        </View>
                    </InfoBar>
                </Content.Row>
            </Content>
            {
                removeModal &&
                <Modal style={{ position: 'absolute', height: '100%', width: '100%' }} visible={removeModal} transparent={true}>
                    <RemovePhoneModal
                        title="Delete Pattern"
                        message="Are you sure you'd like to permanently delete this monitoring pattern?"
                        handleRemove={() => {
                            handleDelete(toDelete.id);
                            setRemoveModal(false);
                        }}
                        setRemoveModal={setRemoveModal}
                    />
                </Modal>
            }

        </Wrapper>
    )
}

export default Monitoring;