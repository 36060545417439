import React from 'react';
import { formatNumber } from '../Middleware/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'native-base';
import { Modal, Text, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import AppFonts from '../Constants/Fonts';
import SwitchAccountModal from './SwitchAccountModal';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { clearProfile } from '../Redux';
import { RemovePhoneModal } from '../Screens/Contacts/modals';

const AccountsDropDownMenu = (props) => {
    const number = useSelector((state) => state.account_health)?.mrcNumber;
    const formattedNumber = formatNumber(number?.value);
    const user = useSelector((state) => state.User.profile)?.user;
    const dispatch = useDispatch();
    const tenant = useSelector((state) => state.User.profile)?.tenant;
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigation = useNavigation();

    let contact_name = '';
    let accounts = user?.accounts ?? [];
    for (let x of accounts) {
         if (x.id == tenant?.id) {
            contact_name = x.contact
        } 
    }

    if(user?.permissions[0]?.label !== 'MyRepChat User'){
        contact_name = 'Administrator';
    }

    return (
        <Menu style={{maxHeight:'80vh', overflowY:'scroll'}} placement='bottom' w="190" trigger={triggerProps => {
            return (
                <TouchableOpacity
                    onPress={e => user?.accounts.length > 1 ? triggerProps.onPress() : e.preventDefault()}
                    {...triggerProps}
                    style={{ flexDirection: 'row', gap: 20, marginVertical: 20, alignItems: 'center' }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flex: 1,
                        }}>
                        <View
                            style={{
                                justifyContent: 'center',
                                alignItems: 'flex-end',
                            }}>
                            <Text
                                style={{
                                    color: 'white',
                                    fontWeight: 600,
                                    marginBottom: 5,
                                    fontSize: 16
                                }}>
                                {contact_name}
                            </Text>
                            <Text
                                style={{
                                    color: 'white',
                                    fontFamily: AppFonts.Roboto_Regular,
                                }}>
                                {formattedNumber}
                            </Text>
                        </View>
                        <View
                            style={{
                                justifyContent: 'center',
                                alignSelf: 'center',

                                marginLeft: 10,
                            }}>

                        </View>
                        <Icon
                            size={32}
                            color={'white'}
                            name={'user-circle-o'}
                            type={'font-awesome'}
                        />

                        <Icon
                            style={{ marginLeft: 15 }}
                            size={15}
                            color={'white'}
                            name={'angle-down'}
                            type={'font-awesome-5'}
                        />


                    </View>

                </TouchableOpacity>
            )
        }}>

            <SwitchAccountModal
                user={user}
                tenant={tenant}
                dispatch={dispatch}
                visible={visible}
                setVisible={setVisible}
                setLoading={setLoading}
            />

            <Menu.Item style={{ cursor:'pointer', alignItems: 'center', flexDirection: 'row', gap: 10 }} onPress={props.handleLogout}>
                <Icon name='log-out-outline' type='ionicon' />
                <Text>Log Out</Text>
            </Menu.Item>
        </Menu>
    )
}

export default AccountsDropDownMenu;