import React from "react";
import { Text } from "react-native";
import AppFonts from "../../Constants/Fonts";
import CustomButton from "../../Components/Button/index.web";
import RequestMaker from "../../Middleware/ApiCaller";
import { useSelector } from "react-redux";
import { useToast } from "native-base";

const UploadFile = () => {
    const inputRef = React.useRef(null);
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const Toast = useToast();

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;
        try{
        if (files.length > 0) {
            const file = files[0];
            const base64File = await getBase64(file);

            const body = {
                action: "upload_file",
                file: base64File,
                name: file.name,
                affiliate: affiliate.id,
            };

            const result = await RequestMaker(body);
            Toast.show({status:'success',title:'File Uploaded Successfully'})

        }
        } catch(err){
            Toast.show({status:'error',title:'Error Uploading File'})
        }
    };


    return(
        <>
        <Text style={{fontFamily:AppFonts.Roboto_Bold,marginVertical:20}}>
            Secure File Upload
        </Text>

        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
            From here you can upload CSV files securely to the MRC servers (NOTE: 25mb file size limit)
        </Text>

        <CustomButton
        onPress={() => {inputRef.current.click()}}
        title='Upload CSV'
        style={{alignSelf:'flex-start',marginTop:20}}
        width={'20%'}
        />
		<input onChange={handleFileChange} ref={inputRef} accept="text/csv" type="file" style={{display:'none'}} >
        </input>
        </>
    )
}

export default UploadFile;