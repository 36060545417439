import React from 'react';
import { Text, View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import Modal from 'modal-react-native-web';
import Emojis from './emojiList.json';
import Search from '../../Search';
import { Icon } from 'react-native-elements';
import AppFonts from '../../../Constants/Fonts';

const EmojisModal = React.memo(props => {
    const [search, setSearch] = React.useState('');
    const [emojis, setEmojis] = React.useState([]);

    React.useEffect(()=>{
        const result = {};

        Emojis.forEach(e => {
            if(!result[e.category]){
                result[e.category] = [{emoji: e.emoji, tags: e.tags}]
            } else {
                result[e.category].push({emoji:e.emoji, tags: e.tags})
            }
        })

        setEmojis(result);
    },[])
    
    
    console.log('reeee')
    return(
        <Modal
        animationIn={'fadeIn'}
        animationOut={'fadeOut'}
        transparent={true}
        visible={props.visible}
        onDismiss={() => {
          props.setVisible(false);
        }}>
        <View
        style={{position:'absolute',width:'100vw',height:'100vh',justifyContent:'center',alignItems:'center'}}>
            <View style={{padding: 30,
            backgroundColor:'white',
            position:'relative',
            width: '50%',
            height:'50%',
            justifyContent:'center',
            alignItems:'center',
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,
            pointerEvents:'box',
            elevation: 5,
        
            }}>
                <View style={{flexDirection:'column',width:'100%',alignItems:'flex-end'}}>
                <Icon 
                type='Ionicons'
                name="close"
                size={30}
                onPress={()=>{props.setVisible(false)}}
                style={{alignSelf:'flex-end'}}
                />
                </View>
            <ScrollView style={{flex:1}}>
                {
                    Object.keys(emojis).map(e => {
                        return(<>
                            <Text style={{marginVertical:15,fontFamily:AppFonts.Roboto_Regular,fontSize:18}}>{e}</Text>
                            <View style={{flexDirection:'row',maxWidth:'100%',flexWrap:'wrap'}}>
                            {
                                emojis[e].map(e => {
                                    return(
                                        <Text onPress={() => props.onPressEmoji(e.emoji)} style={{fontSize:28,margin:3}}>{e.emoji}</Text>
                                    )
                                })
                            }
                            </View>
                            </>
                        )
                    })
                }
                </ScrollView>
            </View>
            </View>
        </Modal>
    )
})

export default EmojisModal;
