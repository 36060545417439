import Clipboard from '@react-native-clipboard/clipboard';
import Modal from 'modal-react-native-web';
import React from 'react';
import { ScrollView, Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';

const CopyMessageTextModal = ({ text, modal, setModal }) => {
    return (
        <Modal
            useNativeDriver={true}
            visible={modal}
            onBackdropPress={() => setModal(false)}>
            <ScrollView style={{ backgroundColor: 'white', marginHorizontal: 10 }}>
                <Text
                    style={{
                        fontSize: 20,
                        textAlign: 'center',
                        borderBottomWidth: 0.5,
                        borderBottomColor: AppColor.Dark,
                        paddingBottom: 10,
                        marginVertical: 10,
                    }}>
                    Message Options
                </Text>
                <Text
                    style={{
                        fontSize: 20,
                        textAlign: 'center',
                        borderBottomWidth: 0.5,
                        borderBottomColor: AppColor.Dark,
                        paddingBottom: 10,
                        marginVertical: 10,
                    }}>{text}</Text>
                <TouchableOpacity
                    accessible={true}
                    accessibilityLabel='Copy message text'
                    onPress={() => {
                        setModal(false);
                        Clipboard.setString(text);
                    }}
                    style={{
                        paddingVertical: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                    <Icon type="MaterialIcons" name="content-copy" />
                    <Text style={{ fontSize: 15, fontFamily: AppFonts.Roboto_Regular }}>
                        Copy message text
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    accessible={true}
                    accessibilityLabel='Copy message text'
                    onPress={() => {
                        setModal(false);
                    }}
                    style={{
                        paddingVertical: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}>
                    <Text style={{ fontSize: 15, fontFamily: AppFonts.Roboto_Regular }}>
                        Cancel
                    </Text>
                </TouchableOpacity>
            </ScrollView>
        </Modal>

    )
}

export default CopyMessageTextModal;