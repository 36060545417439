import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

import AppFonts from '../../Constants/Fonts';
import AppColor from '../../Constants/Color';
import moment from 'moment';

const VoiceMail = ({ item, onPress }) => {
  return (
    <TouchableOpacity onPress={() => onPress(item)}>
      <View style={styles.switchWrapper}>

        <View style={{width:'33%',alignItems:'center'}}>
          <Text style={styles.label}>{item.type}</Text>
        </View>

        <View style={{width:'33%',alignItems:'center'}}>
          <Text style={styles.label}>{moment(item.created).format('MM/DD/YYYY')}</Text>
        </View>

        <View style={{width:'33%',alignItems:'center'}}>
          <Text style={styles.label}>{item.duration} seconds</Text>
        </View>

      </View>
    </TouchableOpacity>
  );
};

export default VoiceMail;

const styles = StyleSheet.create({
  switchWrapper: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    width: '100%',
  },
  label: {
    fontFamily: AppFonts.Roboto_Bold,
    fontSize: 14,
    color: AppColor.PrimaryColor,
  },
});
