import { createSlice } from '@reduxjs/toolkit';
import getData from '../AsyncUtils/GetData';
import End_Points from '../Constants/Api';
import RequestMaker from '../Middleware/ApiCaller';

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    providers: [],
    integrations: [],
  },
  reducers: {
    setIntegrationProviders: (state, action) => {
      return {
        ...state,
        providers: action.payload.providers,
      };
    },
    setIntegrations: (state, action) => {
      return {
        ...state,
        integrations: action.payload.integrations,
      };
    },
  },
});

export const { setIntegrationProviders, setIntegrations } =
  integrationsSlice.actions;

export const loadIntegrationProviderThuunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_integration_providers',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setIntegrationProviders({ providers: result.data.providers }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const loadIntegrationsThuunk = () => {
  return async (dispatch) => {
    try {
      const body = {
        action: 'get_integrations',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      let result = await RequestMaker(Config);
      if (result.data.success) {
        dispatch(setIntegrations({ integrations: result.data.integrations }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export default integrationsSlice.reducer;
