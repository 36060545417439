import { useEffect, useState } from "react";

export const useGoogleAnalytics = (url) => {
  const [state, setState] = useState(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      if (e.type === "load") {
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-ZFZTQC1E61');

        setState("ready");
      } else {
        setState("error");
      }
    };

    if (!script) {
      script = document.createElement("script");
      script.async = true;
      script.src = url;
      script.addEventListener("load", handleScript);
      script.addEventListener("error", handleScript);

      document.head.appendChild(script);
    }

    return () => {
      script.removeEventListener("load", handleScript);
      script.removeEventListener("error", handleScript);
    };

  }, [url]);

  return state;
};