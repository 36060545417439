import React, { useEffect, useState, useCallback } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToast, Select } from 'native-base';
import { useFocusEffect } from '@react-navigation/core';
import Alert from 'Alert';

import Header from '../../../Components/Header';
import CustomButton from '../../../Components/Button';
import Loader from '../../../Components/Loader';
import AppColor from '../../../Constants/Color';
import AppFonts from '../../../Constants/Fonts';
import AddPhoneModal from './AddPhoneModal';
import getData from '../../../AsyncUtils/GetData';
import End_Points from '../../../Constants/Api/index';
import RequestMaker from '../../../Middleware/ApiCaller';
import { loadAccountHealthThunk } from '../../../Redux/account_health';

const VoiceNumber = ({ navigation }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phones, setPhones] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState(null);
  const tenant = useSelector((state) => state.User.profile)?.tenant;
  const account_health = useSelector((state) => state.account_health);
  const Toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetPhone();
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (tenant?.id) {
        dispatch(loadAccountHealthThunk(tenant?.id));
      }
    }, [tenant])
  );

  useEffect(() => {
    if (account_health?.voice?.value) {
      setSelectedPhone(account_health.voice.value);
    }
  }, [account_health]);

  const handleSave = async () => {
    if (!selectedPhone) {
      Alert.alert('Error', 'Please select Phone number');
      return;
    }
    try {
      let body = {
        action: 'set_forward',
        countryCode: '1',
        phone: selectedPhone,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Voice Number Saved',
          status: 'success',
        });
        handleGetPhone();
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedPhone) {
      Alert.alert('Error', 'Please select Phone number');
      return;
    }
    try {
      let body = {
        action: 'delete_forwarding_phone',
        countryCode: '1',
        phone: selectedPhone,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      setLoading(true);
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        Toast.show({
          duration: 2000,
          title: 'Voice Number is removed',
          status: 'success',
        });
        handleGetPhone();
      } else {
        Toast.show({
          duration: 2000,
          title: data.errorMessage,
          status: 'error',
        });
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetPhone = async () => {
    setLoading(true);
    try {
      let body = {
        action: 'get_forwarding_phones',
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
      };
      let { data } = await RequestMaker(Config);
      setLoading(false);
      if (data.success) {
        setPhones(data.phones);
      } else {
        Alert.alert('Error', data.errorMessage);
      }
    } catch (err) {
      setLoading(false);
      Alert.alert('Error', err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <View style={styles.content}>
          <View style={styles.body}>
            <Text style={styles.comment}>
            This number will be used to bridge all incoming and outgoing calls to and from your MyRepChat number.  {'\n'}
          You can change this forwarding number at any time.  {'\n'}
          This number will be the phone that rings when you receive an incoming call to your MyRepChat number.  {'\n'}
          When you place an outgoing call from MyRepchat this will be the number that rings, you answer, and the call will be placed to the contact.

            </Text>
            <View style={styles.forms}>
              <Text style={styles.formLabel}>voice number</Text>
              <Select
                selectedValue={selectedPhone}
                minWidth={200}
                placeholder="Choose Phone"
                mt={1}
                onValueChange={(itemValue) => setSelectedPhone(itemValue)}>
                {phones.map((phone) => (
                  <Select.Item label={phone} value={phone} key={phone} />
                ))}
              </Select>
              <View style={{flexDirection:'row',alignItems:'center',gap:10,justifyContent:'flex-end'}}>
              <CustomButton
                  width={'20%'}
                  outline
                  title="remove"
                  onPress={handleDelete}
                  disabled={!selectedPhone}
                />
                <CustomButton
                width={'20%'}
                outline
                title="add" 
                onPress={() => setVisible(true)} />
                
                <CustomButton
                  width={'20%'}
                  title="save"
                  onPress={handleSave}
                  disabled={!selectedPhone}
                />
                </View>
            </View>
          </View>
        </View>

        {loading && <Loader />}
      </SafeAreaView>
      <AddPhoneModal
        visible={visible}
        setVisible={setVisible}
        handleGetPhone={handleGetPhone}
      />
    </>
  );
};

export default VoiceNumber;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.SecondaryColor,
  },
  content: {
    width: '100%',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Regular,
  },
  header: {
    alignSelf: 'center',
  },
  body: {
    maxWidth: 768,
    marginTop: 20,
    padding: 20,
  },
  comment: {
    fontSize: 14,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
  forms: {
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: '#d5e2f5',
    padding: 20,
    marginTop: 20,
  },
  formLabel: {
    color: '#2666b4',
    fontSize: 18,
    fontFamily: AppFonts.Roboto_Regular,
    textAlign: 'center',
  },
  button: {
    marginVertical: 5,
  },
  modal: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  picker: {
    width: '100%',
    borderRadius: 5,
    borderWidth: 1,
    marginVertical: 10,
  },
});
