import React from "react";
import { useSelector } from "react-redux";
import {getUserAgent} from 'react-native-device-info';
import { analytics } from "../../App";

const useLogEvent = () => {
    const profile = useSelector((state) => state.User.profile);

    const logEvent = async (event = '', params = {}) => {
        const userAgent = await getUserAgent()

        if (analytics) {
            analytics.logEvent(event,{
                userId: profile?.user?.user_id,
                affiliateName: profile?.affiliate?.name,
                userAgent: userAgent,
                ...params
            })
        }

    }

    return logEvent;

}

export default useLogEvent;