import React, { useState } from 'react';
import { Text, View, Keyboard, ScrollView } from 'react-native';
import Alert from 'Alert';
import Modal from 'modal-react-native-web';
import { useDispatch } from 'react-redux';

import CustomButton from '../../../Components/Button';
import RequestMaker from '../../../Middleware/ApiCaller';
import End_Points from '../../../Constants/Api/index';
import CustomInput from '../../../Components/CustomInput';

const AddPhoneModal = ({ visible, setVisible, handleSave, mobile }) => {
  const [phone, setPhone] = React.useState('');
  const [isVerified, setIsVerified] = React.useState(false);
  const [verficationSent, setVerficationSent] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const [type, setType] = React.useState('sms');
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (mobile) {
      setPhone(mobile);
    }
  }, [mobile]);

  const sendPhoneCode = async (sms) => {
    Keyboard.dismiss();
    let body = {
      action: 'verify_phone',
      phone: phone,
      sms: sms,
    };
    let Config = {
      method: 'post',
      url: End_Points.Base_url + End_Points.End,
      data: body,
    };
    // setLoading(true)
    let result = await RequestMaker(Config);
    if (result.data.success) {
      if (!result.data.verified) {
        setVerficationSent(true);
        if (sms) {
          setType('sms');
          Alert.alert(
            'Important',
            'Heads up! We just sent a text message with a verification code to ' +
              phone +
              '. Please get the code from the text and enter it into the verification code field to continue.'
          );
        } else {
          setType('call');
          Alert.alert(
            'Important',
            'Heads up! You should receive a call in the next few seconds, get your code from the message and enter it into the verification code field to continue.'
          );
        }
      } else {
        setVerficationSent(true);
        setIsVerified(true);
        Alert.alert(
          'Important',
          'Great News! This phone number has already been verified. Click continue to proceed to the next step.'
        );
      }
    } else {
      Alert.alert('Error', result.data.errorMessage);
    }
    // setLoading(false)
    // console.log(result)
  };

  const handleContinue = async () => {
    // testing
    //   setVisible(false)
    //   navigation.navigate("GroupSetup")
    //   dispatch(setSignUpData({
    //       data: {
    //           phone: {
    //               phone: phone,
    //               countryCode: countries[countrySelected].countryCode
    //           }
    //       }
    //   }))

    if (!isVerified) {
      let body = {
        action: 'verify_phone',
        phone: phone,
        code: otp,
      };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
      };
      // setLoading(true)
      let result = await RequestMaker(Config);
      console.log(result);
      if (result.data.success) {
        if (result.data.verified) {
          setIsVerified(true);
          setVisible(false);
          handleSave(phone);
        }
      } else {
        Alert.alert('Error', result.data.errorMessage);
      }
    } else {
      setVisible(false);
      handleSave(phone);
    }
  };
  return (
    <Modal
      animationIn={'fadeIn'}
      useNativeDriver={true}
      animationOut={'fadeOut'}
      backdropOpacity={0.9}
      visible={visible}
      transparent={true}
      onBackdropPress={() => setVisible(false)}
      onDismiss={() => {
        setVisible(false);
      }}
      ariaHideApp={false}>
      <View
        style={{
          flex: 1,
          backgroundColor: '#0007',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: '100%',
            maxWidth: 767,
            borderRadius: 8,
            backgroundColor: 'white',
            alignSelf: 'center',
            padding: 30,
          }}>
          <ScrollView>
            <Text style={{ textAlign: 'center', fontSize: 20 }}>
              Verify Phone
            </Text>
            <View style={{ marginHorizontal: 20 }} />
            <Text style={{ margin: 10, fontSize: 15 }}>
              We must verify that this phone number is accessible.
            </Text>
            <Text style={{ margin: 10, fontSize: 15 }}>
              + Enter a phone number that can receive a text or call
            </Text>
            <Text style={{ margin: 10, fontSize: 15 }}>
              + Retrieve the code provided on the text or call
            </Text>
            <Text style={{ margin: 10, fontSize: 15 }}>
              + Verify by entering code and selecting continue
            </Text>
            <CustomInput
              keyboardType={'phone-pad'}
              value={phone}
              onChangeText={(t) => setPhone(t)}
              title={''}
              placeholder="Phone Number Only"
              textInputStyle={{ paddingHorizontal: 5 }}
              disabled={verficationSent}
            />
            {verficationSent && (
              <View>
                {!isVerified && (
                  <CustomInput
                    value={otp}
                    onChangeText={(t) => setOtp(t)}
                    title={''}
                    placeholder="Verfication Code"
                    textInputStyle={{ paddingHorizontal: 5 }}
                  />
                )}
                <CustomButton
                  style={{ marginTop: 10 }}
                  title={'Change Number'}
                  onPress={() => {
                    setIsVerified(false);
                    setVerficationSent(false);
                  }}
                />
                {!isVerified && (
                  <CustomButton
                    style={{ marginTop: 10 }}
                    title={'Resend Code'}
                    onPress={() => {
                      if (type == 'sms') {
                        sendPhoneCode(true);
                      } else {
                        sendPhoneCode(false);
                      }
                    }}
                  />
                )}
              </View>
            )}
          </ScrollView>
          {!verficationSent ? (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 5,
              }}>
              <CustomButton
                style={{ width: '30%' }}
                title="Call"
                onPress={() => {
                  sendPhoneCode(false);
                }}
              />
              <CustomButton
                style={{ width: '30%', marginVertical: 10 }}
                title="Text"
                onPress={() => {
                  sendPhoneCode(true);
                }}
              />
              <CustomButton
                style={{ width: '30%' }}
                title="Cancel"
                onPress={() => setVisible(false)}
              />
            </View>
          ) : (
            <View style={{ marginTop: 10 }}>
              <CustomButton title="Continue" onPress={() => handleContinue()} />
              <CustomButton
                style={{ marginTop: 10 }}
                title="Cancel"
                onPress={() => setVisible(false)}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default AddPhoneModal;
