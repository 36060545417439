import React from "react";
import { Switch, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import useUpdateAffiliate from "../useUpdateAffiliate";
import CustomButton from "../../Components/Button/index.web";

const AddOnManagement = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;
    const updateAffiliate = useUpdateAffiliate();

    const [data, setData] = React.useState({
        displayAddonAmounts: affiliate?.displayAddonAmounts || false
    })

    return (
        <View style={{flex:1}}>
            <Text style={{marginTop:10}}>Control whether or not you want the user to see the add-on amount, Note that users are more likely to enable add-ons if they do not realize what the cost is that is associated with the add-on.</Text>
            <Text style={{ fontFamily: AppFonts.Roboto_Bold, marginTop: 10 }}>Display Add-On Amounts</Text>
            <Switch
                onValueChange={e => setData({ ...data, displayAddonAmounts: e })}
                value={data.displayAddonAmounts}
                style={{marginTop:10}}
            />
            <CustomButton
                onPress={() => updateAffiliate(data)}
                width={'30%'}
                title={'Save'}
                style={{ marginTop: 'auto', alignSelf: 'flex-end', marginBottom:10,marginRight:10 }}
            />
        </View>
    )
}

export default AddOnManagement;