import getData from "../../AsyncUtils/GetData";
import End_Points from "../../Constants/Api";
import axios from "axios";

export const deleteItem = async id => {

    const body = {
        action: 'delete_member',
        id: id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };

    return axios(Config);

}

export const callContact = async phoneNumber => {
    const body = {
        action: 'initiate_call',
        toNumber: phoneNumber,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };

    return axios(Config);

}

export const loadMemberNumbers = async id => {
    const body = {
        action: 'get_member_phone_numbers',
        member: id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };
      return axios(Config);
}

export const loadReconcileMember = async id => {
    const body = {
        action: 'reconcile_member',
        id: id,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };

      return axios(Config);
}

export const reconcileMember = async params => {
    const body = {
        action: 'update_member',
        ...params,
      };
      
      let device = await getData('@device_id');
      const headers = { 'x-device': device };
      let Config = {
        method: 'post',
        url: End_Points.Base_url + End_Points.End,
        data: body,
        headers: headers,
        redirect: 'follow',
      };

      return axios(Config);
}