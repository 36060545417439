import React from "react";
import { ScrollView, Text, View } from "react-native";
import AppFonts from "../../Constants/Fonts";
import Loader from "../../Components/Loader/index.web";
import AppColor from "../../Constants/Color";
import { Icon } from "react-native-elements";
import getQuery from "../getQuery";
import RequestMaker from "../../Middleware/ApiCaller";

const Integrations = ({tenant}) => {
    const Tenant = tenant.data.tenant;
    const {data, isLoading, isError, refetch} = getQuery({
        action: 'get_integrations',
        tenant: Tenant.id
    })

    if(isLoading) return <Loader backgroundColor="transparent"/>
    if(!data) return null; 

    const handleDelete = async id => {
        const body = {
            action: 'delete_integration',
            id: id,
            tenant: Tenant.id
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            refetch();
        }
    }

    return(
        <ScrollView style={{flex:1}}>
        <Text style={{fontFamily:AppFonts.Roboto_Regular}}>
            Here you can view the integrations that this account has configured.
        </Text>
        {
            data.integrations.map(e => {
                return(
                    <View style={{flexDirection:'row',alignItems:'center',marginTop:10,justifyContent:'space-between'}}>
                    <Text style={{fontFamily:AppFonts.Roboto_Regular, color: AppColor.BLUE100}}>
                        {`${e.provider}/${e.type}`}
                    </Text>
                    <Icon onPress={() => handleDelete(e.id)} name='trash' type="font-awesome" color={AppColor.danger} size={20} />
                    </View>
                )
            })
        }
        </ScrollView>
    )
}

export default Integrations;