/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text, Switch } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import AppFont from '../../../../Constants/Fonts';
import AppColor from '../../../../Constants/Color';
import { setAttributes } from '../../../../Redux/integrationData';

export default function IntegrationComponent({ provider, integration }) {
  const dispatch = useDispatch();
  const [inte, setIntegration] = React.useState({
    enabled: false,
  });
  const { attributes } = useSelector((state) => state.integrationData);
  React.useEffect(() => {
    if (integration != null && integration) {
      setIntegration(integration);
    }
  }, [integration]);

  return (
    <View style={{ marginBottom: 10 }}>
      <View
        style={{
          flexDirection: 'row',
          marginHorizontal: 20,
          alignItems: 'center',
          marginVertical: 10,
        }}>
        <Text
          style={{
            marginRight: 5,
            fontSize: 18,
            color: AppColor.Black200,
            fontFamily: AppFont.Roboto_Regular,
          }}>
          ACTIVATE ARCHIVING
        </Text>
        <Switch
          value={inte.enabled}
          trackColor={{ false: 'red', true: 'green' }}
          onValueChange={(v) => {
            setIntegration((state) => ({ ...state, enabled: v }));
            dispatch(
              setAttributes({
                ...attributes,
                active: v,
                enabled: v
              }),
            );
          }}
        />
      </View>
    </View>
  );
}
