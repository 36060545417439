import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import AppFonts from "../Constants/Fonts";
import CustomButton from "./Button/index.web";
import { Icon } from "react-native-elements";
import AppColor from "../Constants/Color";

const ComponentAccordion = ({ columns, data, renderComponent, renderListItem, allowAdd }) => {
    const width = 100 / columns.length;
    const [selected, setSelected] = React.useState(null);
    const [addingNew, setAddingNew] = React.useState(false);
    const renderPreview = (item) => {
        const arr = renderListItem(item);
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center',paddingVertical: 10 }}>
                {arr.map(e => {

                    if(React.isValidElement(e)) return <View style={{width:`${width}%`}}>{e}</View>;
                    return <View style={{width:`${width}%`}}><Text style={{ fontFamily: AppFonts.Roboto_Regular }}>{String(e).length ? String(e) : '---'}</Text></View>
                }
            )}
            </View>
        )
    }

 
    
    return (
        <View>
            <View style={styles.columns}>
                {columns.map(e => <Text style={{ fontFamily: AppFonts.Roboto_Bold, width: `${width}%` }}>{e}</Text>)}
            </View>
            {
                data && data.map((item, index) => {
                    return (
                        <>
                            <TouchableOpacity 
                            disabled={!renderComponent}
                            onPress={() => {
                                setSelected(selected === item ? null : item)
                                setAddingNew(false)
                            }}>
                                {
                                renderPreview(item)
                                }

                            </TouchableOpacity>

                            {selected === item &&
                                <View style={{ backgroundColor: '#f1f1f1', padding: 10 }}>
                                    {renderComponent(item)}
                                </View>
                            }
                        </>
                    )
                })
            }

            {addingNew &&
                <View style={{ backgroundColor: '#f1f1f1', padding: 10 }}>
                    {renderComponent(null)}
                </View>
            }

            {
                allowAdd &&
                <CustomButton 
                onPress={() => {
                    if(!addingNew) setSelected(null);
                    setAddingNew(!addingNew)
                }} textStyle={{ fontSize: 16 }} icon={
                    <Icon name={addingNew ? 'close': 'add'} type={'ionicons'} size={16} color={AppColor.PrimaryColor} />
                } title={addingNew ? 'Cancel': 'Add'} outline style={{ height: 30, alignSelf: 'flex-start', marginTop: 20 }} width={'15%'} />
            }

        </View>
    )
}

const styles = StyleSheet.create({
    columns: {
        flexDirection: 'row',
        alignItems: 'center'
    }
})

export default ComponentAccordion;