import React from "react";
import { ScrollView, Text, View, Switch } from "react-native";
import AppFonts from "../../Constants/Fonts";
import { useSelector } from "react-redux";
import Loader from "../../Components/Loader/index.web";
import getQuery from "../getQuery";
import RequestMaker from "../../Middleware/ApiCaller";

const IntegrationProviders = () => {
    const affiliate = useSelector((state) => state.User.profile)?.affiliate;

    const { refetch, data, isLoading, isError } = getQuery({
        action: 'get_integration_providers',
        affiliateId: affiliate.id
    })

    if(isLoading) return <Loader backgroundColor={'transparent'}/>

    if(!data) return null;

    const handleChange = async (name) => {
        let providersArr = [];

        if(data.providers.includes(name)){
            const providers = data.providers.filter(e => e !== name);
            providersArr = providers;
        } else {
            const providers = [
                ...data.providers,
                name
            ]
            providersArr = providers;
        }

        const body = {
            action: 'update_integration_providers',
            affiliateId: affiliate.id,
            providers: providersArr
        }
        const result = await RequestMaker(body);
        if(result.data.success){
            refetch();
        }

    }

    return(
        <ScrollView>
            <Text style={{fontFamily:AppFonts.Roboto_Regular,marginTop:10}}>
                This is optional. Some companies want to hide integration providers that don't apply for their accounts to streamline configuration. Here you can show/hide integrations that will available for the accounts.
            </Text> 
            {data.all.map(e => {
                const isActive = data.providers.includes(e.name);
                
                return(
                    <View style={{flexDirection:'row',alignItems:'center',gap:10,padding:10}}>
                        <Text>{e.label}</Text>
                        <Text>{e.type}</Text>
                        <Switch onValueChange={() => handleChange(e.name)} value={isActive}  />
                    </View>
                )
            })}


        </ScrollView>
    )
}

export default IntegrationProviders;